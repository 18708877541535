import { Injectable, Inject, EventEmitter } from '@angular/core';
import { TRANSLATIONS, TRANSLATIONS_MONTH, TRANSLATIONS_DAY, TRANSLATIONS_AG_GRID,
                                TRANSLATIONS_FMT, SUPPORTED_LANGS, LangSupported } from '../i18n/translation'; // import our opaque token
import I18N_FR from '../../assets/i18n/countries_FR.json';
import I18N_EN from '../../assets/i18n/countries_EN.json';

@Injectable()
export class TranslateService {

    private PLACEHOLDER = '%'; // our placeholder
    private _defaultLang: string;
    private _currentLang: string;
    private _fallback: boolean;
    private supportedLangs: any[];

    public get currentLang() {
        return this._currentLang || this._defaultLang; // return default lang if no current lang
    }

    // inject our translations
    constructor(@Inject(TRANSLATIONS) private _translations: any,
        @Inject(TRANSLATIONS_DAY) private _translationsDay: any,
        @Inject(TRANSLATIONS_MONTH) private _translationsMonth: any,
        @Inject(TRANSLATIONS_FMT) private _translationsFmt: any,
        @Inject(TRANSLATIONS_AG_GRID) private _translationsAgGrid: any,
        ) {
        this.supportedLangs = SUPPORTED_LANGS;
    }

    public onLangChanged: EventEmitter<string> = new EventEmitter<string>();

    public use(lang: string): void {
        // set current language
        this._currentLang = lang;
        this.directionInput(lang);
        this.onLangChanged.emit(lang); // publish changes
    }
    public getCurrentLang() {
        return this._currentLang;
    }
    public getSupportedLangs() {
        return this.supportedLangs;
    }
    public getLangOject(langValue: string): LangSupported {
        let langObj: LangSupported;
        for (langObj of this.supportedLangs) {
            if (langObj.value === langValue) {
              return langObj;
            }
          }
    }
    public getDays(): any {
        // found in current language
        if (this._translationsDay[this.currentLang]) {
            return this._translationsDay[this.currentLang];
        }
        // fallback disabled
        if (!this._fallback) {
            return '';
        }
        // found in default language
        if (this._translationsDay[this._defaultLang]) {
            return this._translationsDay[this._defaultLang];
        }
        // not found
        return '';
    }
    public getLabelToto(langKey: string, labelKey: string) {
        console.log(langKey + ':' + labelKey );
    }
    public getLabelCountry(langKey: string, labelKey: string): string {
        let translateLabel: string;
        let langCur = langKey;
        if (langKey === null) {
          langCur = this._currentLang;
        }
        switch (langCur) {
          case 'FR': {
            translateLabel = I18N_FR[labelKey];
            break;
          }
          case 'EN': {
            translateLabel = I18N_EN[labelKey];
            break;
          }
          default: {
            translateLabel = I18N_EN[labelKey];
            break;
          }
        }
        return translateLabel;
      }
    public getAgGridLabels(): any {
        // found in current language
        if (this._translationsAgGrid[this.currentLang]) {
            return this._translationsAgGrid[this.currentLang];
        }
        // fallback disabled
        if (!this._fallback) {
            return '';
        }
        // found in default language
        if (this._translationsAgGrid[this._defaultLang]) {
            return this._translationsAgGrid[this._defaultLang];
        }
        // not found
        return '';
    }
    public getMonths(): any {
        // found in current language
        if (this._translationsMonth[this.currentLang]) {
            return this._translationsMonth[this.currentLang];
        }
        // fallback disabled
        if (!this._fallback) {
            return '';
        }
        // found in default language
        if (this._translationsMonth[this._defaultLang]) {
            return this._translationsMonth[this._defaultLang];
        }
        // not found
        return '';
    }
    public getLocalFmt(key: string): any {
        return this.localFmt(key);
    }
    public getTranslate(key: string): any {
        return this.translate(key);
    }
    private localFmt(key: string): any {
        // private perform translation
        const translation = key;
        // found in current language
        if (this._translationsFmt[this.currentLang] && this._translationsFmt[this.currentLang][key]) {
            return this._translationsFmt[this.currentLang][key];
        }
        // fallback disabled
        if (!this._fallback) {
            return translation;
        }
        // found in default language
        if (this._translationsFmt[this._defaultLang] && this._translationsFmt[this._defaultLang][key]) {
            return this._translationsFmt[this._defaultLang][key];
        }
        // not found
        return translation;
    }
    private translate(key: string): string {
        // private perform translation
        const translation = key;
        // found in current language
        if (this._translations[this.currentLang] && this._translations[this.currentLang][key]) {
            return this._translations[this.currentLang][key];
        }
        // fallback disabled
        if (!this._fallback) {
            return translation;
        }
        // found in default language
        if (this._translations[this._defaultLang] && this._translations[this._defaultLang][key]) {
            return this._translations[this._defaultLang][key];
        }
        // not found
        return translation;
    }
        // https://developer.mozilla.org/fr/docs/Web/JavaScript/Reference/Objets_globaux/Intl/NumberFormat
        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
        //  new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(number));
        // (new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(number)
        // new Intl.NumberFormat("fr-FR", {style: "currency", currency: "EUR", currencyDisplay: "symbol"}).format(number);

    public instantNumber(_value: number, args?: string | string[]) { // add optional parameter

        if (args[0] === 'price') {
            return new Intl.NumberFormat(args[1], { minimumFractionDigits: 2 }).format(_value);
        } else if (args[0] === 'quantity') {
            let minFractionDigit = -1;
            let maxFractionDigit = -1;
            if (args.length > 2 ) {
                minFractionDigit = parseInt(args[2], 10);
            }
            if (args.length > 3 ) {
                maxFractionDigit = parseInt(args[3], 10);
            }
            const fractionDigit = {
                minimumFractionDigits: (minFractionDigit > -1 ? minFractionDigit : undefined ),
                maximumFractionDigits: (maxFractionDigit > -1 ? maxFractionDigit : undefined )
            };
            return new Intl.NumberFormat(args[1], fractionDigit).format(_value);

        } else if (args[0] === 'number') {
            let newValue = new Intl.NumberFormat(args[1], { minimumIntegerDigits: 2, maximumFractionDigits: 0, minimumFractionDigits: 0 }).format(Math.floor(_value));
            newValue = newValue.trim();
            return newValue;
            // console.log(new Intl.NumberFormat("en", { minimumIntegerDigits: 2, maximumFractionDigits: 0, minimumFractionDigits: 0 }).format(Math.floor(0.8)));
        } else if (args[0] === 'currency') {
            let displayCur = 'symbol';
            if (args.length > 3 ) {
                displayCur = args[3];
            }
            const styleCurrency = {
                style: 'currency',
                currency: args[2],
                currencyDisplay: displayCur
            };
            return new Intl.NumberFormat(args[1], styleCurrency).format(_value);
        } else if (args[0] === 'percent') {
            return new Intl.NumberFormat('default', {
                style: 'percent',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
                }).format(_value / 100);
            
        } else {
            return _value;
        }
    }
    public instant(key: string, words?: string | string[]) { // add optional parameter
        const translation: string = this.translate(key);

        // tslint:disable-next-line:curly
        if (!words) return translation;
        return this.replace(translation, words); // call replace function
    }

    public replace(word: string = '', words: string | string[] = '') {
        let translation: string = word;

        const values: string[] = [].concat(words);
        values.forEach((e, i) => {
            translation = translation.replace(this.PLACEHOLDER.concat(<any>i), e);
        });

        return translation;
    }
    public setDefaultLang(lang: string) {
        this._defaultLang = lang; // set default lang
    }
    public enableFallback(enable: boolean) {
        this._fallback = enable; // enable or disable fallback language
    }
    public directionInput(lang: string) {
        let directionCur: string = null;
        for (const langCur of this.supportedLangs) {
            if (langCur.value === lang) {
                directionCur = langCur.direction;
                break;
            }
        }
        if (directionCur !== null) {
            document.documentElement.style.setProperty(
                '--direction',
                directionCur
              );
        }

    }
}
