
import { Component, OnInit, ViewEncapsulation, Input, SimpleChanges, OnChanges, HostListener, ViewChild, ElementRef, NgZone, ChangeDetectorRef, TemplateRef, EventEmitter } from '@angular/core';
import { Papa } from 'ngx-papaparse';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material';
import { Subscription, Subject } from 'rxjs';
import { HttpResponse, HttpEventType } from '@angular/common/http';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { WksCentralService } from '../../../../services/wks-central.service';
import { TranslateService } from '../../../../../../services/translate.service';
import { ControlCsvService } from '../../../../../common/services/control-csv.service';
import { RessourcesService } from '../../../../../common/services/ressources.service';
import { ReadFile, ColumnsHeaderStatut, ColumnsHeaderUpload, FileUpload, Criterias, AlgoDatas, CalculationCmd, AlgoData } from '../../../../../../models/common.model';
import { ModalCommonComponent } from '../../../../../../job/common/components/modal-common/modal-common.component';

import { RemoteFilesService } from '../../../../../common/services/remote-files.service';
import { UserService } from '../../../../../../services/user.service';
import { ColDef } from '../../../../../../models//tables.model';
import { BrandWksModel } from '../../../../models/wks-common.model';
import { EconomicAreas, LabelValue } from '../../../../../../models/data.model';
import { CommonMethods } from '../../../../../../job/common/tools/commonMethods';
import { CalculationAlgoService } from '../../../../../../job/common/services/calculation-algo.service';
import { LangLabelModel, ParamCustoms } from '../../../../models/wks-param.model';
import { SUPPORTED_LANGS, LangSupportedModel } from '../../../../../../i18n/translation';
import { environment } from '../../../../../../../environments/environment';
import { env } from 'process';

export interface ReportDataInput { 
  reportType: string;
  reportActive: boolean;
  reportTitle: string;
  reportSubTitle: string;
  contentMessage: string;
  progressBar: string;
  progressValue: number;
  progressMessage: string;
  statut: string;
  nbErrors: number;
  nbRows: number;
  fileOrig: number;
  fileTarget: number;
  rowContentError: {
    rowNum: number;
    whereClause: string;
    rowContent: any;
    errorType: string;
  }[];
}
/*
export interface ReportEvent { 
  idxReport: number;
  reportDataCur: ReportDataInput;
}
*/
@Component({
  selector: 'mdi-parts-supplies-upload',
  templateUrl: './parts-supplies-upload.component.html',
  styleUrls: ['./parts-supplies-upload.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class PartsSuppliesUploadComponent implements OnInit, OnChanges {

  @Input() statutCall: string;
  @Input() optionItem: string;
  @Input() actionType: string;
  @Input() randomIdString: string;

  @ViewChild('modalBasic', {read: TemplateRef, static: false}) modalBasic: TemplateRef<any>;

  progress: { percentage: number } = { percentage: 0 };
  private readonly onDestroy = new Subject<void>();

  @ViewChild('dropArea', {static: false}) dropArea: any;
  @ViewChild('filterbrand', {static: false}) filterbrand: ElementRef;
  @ViewChild('filtercurrency', {static: false}) filtercurrency: ElementRef;


  uploadFileNameCtrl: FormControl;
  uploadForm: FormGroup;
  isReadOnly: boolean;
  isFinishLoading: boolean; 
  alertMessage: boolean; 
  doUpload: boolean;
  doVerify: boolean;

  fileExt: String = 'TXT, CSV, XLSX';
  fileRead: ReadFile;
  messageInfo = '';
  dataMessageInfo1 = '';
  dataMessageInfo2 = '';
  messageType = '';
  colErrors: string[];
  isUploadInProgress: boolean;
  isSearch: boolean;
  isAddOne: boolean;
  isReferential: boolean;
  isImportSupply: boolean;
  isLocalSupply: boolean;
  isOKToSelect: boolean;
  choiceCur: string;
  titleCard: string;

  filesUpload: FileUpload[];
  displayColumnsBdd: boolean;
  displayColumnsLinks: boolean;
  colSchema: ColDef[];
  colFinal: ColDef[];

  brandCacheList: BrandWksModel[];
  brandFilteredList: BrandWksModel[];

  numberFormat: {
    locale: string;
    minDecimal: string;
    maxDecimal: string;
    currency: string;
  };

  criterias: Criterias;
  // currenciesList: LabelValue[];
  currenciesCacheList: LabelValue[];
  currenciesFilteredList: LabelValue[];
  errorMessageDisplay: boolean;
  errorMessageDisplayAlarm: boolean;
  doUploadMulti: boolean;
  errorMessageText: string;


  crossRefAction: {
    addOK: boolean;
    removeOK: boolean;
  }[];

  columnAction: {
    unBind: boolean;
  }[][];


  crossRefKeys: {
    keySet: {
      keyOrig: {
        fileNum: number;
        field: string;
      },
      keyTarget: {
        fileNum: number;
        field: string;
      }
    }[]
  }[];
  crossRefValues: {
    fileNumOrig: number;
    fileNumTarget: number;
    whereClause: {
      field: string;
      value: any;
    }[]
  }[][];

  rowContentRead: {
    failure: boolean;
    fileContent: any[];
    targetRecordStatut: string[];
    targetRecord: any;
  }[] = [];

  sellingPriceIsSelected: boolean;
  dialogRef: MatDialogRef<ModalCommonComponent, any>;

  // utilisation de bs modal suite problème avec mat dialog dans l'analyse des fichiers.
  modalRef: BsModalRef;
  
  modalStatutCall: string;
  modalDataInput: { 
    contentMessage: string;
    progressValue?: number;
  };
  modalTitle: string;

  modalParam: { 
    modalDismissClose: boolean;
    progressBar: string;
    modalTitle: string;
    modalSubTitle?: string;
  };
  isReportActive: boolean;
  doAnalysisFile: boolean;

  reportDataInput: ReportDataInput[];

  // displayProgress: EventEmitter<ReportEvent> = new EventEmitter<ReportEvent>();

  targetDataList: any[];

  economicAreaCur: string; 
  algoImportedPrice:  CalculationCmd[];
  algoLocalPrice:  CalculationCmd[];
  supportedLangs: LangSupportedModel[];
  langLabelsList: LangLabelModel[];

  maxNbFiles: number;
  maxSizeDefault: number;
  maxSizePdf: number;
  maxSizeCSV: number; 

  constructor(private fb: FormBuilder, 
              private _wksCentralService: WksCentralService,
              private _userService: UserService,
              private _controlCsvService: ControlCsvService,
              private _remoteFilesService: RemoteFilesService,
              private _dialog: MatDialog,
              private _translate: TranslateService,
              private zone: NgZone ,
              private papa: Papa,
              private _calculationAlgoService: CalculationAlgoService,
              private _modalService: BsModalService,
              private ressourceService: RessourcesService) { }

  ngOnInit() {
  }
  ngOnChanges(changes: SimpleChanges) {
 
    const listKey = Object.keys(changes);
    for (const propName of listKey) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'actionType': {
            this.actionType = changes['actionType'].currentValue;
            break;
          }
          case 'statutCall': {
            this.statutCall = changes['statutCall'].currentValue;
            break;
          }
          case 'optionItem': {
            this.optionItem = changes['optionItem'].currentValue;
            break;
          }
          case 'randomIdString': {
            this.randomIdString = changes['randomIdString'].currentValue;
            break;
          }
        } // end switch
      } // end if
    } // end loop
    
    this.initData();
  }
  initData() {
    this.initBoolean();
    this.initOthers();
    this.settingFormats();
    this.buildForm(); 
    this.initUploadFiles();
  }
  initBoolean(): void {
    this.isFinishLoading = false;
    this.alertMessage = false;
    this.doUpload = false;
    this.doVerify = false;
    this.isUploadInProgress = false;
    this.isReadOnly = false;
    this.isImportSupply = false;
    this.isLocalSupply = false;
    this.isReferential = false;
    this.displayColumnsBdd = false;
    this.displayColumnsLinks = false;
    this.errorMessageDisplay = false;
    this.errorMessageDisplayAlarm = false;
    this.isOKToSelect = false;
    this.doUploadMulti = false;
    this.doAnalysisFile = false;
    this.isReportActive = false;
    this.sellingPriceIsSelected = false; 
    this.initReportInput();
  }
  initReportInput(): void {
    this.reportDataInput = [];
    for (let i = 0 ; i < 15 ; i++) {
      this.reportDataInput[i] = this.emptyDataInput();
    }
    this.crossRefValues = [];
  }
  emptyDataInput(): ReportDataInput {
    const reportDataInputEmpty = {
      reportType: '',
      reportActive: false,
      reportTitle: '',
      reportSubTitle: '',
      contentMessage: '',
      progressBar: '',
      progressValue: 0,
      progressMessage: '',
      statut: '',
      nbErrors: 0,
      nbRows: 0,
      fileOrig: -1,
      fileTarget: -1,
      rowContentError: []
    };
    return reportDataInputEmpty;
  }
  initFormMulti(): void {
    this.filesUpload = [];
    this.addFile();
    this.initOthers();
    this.isOKToSelect = false;
  }
  initOthers(): void {
    this.criterias = {
      brandName: '',
      incomingCurrency: '',
      targetCurrency: '',
      exchangeRate: '',
      lang : [],
      action: ''
    };
    this.settingFormats();
    this.crossRefAction = [];
    this.columnAction = [];
    this.langLabelsList = [];
    this.supportedLangs = [...SUPPORTED_LANGS];
    for (const langCur of this.supportedLangs) {
      const langTmp: LangLabelModel = {
        langValue: langCur.value,
        langDisplay: this._translate.getTranslate(langCur.display),
        langDirection: langCur.direction,
        langLabel: ''
      } ;
      this.langLabelsList.push(langTmp);
    }
    this.maxNbFiles = (this._wksCentralService.getWksMaxDefaults().maxNbFiles ? this._wksCentralService.getWksMaxDefaults().maxNbFiles : 5);
    this.maxSizeDefault = (this._wksCentralService.getWksMaxDefaults().maxSizeDefault ? this._wksCentralService.getWksMaxDefaults().maxSizeDefault : 20);
    this.maxSizePdf = (this._wksCentralService.getWksMaxDefaults().maxSizePdf ? this._wksCentralService.getWksMaxDefaults().maxSizePdf : 20);
    this.maxSizeCSV = (this._wksCentralService.getWksMaxDefaults().maxSizeCSV ? this._wksCentralService.getWksMaxDefaults().maxSizeCSV : 20);
  }
  settingFormats(): void {
    const otherData = JSON.parse(this._wksCentralService.getWksEntityCur().otherData);
    const intFormat = otherData.internationnalFormat;
    this.criterias.targetCurrency = otherData.entityCurrency;
    this.criterias.lang =  [... otherData.usersLanguages];
    this.numberFormat = {
      locale: this.ressourceService.getFormat(intFormat, 'numbers', 'locale'),
      minDecimal: this.ressourceService.getFormat(intFormat, 'numbers', 'minimumFractionDigits'),
      maxDecimal: this.ressourceService.getFormat(intFormat, 'numbers', 'maximumFractionDigits'),
      currency:  this.ressourceService.getFormat(intFormat, 'numbers', 'currency'),
    };
    this.algoImportedPrice = otherData.algoPrices.algoImportedPrice;
    this.algoLocalPrice = otherData.algoPrices.algoLocalPrice;
    
    this.loadEconomicAreas(this._wksCentralService.getWksEntityCur().entityCountry);
  }
  buildForm() {

    this.uploadFileNameCtrl = this.fb.control({value: '', disabled: this.isReadOnly});

    this.uploadForm = this.fb.group({
      uploadFileName: this.uploadFileNameCtrl,
    },
    {
      updateOn: 'blur'
    });
  }
  loadEconomicAreas(entityCountry: string)  {
    this._remoteFilesService.loadEconomicAreas()
    .subscribe(
      data => {
        const economicAreas = data.body as EconomicAreas[];
        this._calculationAlgoService.setEconomicAreas(economicAreas);
        this.economicAreaCur = this._calculationAlgoService.getEconomicArea(entityCountry);
        this.loadParamCustomsEcoZoneList();
      },
      err =>  {
        console.log('loadEconomicAreas ' + err);
      }
    );
  }
  loadParamCustomsEcoZoneList(): void {
    this._wksCentralService.getParamCustomsEcoZoneList(this.economicAreaCur)
    .subscribe(
      data => {
        const paramCustoms = data.body as ParamCustoms[];
        this._calculationAlgoService.setParamCustoms(paramCustoms);
      },
      err =>  {
        console.log('loadParamCustomsEcoZoneList ' + err);
      }
    );
  
  }
// ============================================================ start multifiles 
  initUploadFiles(): void {

    this.choiceCur = 'importSupply';
    this.isImportSupply = true;
    this.titleCard = 'upload supply';
    this.colSchema = this._wksCentralService.getColumnsHeadersImportSupplyCsv();
    for (const colBdd of this.colSchema) {
      colBdd.selected = false;
    }
    this.colFinal = this._wksCentralService.getColumnsHeadersImportSupplyFinalCsv();
    this.filesUpload = [];
    this.filesUpload.push(this.fileReadEmpty());
    this.loadBrands()
    .then (brandList => {
      this.loadCurrencies();
      this.isFinishLoading = true;
    })
    .catch(error  => {
      console.log(error);
      this.isFinishLoading = true;
    });
  }
  loadCurrencies() {
    const currenciesOrig = this.ressourceService.getCurrencies();
    this.currenciesFilteredList = [];
    this.currenciesCacheList = [];
    for (const curTmp of currenciesOrig) {
      const currrency = {
        label: curTmp.label,
        value: curTmp.iso,
      };
      this.currenciesCacheList.push(currrency);
    }
    this.currenciesCacheList.sort((obj1, obj2) => {
      return obj1.value > obj2.value ? 1 : -1;
    });
    this.currenciesFilteredList = [...this.currenciesCacheList];
  }
  onKeyCurrency($event: any) {
    // console.log(value);
    let valueFinal: any;
    if ($event === '') {
      valueFinal = '';
    } else  {
      valueFinal = $event.target.value;
      if ( $event.keyCode === 32) {
        valueFinal += ' ';
      }
    }
    this.currenciesFilteredList = this.searchCurrency(valueFinal);

    this.verifyUploadMulti();
  }
  searchCurrency(value: string) { 
    const filter = value.toLowerCase();
    return this.currenciesCacheList.filter(brandCur => brandCur.value.toLowerCase().includes(filter));
  }
  loadBrands() {
    if (this._userService.getUserLogged() === undefined ) { return; }
    this.brandCacheList = [];
    this.brandFilteredList = [];
    
    let brandList: BrandWksModel[];

    return new Promise((resolve, reject) => {
      this._wksCentralService.getWksBrandsList(this._userService.getUserLogged().entity)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(
        data => {
          brandList = data.body;
          for (const brandCur of brandList) {
            this.brandCacheList.push(brandCur);
          }
          this.brandCacheList.sort((obj1, obj2) => {
            return obj1.brandName > obj2.brandName ? 1 : -1;
          });
          this.brandCacheList.splice(0, 0,  {stdEntity: this._userService.getUserLogged().entity, brandRow: 'brand', brandName: ''} );
          this.brandFilteredList = [...this.brandCacheList];
          resolve(brandList);
        }, err => {
          // console.log(err);
          if (err.status === 404) {
            this.brandCacheList.push({
              stdEntity: this._userService.getUserLogged().entity,
              brandRow: 'brand',
              brandName: ''
            });
            this.brandFilteredList = [...this.brandCacheList];
          }
          reject(err.status);
        }
      );
    });
  }
  onKeyBrand($event: any) {
    // console.log(value);
  
    let valueFinal: any;
    if ($event === '') {
      valueFinal = '';
    } else  {
      valueFinal = $event.target.value;
      if ( $event.keyCode === 32) {
        valueFinal += ' ';
      }
    }
    this.brandFilteredList = this.searchBrand(valueFinal);
    this.filterbrand.nativeElement.value = valueFinal;
  }
  searchBrand(value: string) { 
    const filter = value.toLowerCase();
    return this.brandCacheList.filter(brandCur => brandCur.brandName.toLowerCase().includes(filter));
  }
  addBrand(newBrandName: string) {
    // console.log(newBrand);
    if (newBrandName === '' ) { 
      return;
    }
    const newBrand = {
      stdEntity: this._userService.getUserLogged().entity,
      brandRow: 'brand',
      brandName: newBrandName,
      equipType: '',
      modelName: '',
    };
    this.brandCacheList.push(newBrand);
    this.brandCacheList.sort((obj1, obj2) => {
      return obj1.brandName > obj2.brandName ? 1 : -1;
    });
    if (this.brandCacheList[0].brandName === '') {
      this.brandCacheList.splice(0, 1);
    }
    this.brandFilteredList = [...this.brandCacheList];

    // this._wksCentralService.saveWksBrand( newBrand, 'create');
    this._wksCentralService.saveBrandModel(newBrand);
  }
  changeCriterias(orig: string, value: any) {
    this.modifFiles();
    this.isReportActive = false;
    switch (orig) {
      /*
      case 'lang':
        this.criterias.lang = value;
      this.verifOKSelectFields();
      break;
      */
      case 'brandName':
        this.criterias.brandName = value;

      this.verifOKSelectFields();
      break;
      case 'incomingCurrency':
        this.criterias.incomingCurrency = value;
        this.verifOKSelectFields();
      break;
      case 'targetCurrency':
        this.criterias.targetCurrency = value;
        this.verifOKSelectFields();
      break;
      case 'exchangeRate':
        this.criterias.exchangeRate = value;
        this.verifOKSelectFields();
      break;
    }
    this.verifyUploadMulti();
  }
  verifOKSelectFields(): void {
    this.isOKToSelect = false;
    if (this.isReferential) {

        this.isOKToSelect = this.criterias.brandName !== '' ? true : false;
    }
    if (this.isLocalSupply) {
      this.isOKToSelect = this.criterias.brandName === '' ? false : true;
    }
    if (this.isImportSupply) {
      if ( this.criterias.brandName !== ''
          && this.criterias.incomingCurrency !== ''
          && this.criterias.targetCurrency !== ''
          && this.criterias.exchangeRate !== ''
          ) {
            this.isOKToSelect = true;
      }
    }
    
    return ;
  }
// choice type upload
  onChoiceChange(event: any) {
    this.isImportSupply = false;
    this.isLocalSupply = false;
    this.isReferential = false;
    this.choiceCur = event;
    this.initFormMulti();
    switch (event) {
        case 'importSupply':
          this.isImportSupply = true;
          this.titleCard = 'upload import supply';
          this.colSchema = this._wksCentralService.getColumnsHeadersImportSupplyCsv();
          for (const colBdd of this.colSchema) {
            colBdd.selected = false;
          }
          this.colFinal = this._wksCentralService.getColumnsHeadersImportSupplyFinalCsv();
          this.criterias.action = 'importSupply';
        break;
        case 'localSupply':
          this.isLocalSupply = true;
          this.titleCard = 'upload local supply';
          this.colSchema = this._wksCentralService.getColumnsHeadersLocalSupplyCsv();
          for (const colBdd of this.colSchema) {
            colBdd.selected = false;
          }
          this.colFinal = this._wksCentralService.getColumnsHeadersLocalSupplyFinalCsv();
          this.criterias.action = 'localSupply';
        break;
        case 'referential':
          this.isReferential = true;
          this.titleCard = 'upload referential';
          this.colSchema = this._wksCentralService.getColumnsHeadersPartsReferentialCsv();
          for (const colBdd of this.colSchema) {
            colBdd.selected = false;
          }
          this.criterias.action = 'referential';
        break;
    }
    this.verifyUploadMulti();
  }

  toggleColumnsBdd(): void {
    this.displayColumnsBdd = !this.displayColumnsBdd;
    if (this.displayColumnsBdd) {
      this.displayColumnsLinks = false;
    }
  }
  toggleColumnsLinks(): void {
    this.displayColumnsLinks = !this.displayColumnsLinks;
    if (this.displayColumnsLinks) {
      this.displayColumnsBdd = false;
    }
  }
  
  fileReadEmpty(): FileUpload {
    const fileUpload: FileUpload = {
      readFile :  {
        nameFile: '',
        pathFile: '',
        sizeFile: undefined,
        typeFile: '',
        messageFile: '',
        fileObject: undefined,
        validatedFile: undefined,
        uploadedFile: undefined,
        legendFile:  ''
      },
      columnsHeader: [],
      crossReferenced: [],
      jsonContent: undefined,
    };
    return fileUpload;
  }

  
  addCrossReference(): void {
    for (const fileUpload of this.filesUpload) {
      fileUpload.crossReferenced.push('');
    }
    this.verifCrossRefAction(this.filesUpload[0].crossReferenced.length - 1);
  }
  removeCrossReference(idxFile: number, idxRef: number): void {

    if (this.filesUpload[idxFile].crossReferenced.length > 1) {
      for (const fileUpload of this.filesUpload) { 
        fileUpload.crossReferenced.splice(idxRef, 1);
      }
    } else {
      for (const fileUpload of this.filesUpload) { 
        fileUpload.crossReferenced.splice(idxRef, 1);
        fileUpload.crossReferenced.push('');
      }
      this.verifCrossRefAction(this.filesUpload[0].crossReferenced.length - 1);
    }
    this.verifyUploadMulti();
  }
  crossRefSelected(idxFile: number, idxRef: number, value: string): void {
    this.filesUpload[idxFile].crossReferenced[idxRef] = value;
    this.verifCrossRefAction(idxRef);
  }
  verifCrossRefAction(idxRef: number): void {
    let nbCols = 0;
    for (const fileUpload of this.filesUpload) {
      if (fileUpload.crossReferenced[idxRef] !== undefined && fileUpload.crossReferenced[idxRef] !== '') {
        nbCols++;
      }
    }
    this.crossRefAction[idxRef] =  {
      addOK: nbCols > 1 ? true : false, 
      removeOK: nbCols > 1 ? true : false 
    };
    this.verifyUploadMulti();
  }
  addFile(): void {
    this.filesUpload.push(this.fileReadEmpty());
  }
  removeFile(idxFile: number): void {

    this.filesUpload[idxFile].crossReferenced = [];
    for (const columnHeader of  this.filesUpload[idxFile].columnsHeader) {
      if (columnHeader.columnBdd !== undefined && columnHeader.columnBdd !== '' ) {
        for (const colBdd of this.colSchema) {
          if (colBdd.colName === columnHeader.columnBdd) {
            colBdd.selected = false;
          }
        }
        columnHeader.columnBdd = '';
      }
    }
    if (this.filesUpload.length > 1) {
      this.filesUpload.splice(idxFile, 1);
    } else {
      this.filesUpload.splice(idxFile, 1);
      this.filesUpload.push(this.fileReadEmpty());
    }
    this.verifyUploadMulti();
  }
  onFileChangeMulti(event: any, idxFile: number) {
    event.preventDefault();
    event.stopPropagation();
    this.errorMessageDisplay = false;
    this.errorMessageText = '';

    if (event.target.files && event.target.files.length > 0) {
      const files = event.target.files;
      if ((files.length > 0) && (this.isValidFiles(files))) {
        const file: File = files[0];
        const readingFile: ReadFile = new ReadFile();
        readingFile.nameFile = file.name;
        readingFile.sizeFile = file.size;
        readingFile.typeFile = file.type;
        readingFile.fileObject = file;
        readingFile.messageFile = '';
        readingFile.uploadedFile = false;
        readingFile.validatedFile = false;
        if (this.verifyFileName(file.name)) {
          return;
        }
        this.filesUpload[idxFile].readFile = readingFile;
        this.loadSingleFile(idxFile);


      } else  {
        this.filesUpload[idxFile] = this.fileReadEmpty();
      }
    }

  }
  verifyFileName(fileName: string): boolean  {
    for (const fileExistants of this.filesUpload) {
      if (fileExistants.readFile.nameFile === fileName) {
        this.errorMessageDisplay = true;
        this.errorMessageDisplayAlarm = true;
        this.errorMessageText = 'file exists';
        return true;
      }
    }
    return false;
  }
 
  selectColumnFile(idxFile: number, idxCol: number) {

    let idxFileLoc = 0;
    let idxColLoc = 0;

    for (const fileUpload of this.filesUpload) { 
      for (const columnHeader of fileUpload.columnsHeader) {
        if (idxFileLoc === idxFile && idxColLoc === idxCol) {
          idxColLoc++;
          continue;
        }
        columnHeader.selected = false;
        // columnHeader.unBind = false;
        this.columnAction[idxFileLoc][idxColLoc] = {
          unBind: false
        };
        idxColLoc++;
      }
      idxColLoc = 0;
      idxFileLoc++;
    }


    let idxColCur = 0;
    for (const columnHeader of this.filesUpload[idxFile].columnsHeader) {
      if (idxColCur === idxCol) {
        idxColCur++;
        continue;
      }
      columnHeader.selected = false;
      // columnHeader.unBind = false;
      this.columnAction[idxFile][idxColCur] = {
        unBind: false
      };
      idxColCur++;
    }
    this.filesUpload[idxFile].columnsHeader[idxCol].selected = !this.filesUpload[idxFile].columnsHeader[idxCol].selected;
    if (!this.filesUpload[idxFile].columnsHeader[idxCol].selected) {
      // this.filesUpload[idxFile].columnsHeader[idxCol].unBind = false;
      this.columnAction[idxFile][idxCol] = {
        unBind: false
      };
    }
    this.verifyUploadMulti();
  }
  selectColumnBdd(idxBdd: number) { 
    let isFound = false;
    let idxFile = 0;
    let idxCol = 0;
    if (this.colSchema[idxBdd].selected) { 
      for (const fileUpload of this.filesUpload) { 
        for (const columnHeader of fileUpload.columnsHeader) {
          columnHeader.selected = false;
          // columnHeader.unBind = false;
          this.columnAction[idxFile][idxCol] = {
            unBind: false
          };
          idxCol++;
        }
        idxCol = 0;
        idxFile++;
      }
      idxFile = 0;
      idxCol = 0;
      for (const fileUpload of this.filesUpload) { 
        for (const columnHeader of fileUpload.columnsHeader) {
          if (columnHeader.columnBdd === this.colSchema[idxBdd].colName) { 
            columnHeader.selected = true;
            // columnHeader.unBind = true;
            this.columnAction[idxFile][idxCol] = {
              unBind: true
            };
            isFound = true;
            break;
          }
          idxCol++;
        }
        idxCol = 0;
        idxFile++;
        if (isFound) { 
          break;
        }
      }
      this.verifyUploadMulti();
      return;
    }
    idxFile = 0;
    idxCol = 0;
    for (const fileUpload of this.filesUpload) { 
      for (const columnHeader of fileUpload.columnsHeader) {
        if (columnHeader.selected && (columnHeader.columnBdd === '' || columnHeader.columnBdd === undefined ))  { 
          columnHeader.columnBdd = this.colSchema[idxBdd].colName;
          columnHeader.selected = false;
          // columnHeader.unBind = false;
          this.columnAction[idxFile][idxCol] = {
            unBind: false
          };
          this.colSchema[idxBdd].selected = true;
          isFound = true;
          break;
        }
        idxCol++;
      }
      idxCol = 0;
      idxFile++;
      if (isFound) { 
        break;
      }
    }
    this.verifyUploadMulti();
  }
  unBindColumn(idxFile: number, idxCol: number) {
    
    for (const colName of this.colSchema) {
      if (colName.colName === this.filesUpload[idxFile].columnsHeader[idxCol].columnBdd) {
        colName.selected = false;
        break;
      }
    }
    // this.filesUpload[idxFile].columnsHeader[idxCol].unBind = false;
    this.columnAction[idxFile][idxCol] = {
      unBind: false
    };
    this.filesUpload[idxFile].columnsHeader[idxCol].columnBdd = '';
    this.filesUpload[idxFile].columnsHeader[idxCol].selected = false;
    this.verifyUploadMulti();
  }
  loadSingleFile(idxFile: number): void {

    this.messageInfo = 'analysisFile';
    this.dataMessageInfo1 = this.filesUpload[idxFile].readFile.nameFile ;
    this.messageBoxDisplay('analysisFile');

    this.readFile(this.filesUpload[idxFile].readFile.fileObject)
    .then((result) => {
      const arrayOfLines = result as any[];
      this.columnAction[idxFile] = [];
      this.filesUpload[idxFile].readFile.rowsFile = arrayOfLines.length - 1;
      const colHeaders =  arrayOfLines[0].split(';');
      const columnsHeader: ColumnsHeaderUpload[] = [];
      let idxCol = 0;
      for (const colName of colHeaders) {
        if (colName.trim() === '') {
          continue;
        }
        let colRef = ''; 
        let asciiCol = 0;
        if (idxCol <= 25) {
          asciiCol = 65 + idxCol;
          colRef = String.fromCharCode(asciiCol);
        } else if (idxCol > 25 && idxCol <= 50) {
          asciiCol = 65;
          colRef = String.fromCharCode(asciiCol);
          asciiCol = 65 +  (idxCol - 26);
          colRef = colRef + String.fromCharCode(asciiCol);
        } else if (idxCol > 50) {
          asciiCol = 66;
          colRef = String.fromCharCode(asciiCol);
          asciiCol = 65 +  (idxCol - 52);
          colRef = colRef + String.fromCharCode(asciiCol);
        }

        const colNameTmp = colName.trim();
        columnsHeader[idxCol] = {
          columnRef: colRef,
          columnName: colNameTmp.replace(/[ ]/g, '_'),
          selected: false,
          columnBdd: ''
        };
        this.columnAction[idxFile][idxCol] = {
          unBind: false
        };
        idxCol++;
      }
      this.filesUpload[idxFile].columnsHeader = columnsHeader;
      this.filesUpload[idxFile].crossReferenced = [];
      this.filesUpload[idxFile].crossReferenced.push('');
      if (idxFile === 0) {
        this.crossRefAction[0] = {
          addOK: false,
          removeOK: false
        };
      }
      /* 
      this.filesUpload[idxFile].jsonContent = {};
      this.filesUpload[idxFile].jsonContent.data = CommonMethods.csvToJson(columnsHeader, arrayOfLines, ';');
      this.closeMatDialog();
      */

      this.papaLoadPromise(this.filesUpload[idxFile].readFile.fileObject)
      .then((resultObject) => {
        this.filesUpload[idxFile].jsonContent = resultObject;
        this.closeMatDialog();
      })
      .catch((error) => {
        console.error(error);
        this.filesUpload[idxFile].jsonContent = null;
        this.closeMatDialog();
      });
      
    })
    .catch((error) => {
      console.error(error);
 
    }); 
 
  }
  // verify elements mulit files
  verifyUploadMulti(): void {
    this.doUploadMulti = false;
    this.doAnalysisFile = false;
    if (this.criterias === undefined) {
      return;
    }
    if (this.isReferential) {
      if (this.criterias.brandName === '' || this.criterias.brandName === undefined ) {
        return;
      }
    } else if (this.isImportSupply) {

      if (this.criterias.brandName === '' || this.criterias.brandName === undefined ) {
        return;
      }

      if (this.criterias.exchangeRate === '' || this.criterias.exchangeRate === undefined ) {
        return;
      }
      if (this.criterias.incomingCurrency === '' || this.criterias.incomingCurrency === undefined ) {
        return;
      }
      if (this.criterias.targetCurrency === '' || this.criterias.targetCurrency === undefined ) {
        return;
      }
    } else if (this.isLocalSupply) {

      if (this.criterias.brandName === '' || this.criterias.brandName === undefined ) {
        return;
      }

    }
    if (this.filesUpload[0] === undefined) {
      return;
    }
    let isFailed = false;
    for (const coldefCur of this.colSchema) {
      if (coldefCur.actionRules.add.mandatory === true && !coldefCur.selected)  {
        isFailed = true;
      }
    }
    if (isFailed) {
        return;
    }

    for (const fileUpload of this.filesUpload) {
      if (fileUpload.columnsHeader === undefined  || fileUpload.columnsHeader.length === 0) {
          isFailed = true;
      }
    }
    if (isFailed) {
      return;
    }
    if (this.filesUpload.length > 1) {
      const crossRefValue: number[] = [];
      for (const fileUpload of this.filesUpload) {
        if (fileUpload.crossReferenced.length === 1) {
          if (fileUpload.crossReferenced[0] === '') {
            isFailed = true;
            break;
          }
        }
      }
      if (isFailed) {
        return;
      }
      for (let i = 0 ; i <  this.filesUpload[0].crossReferenced.length ; i++) {
        crossRefValue[i] = 0;
      }

      for (let idxRow = 0 ; idxRow <  crossRefValue.length ; idxRow++) {
        for (const fileUpload of this.filesUpload) {
          try {
            if (fileUpload.crossReferenced[idxRow] !== undefined && fileUpload.crossReferenced[idxRow] !== '' ) {
              crossRefValue[idxRow]++;
            }
          } catch (error) {
          } finally { }
        }
      }
      for (const crossRefValueTmp of crossRefValue) {
        if (crossRefValueTmp < 2) {
          isFailed = true;
        }
      }
      if (isFailed) {
        return;
      }
    }
    this.doAnalysisFile = true;

  } 
  // 
  async parseMultiFiles(statut: boolean): Promise<any[]> {
    const results: any[] = []; 
    let idxFile = 0;
    for (const fileUpload of this.filesUpload) {
      try {
        await this.papaLoadPromise(fileUpload.readFile.fileObject)
        .then((result) => {
          results[idxFile] = result;
        })
        .catch((error) => {
          console.error(error);
          statut = false;
          return null;
        }); 
      } catch (err) {
        console.error(JSON.stringify(err));
        statut = false;
        return null;
      }
      idxFile++;
    }
    return results;
  }
  
  async multiFilesValidation() {

    this.isReportActive = true;
    
    this.errorMessageDisplay = true;
    this.errorMessageDisplayAlarm = false;
    this.errorMessageText = this._translate.instant('buildCrossreferences');
    // this.rowContentError = [];
    const rowsCSV: any[] = [];
    let rowData = 0;
    for (const fileUpload of this.filesUpload) {
      rowsCSV[rowData] =  fileUpload.jsonContent;
      rowData++;
    }
    this.sellingPriceIsSelected = false;
    for (const colItem of this.colSchema) {
      if (colItem.colName === 'sellingPrice') {
        this.sellingPriceIsSelected = colItem.selected;
        break;
      }
    }
    // const nbPrimaryLines = rowsCSV[0].data.length;
    const nbPrimaryLines = environment.maxLinesImport !== -1 ? environment.maxLinesImport : rowsCSV[0].data.length;
    // const nbPrimaryLines = 1000;
    let argsMessage = [''];
    let idxReport = 0;
    /*
    const reportEvent: ReportEvent =  { 
      idxReport: 0,
      reportDataCur: this.emptyDataInput()
    };
    */
    this.errorMessageText = this._translate.instant('buildCrossrequest');
    
    this.reportDataInput[idxReport].reportActive = true;
    this.reportDataInput[idxReport].reportTitle = this._translate.instant('buildCrossReferences');
    this.reportDataInput[idxReport].reportType = 'buildCrossReferences';
    this.reportDataInput[idxReport].progressBar = 'undeterminate';
    this.reportDataInput[idxReport].statut = 'inProgress';
    this.reportDataInput[idxReport].nbErrors = 0;
    
    let crossRefkey: any;
    /*
    reportEvent.reportDataCur.reportActive = true;
    reportEvent.reportDataCur.reportTitle = this._translate.instant('buildCrossReferences');
    reportEvent.reportDataCur.reportType = 'buildCrossReferences';
    reportEvent.reportDataCur.progressBar = 'undeterminate';
    reportEvent.reportDataCur.statut = 'inProgress';
    reportEvent.reportDataCur.nbErrors = 0;
    this.displayProgress.emit(reportEvent);
    */


    await this.buildCrossReferences()
      .then(() => {
        this.reportDataInput[idxReport].progressBar = '';
        this.reportDataInput[idxReport].statut = 'terminate';
        this.reportDataInput[idxReport].contentMessage = this._translate.instant('ProcessTerminate');
        idxReport++;
        this.reportDataInput[idxReport].reportActive = true;
        this.reportDataInput[idxReport].reportTitle = this._translate.instant('extractDataMainFile');
        this.reportDataInput[idxReport].reportType = 'extractDataMainFile';
        this.reportDataInput[idxReport].progressBar = 'determinate';
        this.reportDataInput[idxReport].statut = 'inProgress';
        this.reportDataInput[idxReport].nbErrors = 0;

      })
      .catch((error) => {
        console.error(error);
      
        return ;
      }); 

    await this.extractDataMainFile(rowsCSV, nbPrimaryLines, idxReport)
      .then(() => {
        this.reportDataInput[idxReport].progressBar = '';
        this.reportDataInput[idxReport].statut = 'terminate';
        this.reportDataInput[idxReport].contentMessage = this._translate.instant('ProcessTerminate');
      })
      .catch((error) => {
        console.error(error);
      
        return ;
      }); 

    for (let idxCrossRef = 0 ; idxCrossRef < this.crossRefKeys.length ; idxCrossRef++ ) {
      idxReport++;
      crossRefkey = this.crossRefKeys[idxCrossRef];
      argsMessage = [(crossRefkey.keySet[0].keyTarget.fileNum + 1).toString()];
      this.reportDataInput[idxReport].reportActive = true;
      this.reportDataInput[idxReport].reportTitle = this._translate.instant('buildWhereClauseFileTitle');
      this.reportDataInput[idxReport].fileTarget = crossRefkey.keySet[0].keyTarget.fileNum;
      this.reportDataInput[idxReport].fileOrig = crossRefkey.keySet[0].keyOrig.fileNum;
     
      this.reportDataInput[idxReport].reportSubTitle = this._translate.instant('buildWhereClauseFile', argsMessage);
      this.reportDataInput[idxReport].reportType = 'extractData';
      this.reportDataInput[idxReport].progressBar = 'determinate';
      this.reportDataInput[idxReport].statut = 'inProgress';
      this.reportDataInput[idxReport].nbErrors = 0;

      await this.buildCrossKeysWhere(idxCrossRef, nbPrimaryLines, idxReport)
      .then((result) => {
        this.reportDataInput[idxReport].progressBar = '';
        this.reportDataInput[idxReport].statut = 'terminate';
        this.reportDataInput[idxReport].contentMessage = this._translate.instant('ProcessTerminate');
      })
      .catch((error) => {
        console.error(error);
        return ;
      }); 
      idxReport++;

      this.reportDataInput[idxReport].reportActive = true;
      this.reportDataInput[idxReport].reportTitle = this._translate.instant('extractData');
      this.reportDataInput[idxReport].fileTarget = crossRefkey.keySet[0].keyTarget.fileNum;
      this.reportDataInput[idxReport].fileOrig = crossRefkey.keySet[0].keyOrig.fileNum;

      this.reportDataInput[idxReport].reportSubTitle = this._translate.instant('extractDataSubFile', argsMessage);
      this.reportDataInput[idxReport].reportType = 'extractData';
      this.reportDataInput[idxReport].progressBar = 'determinate';
      this.reportDataInput[idxReport].statut = 'inProgress';
      this.reportDataInput[idxReport].nbErrors = 0;
      await this.extractDatas(rowsCSV, nbPrimaryLines, idxCrossRef, idxReport)
      .then((result) => {
        this.reportDataInput[idxReport].progressBar = '';
        this.reportDataInput[idxReport].statut = 'terminate';
        this.reportDataInput[idxReport].contentMessage = this._translate.instant('ProcessTerminate');

      })
      .catch((error) => {
        console.error(error);
      
        return ;
      }); 
      
    }
    idxReport++;
    this.reportDataInput[idxReport].reportActive = true;
    this.reportDataInput[idxReport].reportTitle = this._translate.instant('analysisData');
    this.reportDataInput[idxReport].reportType = 'analysisData';
    this.reportDataInput[idxReport].progressBar = 'determinate';
    this.reportDataInput[idxReport].statut = 'inProgress';
    this.reportDataInput[idxReport].nbErrors = 0;
    await this.ctrlMandatoryDatas(nbPrimaryLines, idxReport)
      .then((result) => {
        this.reportDataInput[idxReport].progressBar = '';
        this.reportDataInput[idxReport].statut = 'terminate';
        this.reportDataInput[idxReport].contentMessage = this._translate.instant('ProcessTerminate');
      })
      .catch((error) => {
        console.error(error);
      
        return ;
      }); 

    // selling price calculation
    if (!this.sellingPriceIsSelected) {
      idxReport++;
      this.reportDataInput[idxReport].reportActive = true;
      this.reportDataInput[idxReport].reportTitle = this._translate.instant('sellingPriceCalculation');
      this.reportDataInput[idxReport].reportType = 'sellingPrice';
      this.reportDataInput[idxReport].progressBar = 'determinate';
      this.reportDataInput[idxReport].statut = 'inProgress';
      this.reportDataInput[idxReport].nbErrors = 0;
      await this.sellingPriceCalculation(idxReport)
        .then((result) => {
          this.reportDataInput[idxReport].progressBar = '';
          this.reportDataInput[idxReport].statut = 'terminate';
          this.reportDataInput[idxReport].contentMessage = this._translate.instant('ProcessTerminate');
        })
        .catch((error) => {
          console.error(error);
        
          return ;
        }); 
    }
    this.doUploadMulti = true;
    this.doAnalysisFile = false;

    this.errorMessageDisplay = false;
    this.errorMessageDisplayAlarm = false;
    this.errorMessageText = '';
    return ;
  }
  
  buildCrossReferences() {
    return new Promise<void>((resolve, reject) => {
      this.crossRefKeys = [];
      let idxFile = 0 ;
      // Construct cross reference keys
      for (const fileUpload of this.filesUpload) {
        for (let i = 0 ; i <  fileUpload.crossReferenced.length ; i++) {
          if (fileUpload.crossReferenced[i] === undefined || fileUpload.crossReferenced[i] === '') {
            continue;
          }
          const keyCur =  {
            fileNum: idxFile,
            field: fileUpload.crossReferenced[i]
          };
          if (this.crossRefKeys[i] === undefined) { // new crossref : key orig
            this.crossRefKeys[i] = {
              keySet: []
            };
            this.crossRefKeys[i].keySet = [];
            const keySetTmp = {
              keyOrig: keyCur,
              keyTarget: undefined
            };
            this.crossRefKeys[i].keySet.push(keySetTmp);
          } else  { 
            let isFound = false;
            for (const keySetCur of this.crossRefKeys[i].keySet) {
              if (keySetCur.keyTarget === undefined)  {
                keySetCur.keyTarget = keyCur;
                isFound = true;
                break;
              }
            }
            if (!isFound) {
              const keySetTmp = {
                keyOrig: keyCur,
                keyTarget: undefined
              };
              this.crossRefKeys[i].keySet.push(keySetTmp);
            }
          }
        }
        idxFile++;
      }
      resolve();
    });
  } 
  extractDataMainFile(rowsCSV: any[], nbPrimaryLines: number, idxReport: number) {

    return new Promise<void>((resolve, reject) => {

      for (let idxRows = 0; idxRows < nbPrimaryLines; idxRows++) {
        this.rowContentRead[idxRows] =  {
          failure: false,
          fileContent: [],
          targetRecordStatut: [],
          targetRecord: undefined
        };
        this.rowContentRead[idxRows].fileContent[0] = JSON.parse(JSON.stringify(rowsCSV[0].data[idxRows]));

        this.reportDataInput[idxReport].progressMessage = this._translate.instant('line') + ' ' + (idxRows + 1).toString() + ' ' 
            + this._translate.instant('out of') + ' ' + nbPrimaryLines.toString();
        this.reportDataInput[idxReport].progressValue =  Math.floor((idxRows / nbPrimaryLines) * 100);
      }
      this.crossRefValues = [];
      // verif cross ref 
      // construct where clause for one raw    
      resolve();
    });
  }
  // construct where clause for one raw
  buildCrossKeysWhere(idxCrossRef: number, nbPrimaryLines: number, idxReport: number) {
    return new Promise<void>((resolve, reject) => {

      this.crossRefValues[idxCrossRef] = [];
      const crossRefkey = this.crossRefKeys[idxCrossRef];
      const fileOrig = crossRefkey.keySet[0].keyOrig.fileNum;
      const fileTarget = crossRefkey.keySet[0].keyTarget.fileNum;
      let rowsFailure = 0;
      // for (const crossRefkey of this.crossRefKeys ) {
        for (const keySetTmp of crossRefkey.keySet) {   
          if (keySetTmp.keyTarget.fileNum !== fileTarget) {
            break;
          }   
          if (keySetTmp.keyOrig.fileNum !== fileOrig) {
            continue;
          }   
          for (let idxRows = 0 ; idxRows < nbPrimaryLines ; idxRows++) {
            if (this.rowContentRead[idxRows].failure)  {
              rowsFailure++;
              continue;
            } 
            if (this.crossRefValues[idxCrossRef][idxRows] === undefined) {
              this.crossRefValues[idxCrossRef][idxRows] =  {
                fileNumOrig: 0,
                fileNumTarget: 0,
                whereClause: [],
              };
            }
            let whereValue = '';
            this.reportDataInput[idxReport].progressMessage = this._translate.instant('line') + ' ' + (idxRows + 1 - rowsFailure).toString() + ' ' 
                  + this._translate.instant('out of') + ' ' + nbPrimaryLines.toString();
            this.reportDataInput[idxReport].progressValue =  Math.floor((idxRows / nbPrimaryLines) * 100);
            try {
              whereValue = this.rowContentRead[idxRows].fileContent[keySetTmp.keyOrig.fileNum][keySetTmp.keyOrig.field] as string;
            } catch (error) {
              reject();
            } finally { }
            
            const patternCur = /[']/;
            // const format = /^[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/;
            // const format = /^[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/; 
            
            let valueString =  whereValue.toString();
            if (valueString.match(patternCur)) {
              valueString = valueString.replace(/[']/g, ' ');
              whereValue = valueString;
            }
            
            // whereValue = whereValue.replace(/[']/g, ' ');
            const whereClauseTmp = {
              field: keySetTmp.keyTarget.field,
              value: whereValue,
            };
            this.crossRefValues[idxCrossRef][idxRows].fileNumOrig  = keySetTmp.keyOrig.fileNum;
            this.crossRefValues[idxCrossRef][idxRows].fileNumTarget  = keySetTmp.keyTarget.fileNum;
            this.crossRefValues[idxCrossRef][idxRows].whereClause.push(whereClauseTmp);
          }
        }
      // }
      resolve();
    });
  }
  
  extractDatas(rowsCSV: any[], nbPrimaryLines: number, idxCrossRef: number, idxReport: number) {

    return new Promise<void>((resolve, reject) => {
      let idxRowsRead = 0;
      let rowsFailure = 0;
      const crossRefValuesTmp = this.crossRefValues[idxCrossRef] as any[];
      
      for (const crossRefValue of crossRefValuesTmp) {
        if (this.rowContentRead[idxRowsRead].failure)  {
          idxRowsRead++;
          rowsFailure++;
          continue;
        } 
        let whereClauseDef = '';
        let whereTmpIdx = 0;
        for (const whereTmp of crossRefValue.whereClause) {
          let clauseValue = '';
           // when json papa.parse is used
          if (isNaN(whereTmp.value)) {
            clauseValue = '\'' + whereTmp.value + '\'';
          } else {
            clauseValue = whereTmp.value;
          }
          
         // when json customised is used : CommonMethods.csvToJson
         // clauseValue = '\'' + whereTmp.value + '\'';

          if (whereTmpIdx === 0 ) {
            //  \'' + _secondArg + '\''
            whereClauseDef += ' WHERE ' + whereTmp.field + ' = ' + clauseValue ;
          } else {
            whereClauseDef += ' AND ' + whereTmp.field + ' = ' + clauseValue ;
          }
          whereTmpIdx++;
        }

        this.reportDataInput[idxReport].progressMessage = this._translate.instant('line') + ' ' + (idxRowsRead + 1 - rowsFailure).toString() + ' ' 
                          + this._translate.instant('out of') + ' ' + nbPrimaryLines.toString();
        this.reportDataInput[idxReport].progressValue =  Math.floor((idxRowsRead / nbPrimaryLines) * 100);
      
        try {
          const resultSQL = CommonMethods.sqlWhereSearch(rowsCSV[crossRefValue.fileNumTarget].data, whereClauseDef)[0];
          this.rowContentRead[idxRowsRead].fileContent[crossRefValue.fileNumTarget] = resultSQL;
          // rowContentRead[idxRowsRead][crossRefValue.fileNumTarget] = resultSQL;
          if (resultSQL === undefined) {
            this.rowContentRead[idxRowsRead].failure = true;
            const rowContentErrorTmp = {
              fileNumOrig: crossRefValue.fileNumOrig,
              fileNumTarget: crossRefValue.fileNumTarget,
              rowNum: idxRowsRead,
              whereClause: whereClauseDef,
              rowContent : this.rowContentRead[idxRowsRead],
              errorType: 'noCrossReference'
            };
            // this.rowContentError.push(rowContentErrorTmp);
            this.reportDataInput[idxReport].nbErrors++;
            this.reportDataInput[idxReport].rowContentError.push(rowContentErrorTmp);
          }
        } catch (error) {
          // console.log('ERREUR : ligne lue ' + idxRowsRead + ' erreur ' + JSON.stringify(error));
          this.rowContentRead[idxRowsRead].failure = true;
          const rowContentErrorTmp = {
            fileNumOrig: crossRefValue.fileNumOrig,
            fileNumTarget: crossRefValue.fileNumTarget,
            rowNum: idxRowsRead,
            whereClause: whereClauseDef,
            rowContent : this.rowContentRead[idxRowsRead],
            errorType: 'readCrossReference'
          };
          // this.rowContentError.push(rowContentErrorTmp);
          this.reportDataInput[idxReport].rowContentError.push(rowContentErrorTmp);
          this.reportDataInput[idxReport].nbErrors++;
        } 

        idxRowsRead++;

      }
      resolve();
    });
  } 
  sellingPriceCalculation(idxReport: number) {
    return new Promise<void>((resolve, reject) => {
      const algoDatas = this._wksCentralService.getAlgoCalculationData() as AlgoDatas;
      let calculationAlgo:  CalculationCmd[];
      let algoData: AlgoData[];
      if (this.isImportSupply) {
        calculationAlgo = this.algoImportedPrice;
        algoData = algoDatas.importedPrice;
      } else {
        calculationAlgo = this.algoLocalPrice;
        algoData = algoDatas.localPrice;
      }

      // this.economicAreaCur
      let idxRowsRead = 0;
      const nbLines = this.targetDataList.length;
      for ( const targetData of this.targetDataList) {

        this.reportDataInput[idxReport].progressMessage = this._translate.instant('line') + ' ' + (idxRowsRead + 1).toString() + ' ' 
                          + this._translate.instant('out of') + ' ' + nbLines.toString();
        this.reportDataInput[idxReport].progressValue =  Math.floor((idxRowsRead / nbLines) * 100);
        this.reportDataInput[idxReport].nbRows++;
        for (const rowAlgo of calculationAlgo) {
          if (!rowAlgo.fixedData) {
            continue;
          } 
          for (const algoDataCur of algoData) {
            if (algoDataCur.algoName === rowAlgo.constantName) {
              if (algoDataCur.dataOrigin === 'app') {
                 if (algoDataCur.dataName === 'economicAreaEntity') {
                  rowAlgo.currentValue = this.economicAreaCur;
                }
                if (algoDataCur.dataName === 'incomingCurrency') {
                  rowAlgo.currentValue = this.criterias.incomingCurrency;
                }
                if (algoDataCur.dataName === 'exchangeRate') {
                  rowAlgo.currentValue = this.criterias.exchangeRate;
                }
              } else if (algoDataCur.dataOrigin === 'file') {
                rowAlgo.currentValue = targetData[algoDataCur.dataName];
              }
            }
          }
        }
        this._calculationAlgoService.executeAlgo(calculationAlgo);
       
        const sellingPrice = Number(calculationAlgo[calculationAlgo.length - 1 ].currentValue);
        targetData.sellingPrice = Math.round(sellingPrice * 100) / 100;
        idxRowsRead++;
      }
      resolve();
    });
  } 
  ctrlMandatoryDatas(nbPrimaryLines: number, idxReport: number) {
    return new Promise<void>((resolve, reject) => {
      let idxRowsRead = 0;
      this.targetDataList = [];
      for ( const contentRead of this.rowContentRead) {

        if (contentRead.failure) {
          continue;
        }
        this.reportDataInput[idxReport].progressMessage = this._translate.instant('line') + ' ' + (idxRowsRead + 1).toString() + ' ' 
                          + this._translate.instant('out of') + ' ' + nbPrimaryLines.toString();
        this.reportDataInput[idxReport].progressValue =  Math.floor((idxRowsRead / nbPrimaryLines) * 100);
      
        const recordTarget = {};
        let idxFile = 0;
        for (const fileUpload of this.filesUpload) {
          for (const columnHeader of fileUpload.columnsHeader) {
            if (columnHeader.columnBdd !== undefined 
                  && columnHeader.columnBdd !== null
                  && columnHeader.columnBdd !== '') {
              let valueData: any;
              try {
                valueData = contentRead.fileContent[idxFile][columnHeader.columnName];
              } catch (error) {
                contentRead.failure = true;
                contentRead.targetRecordStatut.push('fieldDataFailure;' + idxFile + ';' + columnHeader.columnName);
                this.reportDataInput[idxReport].nbErrors++;
                break;
              } finally { }
              recordTarget[columnHeader.columnBdd]  = valueData;

              for (const colCur of this.colSchema) {
                if (colCur.colName === columnHeader.columnBdd) {
                  if (colCur.actionRules.add.mandatory) {
                    if (valueData === undefined || valueData === '' || valueData === null ) {
                      contentRead.failure = true;
                      contentRead.targetRecordStatut.push('mandatoryFailure;' + columnHeader.columnName);
                      this.reportDataInput[idxReport].nbErrors++;
                    }
                  }
                  if (colCur.colType === 'number') {

                    if (isNaN(valueData)) {
                      contentRead.failure = true;
                      contentRead.targetRecordStatut.push('numberFailure;' + columnHeader.columnName);
                      this.reportDataInput[idxReport].nbErrors++;
                    }
                  }
                  if (colCur.colType === 'decimal') {
                    try {
                      if (valueData.indexOf(',') > -1) {
                        try {
                          valueData = parseFloat(valueData.replace(/\./g, '').replace(',', '.'));
                          recordTarget[columnHeader.columnBdd]  = valueData;
                        } catch (error) {
                          // console.log ('decimal ' + valueData);
                          // valueData = '0.0';
                        }
                      }
                    } catch (error) {
                      // console.log ('decimal ' + valueData);
                      // valueData = '0.0';
                    }
                    if (isNaN(valueData)) {
                      contentRead.failure = true;
                      contentRead.targetRecordStatut.push('decimalFailure;' + columnHeader.columnName);
                      this.reportDataInput[idxReport].nbErrors++;
                    }
                  }
                  break;
                }
              }
            }
          }
          idxFile++;
        }
        for (const colTmp of this.colFinal) {
          let isFound = false;
          for (const colTmp1 of this.colSchema) {
            if (colTmp1.colName === colTmp.colName) {
              isFound = true;
              break;
            }
          }
          if (!isFound) {
            recordTarget[colTmp.colName]  = '';
          }
        }
        contentRead.targetRecord = recordTarget;
        this.targetDataList.push(recordTarget);
        idxRowsRead++;
      }
      resolve();
    });
  } 
  // https://javascript.hotexamples.com/fr/examples/papaparse/-/parse/javascript-parse-function-examples.html
  papaLoadPromise(fileCur: File) {

    return new Promise( (resolve, reject) => {
      this.papa.parse(fileCur, {
        header: true,
        delimiter: ';',
        dynamicTyping: true,
        skipEmptyLines: true,
        transformHeader: function(h) {
          h = h.trim();
          h = h.replace(/[ ]/g, '_');
          return h;
        },
        complete: results => {
          resolve(results);
        },
        error: (error) => {
          console.log('papaLoadPromise : ' + JSON.stringify(error));
          reject(JSON.stringify(error));
        },
      });
    });
  }
  modifFiles() {

  this.doAnalysisFile = false; 
  this.isReportActive = false; 
  this.doUploadMulti = false;
  this.initReportInput();

  }
  // upload process
  uploadMultiProcess() {

    this.closeMatDialog();

    this.messageInfo = 'uploadMultiProcess';
    this.dataMessageInfo1 = '' ;
    this.dataMessageInfo2 = '' ;
    this.messageBoxDisplay('uploadMultiProcess');
    this.doUploadMulti = false;
    this.uploadMultifilesPartsWks()
    .then(
      (val: any) => {
        // const messageRows: string[] = [];
        // ADD:6;UPDATE:0;DEL:0
        // const messageDetail = val.split(';');
        this.closeMatDialog();
        this.messageInfo = 'uploadMultiFilesOK';
        this.dataMessageInfo1 = '' + val ;
        this.messageBoxDisplay('uploadMultiFilesOK');
      },
      (error) => {
        this.closeMatDialog();
        this.fileRead = this.filesUpload[0].readFile;
        console.log('UPLOAD KO ' + this.fileRead.fileObject.name + ' : ' + error);
        this.messageInfo = 'upload file KO';
        this.dataMessageInfo1 = this.fileRead.nameFile ;
        this.dataMessageInfo2 = '(' + error + ')'  ;
        this.messageBoxDisplay('uploadKO');
      }
    ); // end then uploadFile

  }
  // upload
  private uploadMultifilesPartsWks() {
    return  new Promise<any>((resolve, reject) => {
     
      this.isUploadInProgress = true;
      const filesLight: FileUpload[] = [];
      let idxFile = 0;
      for (const fileUpload of this.filesUpload) {
        filesLight[idxFile] = JSON.parse(JSON.stringify(fileUpload));
        filesLight[idxFile].readFile.fileObject = undefined;
        filesLight[idxFile].jsonContent = undefined;
        idxFile++;
      }

      this._remoteFilesService.pushMultifilesPartsWks(filesLight, this.filesUpload, this._userService.getUserLogged().entity, this.criterias, this.targetDataList).subscribe((event) => {
          if (event instanceof HttpResponse) {
            console.log('File is completely uploaded!');
            this.isUploadInProgress = false;
            
            resolve(event.body);
          } else if (event.type === HttpEventType.UploadProgress) {
            this.progress.percentage = Math.round(100 * event.loaded / event.total);
            console.log('Upload ' + this.progress.percentage);
          }
        },
      response => {
        const numError = response.status;
        let message: String;
        switch (numError) {
          case 417:
            message = this._translate.getTranslate('Duplicate file');
            console.log('Error  Message ' + response.message +
                  + 'Status ' + response.status + ' user message : ' + message);
            break;
          case 418:
            message = this._translate.getTranslate('Error file');
            console.log('Error  Message ' + response.message +
                  + 'Status ' + response.status + ' user message : ' + message);
            break;
            case 200:
              break;
            case 202:
              break;
          default:
            message = this._translate.getTranslate('Upload error file');
            console.log('Error  Message ' + response.message +
                    + 'Status ' + response.status + ' user message : ' + message);
            break;
          }
          if (numError === 200 || numError === 202 ) {
            const messageText = response.error.text;
            resolve(messageText);
          }
        this.isUploadInProgress = false;
        reject(message);
        } ,
      );
    });
  }
  downloadErrors(idxReportError: number ): void {

    const reportType = this.reportDataInput[idxReportError].reportType;
    // let csvArray: any[] = [];
    let csvArray: any[];
    // let todayDate = new Date().toISOString().slice(0, 10);
    let todayDate = CommonMethods.formatDate(new Date());
    todayDate = todayDate.replace(/[-]/g, '');
    todayDate = todayDate.replace(/[ ]/g, '');
    todayDate = todayDate.replace(/[:]/g, '');
    const filename = 'errors_' + reportType + '_' + todayDate;

    switch (reportType) {
      case 'extractData':
        csvArray = this.extractdataErrors(idxReportError);
        break;
      case 'analysisData':
        csvArray = this.analysisDataErrors(idxReportError);
        break;
    }
    if (csvArray === undefined) {
      return;
    }
    this.saveFileCsv(csvArray, filename);
  }
  downloadRows(idxReport: number ): void {

    const reportType = this.reportDataInput[idxReport].reportType;
    // let csvArray: any[] = [];
    let csvArray: any[];
    // let todayDate = new Date().toISOString().slice(0, 10);
    let todayDate = CommonMethods.formatDate(new Date());
    todayDate = todayDate.replace(/[-]/g, '');
    todayDate = todayDate.replace(/[ ]/g, '');
    todayDate = todayDate.replace(/[:]/g, '');
    const filename = reportType + '_' + todayDate;
    csvArray = this.extractdataRows(idxReport);
    
    if (csvArray === undefined) {
      return;
    }
    this.saveFileCsv(csvArray, filename);
  }
  analysisDataErrors(idxReportError: number): any[] {
    const csvArray: any[] = [];
    let headersRow = '';
    // const lineSeparator = '\n';
    const lineSeparator = '\r\n';
    headersRow = 'rowNumber';
    for (const headerCur of this.filesUpload[0].columnsHeader) {
      headersRow += ';' + headerCur.columnName;
    }
    csvArray.push(headersRow + lineSeparator);
    let rowNumber = 0;
    for (const rowContentCur of this.rowContentRead) {

      if (!rowContentCur.failure) {
        continue;
      }
      if (rowContentCur.targetRecordStatut.length < 1) {
        continue;
      }
      
      let rowCsv = (rowNumber + 1).toString();
      const rowContent =  rowContentCur.fileContent[0];
      for (const headerCur of this.filesUpload[0].columnsHeader) {
        let columnContent = rowContent[headerCur.columnName];
        if (columnContent === null) {
          columnContent = '';
        }
        rowCsv += ';' +  columnContent;
      }
      rowCsv += ';' + rowContentCur.targetRecordStatut;
      csvArray.push(rowCsv + lineSeparator);
      rowNumber++;
    }

    return csvArray;
  }
  extractdataErrors(idxReportError: number): any[] {
    const csvArray: any[] = [];
    let headersRow = '';
    // const lineSeparator = '\n';
    const lineSeparator = '\r\n';
    headersRow = 'rowNumber';
    for (const headerCur of this.filesUpload[this.reportDataInput[idxReportError].fileOrig].columnsHeader)  {
      headersRow += ';' + headerCur.columnName;
    }
    csvArray.push(headersRow + lineSeparator);

    for (const errorRow of this.reportDataInput[idxReportError].rowContentError) {
      const rowContent =  errorRow.rowContent.fileContent[this.reportDataInput[idxReportError].fileOrig];
      let rowCsv = errorRow.rowNum.toString();
      for (const headerCur of this.filesUpload[this.reportDataInput[idxReportError].fileOrig].columnsHeader) {
        let columnContent = rowContent[headerCur.columnName];
        if (columnContent === null) {
          columnContent = '';
        }
        rowCsv += ';' +  columnContent;
      }
      csvArray.push(rowCsv + lineSeparator);
    }
    return csvArray;

  }
  extractdataRows(idxReport: number): any[] {
    const csvArray: any[] = [];
    let headersRow = '';
    // const lineSeparator = '\n';
    const lineSeparator = '\r\n';
    headersRow = 'rowNumber';
    const hearderColums = this.targetDataList[0];
    const headersNames = Object.keys(hearderColums);
    for (const headerCur of headersNames)  {
      headersRow += ';' + headerCur;
    }
    csvArray.push(headersRow + lineSeparator);
    let iRow = 0 ;
    for (const rowData of this.targetDataList) {
      iRow++;
      let rowCsv = iRow.toString();
      for (const headerCur of headersNames) {
        let columnContent = rowData[headerCur];
        if (columnContent === null) {
          columnContent = '';
        }
        rowCsv += ';' +  columnContent;
      }
      csvArray.push(rowCsv + lineSeparator);
    }
    return csvArray;
  }
// ========================================================== end multifiles 
// ============================================================== start files
  onFileChange(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.alertMessage = false;
    this.doUpload = false;
    this.doVerify = false;

    if (event.target.files && event.target.files.length > 0) {
      const files = event.target.files;
      if ((files.length > 0) && (this.isValidFiles(files))) {
        const file: File = files[0];
        const readingFile: ReadFile = new ReadFile();
        readingFile.nameFile = file.name;
        readingFile.sizeFile = file.size;
        readingFile.typeFile = file.type;
        readingFile.fileObject = file;
        readingFile.messageFile = '';
        readingFile.uploadedFile = false;
        readingFile.validatedFile = false;
        this.doUpload = false;
        this.doVerify = true;
        this.fileRead = readingFile;
        this.uploadForm.controls.uploadFileName.setValue(readingFile.nameFile);
      } else  {
        this.doUpload = false;
        this.doVerify = false;
        this.uploadForm.controls.uploadFileName.setValue('');
        this.fileRead = undefined;
      }
    } 

  }
  private isValidFiles(files): boolean {

    // Check Number of files
    if (files.length > this.maxNbFiles) {
      this.alertMessage = true;
      this.messageType = 'ERROR';
      this.messageInfo = 'The maximum number of files is reached';
      this.dataMessageInfo1 = files.length.toString() ;
      this.dataMessageInfo2 = this.maxNbFiles.toString() ;
      this.messageBoxDisplay('lenghtfile');
      return false;
    } else {
      return this.isValidFileExtension(files);
    }

  }
  private isValidFileExtension(files: any): boolean {
    // Make array of file extensions
    const extensions = (this.fileExt.split(','))
                    .map(function (x) { return x.toLocaleUpperCase().trim(); } );
    this.alertMessage = false;
    for (let i = 0; i < files.length; i++) {
        // Get file extension
        const ext = files[i].name.toUpperCase().split('.').pop() || files[i].name;
        // Check the extension exists
        const exists = extensions.includes(ext);
        if (!exists) {
            this.alertMessage = true;
            this.messageType = 'ERROR';
            this.messageInfo = 'unrecognized extension';
            this.dataMessageInfo1 = ext ;
            this.dataMessageInfo2 = files[i].name ;
            this.messageBoxDisplay('extensionfile');
            break;
        }
        if (!this.alertMessage) {
        // Check file size
          this.isValidFileSize(files[i], ext);
          break;
        } else {
          continue;
        }
    }
    if (this.alertMessage) {
      return false;
    } else {
      return true;
    }
  }

  private isValidFileSize(_file: any, ext: string): boolean {
    const fileSizeinMB = _file.size / (1024 * 1000);
    const size = Math.round(fileSizeinMB * 100) / 100; // convert upto 2 decimal place
    let sizeIsValid = true;
    switch (ext) {
      case 'PDF':
        if (size > this.maxSizePdf) {
            this.alertMessage = true;
            this.messageType = 'ERROR';
            this.messageInfo = 'too heavy file size';
            this.dataMessageInfo1 = _file.name  ;
            this.dataMessageInfo2 = size.toString() + ' MB / ' + this.maxSizePdf.toString() + 'MB MAX' ;
            this.messageBoxDisplay('lenghtfile');
            sizeIsValid = false;
        }
        break;
        case 'CSV':
          if (size > this.maxSizeCSV) {
            this.alertMessage = true;
            this.messageType = 'ERROR';
            this.messageInfo = 'too heavy file size';
            this.dataMessageInfo1 = _file.name  ;
            this.dataMessageInfo2 = size.toString() + ' MB / ' + this.maxSizeCSV.toString() + 'MB MAX' ;
            this.messageBoxDisplay('lenghtfile');
            sizeIsValid = false;
          }
          break;
          default: 
          if (size > this.maxSizeDefault) {
            this.alertMessage = true;
            this.messageType = 'ERROR';
            this.messageInfo = 'too heavy file size';
            this.dataMessageInfo1 = _file.name  ;
            this.dataMessageInfo2 = size.toString() + ' MB / ' + this.maxSizeDefault.toString() + 'MB MAX' ;
            this.messageBoxDisplay('lenghtfile');
            sizeIsValid = false;
          }
    }
    return sizeIsValid;
  }
  // drag and drop 
  @HostListener('dragover', ['$event']) public onDragOver(evt: Event) {
    evt.preventDefault();
    evt.stopPropagation();
    this.dropArea.nativeElement.style.background = 'red';
    this.dropArea.nativeElement.style.border = 'dashed 3px red';
    this.alertMessage = false;
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(evt: Event) {
    evt.preventDefault();
    evt.stopPropagation();
    this.dropArea.nativeElement.style.background = 'var(--background-primary)!important';
    this.dropArea.nativeElement.style.border = 'dotted 3px grey'; 
  }

  @HostListener('drop', ['$event']) public onDrop(evt: any) {
    evt.preventDefault();
    evt.stopPropagation();
    this.dropArea.nativeElement.style.background = 'var(--background-primary)!important';
    this.dropArea.nativeElement.style.border = 'dotted 3px grey';
    const files = evt.dataTransfer.files;


    if ((files.length > 0) && (this.isValidFiles(files))) {
      const file: File = files[0];
      const readingFile: ReadFile = new ReadFile();
      readingFile.nameFile = file.name;
      readingFile.sizeFile = file.size;
      readingFile.typeFile = file.type;
      readingFile.fileObject = file;
      readingFile.messageFile = '';
      readingFile.uploadedFile = false;
      readingFile.validatedFile = false;
      this.doUpload = false;
      this.doVerify = true;
      this.fileRead = readingFile;
      this.uploadForm.controls.uploadFileName.setValue(readingFile.nameFile);
    } else  {
      this.doUpload = false;
      this.doVerify = false;
      this.uploadForm.controls.uploadFileName.setValue('');
      this.fileRead = undefined;
    }
  }
// ============================================================== end files
  doCancel() {
    if  (this.statutCall === 'sidenav') {
      this._wksCentralService.onRegisterValid.emit('cancelUpload');
      return;
    }
  }
  uploadFile() {
    this.uploadProcess(this.fileRead.fileObject).then(
      (val: any) => {
        const messageRows: string[] = [];
        // ADD:6;UPDATE:0;DEL:0
        const messageDetail = val.split(';');
        const addedRows = messageDetail[0].split(':')[1];
        const updatedRows = messageDetail[1].split(':')[1];
        const deletedRows = messageDetail[2].split(':')[1];
        if (addedRows !== '0')  {
          messageRows.push( this._translate.instant('partsSuppliesAdded', [addedRows]));
        }
        if (updatedRows !== '0')  {
          messageRows.push( this._translate.instant('partsSuppliesUpdated', [updatedRows]));
        }
        if (deletedRows !== '0')  {
          messageRows.push( this._translate.instant('partsSuppliesDeleted', [deletedRows]));
        }
        let row = 0;
        for (const rowCur of messageRows)  {
          if (row === 0) {
            this.dataMessageInfo2 = rowCur;
          } else {
            this.dataMessageInfo2 = ' -- ' + rowCur;
          }
          row++;
        }
        this.messageInfo = 'upload file OK';
        this.dataMessageInfo1 = this.fileRead.nameFile ;
        
        this.doVerify = false;
        this.doUpload = false;
        this.uploadForm.controls.uploadFileName.setValue('');
        this.messageBoxDisplay('uploadOK');
      },
      (error) => {
        console.log('UPLOAD KO ' + this.fileRead.fileObject.name + ' : ' + error);
        this.messageInfo = 'upload file KO';
        this.dataMessageInfo1 = this.fileRead.nameFile ;
        this.dataMessageInfo2 = '(' + error + ')'  ;
        this.doVerify = false;
        this.doUpload = false;
        this.uploadForm.controls.uploadFileName.setValue('');
        this.messageBoxDisplay('uploadKO');
      }
    ); // end then uploadFile

  }
  private endProcessFile() {
    this.closeMatDialog();
    if  (this.statutCall === 'sidenav') {
      this._wksCentralService.onRegisterValid.emit('closeUpload');
      return;
    }
  }
  
  verifyFile(): boolean {
    this._controlCsvService.setColumsHeaders('partsSupplies', this._wksCentralService.getColumnsHeadersPartsSuppliesCsv());

    let isErrors = false;
    this.readFile(this.fileRead.fileObject)
    .then((result) => {
      const arrayOfLines = result as any[];
      const colErrorList: ColumnsHeaderStatut[] = this._controlCsvService.controlColumns(arrayOfLines, this.fileRead.fileObject.name, 'partsSupplies');
      if (colErrorList.length > 0) {
        isErrors = true;
        this.colErrors = [];
        for (const errorCur of colErrorList) {
          if (errorCur.notPresent) {
            this.colErrors.push(this._translate.instant('missingColumn', [errorCur.columnName] ));
          }
          if (errorCur.unKnow) {
            this.colErrors.push(this._translate.instant('unknownColumn', [errorCur.columnName] ));
          }
        }
        this.messageBoxDisplay('csvControl');
      } else {
        this.colErrors = [];
        const listError = this._controlCsvService.linesAnalyzeProcess(arrayOfLines,  
                  this._wksCentralService.getColumnsHeadersPartsSuppliesCsv(), this. _userService.getUserLogged(), this.fileRead.fileObject.name);
       
        if (listError.length > 0) {
          for (const errorCur of listError) {
            this.colErrors.push(this._controlCsvService.getErrorProcess(errorCur));
          }
          this.messageBoxDisplay('csvControl');
          this.doVerify = true;
          this.doUpload = false;
          return false;
        } else {
          this.doVerify = false;
          this.doUpload = true;
          return true;
        }
      }
    })
    .catch((error) => {
      console.error(error);
      return false;
    }); 
    return !isErrors;
  }
  private readFile(_file: File) {

    return  new Promise((resolve, reject) => {
      this._controlCsvService.readCsvFile(_file).then(
        (arrayOfLines) => {
          resolve(arrayOfLines);
        },
        (error) => {
          console.error('File could not be read! Code ' + error);
          reject('ProcessControlKO');
        },
      ); 
    });
  }
  // upload
  private uploadProcess(_file: File) {
    return  new Promise<void>((resolve, reject) => {
      this.isUploadInProgress = true;
      this._remoteFilesService.pushFilePartsSupplies(_file, 'partsSupplies', this._userService.getUserLogged().entity).subscribe((event) => {
          if (event instanceof HttpResponse) {
            console.log('File is completely uploaded!');
            this.isUploadInProgress = false;
            resolve();
          } else if (event.type === HttpEventType.UploadProgress) {
            this.progress.percentage = Math.round(100 * event.loaded / event.total);
            console.log('Upload ' + this.progress.percentage);
          }
        },
        response => {
          const numError = response.status;
          let message: String;
          switch (numError) {
            case 417:
              message = this._translate.getTranslate('Duplicate file');
              console.log('Error  Message ' + response.message +
                    + 'Status ' + response.status + ' user message : ' + message);
              break;
            case 418:
              message = this._translate.getTranslate('Error file');
              console.log('Error  Message ' + response.message +
                    + 'Status ' + response.status + ' user message : ' + message);
              break;
              case 200:
                break;
              case 202:
                break;
            default:
              message = this._translate.getTranslate('Upload error file');
              console.log('Error  Message ' + response.message +
                      + 'Status ' + response.status + ' user message : ' + message);
              break;
            }
            if (numError === 200 || numError === 202 ) {
              const messageText = response.error.text;
              resolve(messageText);
            }
          this.isUploadInProgress = false;
          reject(message);
          } ,
        );
      });
  }
  // errors files
  saveFileCsv(csvArray: any[], fileName: string): void {
    CommonMethods.saveFileCsv(csvArray, fileName);
  }

  // ========================================================== errors alerts 
  messageBoxDisplay(_actionCur: string) {
    const dialogConfig = new MatDialogConfig();
    let titleBox: string;
    let action: string;
    let typeBox = 'alertWks';
    let progressDeterminate: boolean;
    const argsMessage = [this.dataMessageInfo1, this.dataMessageInfo2];
    const messageBox = this._translate.instant(this.messageInfo, argsMessage );

    this.modifFiles();
    this.isReportActive = false;

    if (_actionCur === 'lenghtfile') {
      titleBox = 'too heavy file'; 
      this.colErrors = undefined;
    }
    if (_actionCur === 'extensionfile') {
      titleBox = 'extension error';
      this.colErrors = undefined;
    }
    if (_actionCur === 'csvControl') {
      titleBox = 'ReportAnalyzeCSV';
      action = 'csvControl';
    }
    if (_actionCur === 'uploadOK') {
      titleBox = 'upload File parts & supplies';
      this.colErrors = undefined;
    }
    if (_actionCur === 'uploadKO') {
      titleBox = 'upload File parts & supplies';
      this.colErrors = undefined;
    }
    if (_actionCur === 'analysisFile') {
      titleBox = 'analysisFileTitle';
      this.colErrors = undefined;
      typeBox = 'infoProgressWks';
    }
    if (_actionCur === 'verifCrossReferences') {
      titleBox = 'verifCrossReferencesTitle';
      typeBox = 'infoProgressWks',
      progressDeterminate = false;
      this.colErrors = undefined;
    }
    // uploadMultiProcess
    if (_actionCur === 'uploadMultiProcess') {
      titleBox = 'uploadMultiProcessTitle';
      typeBox = 'infoProgressWks';
      this.colErrors = undefined;
    }
    // uploadMultiFilesOK
    if (_actionCur === 'uploadMultiFilesOK') {
      titleBox = 'uploadMultiFilesOKTitle';
      this.colErrors = undefined;
    }
    if (_actionCur === 'extractData') {
      titleBox = 'extractData';
      typeBox = 'infoProgressWks';
      progressDeterminate = true;
      this.colErrors = undefined;
    }
    if (_actionCur === 'analysisData') {
      titleBox = 'analysisData';
      typeBox = 'infoProgressWks';
      progressDeterminate = true;
      this.colErrors = undefined;
    }
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      id: 1,
      title: this._translate.getTranslate(titleBox),
      typeDialog: typeBox,
      panelClass: 'stdTheme',
      contentMessage: messageBox,
      data1: '',
      data2: '',
      actionCur: action,
      progressDeterminate: progressDeterminate,
      errorsList: this.colErrors,
      messageType: 'ERROR'
    };
 //   this._dialog.open(ModalCommonComponent, dialogConfig);
    this.zone.run(() => this.dialogRef = this._dialog.open(ModalCommonComponent, dialogConfig));
    // this.dialogRef = this._dialog.open(ModalCommonComponent, dialogConfig);
    
    this.dialogRef.afterClosed()
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
        if (_actionCur === 'uploadOK') {
          this.endProcessFile();
        } else if (_actionCur === 'uploadMultiFilesOK') {
          this.endProcessFile();
        } else {
          this.closeMatDialog();
        }
        // console.log('Dialog output:', data);
        // this.dialogRef = null;
      });

  }
  closeMatDialog(): void {
    this._dialog.closeAll();

    // console.log(this._dialog.openDialogs.length);
    if (this._dialog.openDialogs.length > 0) {
  
      for (const instCur of this._dialog.openDialogs) {
        this._dialog.openDialogs.splice(0, 1);
      }
    }
    this.dialogRef = null;
  }
  openModalBasic(modalStatutCallArg: string) {

    this.modalStatutCall = modalStatutCallArg;

    if (modalStatutCallArg === 'verifCrossReferences') {
      this.modalParam = { 
        modalDismissClose: false,
        progressBar: 'undeterminate',
        modalTitle: this._translate.instant('verifCrossReferencesTitle')
      };
    
      this.modalDataInput = {
        contentMessage: this._translate.instant('verifCrossReferences')
      };
    }

    const configModalBasic = {
      class: 'modal-dialog-centered modal-dialog-basic',
      backdrop: true,
      ignoreBackdropClick: true,
      animated: true,
    };
    this.modalRef = this._modalService.show(this.modalBasic, configModalBasic);
  } 
  closeModalBasic() {
    this.modalRef.hide();
  }
}
