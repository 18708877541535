import { Component, OnInit, SimpleChanges, OnChanges, Input, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { Subscription, Subject } from 'rxjs';

import { UserService } from '../../../../../../services/user.service';
import { WksCentralService } from '../../../../services/wks-central.service';
import { TranslateService } from '../../../../../../services/translate.service';

import { WksEntityModel } from '../../../../models/wks-entity.model';
import {  BrandWksModel } from '../../../../models/wks-common.model';
import { UpdateRatesModel } from '../../../../models/wks-param.model';

import { ModalCommonComponent } from '../../../../../common/components/modal-common/modal-common.component';
import { LabelValue } from '../../../../../../models/data.model';

@Component({
  selector: 'mdi-parts-supplies-update',
  templateUrl: './parts-supplies-update.component.html',
  styleUrls: ['./parts-supplies-update.component.css']
})
export class PartsSuppliesUpdateComponent implements OnInit, OnChanges  {
  @Input() statutCall: any;
  @ViewChild('filterbrand', {static: false}) filterbrand: ElementRef;
  private readonly onDestroy = new Subject<void>();

  paramTypeList: LabelValue[];
  isFinishLoading: boolean;
  isUpdateInProcess: boolean;

  
  updateRatesForm: FormGroup;

  titleCard: string;

  brandCacheList: BrandWksModel[];
  brandFilteredList: BrandWksModel[];
  brandSelected: BrandWksModel;

  entityCur: string;

  wksEntity: WksEntityModel;
  isIncrease: boolean;
  isReduction: boolean;

  updateRatesValues: UpdateRatesModel;
  isSearch: boolean;
  isAddOne: boolean;
  isUploadInProgress: boolean;

  constructor(private _userService: UserService,
    private _translate: TranslateService,
    private _wksCentralService: WksCentralService,
    private _dialog: MatDialog,
    private fb: FormBuilder) {

    }

  ngOnInit() {
  }
  ngOnChanges(changes: SimpleChanges) {
 
    const listKey = Object.keys(changes);
    for (const propName of listKey) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {

          case 'statutCall': {
            this.statutCall = changes['statutCall'].currentValue;
            break;
          }

        } // end switch
      } // end if
    } // end loop
    
    this.initData();
  }
  initData() {
    if ((this._userService.getUserLogged() === undefined) || ( this._userService.getUserLogged().entity === undefined)) {
      return;
    }

    const listItem = this._wksCentralService.getTypesPartsSupplies();
    this.paramTypeList = [];
    for (const item of listItem) {
           const itemCur: LabelValue = {
        label: this._translate.getTranslate(item),
        value: item,
      };
      this.paramTypeList.push(itemCur);
    }

    this.titleCard = this._translate.instant('updateRatesTitle');
    this.isFinishLoading = true;
    this.isUpdateInProcess = false;
    
    this.updateRatesForm = this.fb.group({
      paramType: this.fb.control({value: ''}),
      brandName: this.fb.control({value: ''}),
      brandRefs: this.fb.control({value: ''}),
      percentSens: this.fb.control({value: ''}),
      percentRate: this.fb.control({value: ''}),
    },
    {
      updateOn: 'blur'
    });

    this.entityCur = this._userService.getUserLogged().entity;   

    this.loadEntity();
  }
  loadEntity() {
    
    this._wksCentralService.getWksEntity(this.entityCur)
    .subscribe(
      data => {
        this.wksEntity = data.body;
        this.loadBrands()
        .then (brandList => {
       
          this.fillForm();
          this.isFinishLoading = true;
        })
        .catch(error  => {
          console.log(error);
          this.isFinishLoading = true;
        });
      },
      () => {
        console.log('load entity error');
      }
    );
  }

  loadBrands() {
    if (this._userService.getUserLogged() === undefined ) { return; }
    this.brandCacheList = [];
    this.brandFilteredList = [];

    let brandList: BrandWksModel[];
  
    return new Promise((resolve, reject) => {
      this._wksCentralService.getWksBrandsList(this._userService.getUserLogged().entity)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(
        data => {
          brandList = data.body;
          for (const brandCur of brandList) {
            this.brandCacheList.push(brandCur);
          }
          this.brandCacheList.sort((obj1, obj2) => {
            return obj1.brandName > obj2.brandName ? 1 : -1;
          });
          this.brandCacheList.splice(0, 0,  {stdEntity: this._userService.getUserLogged().entity, brandRow: 'brand', brandName: ''} );
          this.brandFilteredList = [...this.brandCacheList];
          resolve(brandList);
        }, err => {
          console.log(err);
          if (err.status === 404) {
            this.brandCacheList.push({
              stdEntity: this._userService.getUserLogged().entity,
              brandRow: 'brand',
              brandName: ''
            });
            this.brandFilteredList = [...this.brandCacheList];

          }
          reject(err.status);
        }
      );
    });
  }
  onChangeBrand($event: any) {
    // console.log('onChangeBrand : ' + $event);
    let brandSelected: BrandWksModel;
    for (const curBrand of this.brandCacheList) {
      if (curBrand.brandName === $event) {
        brandSelected = curBrand;
        break;
      }
    }
    this.brandSelected = brandSelected;
  }

  onKeyBrand($event: any) {
    // console.log(value);
  
    let valueFinal: any;
    if ($event === '') {
      valueFinal = '';
    } else  {
      valueFinal = $event.target.value;
      if ( $event.keyCode === 32) {
        valueFinal += ' ';
      }
    }
    this.brandFilteredList = this.searchBrand(valueFinal);
    this.filterbrand.nativeElement.value = valueFinal;
  }
  searchBrand(value: string) { 
    const filter = value.toLowerCase();
    return this.brandCacheList.filter(brandCur => brandCur.brandName.toLowerCase().includes(filter));
  }
  addBrand(newBrandName: string) {
    // console.log(newBrand);
    if (newBrandName === '') {
      return;
    }
    const newBrand = {
      stdEntity: this._userService.getUserLogged().entity,
      brandRow: 'brand',
      brandName: newBrandName,
      equipType: '',
      modelName: '',
    };
    this.brandCacheList.push(newBrand);
    this.brandCacheList.sort((obj1, obj2) => {
      return obj1.brandName > obj2.brandName ? 1 : -1;
    });
    if (this.brandCacheList[0].brandName === '') {
      this.brandCacheList.splice(0, 1);
    }
    this.brandFilteredList = [...this.brandCacheList];

    // this._wksCentralService.saveWksBrand( newBrand, 'create');
    this._wksCentralService.saveBrandModel(newBrand);
  }
  onStructureChange(event: any) {
    this.isIncrease = false;
    this.isReduction = false;
    if (event === 'increase')  {
      this.isIncrease = true;
    }
    if (event === 'reduction')  {
      this.isReduction = true;
    }
  }
  
  numberOnly(event: any, min: number, max: number): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  fillForm() {

    this.updateRatesForm.controls.paramType.setValue('part'); 
    this.updateRatesForm.controls.brandName.setValue(''); 
    this.updateRatesForm.controls.brandRefs.setValue(''); 
    this.updateRatesForm.controls.percentSens.setValue('increase'); 
    this.updateRatesForm.controls.percentRate.setValue('1.00'); 
  }
  doCancel() {
    if  (this.statutCall === 'sidenav') {
      this._wksCentralService.onRegisterValid.emit('cancelUpdate');
      return;
    }
  }
  processUpdate() {
    // console.log('deleteEntity Row clicked: ', row);
    let titleBox: string;
    let messageBox: string;
    titleBox = 'Process update';
    

    titleBox = this._translate.getTranslate(titleBox);
    messageBox = 'OK to process ?';

    this.displayMessageBox(titleBox, messageBox, 'WARNING', 'confirmAction', 'processUpdateRates');

  }
  fillModel() {
    this.updateRatesValues = {
      stdEntity: this.entityCur,
      paramType: this.updateRatesForm.controls.paramType.value,
      brandName: this.updateRatesForm.controls.brandName.value,
      brandRefs: this.updateRatesForm.controls.brandRefs.value,
      percentSens: this.updateRatesForm.controls.percentSens.value,
      percentRate: this.updateRatesForm.controls.percentRate.value,
      paramScaleName: 'PUBLIC'
    };

  }
  processUpdateOK() {
    let titleBox: string;
    let messageBox: string;
    titleBox = 'End process update';
    titleBox = this._translate.getTranslate(titleBox);
   

    this.fillModel(); 
    this._wksCentralService.updatesRatesPartsSupplies(this.updateRatesValues, 'update')
    .subscribe(
      data => {
        // this.dataSource.data = data.body;
        const updateRatesReturn = data.body as UpdateRatesModel;
        const eventsList = updateRatesReturn.statut.split(';');
        messageBox =  this._translate.instant('Process update OK', [eventsList[1]]);
        this.displayMessageBox(titleBox, messageBox, 'WARNING', 'confirmAction', 'closeUpdate');
      },
      () => {
        messageBox =  this._translate.getTranslate('Process update KO');
        this.displayMessageBox(titleBox, messageBox, 'WARNING', 'confirmAction', 'closeUpdate');
      }
    );
    
  }
  displayMessageBox(_titleBox: string, _messageBox: string, _messageType: string, _typeDialog: string, _actionCur: string) {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      id: 1,
      title: _titleBox,
      typeDialog: _typeDialog,
      panelClass: 'stdTheme',
      contentMessage: _messageBox,
      data1: '',
      data2: '',
      messageType: _messageType

      };

    const dialogRef = this._dialog.open(ModalCommonComponent, dialogConfig);

    dialogRef.afterClosed()
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
        // console.log('Dialog output:', data);
        if ((_actionCur === 'closeUpdate')) {
          this._wksCentralService.onRegisterValid.emit('closeUpdate');
        }
        if (( data === 'okAction') && (_actionCur === 'processUpdateRates')) {
          this.processUpdateOK();
        }
      });

  }
}
