import { Component, OnInit, ViewChild, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { UserService } from '../../../../../../services/user.service';
import { RessourcesService } from '../../../../../../job/common/services/ressources.service';
import { WksCentralService } from '../../../../services/wks-central.service';
import { TranslateService } from '../../../../../../services/translate.service';
import { WksEntityModel } from '../../../../models/wks-entity.model';

import { TasksActionsModel, TaskrefJsonModel,
          WksTasksModel, ParentTask, TasksActionsContentModel, 
          IhmOptions, TasksActionCompletedModel, } from '../../../../models/wks-param.model';
import { WksEquipmentModel} from '../../../../models/wks-equipment.model';

import { WksWorksModel, WorkEquipmentRef, WorkEquipments } from '../../../../models/wks-works.model';
import { InternationalsFormats } from '../../../../../../models/data.model';
import { MatTabChangeEvent } from '@angular/material';
import { VerificationCkecklistComponent } from './verification-ckecklist/verification-ckecklist.component';
import { GlobalEventsManagerService } from '../../../../../../job/common/services/global-events-manager.service';
import { WksWorksService } from '../../../../services/wks-works.service';

@Component({
  selector: 'mdi-works-checklist',
  templateUrl: './works-checklist.component.html',
  styleUrls: ['./works-checklist.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class WorksChecklistComponent implements OnInit {
  @Input() statutCall: any;
  @Input() actionType: string;

  @Input() iRow: number;
  @Input() ihmOptions: IhmOptions;
  @Input() wksWorks: WksWorksModel;
  
  private readonly onDestroy = new Subject<void>();

  wksEntity: WksEntityModel;
  isReadOnly: boolean;

  modalTitle: string;
  workCur: WksWorksModel;
  workEquipments: WorkEquipments;
  checkEquipmentsIsLoaded: boolean;
  itemChoose: string;
  nameParent: string;

  ihmOptionsCkeck: IhmOptions;
  internationalsFormats: InternationalsFormats;
  dateFormat: string;
  dateFormatMoment: string;
  dateTimeFormat: string;
  checkEquipments: WksEquipmentModel [];
  workEquipmentId: WorkEquipmentRef;

  isCheckLoaded: boolean;
  isOkToValid: boolean;
  isOKToUpdate: boolean;
  itemEquip: WksEquipmentModel;
  itemTasks: TasksActionCompletedModel;
  tasksActionsCompleted: TasksActionCompletedModel[];
  tasksActionsArgs: TasksActionCompletedModel[];
  
 constructor(
    private _userService: UserService, 
    private dialog: MatDialog,
    private _globalEventsManagerService: GlobalEventsManagerService,
    private _wksCentralService: WksCentralService,
    private _translateService: TranslateService,
    private _ressourcesService: RessourcesService,
    private _wksWorksService: WksWorksService
  ) { }

  ngOnInit() {
    this.initData();
  }
  initData() {
    this.wksEntity = this._wksCentralService.getWksEntityCur();
    this.initAllBooleans();
    this.initAllParam();
    this.initAllSubscribe();
    this.loadWork();
    /*.then (() => {

    }, () => {


    }); // loadWork
    */
  }
  initAllBooleans() {
    this.isOKToUpdate = false;
    this.checkEquipmentsIsLoaded = false;
    this.isReadOnly = true;
    this.isCheckLoaded = false;
    this.isOkToValid = false;
  }

  initAllParam() {
    this.wksEntity = this._wksCentralService.getWksEntityCur();
    const otherData = JSON.parse(this.wksEntity.otherData);
    const internationnalFormat = otherData.internationnalFormat;
    this.internationalsFormats = this._ressourcesService.getIntFormat(internationnalFormat);
    for (const fmtCur of this.internationalsFormats.datetime) {
      if (fmtCur.name === 'dateFormat') {
        this.dateFormat = fmtCur.value;
        this.dateFormatMoment = fmtCur.value;
        this.dateFormatMoment = this.dateFormatMoment.replace('dd', 'DD');
        this.dateFormatMoment = this.dateFormatMoment.replace('yyyy', 'YYYY');
      }
      if (fmtCur.name === 'timeFormat') {
        this.dateTimeFormat = fmtCur.value;
        this.dateTimeFormat = this.dateTimeFormat.replace('dd', 'DD');
        this.dateTimeFormat = this.dateTimeFormat.replace('yyyy', 'YYYY');
      }

    }
    this.isCheckLoaded = true;
    this.getIhmOptions();
  }
  initAllSubscribe() {
    this._globalEventsManagerService.synchroEvents
    .pipe(takeUntil(this.onDestroy))
    .subscribe( event => {
      if (event === 'workfinished') {
        this.isOKToUpdate = false;
        const eventWork = {
          workevent: 'finished',
          iRow: this.iRow,
          workStatut: 'finished',
        };
        this._globalEventsManagerService.synchroEvents.next(eventWork);
      } else if (event) {
        const typeEvent = typeof event;
        const eventString = String(event);
        // console.log(typeEvent);
      }
    });
  }
  loadWork()  {

    this.workCur = JSON.parse(JSON.stringify(this.wksWorks));
    this.initUpdate();
    this.workEquipments = {
      mainEquipment: undefined,
      subEquipments: undefined
    };
    this.workEquipmentId = JSON.parse(this.workCur.workEquip);
    this.loadMainEquipment(this.workEquipmentId.mainEquipment)
    .then ((response: WksEquipmentModel) => {
      this.workEquipments.mainEquipment = response;
      if (this.workEquipmentId.subEquipments) {
        this.workEquipments.subEquipments = [];
        this.loadsubEquipments()
        .then ((responseCur: WksEquipmentModel[]) => {
          // responseCur.isWorkEquip = true;
          this.workEquipments.subEquipments = responseCur;
          for (const itemEquipment of this.workEquipments.subEquipments) {
            itemEquipment.isWorkEquip = true;
          }
          this.initCheck();
        })
        .catch ((error: number) => {
          console.log('loadSubEquipment ' + error);
          return;
        });
      } else {
        this.initCheck();
      }
    })
    .catch ((error: number) => {
      console.log('loadEquipment ' + error);

      return;
    });
  }
  
  loadsubEquipments() {

    if (this._userService.getUserLogged() === undefined ) { return; }
    let equipList = '';
    for (const equipCur of this.workEquipmentId.subEquipments) {
      if (equipList === '') {
        equipList = equipCur;
      } else {
        equipList = equipList + ';' + equipCur;
      }
    }

    return new Promise((resolve, reject) => {
      this._wksCentralService.getWksSubEquipment(this.workCur.stdEntity, equipList)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(
        data => {
          resolve(data.body);
        }, err => {
          // console.log('getOwnerEquipmentList ' + err.message);
          reject(err.status);
        }
      );
    });
  }
  loadMainEquipment(equipId: string) {

    if (this._userService.getUserLogged() === undefined ) { return; }

    return new Promise((resolve, reject) => {
      this._wksCentralService.getWksEquipmentByEquipId(this.workCur.stdEntity, equipId)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(
        data => {
          resolve(data.body);
        }, err => {
          // console.log('getOwnerEquipmentList ' + err.message);
          reject(err.status);
        }
      );
    });
  }

  getIhmOptions(): void {
    this.ihmOptionsCkeck = JSON.parse(JSON.stringify(this.ihmOptions));
    // this.ihmOptionsCkeck.readOnly = true;
    this.ihmOptionsCkeck.collapse = false;
    this.isReadOnly = true;
  }
  initUpdate(): void {
    this.isOKToUpdate = false;

    switch (this.workCur.workStatut) {
      case 'finished': {
        this.isOKToUpdate = false;
        break;
      } // end case
      case 'assigned': {
        this.isOKToUpdate = true;
        break;
      } // end case
      case 'stand-by': {
        this.isOKToUpdate = true;
        break;
      } // end case
      case 'in-progress': {
        this.isOKToUpdate = true;
        break;
      } // end case
      default: {
        this.isOKToUpdate = false;
        break;
      } // end case 
    }
  }

  initCheck(): void {
    this.checkEquipments = [];
    if (this.workEquipments.subEquipments) {
      this.checkEquipments = this.workEquipments.subEquipments;
    } else {
      this.checkEquipments.push(this.workEquipments.mainEquipment);
    }
    this.fillCheckTasks();
    this.itemEquip = this.checkEquipments[0];
    this.tasksActionsArgs = [];
    for (const itemTasks of this.tasksActionsCompleted) {
      if (itemTasks.equipId === this.itemEquip.id) {
        this.tasksActionsArgs.push(itemTasks);
      }
    }
    this.checkEquipments[0].isSelected = true;
    this.checkEquipmentsIsLoaded = true;
  }
  setUpdate(): void {
    this.ihmOptionsCkeck = undefined;
    this.ihmOptionsCkeck = JSON.parse(JSON.stringify(this.ihmOptions));

    this.ihmOptionsCkeck.collapse = false;
    this.ihmOptionsCkeck.readOnly = false;
    this.isReadOnly = false;
  }
  cancelUpdate(): void {
    this.ihmOptionsCkeck = undefined;
    this.ihmOptionsCkeck = JSON.parse(JSON.stringify(this.ihmOptions));

    this.ihmOptionsCkeck.collapse = false;
    this.ihmOptionsCkeck.readOnly = true;
    this.isReadOnly = true;
    this.isOkToValid = false;
    this.initCheck();
  }
  fillCheckTasks(): void {

    let worksActionsSelected: TasksActionsModel[] = [];
    let suppliesActionsSelected: TasksActionsModel[] = [];
    let servicesActionsSelected: TasksActionsModel[] = [];
    let equipments: {
        all: boolean;
        equipId: string[];
    };
    let okToCheck: boolean;
    this.tasksActionsCompleted = [];
    let workEquipmentId: WorkEquipmentRef;
    let numberEquip = 0;
    workEquipmentId = JSON.parse(this.workCur.workEquip);
    if (workEquipmentId.subEquipments === undefined || workEquipmentId.subEquipments.length === 0) {
      numberEquip = 1;
    } else {
      numberEquip = workEquipmentId.subEquipments.length;
    }
    let durationCur = 0;
    let equipmentsCur = 0;
    for (const taskCur of this.workCur.workTasksContent) {
      worksActionsSelected = [];
      suppliesActionsSelected = [];
      servicesActionsSelected = [];
      const taskrefJson = JSON.parse( taskCur.taskrefJson) as TaskrefJsonModel;
      if (taskrefJson.tasksActionsParentContent !== undefined) {
        const tasksActionsParentContent = taskrefJson.tasksActionsParentContent as TasksActionsContentModel;
        worksActionsSelected = tasksActionsParentContent.works;
        suppliesActionsSelected = tasksActionsParentContent.supplies;
        servicesActionsSelected = tasksActionsParentContent.services;
      }
      if (taskrefJson.tasksActionsContent !== undefined) {
        const tasksActionsContent = taskrefJson.tasksActionsContent as TasksActionsContentModel;
        worksActionsSelected.push(...tasksActionsContent.works);
        suppliesActionsSelected.push(...tasksActionsContent.supplies);
        servicesActionsSelected.push(...tasksActionsContent.services);
        equipments = tasksActionsContent.equipments;
        if (equipments.all) {
          equipmentsCur = numberEquip;
        } else {
          equipmentsCur = equipments.equipId.length;
        }
        // console.log(tasksActionsContent);
      }
      if (taskrefJson.tasksActionCompleted !== undefined) {
        // this.tasksActionsCompleted = [...taskrefJson.tasksActionCompleted as TasksActionCompletedModel[]];
        for (const taskTmpCur of taskrefJson.tasksActionCompleted ) {
          if (taskTmpCur.equipId ===  this.workEquipments.mainEquipment.id) {
            taskTmpCur.equipSN = this.workEquipments.mainEquipment.equipSN;
          } else {
            if (this.workEquipments.subEquipments) {
              for (const equipCur of this.workEquipments.subEquipments) {
                if (taskTmpCur.equipId ===  equipCur.id) {
                  taskTmpCur.equipSN = equipCur.equipSN;
                  break;
                }
              }
            }
          }
          this.tasksActionsCompleted.push(taskTmpCur);
        }
      } else {
        for (const equipItem of this.checkEquipments) {
          okToCheck = false;
          if (equipments.all) {
            okToCheck = true;
          } else {
            for (const idEquip of equipments.equipId) {
              if (idEquip === equipItem.id) {
                okToCheck = true;
                break;
              }
            }
          }
          if (okToCheck) {
            durationCur = 0;
            for (const workTmp of worksActionsSelected) {
              durationCur += Number(workTmp.quantity);
            }
            const taskEmpty: TasksActionCompletedModel = {
              equipId: equipItem.id,
              equipSN: (equipItem.equipSN ? equipItem.equipSN : undefined),
              taskrefId: taskCur.taskrefId,
              taskrefLabel: taskCur.taskrefLabel,
              works: worksActionsSelected,
              supplies: suppliesActionsSelected,
              services: servicesActionsSelected,
              additionalTasks: undefined,
              equipHours: (equipItem.equipHours ? equipItem.equipHours : -1),
              taskDuration: durationCur
            };
            this.tasksActionsCompleted.push(taskEmpty);
          }
        }
        if (worksActionsSelected.length > 0 ) {
          durationCur = 0;
          for (const workTmp of worksActionsSelected) {
            durationCur += Number(workTmp.quantity);
          }
          this.workCur.workDuration += (durationCur * equipmentsCur);
        }
      }
    }

  }
  selectedTabChange($event: string, matTabSelected: MatTabChangeEvent): void {
    // console.log(matTabSelected);
    const itemArg = this.checkEquipments[matTabSelected.index];
    itemArg.isSelected = true;
    this.itemEquip = itemArg;
    this.tasksActionsArgs = [];
    for (const itemTasks of this.tasksActionsCompleted) {
      if (itemTasks.equipId === itemArg.id) {
        this.tasksActionsArgs.push(itemTasks);
      }
    }
  }
  
  inputData(dataReturn: any): void  {

    const dataTasks = dataReturn.tasksActions as TasksActionCompletedModel[];
    const inputChanged = dataReturn.inputChange;
    if (!inputChanged) {
      this.isOkToValid = false;
      return;
    }

    // console.log(dataTasks);
    for (const taskInput of dataTasks) {
      let isFound = false;
      let idx = 0;
      for (let taskCur of this.tasksActionsCompleted) {
        if ((taskInput.equipId === taskCur.equipId) && (taskInput.taskrefId === taskCur.taskrefId )) {
          isFound = true;
          taskCur = taskInput;
          this.tasksActionsCompleted.splice(idx, 1);
          this.tasksActionsCompleted.push(taskInput);
          break;
        }
        idx++;
      }
      if (!isFound) {
        this.tasksActionsCompleted.push(taskInput);
      }
    }

    this.isOkToValid = true;
  }
  validData(): any {
    this.fillModel();
    this._wksWorksService.saveWksWorksCheckList(this.workCur, this.workEquipments, this._wksCentralService)
    .then ((reponseCur) => {
      this.ihmOptionsCkeck.readOnly = true;
      this.isReadOnly = true;
      
    }, (err: any) => {
      console.log(err);
      this.ihmOptionsCkeck.readOnly = true;
      this.isReadOnly = true;

    }); 
  }
  fillModel(): void {
    this._wksWorksService.fillModelTasksCompleted(this.workCur, this.tasksActionsCompleted, 
                        this.workEquipments, this._userService.getUserLogged().entity);
  }
  displayModalVerification(): void {
    const dialogConfig = new MatDialogConfig();

// https://material.angular.io/components/dialog/api
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = ['checkVerifPanelModal'];
    dialogConfig.data = {
      workId: this.workCur.id,
      ihmOptions: this.ihmOptions,
      title: this._translateService.getTranslate('checkListControl')
      };

    const dialogRef = this.dialog.open(VerificationCkecklistComponent, dialogConfig);

    dialogRef.afterClosed()
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
        this.ihmOptionsCkeck.readOnly = true;
        this.isReadOnly = true;
        if (data === 'finished') {
          this.isOKToUpdate = false;
        }
      });

  }
}
