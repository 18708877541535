import { throwError as observableThrowError, Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class WksApisService {
    constructor(httpClient) {
        this.httpClient = httpClient;
    }
    setHeaders(_headers) {
        this.httpHeaders = _headers;
    }
    settingAPIS(APIS_args) {
        this.APIS = APIS_args;
    }
    getApisParam(apiArg) {
        const apisList = this.APIS;
        let apiCur;
        for (const apiParam of apisList) {
            if (apiParam.apiName === apiArg) {
                apiCur = apiParam.apiTools;
            }
        }
        return apiCur;
    }
    setApisEntity(apisEntity) {
        if (apisEntity !== undefined) {
            for (const apiCur of apisEntity) {
                if (apiCur.apiName === 'stockManagement') {
                    this.stockManagementApi = apiCur.apiTool;
                    if (this.stockManagementApi === undefined || this.stockManagementApi.toolName === undefined) {
                        this.stockManagementApi = undefined;
                    }
                }
                else if (apiCur.apiName === 'accounting') {
                    this.accountingApi = apiCur.apiTool;
                    if (this.accountingApi === undefined || this.accountingApi.toolName === undefined) {
                        this.accountingApi = undefined;
                    }
                }
                else if (apiCur.apiName === 'notifications') {
                    this.notificationsApi = apiCur.apiTool;
                    if (this.notificationsApi === undefined || this.notificationsApi.toolName === undefined) {
                        this.notificationsApi = undefined;
                    }
                }
                else if (apiCur.apiName === 'customers') {
                    this.customersApi = apiCur.apiTool;
                    if (this.customersApi === undefined || this.customersApi.toolName === undefined) {
                        this.customersApi = undefined;
                    }
                }
                else if (apiCur.apiName === 'invoicing') {
                    this.invoicingApi = apiCur.apiTool;
                    if (this.invoicingApi === undefined || this.invoicingApi.toolName === undefined) {
                        this.invoicingApi = undefined;
                    }
                }
            }
        }
    }
    getApiStockManagement() {
        return this.stockManagementApi;
    }
    getServicesStockManagement() {
        const apisDef = this.getApisParam('stockManagement');
        let toolApiCur;
        for (const apisDefItem of apisDef) {
            if (apisDefItem.toolName === this.stockManagementApi.toolName) {
                toolApiCur = apisDefItem;
                break;
            }
        }
        let toolServices;
        for (const toolVersionItem of toolApiCur.toolVersions) {
            if (toolVersionItem.toolVersion === this.stockManagementApi.toolVersion) {
                toolServices = toolVersionItem.toolServices;
            }
        }
        return toolServices;
    }
    getApiAccounting() {
        // const apisDef = this.getApisParam('accounting');
        return this.accountingApi;
    }
    getApiNotifications() {
        // const apisDef = this.getApisParam('notifications');
        return this.notificationsApi;
    }
    getApiCustomers() {
        // const apisDef = this.getApisParam('customers');
        return this.customersApi;
    }
    getApiinvoicing() {
        // const apisDef = this.getApisParam('invoicing');
        return this.invoicingApi;
    }
    readStockByRef(brandRef, stdEntity) {
        const apiService = 'stockByRef';
        const apiName = 'stockManagement';
        const toolArgs = {
            toolDatas: []
        };
        let testData = {
            key: 'toolCustomerNwsCode',
            value: this.getApiStockManagement().toolCustomerNwsCode
        };
        toolArgs.toolDatas.push(testData);
        testData = {
            key: 'toolCustomerNwsId',
            value: this.getApiStockManagement().toolCustomerNwsId
        };
        toolArgs.toolDatas.push(testData);
        const toolKeyValue = {
            key: 'brandRef',
            value: brandRef,
            listValues: undefined,
            arrayValues: undefined
        };
        const jsonTmp = JSON.parse(JSON.stringify(toolKeyValue));
        toolArgs.toolDatas.push(jsonTmp);
        this.requestApis(stdEntity, apiName, apiService, toolArgs)
            .subscribe((data) => {
            const toolApiResponse = data.body;
            if (toolApiResponse.statut === 'KO') {
                const itemResponse = toolApiResponse.toolDatas[0];
                if (itemResponse.value === '404') {
                    toolApiResponse.toolDatas.push({
                        key: 'nws_stock_qty',
                        value: '0.0'
                    });
                }
                return this.affectResponseStockByRef(toolApiResponse);
            }
            else {
                return this.affectResponseStockByRef(toolApiResponse);
            }
        }, err => {
            console.log(' readStockByRef : ' + JSON.stringify(err));
            return undefined;
        });
        return undefined;
    }
    affectResponseStockByRef(toolApiResponse) {
        const toolApiDatasReturn = {
            // datas: ToolApiReturn[];
            datas: []
        };
        // const itemResponse = toolApiResponse.toolDatas[0];
        let itemValue = '';
        for (const itemCur of toolApiResponse.toolDatas) {
            if (itemCur.key === 'nws_stock_qty') {
                itemValue = itemCur.value;
                const toolApiReturn = {
                    dataName: 'nws_stock_qty',
                    dataValue: parseFloat(itemValue)
                };
                toolApiDatasReturn.datas.push(toolApiReturn);
            }
            if (itemCur.key === 'nws_stock_id') {
                const toolApiReturn = {
                    dataName: 'nws_stock_id',
                    dataValue: itemCur.value
                };
                toolApiDatasReturn.datas.push(toolApiReturn);
            }
        }
        return toolApiDatasReturn;
    }
    requestApis(stdEntity, apiName, serviceName, toolTestArgs) {
        const urlCur = `${environment.baseUrl}/wks/wksentity/requestApis/?` + 'stdEntity=' + stdEntity + '&apiName=' + apiName + '&serviceName=' + serviceName;
        const body = JSON.stringify(toolTestArgs);
        return this.httpClient
            .post(urlCur, body, {
            headers: this.httpHeaders,
            observe: 'response',
        })
            .pipe(catchError(err => {
            // console.log(err.status);
            return observableThrowError(err);
        }));
    }
}
WksApisService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function WksApisService_Factory() { return new WksApisService(i0.ɵɵinject(i1.HttpClient)); }, token: WksApisService, providedIn: "root" });
