import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { UserService } from '../../../../../../services/user.service';
import { TranslateService } from '../../../../../../services/translate.service';
import { WksCentralService } from '../../../../services/wks-central.service';

import { BrandWksModel } from '../../../../models/wks-common.model';
import { WksEntityModel } from '../../../../models/wks-entity.model';
import { ParamPartsSuppliesModel, TasksActionsModel} from '../../../../models/wks-param.model';
import { LabelValue } from '../../../../../../models/data.model';

@Component({
  selector: 'mdi-parts-supplies-search',
  templateUrl: './parts-supplies-search.component.html',
  styleUrls: ['./parts-supplies-search.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class PartsSuppliesSearchComponent implements OnInit {

  @Input() statutCall: any;
  @Input() optionItem: any;
  @Input() actionType: any;
  @Input() brandName: any;
  @Input() wksEntity: WksEntityModel;
  @Input() suppliesList: TasksActionsModel[];
  @Input() servicesList: TasksActionsModel[];
  @Input() typeSearch: string;
  @Output() dataOut = new EventEmitter<any>();

  @ViewChild('filterbrand', {static: false}) filterbrand: ElementRef;

  brandList: BrandWksModel[];
  brandCacheList: BrandWksModel[];

  paramTypeList: LabelValue[];
  partSupplieSearchForm: FormGroup;
  titleCard: string; 
  isLoaded: boolean;
  paramPartsSuppliesList: ParamPartsSuppliesModel[];
  paramPartsSuppliesSelected: ParamPartsSuppliesModel[];
  // selectionList: ParamPartsSuppliesModel[];
  isSelectedParts: boolean;
  private readonly onDestroy = new Subject<void>();
  isServices: boolean;

  constructor(private fb: FormBuilder, 
    private _userService: UserService, 
    private _translate: TranslateService,
    private _wksCentralService: WksCentralService) { }

  ngOnInit(): void {

    this.brandList = [];
    this.paramPartsSuppliesSelected = [];
    this.isSelectedParts = false;
    this.isServices = false;
    if (this.brandName === undefined) {
      this.brandName = '';
    }
    const listItem = this._wksCentralService.getTypesPartsSupplies();
    this.paramTypeList = [];
    if (this.typeSearch === 'services') {
      const itemCur: LabelValue = {
        label: this._translate.getTranslate('services'),
        value: 'services',
      };
      this.paramTypeList.push(itemCur);
      this.isServices = true;
      this.buildForm();
      this.isLoaded = true;
      this.partSupplieSearchForm.controls.paramType.setValue('services');
      this.searchReferences();
    } else {
      for (const item of listItem) {
        const itemCur: LabelValue = {
          label: this._translate.getTranslate(item),
          value: item,
        };
      this.paramTypeList.push(itemCur);
      }
      this.loadBrands()
        .then (brandList => {
          this.buildForm();
          this.isLoaded = true;
        })
        .catch(error  => {
          console.log(error);
          this.isLoaded = true;
        });
      }
  }

  buildForm(): void {
    if (this.isServices) {
      this.titleCard = this._translate.instant('partServicesSearchForm');
    } else  {
      this.titleCard = this._translate.instant('partSupplieSearchForm');
    }
    this.partSupplieSearchForm = this.fb.group({
      brandName: this.fb.control({value: '', disabled: this.isServices ? true : false}),
      paramType: this.fb.control({value: '', disabled: this.isServices ? true : false}),
      brandRef: this.fb.control({value: '', disabled: this.isServices ? true : false}),
    },
    {
      updateOn: 'blur'
    });
    this.partSupplieSearchForm.controls.brandName.setValue(this.brandName);
  }

  loadBrands() {
    if (this._userService.getUserLogged() === undefined ) { return; }
    this.brandCacheList = [];
    this.brandList = [];

    let brandData: BrandWksModel[];
  
    return new Promise((resolve, reject) => {
      this._wksCentralService.getWksBrandsList(this._userService.getUserLogged().entity)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(
        data => {
          brandData = data.body;
          for (const brandCur of brandData) {
            this.brandCacheList.push(brandCur);
          }
          this.brandCacheList.sort((obj1, obj2) => {
            return obj1.brandName > obj2.brandName ? 1 : -1;
          });
          this.brandCacheList.splice(0, 0,  {stdEntity: this._userService.getUserLogged().entity, brandRow: 'brand', brandName: ''} );
          this.brandList = [...this.brandCacheList];

          resolve(brandData);
        }, err => {
          console.log(err);
          if (err.status === 404) {
            this.brandCacheList.push({
              stdEntity: this._userService.getUserLogged().entity,
              brandRow: 'brand',
              brandName: ''
            });
            this.brandList = [...this.brandCacheList];

          }
          reject(err.status);
        }
      );
    });
  }
  onChangeBrand($event: any) {
    // console.log('onChangeBrand : ' + $event);
    let brandSelected: BrandWksModel;
    for (const curBrand of this.brandCacheList) {
      if (curBrand.brandName === $event) {
        brandSelected = curBrand;
        break;
      }
    }
    // this.brandSelected = brandSelected;
  }

  onKeyBrand($event: any) {
    // console.log(value);
  
    let valueFinal: any;
    if ($event === '') {
      valueFinal = '';
    } else  {
      valueFinal = $event.target.value;
      if ( $event.keyCode === 32) {
        valueFinal += ' ';
      }
    }
    this.brandList = this.searchBrand(valueFinal);
    this.filterbrand.nativeElement.value = valueFinal;
  }
  searchBrand(value: string) { 
    const filter = value.toLowerCase();
    return this.brandCacheList.filter(brandCur => brandCur.brandName.toLowerCase().includes(filter));
  }

  searchReferences(): void {

    this._wksCentralService.getParamPartSupplieList(this._userService.getUserLogged().entity, 
              this.partSupplieSearchForm.controls.brandName.value, 
              this.partSupplieSearchForm.controls.paramType.value, 
              this._userService.getUserLogged().userlang,
              this.partSupplieSearchForm.controls.brandRef.value )
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
        // this.dataSource.data = data.body;
        this.paramPartsSuppliesList = this.translateLabelsData(data.body);
        this.detectSelected();
        // this.cacheDatatemp = [...data.body];
        // this.cacheDatatemp = [...this.translateLabelsData(data.body)];
      },
      err => {
        console.log(err);
        if ((err.status === 500) || (err.status === 404)) {
          
        }
      }
    );
  }
  addSupplies(index: number) { 
    const partSupplieIndex = this.paramPartsSuppliesList[index];
    for (const partSupplie of this.paramPartsSuppliesSelected ) {
      if (partSupplie.id === partSupplieIndex.id) {
        return;
      }
    }
    this.paramPartsSuppliesList[index].isSelected = true;
    this.paramPartsSuppliesSelected.push(this.paramPartsSuppliesList[index]);
    this.isSelectedParts = true;
  }
  removeSupplies(index: number) { 
    const partSupplieIndex = this.paramPartsSuppliesSelected[index];
    this.paramPartsSuppliesSelected.splice(index, 1); 
    if (this.paramPartsSuppliesSelected.length === 0)  { 
      this.isSelectedParts = false;
    }
    for (const partSupplie of this.paramPartsSuppliesList ) {
      if (partSupplie.id === partSupplieIndex.id) {
        partSupplie.isSelected = false;
        return;
      }
    }
  }
  addPartSupplie() {
    /*
    this._wksCentralService.onSearchParts.emit({
      statut: 'add',
      partsSupplies: this.paramPartsSuppliesSelected
    });
    */
    this.dataOut.emit(this.paramPartsSuppliesSelected);
  }
  doCancel() {
    /*
    this._wksCentralService.onSearchParts.emit({
      statut: 'cancel',
      partsSupplies: []
    });
    */
    this.dataOut.emit('cancel');
  }
  detectSelected(): void {
    if (this.suppliesList !== undefined && this.suppliesList.length > 0 ) {
      for (const partSupplie of this.paramPartsSuppliesList ) {
        for (const partSupplieCur of this.suppliesList ) {
          if (partSupplieCur.partSupplieId === partSupplie.id) {
            partSupplie.isSelected = true;
            break;
          }
        }
      }      
    }
    if (this.paramPartsSuppliesSelected.length === 0) {
      return;
    }
    for (const partSupplie of this.paramPartsSuppliesList ) {
      for (const partSupplieCur of this.paramPartsSuppliesSelected ) {
        if (partSupplieCur.id === partSupplie.id) {
          partSupplie.isSelected = true;
          break;
        }
      }
    }
  }
  translateLabelsData(_dataArray: Array<ParamPartsSuppliesModel>):  Array<any> {
    if (!_dataArray[0]) {
      return null;
    }
    // ParamWorksRatesModel[]
    // langLabels?: ParamLabelsI18n[];
    const paramPartsSuppliesModel: ParamPartsSuppliesModel[] = [];
    for (const rowCur of _dataArray) {
      
      rowCur.paramTypeLabel = this._translate.instant(rowCur.paramType);
      const langLabels = rowCur.langLabels;
      if (langLabels === null ) {
        continue;
      }
      for (const langLabel of langLabels) {
        if (langLabel.labelLang === this._translate.getCurrentLang()) {
          rowCur.partsSuppliesLabel =  langLabel.labelText;
          break;
        }
      }
      paramPartsSuppliesModel.push(rowCur);
    }
    return paramPartsSuppliesModel;
  }
}
