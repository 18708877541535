<div #refElement class="mat-elevation-z8 container-fluid stdTheme"
        [ngStyle]="{'margin-top': '10px', width: ((!isSearch && !isAddOne) ? '100%' : '50%'),padding: '10px'}" >
       
    <div *ngIf="!isFinishLoading || isUploadInProgress" class="flex-row row" style="margin: 0px">
        <div class="container-fluid d-flex justify-content-center" >
            <mat-progress-bar 
                mode="indeterminate"
                style="margin-left: 30px;">
            </mat-progress-bar>
        </div>
    </div>

    <div class="flex-row row wkscard" *ngIf="isFinishLoading">
        <div class="col-2  d-flex justify-content-start">
            <button mat-raised-button  #buttonSubmit type="reset" (click)="doCancel()">
                <mat-icon>arrow_back</mat-icon>
                {{ 'goback' | translate }}
            </button>
        </div>
        <div class="col-5 d-flex justify-content-center">
            <h5 class="titleTheme" >{{ titleCard | translate }}</h5>
        </div>
        <div class="col-5" style="padding-left: 0px;padding-right: 0px;">
            <mat-radio-group #choice="matRadioGroup" aria-label="Select an option" 
                    (change)="onChoiceChange(choice.value)"
                    [value]="choiceCur">
                <mat-radio-button style="margin-left: 10px;margin-right: 10px; margin-top: 5px;" value="referential">{{ 'referential' | translate }}</mat-radio-button>
                <mat-radio-button style="margin-left: 10px;margin-right: 10px; margin-top: 5px;" value="importSupply">{{ 'upload import supply' | translate }}</mat-radio-button>
                <mat-radio-button style="margin-left: 10px;margin-right: 10px; margin-top: 5px;" value="localSupply">{{ 'upload local supply' | translate }}</mat-radio-button>
            </mat-radio-group>
        </div>
    </div>
    <div class="flex-row row wkscard" *ngIf="isFinishLoading" style="margin-bottom: 10px">
        <div class="col-3 ">
            <mat-form-field class="mdiinput form-group stdTheme" appearance="standard">
                <mat-label>{{ 'brandName' | translate }}</mat-label>
                <mat-select id="brandName" placeholder="{{ 'Choose brand' | translate }}" 
                            panelClass="mdiselectcontent" #brandName required
                            (selectionChange)="changeCriterias('brandName',brandName.value)"
                            [value]="criterias.brandName"
                            ngDefaultControl >
                    <mat-select-header>
                        <div style="display: inline-block;" >
                            <button mat-icon-button matPrefix aria-label="search" >
                                <mat-icon>search</mat-icon>
                            </button>
                            <input class="searchTheme" #filterbrand placeholder="{{ 'filterInput' | translate }}" 
                                    (keyup)="onKeyBrand($event)" >
                            <button mat-icon-button matSuffix aria-label="clear" 
                                    *ngIf="filterbrand.value" (click)="filterbrand.value=''; onKeyBrand('');">
                                <mat-icon>close</mat-icon>
                            </button>
                            <button mat-icon-button matSuffix aria-label="add" 
                                 (click)="addBrand(filterbrand.value)">
                                <mat-icon>add</mat-icon>
                            </button>
                        </div>
                    </mat-select-header>
                    <mat-option class="selectOptionsTheme" *ngFor="let brandCur of brandFilteredList" 
                        [value]="brandCur.brandName"  >{{brandCur.brandName}}
                    </mat-option>
                </mat-select>                         
            </mat-form-field>
        </div>
        <!--
        <div *ngIf="!isLocalSupply" class="col-2 ">
            <mat-form-field class="mdiinput form-group stdTheme" appearance="standard">
                <mat-label>{{ 'language' | translate }}</mat-label>
                <mat-select id="lang" placeholder="{{ 'Choose lang' | translate }}" 
                            panelClass="mdiselectcontent" #lang required
                            (selectionChange)="changeCriterias('lang',lang.value)"
                            [value]="criterias.lang"
                            ngDefaultControl >
                    <mat-option class="selectOptionsTheme" *ngFor="let langCur of langLabelsList" 
                        [value]="langCur.langValue"  >{{langCur.langDisplay}}
                    </mat-option>
                </mat-select>                         
            </mat-form-field>
        </div>
        -->
        <div *ngIf="isImportSupply" class="col-2 d-flex justify-content-center">
            <mat-form-field class="mdiinput form-group" appearance="standard">
                <mat-label>{{ 'incomingCurrency' | translate }}</mat-label>
                <mat-select id="incomingCurrency" placeholder="{{ 'Choose brand' | translate }}" 
                            panelClass="mdiselectcontent" #incomingCurrency required
                            (selectionChange)="changeCriterias('incomingCurrency',incomingCurrency.value)"
                            [value]="criterias.incomingCurrency"
                            ngDefaultControl >
                    <mat-select-header>
                        <div style="display: inline-block;" >
                            <button mat-icon-button matPrefix aria-label="search" >
                                <mat-icon>search</mat-icon>
                            </button>
                            <input class="searchTheme" #filtercurrency placeholder="{{ 'filterInput' | translate }}" 
                                    (keyup)="onKeyCurrency($event)" >
                            <button mat-icon-button matSuffix aria-label="clear" 
                                    *ngIf="filtercurrency.value" (click)="filtercurrency.value=''; onKeyCurrency('');">
                                <mat-icon>close</mat-icon>
                            </button>
                        </div>
                    </mat-select-header>
                    <mat-option class="selectOptionsTheme" *ngFor="let currencyCur of currenciesFilteredList" 
                        [value]="currencyCur.value" matTooltip={{currencyCur.label}} matTooltipPosition="above" matTooltipClass="tooltipCurrency">{{currencyCur.value}}
                    </mat-option>
                </mat-select>    
                <!--
                <input id="incomingCurrency" class="form-control inputTheme" 
                    required [value]="criterias.incomingCurrency"
                    matInput #input placeholder="{{ 'incomingCurrency' | translate }}"
                    />
                -->
            </mat-form-field>
        </div>
        <div *ngIf="isImportSupply" class="col-2" >
            <mat-form-field class="mdiinput form-group" appearance="standard">
                <mat-label>{{ 'currencyTarget' | translate }}</mat-label>
                <input id="currencyCur" class="form-control inputTheme" 
                    readonly [value]="criterias.targetCurrency"
                    matInput #input placeholder="{{ 'currencyTarget' | translate }}"
                    />
            </mat-form-field>
        </div>
        <div *ngIf="isImportSupply" class="col-3" >
            <mat-form-field class="mdiinput form-group" appearance="standard">
                <mat-label>{{ 'exchangeRate' | translate }}</mat-label>
                <input id="exchangeRate" class="form-control inputTheme"  required
                    value="{{criterias.exchangeRate  | formatNumber : ['quantity',numberFormat.locale,numberFormat.minDecimal, '6' ]}}"
                    #exchangeRate placeholder="{{ 'exchangeRate' | translate }}"
                    matInput (change)="changeCriterias('exchangeRate',exchangeRate.value)"
                >
            </mat-form-field>
        </div>
    </div>
    <div  class="flex-row row" style="margin: 0px"  >
        <div *ngIf="isFinishLoading && !isImportSupply && !isLocalSupply && !isReferential" 
                class="w-100 col-12 dropzone"  draggable="true" #dropArea>
            <ng-container *ngTemplateOutlet="uploadTemplate"></ng-container>
        </div>
        <div *ngIf="isFinishLoading && isImportSupply && isOKToSelect" class="w-100 col-12">
            <ng-container *ngTemplateOutlet="uploadMultiTemplate"></ng-container>
        </div>
        <div *ngIf="isFinishLoading && (isReferential || isLocalSupply) && isOKToSelect" class="w-100 col-12">
            <ng-container *ngTemplateOutlet="uploadMultiTemplate"></ng-container>
        </div>
        
    </div>

</div>
<ng-template #uploadMultiTemplate >
   
    <div class="col-12"  style="padding-right: 5px; padding-left: 5px;">
        <mat-card appMaterialElevation class="register-card stdTheme" layout="column" flex style="padding: 0px; padding-right: 5px; padding-left: 5px;">
            <mat-card-actions style="margin-bottom: 0px;">
                <div class="flex-row row"  style="margin-left: 0px;margin-right: 0px;">
                    <div class="col-1" style="padding-left: 0px;padding-right: 0px;" >
                        <button [disabled]="!(filesUpload[0] != undefined
                                            && filesUpload[0].columnsHeader != undefined 
                                            && filesUpload[0].columnsHeader.length > 0 
                                            && filesUpload.length < 3)" mat-icon-button (click)="addFile()" [disabled]="isReadOnly">
                            <span class="fa fa-plus" style="font-size: 15px" aria-hidden="true"></span>
                        </button>
                    </div>
                    <div class="col-4" style="padding-left: 0px;padding-right: 0px;">
                        <div  [ngClass]="{'stdTheme': !errorMessageDisplayAlarm ,'redTheme': errorMessageDisplayAlarm}"
                                style="padding-left: 0px;padding-right: 0px; margin-top: 10px;" *ngIf="errorMessageDisplay">
                            <button type="button" class="close buttonTheme" aria-label="Close" (click)="errorMessageDisplay = false;">
                            <!-- <span aria-hidden="true">&#215;</span> -->
                            </button>
                            {{ errorMessageText  | translate }}
                        </div>
                    </div>
                    <div class="col-2" style="padding-left: 0px;padding-right: 0px;">

                    </div>
                    <div class="col-5 d-flex justify-content-between" style="padding-left: 0px;padding-right: 0px;">
                        <button *ngIf="doUploadMulti" mat-raised-button class="buttonTheme" (click)="modifFiles()" >
                            <mat-icon>edit</mat-icon>
                            {{ 'modifFiles' | translate }}
                        </button>
                        <button [disabled]="!doAnalysisFile" mat-raised-button class="buttonTheme" (click)="multiFilesValidation()" >
                            <mat-icon>warning</mat-icon>
                            {{ 'analysisDatas' | translate }}
                        </button>
                        <button [disabled]="!doUploadMulti" mat-raised-button  class="buttonTheme" (click)="uploadMultiProcess()" >
                            <mat-icon>cloud_upload</mat-icon>
                            {{ 'uploadFiles' | translate }}
                        </button>
                    </div>

                </div>
            </mat-card-actions>
            <mat-card-content class="stdTheme" style="padding-bottom: 20px;">
                <div class="flex-row row" *ngIf= "!isReportActive " style="margin-bottom: 10px;margin-left: 0px;margin-right: 0px;">
                    <div class="col-10" style="padding-left: 0px;padding-right: 0px;">
                        <div class="flex-row row"  style="margin-left: 0px;margin-right: 0px;">
                            <div class="col-4" style="padding-left: 0px;padding-right: 0px;" *ngFor="let fileUpload of filesUpload; let iFile = index"  >
                                <fieldset class="file-border" style="margin-bottom: 10px">
                                    <div class="flex-row row"  style="margin-bottom: 10px;margin-left: 0px;margin-right: 0px;">
                                        <div class="col-10" style="padding-left: 0px;padding-right: 0px;">
                                            <button (click)="fileInput.click()" mat-raised-button class="buttonTheme" >
                                                        <!--<mat-icon>library_add</mat-icon>-->
                                                <span>{{'SelectFiles' | translate}}</span>
                                                <!-- accept="file_extension|audio/*|video/*|image/*|media_type" -->
                                                <input #fileInput type="file" (change)="onFileChangeMulti($event, iFile)" [accept]="fileExt" style="display:none;" />
                                            </button>
                                        </div>
                                        <div class="col-1" style="padding-left: 0px;padding-right: 0px;">
                                        </div>
                                        <div class="col-1" style="padding-left: 0px;padding-right: 0px;">
                                            <button *ngIf="fileUpload.columnsHeader != undefined && fileUpload.columnsHeader.length > 0 " 
                                                style="padding-left: 0px;padding-right: 15px;" mat-icon-button (click)="removeFile(iFile)" [disabled]="isReadOnly">
                                                <span class="fa fa-trash"  style="font-size: 15px" aria-hidden="true"></span>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="flex-row row"  style="margin-bottom: 10px;margin-left: 0px;margin-right: 0px;">
                                        <div class="col-12" style="padding-left: 0px;padding-right: 0px;">
                                            <mat-form-field class="mdiinput form-group" appearance="standard">
                                                <mat-label>{{ 'uploadFileName' | translate }}</mat-label>
                                                <input id="uploadFilename" class="form-control inputTheme" 
                                                    required readonly [value]="fileUpload.readFile.nameFile"
                                                    matInput #input placeholder="{{ 'uploadFileName' | translate }}"
                                                    >
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div *ngIf="fileUpload.readFile.rowsFile !== undefined">
                                        <div class="flex-row row" style="margin-left: 0px;margin-right: 0px;">
                                            <div class="col-7" style="padding-left: 0px;padding-right: 0px;">
                                                <span>{{ 'readingLines' | translate }}</span>
                                            </div>
                                            <div class="col-2 d-flex justify-content-center" style="padding-left: 0px;padding-right: 0px;">
                                                <span>--></span>
                                            </div>
                                            <div class="col-3 d-flex justify-content-end" style="padding-left: 0px;padding-right: 0px;">
                                                <span>{{fileUpload.readFile.rowsFile | formatNumber : ['number',numberFormat.locale,numberFormat.minDecimal, numberFormat.maxDecimal ]}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="fileUpload.columnsHeader !== undefined && fileUpload.columnsHeader.length > 0" >
                                        <div class="flex-row row collapseTitle" style="margin-left: 0px;margin-right: 0px;" >
                                            <button class="col-1" mat-icon-button (click)="toggleColumnsBdd()">
                                                <span *ngIf="!displayColumnsBdd" class="fa fa-chevron-down" style="font-size: 15px" aria-hidden="true"></span>
                                                <span *ngIf="displayColumnsBdd" class="fa fa-chevron-up" style="font-size: 15px" aria-hidden="true"></span>
                                            </button> 
                                            <h6 class="col-11 collapseTitle" style="padding-top: 12px;" (click)="toggleColumnsBdd()" > {{'columnsBddUpload' | translate}} </h6>
                                        </div>
                                    </div>
                                    <div *ngIf="fileUpload.columnsHeader !== undefined && fileUpload.columnsHeader.length > 0" 
                                                style="overflow-y: hidden;">
                                        <div *ngIf="displayColumnsBdd" 
                                                style="margin-left: 10px;margin-right: 10px">
                                            <div class="flex-row row" style="margin-left: 0px;margin-right: 0px;">
                                                <div class="col-6" style="padding-left: 0px;padding-right: 0px;">
                                                    <span><strong>{{'colFile'  | translate }}</strong></span>
                                                </div>
                                                <div class="col-6" style="padding-left: 0px;padding-right: 0px;">
                                                    <span><strong>{{'bddName'  | translate }}</strong></span>
                                                </div>
                                            </div>
                                            <div style="padding-left: 0px;padding-right: 0px;width: 100%;overflow-y: auto;overflow-x: hidden; height: 350px; max-height: 350px">
                                                <div class="flex-row row" *ngFor="let colHeader of fileUpload.columnsHeader; let iCol = index"
                                                            style="margin-left: 0px;margin-right: 0px;">
                                                    <div class="col-6" style="padding-left: 0px;padding-right: 0px;"
                                                        [ngClass]="{'stdTheme': !colHeader.selected,
                                                                'amberTheme': colHeader.selected}" >
                                                        <span (click)="selectColumnFile(iFile, iCol)" 
                                                            [ngClass]="{'stdTheme': !colHeader.selected,
                                                                        'amberTheme': colHeader.selected}">
                                                                {{colHeader.columnRef}} - {{colHeader.columnName}}
                                                        </span>
                                                    </div>
                                                    <div class="col-6" style="padding-left: 0px;padding-right: 0px;">
                                                        <div class="flex-row row"  style="margin-left: 0px;margin-right: 0px;">
                                                            <div class="col-10" (click)="selectColumnFile(iFile, iCol)" style="padding-left: 0px;padding-right: 0px;" >
                                                                <span>
                                                                    {{colHeader.columnBdd  | translate }}
                                                                </span>
                                                            </div>
                                                            <div class="col-2" style="padding-left: 0px;padding-right: 0px;" >
                                                                <button [disabled]="!this.columnAction[iFile][iCol].unBind || isReadOnly" 
                                                                            mat-icon-button (click)="unBindColumn(iFile, iCol)" style="height: 20px;">
                                                                    <span class="fa fa-times" style="font-size: 12px;margin-top: -25px;" aria-hidden="true"></span>
                                                                </button>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="filesUpload.length > 1 && fileUpload.columnsHeader !== undefined && fileUpload.columnsHeader.length > 0" >
                                        <div class="flex-row row collapseTitle" style="margin-left: 0px;margin-right: 0px;" >
                                            <button class="col-1" mat-icon-button (click)="toggleColumnsLinks()">
                                                <span *ngIf="!displayColumnsLinks" class="fa fa-chevron-down" style="font-size: 15px" aria-hidden="true"></span>
                                                <span *ngIf="displayColumnsLinks" class="fa fa-chevron-up" style="font-size: 15px" aria-hidden="true"></span>
                                            </button> 
                                            <h6 class="col-11 collapseTitle" style="padding-top: 12px;" (click)="toggleColumnsLinks()" > {{'columnsBddCrossRef' | translate}} </h6>
                                        </div>
                                    </div>
                                    <div *ngIf="fileUpload.columnsHeader !== undefined && fileUpload.columnsHeader.length > 0" 
                                        style="overflow-y: hidden;">
                                        <div *ngIf="displayColumnsLinks" 
                                                style="margin-left: 10px;margin-right: 10px">
                                            <div class="flex-row row" style="margin-left: 0px;margin-right: 0px;">
                                                <div class="col-2">

                                                </div>
                                                <div class="col-8 d-flex justify-content-center" style="padding-left: 0px;padding-right: 0px;">
                                                    <span><strong>{{'colName'  | translate }}</strong></span>
                                                </div>
                                                <div class="col-2">
                                                    
                                                </div>
                                            </div>
                                            <div style="padding-left: 0px;padding-right: 0px;width: 100%;overflow-y: auto;overflow-x: hidden; height: 350px; max-height: 350px">
                                                <div class="flex-row row" *ngFor="let crossRef of fileUpload.crossReferenced; let iRef = index"
                                                            style="margin-left: 0px;margin-right: 0px;margin-bottom: 10px;">
                                                    <div class="col-2 d-flex justify-content-center" style="padding-left: 0px;padding-right: 0px;">
                                                        <button [disabled]="isReadOnly || !crossRefAction[iRef].addOK" *ngIf="iFile === 0"
                                                            mat-icon-button (click)="addCrossReference()" style="height: 20px;">
                                                            <span class="fa fa-plus" style="font-size: 12px;margin-top: -25px;" aria-hidden="true"></span>
                                                        </button>
                                                    </div>
                                                    <div class="col-8 d-flex justify-content-center" style="padding-left: 0px;padding-right: 0px;">
                                                        <select class="selectBox inputTheme" #crossReference [disabled]="isReadOnly" style="width: 100%"
                                                                (change)="crossRefSelected(iFile, iRef, crossReference.value)">
                                                            <option></option>
                                                            <option *ngFor="let itemOption of fileUpload.columnsHeader" 
                                                                    [value]="itemOption.columnName"
                                                                    [selected]="itemOption.columnName === crossRef"
                                                                    >{{ itemOption.columnName }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                    <div class="col-2 d-flex justify-content-center" style="padding-left: 0px;padding-right: 0px;">
                                                        <button [disabled]="isReadOnly || !crossRefAction[iRef].removeOK" 
                                                            *ngIf="filesUpload.length === iFile + 1"
                                                            mat-icon-button (click)="removeCrossReference(iFile, iRef)" style="height: 20px;">
                                                            <span class="fa fa-times" style="font-size: 12px;margin-top: -25px;" aria-hidden="true"></span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                    <div class="col-2" style="padding-left: 0px;padding-right: 0px;">
                        <div *ngIf="displayColumnsBdd" class="grid-container-bdd"  >
                            <div>
                                <!-- first grid-container-bdd row : empty area -->
                            </div>
                            <fieldset class="file-border"  *ngIf="colSchema != undefined" style="overflow-y: auto;overflow-x: hidden;" >
                                <div class="flex-row row collapseTitle" style="margin-left: 0px;margin-right: 0px;" >
                                    <h6 class="col-12 collapseTitle" style="padding-top: 12px;"  > {{'columnsBddList' | translate}} </h6>
                                </div>

                                <div class="flex-row row"  style="margin-left: 0px;margin-right: 0px;" *ngFor="let coldefCur of colSchema; let idxBdd = index" 
                                                >
                                    <div class="col-11" style="padding-left: 0px;padding-right: 0px;"
                                        [ngClass]="{'greenTheme': coldefCur.selected ,
                                                'stdTheme': !coldefCur.selected}">
                                        <span (click)="selectColumnBdd(idxBdd)">{{coldefCur.colName | translate }}</span>
                                    </div>
                                    <div class="col-1" style="padding-left: 0px;padding-right: 0px;">
                                        <div *ngIf="coldefCur.actionRules.add.mandatory === true" style="padding-left: 0px;padding-right: 0px;">
                                            <span class="fa fa-asterisk" style="font-size: 10px;margin-top: -25px;" aria-hidden="true"></span>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>
                <div *ngIf= "isReportActive" >
                    <div class="flex-row row" style="margin-left: 0px;margin-right: 0px;">
                        <div class="col-8 stdTheme"  >
                            <h6 class="titleTheme container-fluid d-flex justify-content-center" >{{ 'analysisDatas' | translate }}</h6>
                        </div>
                    </div>
                    <div class="flex-row row" style="margin-bottom: 10px;margin-left: 0px;margin-right: 0px;">
                        <div class="col-8 stdTheme" *ngFor="let reportCur of reportDataInput; let idxItem = index" >
                            <div *ngIf="reportCur.reportActive" class="container-fluid">
                                <fieldset class="report-border" >
                                    <div class="flex-row row" style="margin-left: 0px;margin-right: 0px;">
                                        <h6 class="titleTheme d-flex justify-content-center">{{ reportCur.reportTitle }}</h6>
                                    </div>
                                    <div *ngIf="reportCur.reportSubTitle !== '' " class="flex-row row" style="margin-left: 0px;margin-right: 0px;">
                                        <h6 class="titleTheme d-flex justify-content-center">{{ reportCur.reportSubTitle }}</h6>
                                    </div>
                                    <div  style="width: 100%;">
                                        <div class="flex-row row  stdTheme" style="width: 100%; margin: 0px">
                                            <div *ngIf="reportCur.progressBar === 'undeterminate'" class="spinner-container  stdTheme col-12"  fxLayout="row" fxLayoutAlign="space-around center">
                                                <mat-spinner diameter="20" strokeWidth="2"></mat-spinner>
                                            </div>
                                            <div *ngIf="reportCur.progressBar === 'determinate'" class="stdTheme col-12"  fxLayout="row" fxLayoutAlign="space-around center" >
                                                <mat-progress-bar mode="determinate" value="reportCur.progressValue"></mat-progress-bar>
                                            </div>
                                        </div>
                                        <div *ngIf="reportCur.progressMessage !== '' && reportCur.progressMessage !== undefined" class="flex-row row  stdTheme" style="width: 100%; margin: 0px">

                                            <div class="stdTheme col-12"  style="text-align: center;">
                                                <span > 
                                                    {{ reportCur.progressMessage }}
                                                </span>
                                            </div>
                                        </div>
                                        <div *ngIf="reportCur.nbErrors > 0" class="flex-row row  stdTheme" style="width: 100%; height: 40px; line-height: 40px; margin: 0px">

                                            <div class="stdTheme col-8"  style="text-align: center;">
                                                <span > 
                                                    {{ 'nbErrorsMultiFiles' | translate }} :  {{ reportCur.nbErrors }}
                                                </span>
                                            </div>
                                            <div class="col-4 d-flex justify-content-between" style="padding-left: 0px;padding-right: 0px;">
                                                <button  mat-raised-button  class="buttonTheme" (click)="downloadErrors(idxItem)" >
                                                    <mat-icon>cloud_download</mat-icon>
                                                    {{ 'downloadErrorsMultiFiles' | translate }}
                                                </button>
                                            </div>
                                        </div>
                                        <div *ngIf="reportCur.nbRows > 0" class="flex-row row  stdTheme" style="width: 100%; height: 40px; line-height: 40px; margin: 0px">

                                            <div class="stdTheme col-8"  style="text-align: center;">
                                                <span > 
                                                    {{ 'nbRowsMultiFiles' | translate }} :  {{ reportCur.nbRows }}
                                                </span>
                                            </div>
                                            <div class="col-4 d-flex justify-content-between" style="padding-left: 0px;padding-right: 0px;">
                                                <button  mat-raised-button  class="buttonTheme" (click)="downloadRows(idxItem)" >
                                                    <mat-icon>cloud_download</mat-icon>
                                                    {{ 'downloadRowsMultiFiles' | translate }}
                                                </button>
                                            </div>
                                        </div>
                                        <div class="flex-row row  stdTheme" style="width: 100%; margin: 0px">
                                            <div class="stdTheme col-12"  style="text-align: center;">
                                                <span > 
                                                    {{ reportCur.contentMessage }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>

</ng-template>

<ng-template #uploadTemplate >
    <div  class="flex-row row dropzone" style="margin: 0px"  draggable="true" #dropArea >
        <div class="col-12"  style="padding-right: 5px; padding-left: 5px;">
            <form [formGroup]="uploadForm"  >
                <!-- https://stackblitz.com/edit/angular-material-elevation-hover?file=src%2Fapp%2Fapp.component.html -->
                <mat-card appMaterialElevation class="register-card stdTheme" layout="column" flex style="padding: 0px; padding-right: 5px; padding-left: 5px;">
                    <mat-card-header>
                        <mat-card-title>
                        <!-- <span #titleCard>{{ titleCard | translate }}</span>-->
                        <section class="mat-typography">
                            <h1 class="titleTheme" >{{ titleCard | translate }}</h1>
                        </section>
                        </mat-card-title>
                    </mat-card-header>
                    <mat-card-actions>
                        <div class="container-fluid d-flex justify-content-between" >
                            <button mat-raised-button  #buttonSubmit type="reset" (click)="doCancel()">
                                <mat-icon>arrow_back</mat-icon>
                                {{ 'goback' | translate }}
                            </button>
                            <button *ngIf="uploadForm.valid && doUpload" mat-raised-button  #buttonSubmit type="submit" (click)="uploadFile()" >
                                <mat-icon>cloud_upload</mat-icon>
                                {{ 'uploadFiles' | translate }}
                            </button>
                            <button *ngIf="uploadForm.valid && doVerify" mat-raised-button  #buttonSubmit type="submit" (click)="verifyFile()" >
                                <mat-icon> insert_drive_file</mat-icon>
                                {{ 'verifyFiles' | translate }}
                            </button>
                        </div>
                    </mat-card-actions>

                    <mat-card-content class="stdTheme" style="padding-bottom: 20px;">
                        <div class="flex-row row"  style="margin-bottom: 10px;">
                            <div class="col-2"></div>
                            <div class="col-3" >
                               <!--https://getbootstrap.com/docs/4.4/utilities/spacing/-->
                                <div class="pt-4 mt-2">
                                    <button (click)="fileInput.click()" mat-raised-button class="buttonTheme" >
                                                <mat-icon>library_add</mat-icon>
                                        <span>{{'SelectFiles' | translate}}</span>
                                        <!-- accept="file_extension|audio/*|video/*|image/*|media_type" -->
                                        <input #fileInput type="file" (change)="onFileChange($event)" [accept]="fileExt" style="display:none;" />
                                    </button>
                                </div>
                            </div>
                            <div class="col-4">
                                <mat-form-field class="mdiinput form-group" appearance="standard">
                                    <mat-label>{{ 'uploadFileName' | translate }}</mat-label>
                                    <input id="uploadFilename" class="form-control inputTheme" formControlName="uploadFileName" 
                                        required readonly 
                                        matInput #input placeholder="{{ 'uploadFileName' | translate }}"
                                        >
                                    </mat-form-field>
                            </div>
                           
                        </div>

                    </mat-card-content>
                </mat-card>
            </form>
        </div>
    </div>
</ng-template>
    <!--
<ng-template #modalBasic let-modal >
    
    <mdi-modal-basic 
        [modalStatutCall]='modalStatutCall'
        [modalDataInput]= 'modalDataInput'
        [modalParam]= 'modalParam'
        >
    </mdi-modal-basic>
    
    <div class="modal-header headerBasic " style="padding: 0px; padding-top: 5px; padding-bottom: 5px">
        <h6 class="headerBasic container-fluid d-flex justify-content-center">{{ modalTitle }}</h6>
        <span > 
            toto
        </span>
        <button type="button" class="close pull-right" [disabled]="modalParam.modalDismissClose" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" style="padding: 0px">
        <div class="container-fluid" style="width: 100%;">
            <div class="flex-row row  stdTheme" style="width: 100%; margin: 0px">
                <div *ngIf="modalParam.progressBar === 'undeterminate'" class="spinner-container  stdTheme col-12"  fxLayout="row" fxLayoutAlign="space-around center">
                    <mat-spinner diameter="20" strokeWidth="2"></mat-spinner>
                </div>
                <div *ngIf="modalParam.progressBar === 'determinate'" class="stdTheme col-12"  fxLayout="row" fxLayoutAlign="space-around center" >
                    <mat-progress-bar mode="determinate" value="progressValue"></mat-progress-bar>
                </div>
            </div>
            <div class="flex-row row  stdTheme" style="width: 100%; height: 100px; line-height: 100px; margin: 0px">
                <div class="stdTheme col-12"  style="text-align: center;">
                    <span > 
                        {{ modalDataInput.contentMessage }}
                    </span>
                </div>
            </div>
        </div>
    </div>
    
</ng-template>
-->