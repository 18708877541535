<div  >
    <div fxFlex style="font-size: small;padding-top: 0.5%;">
        <mdi-topbar [isHome]="true" [isReportHome]="false"></mdi-topbar>
    </div>
    <!-- <div class="row">-->
    <div *ngIf="menuIsLoaded" class="text-center w-100" style="margin-top: 75px;">
        <div class="container homeContainer" >
            <!--
            <div class="row">
                <div class="col-12">
                    <h1 class="homeTheme" >{{appSigle}} <small>{{appTitle}}</small></h1>
                </div>
            </div>
       -->
            <div class="w-100" *ngIf="appSigle === 'NWS'">
                <div class="flex-row row" >
                    <div class="col-12">
                        <div class="container-fluid d-flex justify-content-between" style="margin-top: 50px">
                            <div *ngFor="let item of othersButtonsItems; let index = index; let isFirst = first; let isOdd = odd;">
                                <div *ngIf="item.itemLabel === 'anchorages' || item.itemLabel === 'docs'">
                                    <!--
                                    <button class="btn buttonMenu mat-raised-button mat-elevation-z8" id="{{item.itemLabel}}"
                                                matTooltip="{{item.itemLabel | translate }}"
                                                matTooltipPosition="below"
                                                matTooltipClass="tooltip-menu-below"
                                                [routerLink]="[item.itemRoutingPath,'list']">
                                        <a> <img src="assets/img/{{item.itemImg}}" width="100" height="100"> </a>
                                    </button>
                                -->
                                    <button class="btn buttonMenu mat-raised-button mat-elevation-z8 tooltipButton" id="{{item.itemLabel}}"
                                                [routerLink]="[item.itemRoutingPath,'list']">
                                        <a> <img src="assets/img/{{item.itemImg}}" width="100" height="100"> </a>
                                        <span class="tooltiptext">{{item.itemLabel | translate }}</span>
                                    </button>
                                    <!--<div [innerHTML]="item.buttonHtml"></div>-->
                                </div>
                                <div *ngIf="item.itemLabel !== 'anchorages' && item.itemLabel !== 'docs'">
                                    <div ngbDropdown class="d-inline-block">
                                        <button class="btn buttonMenu mat-raised-button mat-elevation-z24 tooltipButton" id="{{item.itemLabel}}" 
                                                ngbDropdownToggle>
                                            <a> <img src="assets/img/{{item.itemImg}}" width="100" height="100"> </a>
                                            <span class="tooltiptext">{{item.itemLabel | translate }}</span>
                                        </button>
                                        <div *ngIf="item.itemLabel !== 'wks_appointments' && item.itemLabel !== 'wks_thirdparties' && item.itemLabel !== 'wks_parameters' && item.options" ngbDropdownMenu class="stdTheme" aria-labelledby="{{item.itemLabel | translate}}" >
                                            <div *ngFor="let optCur of item.options">
                                                <button *ngIf="optCur === 'create'" class="stdTheme" ngbDropdownItem [routerLink]="[item.itemRoutingPath,'add']">
                                                    <a><i class="fas fa-plus"></i> &nbsp;{{'add equipment'| translate}} </a>
                                                </button>
                                                <button *ngIf="optCur === 'search'" class="stdTheme" ngbDropdownItem [routerLink]="[item.itemRoutingPath,'search']" >
                                                    <a><i class="fas fa-search"></i> &nbsp;{{'search equipment' | translate}} </a>
                                                </button>
                                                <button *ngIf="optCur === 'list'" class="stdTheme" ngbDropdownItem [routerLink]="[item.itemRoutingPath,'list']" >
                                                    <a><i class="fas fa-list"></i> &nbsp;{{'all equipments' | translate}}&nbsp;{{item.itemLabel | translate}}</a>
                                                </button>
                                            </div>
                                        </div>
                                        <div *ngIf="item.itemLabel === 'wks_parameters'" ngbDropdownMenu class="stdTheme" aria-labelledby="{{item.itemLabel | translate}}" >
                                            <div *ngFor="let itemSettings of settingsButtonsItems " >
                                                <div *ngIf="itemSettings.itemParent === item.itemLabel">
                                                    <button class="stdTheme" ngbDropdownItem [routerLink]="[itemSettings.itemRoutingPath]">
                                                        <a>{{itemSettings.itemLabel| translate}} </a>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="item.itemLabel === 'wks_thirdparties'" ngbDropdownMenu class="stdTheme" aria-labelledby="{{item.itemLabel | translate}}" >
                                            <div *ngFor="let itemThirdparties of thirdpartiesButtonItems " >
                                                <div *ngIf="itemThirdparties.itemParent === item.itemLabel">
                                                    <button class="stdTheme" ngbDropdownItem [routerLink]="[itemThirdparties.itemRoutingPath]">
                                                        <a>{{itemThirdparties.itemLabel| translate}} </a>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex-row row" >
                    <div class="col-12">
                        <div class="container-fluid d-flex justify-content-between" style="margin-top: 50px">
                            <div *ngFor="let item of equipButtonsItems; let index = index; let isFirst = first; let isOdd = odd;">
                                <button class="btn buttonMenu mat-raised-button mat-elevation-z8 tooltipButton" id="{{item.itemLabel}}"
                                    [routerLink]="[item.itemRoutingPath,'list']">
                                    <a> <img src="assets/img/{{item.itemImg}}" width="100" height="100"> </a>
                                    <span class="tooltiptext">{{item.itemLabel | translate }}</span>
                                </button>
                                <!--
                                <div ngbDropdown class="d-inline-block">
                                    <button class="btn buttonMenu mat-raised-button mat-elevation-z24 tooltipButton" id="{{item.itemLabel}}" 
                                            ngbDropdownToggle>
                                        <a> <img src="assets/img/{{item.itemImg}}" width="100" height="100"> </a>
                                        <span class="tooltiptext">{{item.itemLabel | translate }}</span>
                                    </button>
                                    <div ngbDropdownMenu class="stdTheme" aria-labelledby="{{item.itemLabel | translate}}" >
                                        <button class="stdTheme" ngbDropdownItem [routerLink]="[item.itemRoutingPath,'add']">
                                            <a><i class="fas fa-plus"></i> &nbsp;{{'add equipment'| translate}} </a>
                                        </button>
                                        <button class="stdTheme" ngbDropdownItem [routerLink]="[item.itemRoutingPath,'search']" >
                                            <a><i class="fas fa-search"></i> &nbsp;{{'search equipment' | translate}} </a>
                                        </button>
                                        <button class="stdTheme" ngbDropdownItem [routerLink]="[item.itemRoutingPath,'list']" >
                                            <a><i class="fas fa-list"></i> &nbsp;{{'all equipments' | translate}}&nbsp;{{item.itemLabel | translate}}</a>
                                        </button>
                                    </div>
                                </div>
                            -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex-row row" >
                    <div class="col-12">
                        <div class="container-fluid d-flex justify-content-between" style="margin-top: 50px">
                            <div *ngFor="let item of jobsButtonsItems; let index = index; let isFirst = first; let isOdd = odd;">
                                <div *ngIf="item.options.length === 1" >
                                    <button class="btn buttonMenu mat-raised-button mat-elevation-z24 tooltipButton" id="{{item.itemLabel}}" [routerLink]="[item.itemRoutingPath,item.options[0]]"
                                            >
                                        <a> <img src="assets/img/{{item.itemImg}}" class="svg-scaled-1"width="100" height="100"> </a>
                                        <span class="tooltiptext">{{item.itemLabel | translate }}</span>
                                    </button>
                                </div>
                                <div ngbDropdown class="d-inline-block"  *ngIf="item.options.length !== 1" >
                                    <button class="btn buttonMenu mat-raised-button mat-elevation-z24 tooltipButton" id="{{item.itemLabel}}" ngbDropdownToggle
                                            >
                                        <a> <img src="assets/img/{{item.itemImg}}" class="svg-scaled-1"width="100" height="100"> </a>
                                        <span class="tooltiptext">{{item.itemLabel | translate }}</span>
                                    </button>
                                    <div *ngIf="item.itemLabel !== 'wks_worksOptions' && item.itemLabel !== 'wks_appointments' && item.options" ngbDropdownMenu class="stdTheme" aria-labelledby="{{item.itemLabel | translate}}" >
                                        <div *ngFor="let optCur of item.options">
                                            <button *ngIf="optCur === 'create'" class="stdTheme" ngbDropdownItem [routerLink]="[item.itemRoutingPath,'add']">
                                                <a><i class="fas fa-plus"></i> &nbsp;{{'add equipment'| translate}} </a>
                                            </button>
                                            <button *ngIf="optCur === 'search'" class="stdTheme" ngbDropdownItem [routerLink]="[item.itemRoutingPath,'search']" >
                                                <a><i class="fas fa-search"></i> &nbsp;{{'search equipment' | translate}} </a>
                                            </button>
                                            <button *ngIf="optCur === 'list'" class="stdTheme" ngbDropdownItem [routerLink]="[item.itemRoutingPath,'list']" >
                                                <a><i class="fas fa-list"></i> &nbsp;{{'all equipments' | translate}}&nbsp;{{item.itemLabel | translate}}</a>
                                            </button>
                                        </div>
                                    </div>
                                    <div *ngIf="item.itemLabel === 'wks_appointments' || item.itemLabel === 'wks_worksOptions'" ngbDropdownMenu class="stdTheme" aria-labelledby="{{item.itemLabel | translate}}" >
                                        <div *ngFor="let itemSettings of settingsButtonsItems " >
                                            <div *ngIf="itemSettings.itemParent === item.itemLabel">
                                                <button class="stdTheme" ngbDropdownItem [routerLink]="[itemSettings.itemRoutingPath]">
                                                    <a>{{itemSettings.itemLabel| translate}} </a>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--
       <mat-sidenav-container fxFlexFill class="container-sidenav w-100" >
            <mat-sidenav #sidenav  [fixedInViewport]="true" [fixedTopGap]="70"  [fixedBottomGap]= "20" 
                                    fxLayout="column" role="navigation" mode="push" opened="false" position="start" >
                <div class="d-flex justify-content-between" style="margin: 10px;" >
                  
                    <button mat-icon-button  (click)="displayHome()" >
                        <mat-icon>home</mat-icon>
                    </button>
                    <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="toggleSidenav('')" >
                                <mat-icon aria-label="Side nav toggle icon">close</mat-icon>
                    </button>

                </div>

            </mat-sidenav>

            <mat-sidenav-content [fixedInViewport]="true" [fixedTopGap]="70"  [fixedBottomGap]= "20" style="height: 740px;">
               
                <button  type="button" aria-label="Toggle sidenav" mat-icon-button (click)="toggleSidenav('')" color="primary">
                    <mat-icon aria-label="Side nav toggle icon">more_vert</mat-icon>
                </button>
                <div fxFlex fxLayout fxLayoutAlign="center">
                    <h1>MJL-Data <small>{{ 'an interface for data' | translate }}</small></h1>
                </div>
               
            </mat-sidenav-content>
        </mat-sidenav-container>

    </div>
-->
</div>  
