<div #refElement class="mat-elevation-z8 container-fluid stdTheme entity-card-container" style="margin-top: 10px; padding-left: 0px; padding-right: 0px;" >
  <mat-tab-group mat-align-tabs="start" dynamicHeight class="mat-tab-fill-height" style="margin: 0px; height: 100%;">
    <mat-tab label="{{ 'description' | translate }}" >
        <mat-card class="register-card stdTheme" layout="column" flex style="padding-left: 0px; padding-right: 0px;">
        <!-- <mat-card-header>
            <mat-card-title  >
              <span #titleCard>{{ RegisterTitle | translate }}</span>
            </mat-card-title>
          </mat-card-header> -->
          <mat-card-actions>
            <div id="registration-error" class="alert alert-danger stdTheme" *ngIf="registrationFailed ">
              <button type="button" class="close buttonTheme" aria-label="Close" (click)="registrationFailed = false;">
                <!-- <span aria-hidden="true">&#215;</span> -->
              </button>
              {{ messageTranslate }}
            </div>
          </mat-card-actions>
          <form (ngSubmit)="register()" [formGroup]="entityForm"  >
            <mat-card-content class="stdTheme">
              <div class="flex-row row" style="margin-left: 0px; margin-right: 0px;">
                <mat-form-field class="mdiinput form-group stdTheme" appearance="standard" >
                  <mat-label>{{ 'entity' | translate }}</mat-label>
                  <input id="entity" class="form-control inputTheme" formControlName="entity" matInput #input minlength="3" placeholder="{{ 'entity' | translate }}"
                    disabled >
                  <!--<mat-hint align="end">{{input.value?.length || 0}}/3</mat-hint>-->
                  <mat-error *ngIf="entityCtrl.invalid">{{getErrorMessage('entityCtrl')}}</mat-error>
                  <mat-error *ngIf="isEntityFound">{{getErrorMessage('entityFound')}}</mat-error>
                </mat-form-field>
              </div>
              <div class="flex-row row" style="margin-left: 0px; margin-right: 0px;">
                <div class="col-6" style="padding-left: 0px; padding-right: 0px;">
                  <mat-form-field class="mdiinput form-group" appearance="standard">
                    <mat-label>{{ 'entitylabel' | translate }}</mat-label>
                    <input id="entitylabel" class="form-control inputTheme" formControlName="entitylabel" matInput #input minlength="3" placeholder="{{ 'entitylabel' | translate }}"
                      required>
                    <!--<mat-hint align="end">{{input.value?.length || 0}}/3</mat-hint>-->
                    <mat-error *ngIf="entitylabelCtrl.invalid">{{getErrorMessage('entitylabel')}}</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-6" style="padding-left: 0px; padding-right: 0px;">
                  <mat-form-field class="mdiinput form-group" appearance="standard">
                    <mat-label>{{ 'entitydesc' | translate }}</mat-label>
                    <input id="entitydesc" class="form-control inputTheme" formControlName="entitydesc" matInput #input placeholder="{{ 'entitydesc' | translate }}"
                      >
                  </mat-form-field>
                </div>
              </div>
              <div class="flex-row row" style="margin-left: 0px; margin-right: 0px;">
                <div class="col-6" style="padding-left: 0px; padding-right: 0px;">
                  <mat-form-field class="mdiinput form-group" appearance="standard">
                    <mat-label>{{ 'Country' | translate }}</mat-label>
                    <!-- <input id="entitycountry" class="form-control inputTheme" formControlName="entitycountry" matInput #input placeholder="{{ 'Country' | translate }}"
                      > -->
                    <mat-select id="entitycountry" placeholder="{{ 'Choose country' | translate }}" formControlName="entitycountry" 
                      panelClass="mdiselectcontent"
                      (ngModelChange)="onChangeCountry($event)"
                      ngDefaultControl required>
                      <div style="display: inline-block;" >
                        <button mat-icon-button matSuffix aria-label="search" >
                          <mat-icon>search</mat-icon>
                        </button>
                        <input class="searchTheme" #filtercountry placeholder="{{ 'filterInput' | translate }}" 
                                    (keyup)="onKeyCountry($event.target.value)" >
                        <button mat-icon-button matSuffix aria-label="clear" 
                                    *ngIf="filtercountry.value" (click)="filtercountry.value=''; onKeyCountry('');">
                          <mat-icon>close</mat-icon>
                        </button>
                      </div>
                        <mat-option class="selectOptionsTheme" *ngFor="let country of countriesFilteredList" 
                            [value]="country.isoAlpha2"  >{{country.commonName | translate}}
                        </mat-option>
                    </mat-select>      
                  </mat-form-field>
                </div>
                <div class="col-6" style="padding-left: 0px; padding-right: 0px;">
                  <mat-form-field class="mdiinput form-group" appearance="standard">
                    <mat-label>{{ 'language' | translate }}</mat-label>
                    <!-- <input id="language" class="form-control inputTheme" formControlName="entitylang" matInput #input placeholder="{{ 'Language' | translate }}"
                      >-->
                    <mat-select id="entitylang" placeholder="{{ 'Choose lang' | translate }}" formControlName="entitylang" 
                                panelClass="mdiselectcontent"
                                (ngModelChange)="onChangeLang($event)"
                                ngDefaultControl required>
                        <mat-option class="selectOptionsTheme" *ngFor="let lang of supportedLangs" 
                                      [value]="lang"  >{{ lang.display | translate }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div class="flex-row row" style="margin-left: 0px; margin-right: 0px;">
                <mat-form-field class="mdiinput form-group" appearance="standard">
                  <mat-label>{{ 'datafilter' | translate }}</mat-label>
                  <input id="datafilter" class="form-control inputTheme" formControlName="datafilter" matInput #input placeholder="{{ 'datafilter' | translate }}"
                    >
                </mat-form-field>
              </div>
              <div class="flex-row row" style="margin-left: 0px; margin-right: 0px;">
                <mat-form-field class="mdiinput form-group" appearance="standard">
                  <mat-label>{{ 'supervisoremail' | translate }}</mat-label>
                  <input id="supervisoremail" class="form-control inputTheme" formControlName="supervisoremail" matInput #input minlength="3" placeholder="{{ 'supervisoremail' | translate }}"
                    required>
                  <mat-error *ngIf="supervisoremailCtrl.invalid">{{getErrorMessage('supervisoremail')}}</mat-error>
                </mat-form-field>
              </div>
              <div class="flex-row row" style="padding-top: 10px;margin-left: 0px;margin-right: 0px;">
                <!-- <section class="mat-typography">-->
              <mat-form-field class="mdiselect form-group stdTheme" appearance="standard">
                <mat-label>{{ 'Applications' | translate }}</mat-label>
                <mat-select #selectRoles placeholder="{{ 'Choice applications' | translate }}" 
                            panelClass="mdiselectcontent"
                            disableOptionCentering formControlName="applications" multiple ngDefaultControl required>
                    <mat-select-header>
                      <div class="flex-row button-row text-center" >
                        <button mat-raised-button class="buttonTheme"  (click)="selectRoles.toggle()">
                          {{'Close' | translate}}
                          <mat-icon>cancel</mat-icon>
                        </button>
                      </div>
                    </mat-select-header>
                     <mat-option class="selectOptionsTheme" *ngFor="let appli of applicationsList" [value]="appli.value">{{ appli.display | translate }}</mat-option>
                </mat-select>
                <mat-error *ngIf="applicationsCtrl.invalid">{{getErrorMessage('applications')}}</mat-error>
              </mat-form-field>
              <!-- </section>-->
            </div>
              <!-- extended access start -->
              <div class="flex-row row" style="margin-left: 0px; margin-right: 0px;">
              <mat-form-field class="mdiselect form-group" appearance="standard">
                <mat-label>{{ 'extendedaccess' | translate }}</mat-label>
                  <mat-select #selectAccess placeholder="{{ 'Add extended access' | translate }}" 
                              panelClass="mdiselectcontent"
                              disableOptionCentering formControlName="extendedaccess" multiple ngDefaultControl >
                      <mat-select-header>
                        <div class="flex-row button-row text-center" >
                          <button mat-raised-button class="buttonTheme" (click)="selectAccess.toggle()">
                            {{'Close' | translate}}
                            <mat-icon>cancel</mat-icon>
                          </button>
                        </div>
                      </mat-select-header>
                      <mat-option  class="selectOptionsTheme"  *ngFor="let entity of entitiesList" [value]="entity">{{ entity }}</mat-option>
                  </mat-select>
                  <mat-error *ngIf="extendedaccessCtrl.invalid">{{getErrorMessage('extendedAccess')}}</mat-error>
                </mat-form-field>
                <!-- </section>-->
              </div>
              <div class="flex-row row" style="margin-left: 0px; margin-right: 0px;">
                <mat-form-field class="mdiinput form-group" appearance="standard">
                  <mat-label>{{ 'tags' | translate }}</mat-label>
                  <input id="tags" class="form-control inputTheme" formControlName="tags" matInput #input placeholder="{{ 'tags' | translate }}"
                    >
                </mat-form-field>
              </div>
              <!-- extended access end -->
            </mat-card-content>
            <mat-card-actions>
              <div class="flex-row button-row text-center stdTheme" style="margin-top: 20px;">
                <button mat-raised-button class="buttonTheme" #buttonSubmit type="submit" [disabled]="!entityForm.valid || isEntityFound">
                  <mat-icon>create</mat-icon>
                  {{ 'submit' | translate }}
                </button>
                <button mat-raised-button class="buttonTheme" #buttonSubmit type="reset" (click)="doCancel()">
                  <mat-icon>cancel</mat-icon>
                  {{ 'cancel' | translate }}
                </button>
              </div>
            </mat-card-actions>
          </form>
        </mat-card>
      </mat-tab>
      <!-- 
      <mat-tab label="{{ 'models' | translate }}" >
        <mat-tab-body >
          <ng-template matTabContent >
            <ng-container *ngTemplateOutlet="modelsTemplate"></ng-container>
        </ng-template>
        </mat-tab-body>
      </mat-tab>
     -->
  </mat-tab-group>
</div>
<ng-template #modelsTemplate >
  <!--   https://stackblitz.com/angular/ynbnyenqnvx?file=src%2Fapp%2Fsidenav-open-close-example.ts -->
  <div class="container-fluid stdTheme " >
    <div class="flex-row row stdTheme" style="margin: 0px; margin-left: 0px; margin-right: 0px;">
      <div class="col-1" style="margin-top: 20px;; padding-left: 0px; padding-right: 0px;">
          <mat-toolbar class="stdTheme">
              <mat-toolbar-row class="toolbar-work" *ngIf="!displayUpload">
                  <button mat-icon-button class="buttonTheme" matTooltip="{{ 'uploadFiles' | translate }}" 
                          (click)="toggleUploadFiles()"
                      style="margin-left: 10px;margin-right: 10px;">
                      <mat-icon class="mat-icon-work">cloud_upload</mat-icon>
                  </button>
              </mat-toolbar-row>

              <mat-toolbar-row class="toolbar-work"  *ngIf="!displayList">
                  <button mat-icon-button class="buttonTheme" matTooltip="{{ 'displayList' | translate }}" 
                          (click)="toggleDisplayList()"
                      style="margin-left: 10px;margin-right: 10px;">
                      <mat-icon class="mat-icon-work">list</mat-icon>
                  </button>
              </mat-toolbar-row>
          </mat-toolbar>
      </div>

      <div class="col-11" *ngIf="displayUpload" style="margin-top: 20px; padding-left: 0px; padding-right: 0px;">
          <mdi-upload-files fileExt="XLSX, DOCX, DOC, ODT" uploadType="entity" ></mdi-upload-files>
      </div>
    </div>
  </div>
</ng-template>
