import { Component, OnInit, SimpleChanges, OnChanges, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { ToolApiConnect, ToolApi, ToolApiVersion, ToolKeyValue, ToolService, 
      MessageDisplay, ToolArgs, ToolApiDatas, BodyApi, ToolApiTest, OtherService, ToolApiParam, 
      ToolApiResponse} from '../../../../../../../models/common.model';
import { FormArray, FormBuilder, FormControl, FormGroup} from '@angular/forms';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ModalCommonComponent } from '../../../../../../../job/common/components/modal-common/modal-common.component';

import { WksCentralService } from '../../../../../services/wks-central.service';
import { CommonMethods } from '../../../../../../..//job/common/tools/commonMethods';
import { TranslateService } from '../../../../../../../services/translate.service';
import { GlobalEventsManagerService } from '../../../../../../../job/common/services/global-events-manager.service';

import { PartsRequirement } from '../../../../../../job-wks/models/wks-param.model';
import { WksApisService } from '../../../../../services/wks-apis.service';

@Component({
  selector: 'mdi-apis',
  templateUrl: './apis.component.html',
  styleUrls: ['./apis.component.css'],
  encapsulation: ViewEncapsulation.None,
})

export class ApisComponent implements OnInit, OnChanges {

  @Input() apiType: string;
  @Input() apiContent: ToolApiConnect;
  @Input() entityCur: string;
  @Input() otherData: any;
  @Input() isReadOnly: boolean;

  @Output() dataOut = new EventEmitter<ToolApiConnect>();
  private readonly onDestroy = new Subject<void>();

  apiContentForm: FormGroup;
  // toolServicesFa: FormArray;
  
  apiContentCache: ToolApiConnect;
  apiContentInput: ToolApiConnect;
  methodsList: string[];
  isOkToSave: boolean;
  isOkToTest: boolean;
  isformOK: boolean;
  disableTest: boolean;
  activateJwtChecked: boolean;
  toolAuthentificationOpt: string;
  toolsApis: ToolApi[];
  toolApiVersions: ToolApiVersion[];
  toolHeaders: ToolKeyValue[];
  toolArgs: ToolKeyValue[];
  toolTests: ToolApiParam[];
  toolTestOK: boolean[];
  timeFormat: string;
  toolTestArgs: ToolArgs;

  dialogRef: MatDialogRef<ModalCommonComponent, any>;
  
  constructor(private fb: FormBuilder, 
    private _wksCentralService: WksCentralService,
    private _dialog: MatDialog,
    private _translate: TranslateService,
    private _apisService: WksApisService,
    private _globalEventsManagerService: GlobalEventsManagerService) { }

  ngOnInit() {
  }
  ngOnChanges(changes: SimpleChanges) {
 
    const listKey = Object.keys(changes);
    for (const propName of listKey) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'apiType': {
            this.apiType = changes['apiType'].currentValue;

            break;
          }
          case 'apiContent': {
            this.apiContent = changes['apiContent'].currentValue;
            break;
          }
          case 'entityCur': {
            this.entityCur = changes['entityCur'].currentValue;
            break;
          }
          case 'otherData': {
            this.otherData = changes['otherData'].currentValue;
            break;
          }
          case 'isReadOnly': {
            this.isReadOnly = changes['isReadOnly'].currentValue;
            break;
          }
        } // end switch
      } // end if
    } // end loop
    
    this.initData();
  }
  initData() {
    this.initBoolean();
    this.initParams();
    this.initSessionData();
    this.buildForm();
    this.fillForm();

    this.initAllSubscribe();
    this.isformOK = true;
  }
  initBoolean(): void {
    this.isOkToSave = false;
    this.isOkToTest = false;
    this.isformOK = false;
    this.activateJwtChecked = false;
    this.disableTest = false;
  }
  initParams(): void {
    this.toolsApis = this._apisService.getApisParam(this.apiType);
    this.toolTests = [];
    this.timeFormat = this._translate.getLocalFmt('timeFormat');
  }
  initAllSubscribe(): void {  
    
    this.apiContentForm.valueChanges.subscribe(x => {
      if (JSON.stringify(this.apiContentCache) !== JSON.stringify(this.fillModel())) {
        this.isOkToSave = false;
        const formContent = this.findInvalidControlsRecursive(this.apiContentForm);
        // const formArrayContent = this.findInvalidControlsRecursive(this.toolServicesFa);
        // if (formContent.length === 0 && formArrayContent.length === 0) {
        if (formContent.length === 0) {
        // if (this.apiContentForm.status === 'VALID' && this.toolServicesFa.status === 'VALID') {
          this.apiContentInput = this.fillModel();
          this.isOkToSave = true;
          this.isOkToTest = true;
        }
      }
    });
    
  }
  findInvalidControlsRecursive(formToInvestigate: FormGroup|FormArray): string[] {

    const invalidControls: string[] = [];
    const recursiveFunc = (form: FormGroup|FormArray) => {
      Object.keys(form.controls).forEach(field => { 
        const control = form.get(field);
        if (control.invalid)  {
          invalidControls.push(field);
        }
        if (control instanceof FormGroup) {
          recursiveFunc(control);
        } else if (control instanceof FormArray) {
          recursiveFunc(control);
        }        
      });
    };
    recursiveFunc(formToInvestigate);
    return invalidControls;
  }
  buildForm(): void {

    this.apiContentForm = undefined;

    // this.toolServicesFa = new FormArray([this.buildToolServices()]);

    this.apiContentForm = new FormGroup({
      toolNameFg: this.fb.control({value: '', disabled: this.isReadOnly}),
      toolVersionFg: this.fb.control({value: '', disabled: this.isReadOnly}),
      toolCustomerNwsIdFg: this.fb.control({value: '', disabled: this.isReadOnly}),
      toolCustomerNwsIdFieldFg: this.fb.control({value: '', disabled: this.isReadOnly}),
      toolCustomerNwsCodeFg: this.fb.control({value: '', disabled: this.isReadOnly}),
      toolCustomerNwsCodeFieldFg: this.fb.control({value: '', disabled: this.isReadOnly}),
      toolProtocolFg: this.fb.control({value: '', disabled: true}),
      toolAuthentificationFg: this.fb.control({value: '', disabled: this.isReadOnly}),
      usernameFg: this.fb.control({value: '', disabled: this.isReadOnly}),
      pwdFg: this.fb.control({value: '', disabled: this.isReadOnly}),
      toolkeynameFg: this.fb.control({value: '', disabled: this.isReadOnly}),
      toolkeyvalueFg: this.fb.control({value: '', disabled: this.isReadOnly}),
      // toolServicesFg: new FormArray([this.buildToolServices()]),
      toolServerUrlFg: this.fb.control({value: '', disabled: this.isReadOnly}),
      toolServerArgsFg: this.fb.control({value: '', disabled: this.isReadOnly}),
      activateJwtFg: this.fb.control({value: false, disabled: this.isReadOnly}),
      toolJwtServerUrlFg: this.fb.control({value: '', disabled: this.isReadOnly}),
      toolJwtServerArgsFg: this.fb.control({value: '', disabled: this.isReadOnly}),
      toolHeadersFa: this.fb.array([]) ,
      toolArgsFa: this.fb.array([]) ,
    },
    {
      updateOn: 'blur'
    });
    
  }
  // start toolHeadersFa methods
  get toolHeadersFa(): FormArray {
    return this.apiContentForm.get('toolHeadersFa') as FormArray;
  }
  newToolKeyValuesRow(): FormGroup {
    return this.fb.group({
      keyFg: this.fb.control({value: '', disabled: this.isReadOnly}),
      valueFg: this.fb.control({value: '', disabled: this.isReadOnly})
    });
  }
  addToolKeyValuesRow() {
    this.toolHeadersFa.push(this.newToolKeyValuesRow());
  }
  removelHeadersRow(idxToolKeyValue: number) {
    this.toolHeadersFa.removeAt(idxToolKeyValue);
  }
  // end toolHeadersFa methods 
  // start toolArgsFa methods
  get toolArgsFa(): FormArray {
    return this.apiContentForm.get('toolArgsFa') as FormArray;
  }
  newToolArgsRow(): FormGroup {
    return this.fb.group({
      keyFg: this.fb.control({value: '', disabled: this.isReadOnly}),
      valueFg: this.fb.control({value: '', disabled: this.isReadOnly})
    });
  }
  addToolArgsRow() {
    this.toolArgsFa.push(this.newToolArgsRow());
  }
  removelArgsRow(idxToolArg: number) {
    this.toolArgsFa.removeAt(idxToolArg);
  }
  // end toolArgsFa methods 
  buildToolServices(): FormGroup {
    const isDisabled = false ;
    const FormGroupTmp: FormGroup = new FormGroup ({
      toolServiceNameFg: new FormControl({value: '', disabled: true}),
      toolServiceMethodFg: new FormControl({value: '', disabled: isDisabled}),
      toolServiceUrlFg: new FormControl({value: '', disabled: isDisabled}),
      toolServiceArgsFg: new FormControl({value: '', disabled: isDisabled})
    });
    /*
    if (!isDisabled) {
      FormGroupTmp.controls['toolServiceNameFg'].setErrors({'incorrect': true});
      FormGroupTmp.controls['toolServiceMethodFg'].setErrors({'incorrect': true});
      FormGroupTmp.controls['toolServiceUrlFg'].setErrors({'incorrect': true});
      FormGroupTmp.controls['toolServiceArgsFg'].setErrors({'incorrect': true});
    }
    */
    return FormGroupTmp;
  }
  
  emptyToolApiConnect(): ToolApiConnect {

    const apiContent: ToolApiConnect = {
      toolName: '',
      toolVersion: '',
      toolCustomerNwsId: '',
      toolCustomerNwsIdField: '',
      toolCustomerNwsCode: '',
      toolCustomerNwsCodeField: '',
      toolWebService: { 
        toolProtocol: 'REST',
        toolCredentials: { 
          toolAuthentification: 'tooluser',
          username: '',
          pwd: '',
          toolkeyname: '',
          toolkeyvalue: '',
        },
        toolArgs: [],
        toolHeaders: [],
        toolServerUrl: '',
        toolServerArgs: '',
        // toolServices: this.emptyServices(),
        toolAuth2: { 
          activateJwt: false,
          toolJwtServerUrl: '',
          toolJwtServerArgs: ''
        }
      },
      toolTests: [],
      toolImport: { 
        model: '',
        scheduling: '',
      },
      toolExport: { 
          model: '',
          scheduling: '',
      },
    };
    this.toolAuthentificationOpt = 'tooluser';
    this.toolHeaders = [];
    return apiContent;
  }
 
  onChangeToolApis(value: string, orig: string): void {

    switch (orig) {
      case 'toolName': {
        for (const toolApi of this.toolsApis) {
          if (toolApi.toolName === value) {
            this.toolApiVersions = toolApi.toolVersions;
            break;
          }
        }
        break;
      }
      case 'toolVersion': {
        this.toolTests = [];
        for (const toolVersion of this.toolApiVersions) {
          if (toolVersion.toolVersion === value) {
            for (const toolService of toolVersion.toolServices) {
              if (toolService.menuTest === '') {
                continue;
              }
              const toolTest = this.emptyToolApiParam();
              toolTest.apiService = toolService.name;
              toolTest.menuTest = toolService.menuTest;
              toolTest.authorised = toolService.authorised;
              this.toolTests.push(toolTest);
            }
          }
        }
        this.toolTests.sort((obj1: ToolApiParam, obj2: ToolApiParam) => {
          return obj1.menuTest > obj2.menuTest ? 1 : -1;
        });
        this.toolTestOK = [];
        for (let i = 0; i < this.toolTests.length; i++ ) {
          this.toolTestOK.push(true);
        }
        break;
      }
      
      default : {
        
        break;
      }
    }
  }
  changeToolAuthentification(authentificationChecked: string ): void {

    switch (authentificationChecked) {
      case 'tooluser': {
        this.toolAuthentificationOpt = 'tooluser';
        this.apiContentForm.controls.usernameFg.reset({value: '', disabled: false });
        this.apiContentForm.controls.pwdFg.reset({value: '', disabled: false });
        this.apiContentForm.controls.toolkeynameFg.reset({value: '', disabled: true });
        this.apiContentForm.controls.toolkeyvalueFg.reset({value: '', disabled: true });
        break;
      }
      case 'toolkey': {
        this.toolAuthentificationOpt = 'toolkey';
        this.apiContentForm.controls.usernameFg.reset({value: '', disabled: true });
        this.apiContentForm.controls.pwdFg.reset({value: '', disabled: true });
        this.apiContentForm.controls.toolkeynameFg.reset({value: '', disabled: false });
        this.apiContentForm.controls.toolkeyvalueFg.reset({value: '', disabled: false });
        break;
      }
      
      default : {
        this.toolAuthentificationOpt = 'tooluser';
        break;
      }
    }

  }
  // =================================
  onToolHeadersChange(_typeInput: string, inputValue: any, idxHeader: number): void {
    // const headersInput = this.apiContentForm.get('toolHeadersFa') as FormArray;
    // const headerForm = (<FormArray>this.apiContentForm.get('toolHeadersFa')).at(idxHeader) as FormGroup;
    // console.log(JSON.stringify(headerForm));
    switch (_typeInput) {
      case 'key':
        this.toolHeaders[idxHeader].key = inputValue;
        break;
      case 'value':
        this.toolHeaders[idxHeader].value  = inputValue;
        break;    
    }
  }
  addRowToolHeaders(idxHeader: number): void {
    const headerEmpty: ToolKeyValue = {
      key: '',
      value: ''
    };
    this.toolHeaders.splice(idxHeader + 1, 0 , headerEmpty);
    this.toolHeadersFa.insert(idxHeader + 1 , this.newToolKeyValuesRow());
  }
  removeRowToolHeaders(idxHeader: number): void {
    this.toolHeaders.splice(idxHeader, 1);
    this.removelHeadersRow(idxHeader);
    if (this.toolHeaders.length === 0 ) {
      this.addRowToolHeaders(0);
      this.newToolKeyValuesRow();
    }
  }
  onToolArgsChange(_typeInput: string, inputValue: any, idxArgs: number): void {
    // console.log(JSON.stringify(inputValue));
    // const argsInput = this.apiContentForm.get('toolArgsFa') as FormArray;
    switch (_typeInput) {
      case 'key':
        this.toolArgs[idxArgs].key = inputValue;
        break;
      case 'value':
        this.toolArgs[idxArgs].value = inputValue;
        break;    
    }
  }
  addRowToolArgs(idxArgs: number): void {
    const headerEmpty: ToolKeyValue = {
      key: '',
      value: ''
    };
    this.toolArgs.splice(idxArgs + 1, 0 , headerEmpty);
    this.toolArgsFa.insert(idxArgs + 1 , this.newToolArgsRow());
  }
  removeRowToolArgs(idxArgs: number): void {
    this.toolArgs.splice(idxArgs, 1);
    this.removelArgsRow(idxArgs);
    if (this.toolArgs.length === 0 ) {
      this.addRowToolArgs(0);
      this.newToolArgsRow();
    }
  }
  refreshTest(): void {
    this.toolTestOK = [];
    this.initSessionData();

    for (let i = 0; i < this.toolTests.length; i++ ) {
      this.toolTestOK.push(false);
      this.toolTests[i].statut = 'NT';
      this.toolTests[i].testDate = undefined;
      this.toolTests[i].toolDatas = [];
    }
    this.toolTestOK[0] = true;
    this.apiContentInput = this.fillModel();
    this.validData();
  }
// ==========================
  onChangeMethod(value: string, idxService: number): void {
    // this.apiContentCache.toolWebService.toolServices[idxService].toolServiceMethod = value;
  }
  activateJwtChange($event: any): void {
    this.activateJwtChecked = $event;

    this.apiContentForm.controls.toolJwtServerUrlFg.reset({value: '', disabled: !this.activateJwtChecked });
    this.apiContentForm.controls.toolJwtServerArgsFg.reset({value: '', disabled: !this.activateJwtChecked });

  }
  fillModel(): ToolApiConnect {
    const apiContent: ToolApiConnect = {
      toolName: this.apiContentForm.controls.toolNameFg.value,
      toolVersion: this.apiContentForm.controls.toolVersionFg.value,
      toolCustomerNwsId: this.apiContentForm.controls.toolCustomerNwsIdFg.value,
      toolCustomerNwsIdField: this.apiContentForm.controls.toolCustomerNwsIdFieldFg.value,
      toolCustomerNwsCode: this.apiContentForm.controls.toolCustomerNwsCodeFg.value,
      toolCustomerNwsCodeField: this.apiContentForm.controls.toolCustomerNwsCodeFieldFg.value,
      toolWebService:  {
        toolProtocol: this.apiContentForm.controls.toolProtocolFg.value,
        toolCredentials: { 
            toolAuthentification: this.toolAuthentificationOpt,
            username: this.apiContentForm.controls.usernameFg.value,
            pwd: this.apiContentForm.controls.pwdFg.value,
            toolkeyname: this.apiContentForm.controls.toolkeynameFg.value,
            toolkeyvalue: this.apiContentForm.controls.toolkeyvalueFg.value,
        },
        toolArgs: this.toolArgs,
        toolHeaders: this.toolHeaders,
        toolServerUrl: this.apiContentForm.controls.toolServerUrlFg.value,
        toolServerArgs: this.apiContentForm.controls.toolServerArgsFg.value,
        // toolServices: this.fillServicesModel(),
        toolAuth2: { 
          activateJwt: this.apiContentForm.controls.activateJwtFg.value,
          toolJwtServerUrl: this.apiContentForm.controls.toolJwtServerUrlFg.value,
          toolJwtServerArgs: this.apiContentForm.controls.toolJwtServerArgsFg.value,
        }
      },
      toolTests: this.toolTests,
      toolImport: { 
        model: '',
        scheduling: ''
      },
      toolExport: { 
        model: '',
        scheduling: ''
      }
    };
    return apiContent;
  }

  emptyToolApiParam(): ToolApiParam {

    const toolTest: ToolApiParam = {
      apiService: '',
      menuTest: '',
      authorised: false,
      initSession: false,
      previousServices: [],
      nextServices: [],
      alternativeService: '',
      statut: 'NT',
      testDate: undefined,
      toolDatas: []
    };
    return toolTest;
  }

  fillForm(): void {
    /*
    if (this.apiContent === undefined) {
      // testToolApiConnect
      this.apiContent = this.emptyToolApiConnect();
    }
    */
    this.apiContentCache = JSON.parse(JSON.stringify(this.apiContent));
    
    // this.apiContentInput = this.testToolApiConnect();
    this.apiContentInput = JSON.parse(JSON.stringify(this.apiContent));

    for (const toolApi of this.toolsApis) {
      if (toolApi.toolName === this.apiContentInput.toolName) {
        this.toolApiVersions = toolApi.toolVersions;
        break;
      }
    }

    this.apiContentForm.controls.toolNameFg.setValue(this.apiContentInput.toolName);
    this.apiContentForm.controls.toolVersionFg.setValue(this.apiContentInput.toolVersion);
    this.apiContentForm.controls.toolCustomerNwsIdFg.setValue(this.apiContentInput.toolCustomerNwsId);
    this.apiContentForm.controls.toolCustomerNwsIdFieldFg.setValue(this.apiContentInput.toolCustomerNwsIdField);
    this.apiContentForm.controls.toolCustomerNwsCodeFg.setValue(this.apiContentInput.toolCustomerNwsCode);
    this.apiContentForm.controls.toolCustomerNwsCodeFieldFg.setValue(this.apiContentInput.toolCustomerNwsCodeField);
    this.apiContentForm.controls.toolProtocolFg.setValue(this.apiContentInput.toolWebService.toolProtocol);
    this.toolAuthentificationOpt = this.apiContentInput.toolWebService.toolCredentials.toolAuthentification;
    this.apiContentForm.controls.usernameFg.setValue(this.apiContentInput.toolWebService.toolCredentials.username);
    this.apiContentForm.controls.pwdFg.setValue(this.apiContentInput.toolWebService.toolCredentials.pwd);
    this.apiContentForm.controls.toolkeynameFg.setValue(this.apiContentInput.toolWebService.toolCredentials.toolkeyname);
    this.apiContentForm.controls.toolkeyvalueFg.setValue(this.apiContentInput.toolWebService.toolCredentials.toolkeyvalue);
    this.apiContentForm.controls.toolServerUrlFg.setValue(this.apiContentInput.toolWebService.toolServerUrl);
    this.apiContentForm.controls.toolServerArgsFg.setValue(this.apiContentInput.toolWebService.toolServerArgs);
    this.apiContentForm.controls.activateJwtFg.setValue(this.apiContentInput.toolWebService.toolAuth2.activateJwt);
    this.apiContentForm.controls.toolJwtServerUrlFg.setValue(this.apiContentInput.toolWebService.toolAuth2.toolJwtServerUrl);
    this.apiContentForm.controls.toolJwtServerArgsFg.setValue(this.apiContentInput.toolWebService.toolAuth2.toolJwtServerArgs);
    this.activateJwtChecked = this.apiContentInput.toolWebService.toolAuth2.activateJwt;
    if (!this.activateJwtChecked) {
      this.apiContentForm.controls.toolJwtServerUrlFg.reset({value: '', disabled: true });
      this.apiContentForm.controls.toolJwtServerArgsFg.reset({value: '', disabled: true });
    }
    this.toolHeaders = [];
    if (this.apiContentInput.toolWebService.toolHeaders !== undefined) {
      this.toolHeaders = this.apiContentInput.toolWebService.toolHeaders ;
      let idxHeader = 0;
      for (const toolHeader of this.toolHeaders) {
        this.addToolKeyValuesRow();
        // const headerInput = this.apiContentForm.get('toolHeadersFa') as FormArray;
        const headerForm = (<FormArray>this.apiContentForm.get('toolHeadersFa')).at(idxHeader);
        headerForm.patchValue({
          keyFg: toolHeader.key,
          valueFg: toolHeader.value
        });

        idxHeader++;
      }
    }
    if (this.toolHeaders.length === 0) {
      this.addRowToolHeaders(0);
    }
    this.toolArgs = [];
    if (this.apiContentInput.toolWebService.toolArgs !== undefined) {
      this.toolArgs = this.apiContentInput.toolWebService.toolArgs ;
      let idxArgs = 0;
      for (const toolArg of this.toolArgs) {
        this.addToolArgsRow();
        const argsForm = (<FormArray>this.apiContentForm.get('toolArgsFa')).at(idxArgs);
        argsForm.patchValue({
          keyFg: toolArg.key,
          valueFg: toolArg.value
        });


        idxArgs++;
      }
    }
    if (this.toolArgs.length === 0) {
      this.addRowToolArgs(0);
    }
    this.toolTests = [];
    
    if (this.apiContentInput.toolTests !== undefined &&  this.apiContentInput.toolTests !== null) {
      this.toolTests = this.apiContentInput.toolTests;
      this.buildMenuTest();
      this.isOkToTest = true;
    }
    
    if (this.apiContentInput.toolName !== '' 
      && this.apiContentInput.toolVersion !== '' 
      && this.toolTests.length === 0) {
      for (const toolApi of this.toolsApis) {
        if (toolApi.toolName === this.apiContentInput.toolName) {
          this.toolApiVersions = toolApi.toolVersions;
          break;
        }
      }
      for (const toolVersion of this.toolApiVersions) {
        if (toolVersion.toolVersion === this.apiContentInput.toolVersion) {
          for (const toolService of toolVersion.toolServices) {
            if (toolService.menuTest === '') {
              continue;
            } 
            const toolTest = this.emptyToolApiParam();
            toolTest.apiService = toolService.name;
            toolTest.authorised = toolService.authorised;
            toolTest.menuTest = toolService.menuTest;
            this.toolTests.push(toolTest);
          }
        }
      }
      this.toolTests.sort((obj1: ToolApiParam, obj2: ToolApiParam) => {
        return obj1.menuTest > obj2.menuTest ? 1 : -1;
      });
      this.isOkToTest = true;
    }

    this.initToolApiParams(false);
    this.apiContentCache = this.fillModel();
  }
  buildMenuTest(): void {
    this.toolTests = this.apiContentInput.toolTests;
    let idxTest = 0;
    const toolTestsTmp: ToolApiParam[] = [];
    for (const testItem of this.toolTests) {
      if (testItem.menuTest === '') {
        // this.toolTests.splice(idxTest, 1);
        continue;
      }
      toolTestsTmp.push(testItem);
      idxTest++;
    }
    this.toolTests = JSON.parse(JSON.stringify(toolTestsTmp));
    this.toolTests.sort((obj1: ToolApiParam, obj2: ToolApiParam) => {
      return obj1.menuTest > obj2.menuTest ? 1 : -1;
    });
  }
  initSessionData(): void {
    this.toolTestArgs = {
      toolDatas : []
    };
    let testData: ToolKeyValue;

    for (let i = 1 ; i < 6 ; i++) {
      testData = {
        key: 'data' + i.toString(),
        value: 'TST_' + CommonMethods.randomString(8).toUpperCase()
      };
      this.toolTestArgs.toolDatas.push(testData);
    }
    
  }
  initToolApiParams(loadParam: boolean): void {

    this.toolTestOK = [];
    if (loadParam) {
      this.toolTests = this._wksCentralService.ctrlApisTestList(this.toolApiVersions, this.apiContentInput.toolVersion, this.toolTests );
      this.buildMenuTest();
    }
    for (let i = 0; i < this.toolTests.length; i++ ) {
      this.toolTestOK.push(false);
    }

    let allOK = true;
    let startOk = false;
    let endOK = false;
    let idxTest = 0;
    for (const toolTest of this.toolTests) {
      if (toolTest.menuTest === '') {
        continue;
      } 
      if (!toolTest.authorised) {
        this.toolTestOK[idxTest] = false;
      } else {
        if (idxTest === 0) { // first index : create
          if (toolTest.statut === 'OK') {
            startOk = true;
            // this.toolTestArgs.toolDatas = toolTest.toolDatas;
            // idxTest++;
            // continue;
          } else {
            this.toolTestOK[idxTest] = true;
            break;
          }
        } if (idxTest === this.toolTests.length - 1) { // last index : delete
          if (toolTest.statut === 'OK') {
            endOK = true;
          }
        } else if (toolTest.statut !== 'OK') {
          this.toolTestOK[idxTest] = true;
          allOK = false;
        } else if (toolTest.statut === 'OK') {
          // this.toolTestArgs.toolDatas.push(...toolTest.toolDatas);
          this.storageToolData(toolTest.toolDatas);
        }
      }
      idxTest++;
    }
    if (allOK && !endOK) { // process done : possibility replay
      this.toolTestOK[idxTest - 1] = true;
    }
    
  }
  storageToolData(toolDatasCur: ToolKeyValue[]): void {
    let isFound: boolean;
 
    for (const toolDataTmp of toolDatasCur) {
      for (let toolDataItem of this.toolTestArgs.toolDatas) {
        if (toolDataTmp.key === toolDataItem.key) {
          toolDataItem = toolDataTmp;
          isFound = true;
          break;
        }
      }
      if (!isFound) {
        this.toolTestArgs.toolDatas.push(toolDataTmp);
      }
      isFound = false;
    }

  }
  displayDate(dateDisplay: any): string {
    if (dateDisplay === undefined)  {
      return '';
    }
    if (dateDisplay === '')  {
      return '';
    }
    if (dateDisplay === null )  {
      return '';
    }
    return CommonMethods.dateToString(dateDisplay, 'unixM', this.timeFormat);
  }
  oddEven(idx: number): boolean {
    return (idx % 2 !== 0 ? true : false);
  }
  validData(): void {

    this.apiContentCache = JSON.parse(JSON.stringify(this.apiContentInput));
    this.dataOut.emit(this.apiContentInput);
  } 
  testApi(apiService: string, idxTest: number): void {
    this.disableTest = true;
    // console.log('testApi : ' + apiService);
    for (const toolVersionItem of this.toolApiVersions) {
      if (toolVersionItem.toolVersion === this.apiContentInput.toolVersion) {
        for (const serviceItem of toolVersionItem.toolServices) {
          if (serviceItem.name === apiService) {
            if (serviceItem.initSession) {
              this.initSessionData();
            }
            this.buildArgsLists(serviceItem.previousServices);
            this.buildBodyLists(serviceItem.body, serviceItem.testData);
          }
        } 
      }
    }

    let testData = {
      key: 'toolCustomerNwsCode',
      value: this.apiContentInput.toolCustomerNwsCode
    };
    this.toolTestArgs.toolDatas.push(testData);
    testData = {
      key: 'toolCustomerNwsId',
      value: this.apiContentInput.toolCustomerNwsId
    };
    this.toolTestArgs.toolDatas.push(testData);
    const messageDisplay: MessageDisplay = {
      titleBox: 'testApi',
      messageLabel: this.apiType + 'Mes',
      messageArgs: [this._translate.getTranslate(apiService), this._translate.getTranslate('inProgress')],
      messageType: 'ERROR',
      messageDatas: undefined,
      typeDialog: 'infoProgressWks',
      actionCur: 'testApi',
      progressDeterminate: false,
    };
    this.messageBoxDisplay(messageDisplay);
    this._wksCentralService.requestApis(this.entityCur, this.apiType, apiService, this.toolTestArgs )
    .subscribe(
      data => {
        this.closeMatDialog();
        const toolTestReturn = data.body;
        if (toolTestReturn.statut === 'KO') {
          this.affectResponse(toolTestReturn);
          this.initToolApiParams(false);
          this.apiContentInput = this.fillModel();
          this.validData();
          const messageDisplay2: MessageDisplay = {
            titleBox: 'testApi',
            messageLabel: this.apiType + 'Mes',
            messageArgs: [this._translate.getTranslate(apiService), 'KO'],
            messageType: 'ERROR',
            messageDatas: undefined,
            typeDialog: 'alertWks',
            actionCur: 'testApiKO',
            progressDeterminate: false,
          };
          this.messageBoxDisplay(messageDisplay2);
        } else  {
          const messageDisplay1: MessageDisplay = {
            titleBox: 'testApi',
            messageLabel: this.apiType + 'Mes',
            messageArgs: [this._translate.getTranslate(apiService), 'OK'],
            messageType: 'INFO',
            messageDatas: undefined,
            typeDialog: 'alertWks',
            actionCur: 'testApiOK',
            progressDeterminate: false,
          };
          this.messageBoxDisplay(messageDisplay1);
          this.affectResponse(toolTestReturn);
          this.initToolApiParams(false);
          this.apiContentInput = this.fillModel();
          this.validData();
        }
      },
      err => {
        this.closeMatDialog();
        const toolTestReturn = err.error as ToolApiResponse;
        toolTestReturn.statut = err.status.toString();
        this.affectResponse(toolTestReturn);
        this.initToolApiParams(true);
        this.apiContentInput = this.fillModel();
        this.validData();
        const messageDisplay2: MessageDisplay = {
          titleBox: 'testApi',
          messageLabel: this.apiType + 'Mes',
          messageArgs: [this._translate.getTranslate(apiService), 'KO'],
          messageType: 'ERROR',
          messageDatas: undefined,
          typeDialog: 'alertWks',
          actionCur: 'testApiKO',
          progressDeterminate: false,
        };
        this.messageBoxDisplay(messageDisplay2);

      }
    );
  }
  getServiceTestData(serviceName: string): ToolApiTest [] {
    let testData: ToolApiTest [] ;
    let isFound = false;
    for (const toolVersionItem of this.toolApiVersions) {
      if (toolVersionItem.toolVersion === this.apiContentInput.toolVersion) {
        for (const serviceItem of toolVersionItem.toolServices) {
          if (serviceItem.name === serviceName) {
            testData = serviceItem.testData;
            isFound = true;
            break;
          }
        } 
      }
      if (isFound) {
        break;
      }  
    }
    return testData;
  }
  buildArgsLists(otherServices: OtherService[]): void {
    if (otherServices === undefined) {
      return;
    }
    
    for (const serviceItem of otherServices) {
      const testData = this.getServiceTestData(serviceItem.name);
      for (const argsItem of serviceItem.args) {
        if (argsItem.typeData === 'arrayList') {
          this.parsingArgsList(testData, argsItem.key);
        }
      }
    }
  }
  buildBodyLists(bodyArg: BodyApi[], testData: ToolApiTest []): void {
    let bodyRoot: BodyApi;
    for (const bodyItem of bodyArg) {
      if (bodyItem.nodePath === 'root') {
        bodyRoot = bodyItem;
        break;
      } 
    }
    if (bodyRoot === undefined) {
      return;
    }
    for (const fieldItem of bodyRoot.fields) {
      if (fieldItem.toolDataType === 'nodeArray') {
        this.parsingBodyLists(bodyArg, fieldItem, testData);
      }
    }
  }
  parsingBodyLists(bodyArg: BodyApi[], fieldItem: ToolApiDatas, testData: ToolApiTest []): void {

    for (const bodyItem of bodyArg) {
      if (bodyItem.nodePath === fieldItem.toolDataName) {
        this.parsingBodyList(bodyItem, testData, fieldItem.appDataName);
        break;
      } 
    }
  }
  parsingArgsList(testData: ToolApiTest [], argKey: string): void { 

    if (argKey === 'partsList') { 
      const arrayValuesCur = this.parsingPartsList(testData);
      const toolKeyValue: ToolKeyValue = { 
        key: argKey,
        value: '',
        listValues: undefined,
        arrayValues: arrayValuesCur
      }; 
      const jsonTmp = JSON.parse(JSON.stringify(toolKeyValue));
      this.toolTestArgs.toolDatas.push(jsonTmp);
    }
  }
  parsingBodyList(bodyItem: BodyApi, testData: ToolApiTest [], appDataName: string): void { 

    if (appDataName === 'partsList') { 
      const arrayValuesCur = this.parsingPartsList(testData);
      const toolKeyValue: ToolKeyValue = { 
        key: appDataName,
        value: '',
        listValues: undefined,
        arrayValues: arrayValuesCur
      }; 
      const jsonTmp = JSON.parse(JSON.stringify(toolKeyValue));
      this.toolTestArgs.toolDatas.push(jsonTmp);
    }
  }
  parsingPartsList(testData: ToolApiTest []): PartsRequirement[] { 
    const parts: PartsRequirement[] = [];
    const partCur: PartsRequirement =  { 
      partSupplieId: '',
      partSupplieLabel: '',
      brandRef: '',
      actionTask: '',
      billingUnit: '',
      quantity: 0,
      stockStatus: '',
      nws_order_id: '',
      nws_order_ref: ''
    };
    for (const testDataItem of testData) { 
      if (testDataItem.dataName === 'quantity') {
        partCur.quantity = Number.parseInt(testDataItem.dataValue, 10);
      }
      if (testDataItem.dataName === 'brandRef') {
        partCur.brandRef = testDataItem.dataValue;
      }
      if (testDataItem.dataName === 'partSupplieId') {
        partCur.partSupplieId = testDataItem.dataValue;
      }
      if (testDataItem.dataName === 'partSupplieLabel') {
        partCur.partSupplieLabel = testDataItem.dataValue;
      }
      if (testDataItem.dataName === 'actionTask') {
        partCur.actionTask = testDataItem.dataValue;
      }
      if (testDataItem.dataName === 'billingUnit') {
        partCur.billingUnit = testDataItem.dataValue;
      }
      if (testDataItem.dataName === 'stockStatus') {
        partCur.stockStatus = testDataItem.dataValue;
      }
    }
    parts.push(partCur);
    return parts;
  } 
  affectResponse(toolTestReturn: ToolApiResponse): void {
    for (const testCur of this.toolTests) {
      if (testCur.apiService === toolTestReturn.apiService) {
        testCur.statut = toolTestReturn.statut;
        testCur.testDate = toolTestReturn.testDate;
        testCur.toolDatas = toolTestReturn.toolDatas;
        break;
      }
    }
  }
// ========================================================== errors alerts 
  messageBoxDisplay(_messageDisplay: MessageDisplay): void {

    const dialogConfig = new MatDialogConfig();
    const messageBox = this._translate.instant(_messageDisplay.messageLabel, _messageDisplay.messageArgs );
    
    // const messageDisplayCACHE = JSON.parse(JSON.stringify(_messageDisplay));
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      id: 1,
      title: this._translate.getTranslate( _messageDisplay.titleBox),
      typeDialog: _messageDisplay.typeDialog,
      panelClass: 'stdTheme',
      contentMessage: messageBox,
      data1: _messageDisplay.messageDatas,
      data2: '',
      actionCur: _messageDisplay.actionCur,
      progressDeterminate: _messageDisplay.progressDeterminate,
      errorsList: undefined,
      messageType: _messageDisplay.messageType
    };
    this.dialogRef = this._dialog.open(ModalCommonComponent, dialogConfig);
    
    this.dialogRef.afterClosed()
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
        // data: "okAction"
        if (data === 'okAction') {
          if (_messageDisplay.actionCur === 'testApiOK') {
            this.endProcessFile();
            this.disableTest = false;
          }
          if (_messageDisplay.actionCur === 'testApiKO') {
            this.endProcessFile();
            this.disableTest = false;
          }
        }
      });
  }
  closeMatDialog(): void {
    this._dialog.closeAll();

    // console.log(this._dialog.openDialogs.length);
    if (this._dialog.openDialogs.length > 0) {

      for (const instCur of this._dialog.openDialogs) {
        this._dialog.openDialogs.splice(0, 1);
      }
    }
    this.dialogRef = null;
  }
  private endProcessFile() {
    this.closeMatDialog();

  }
}
