
import { ToolArgs, ToolKeyValue } from '../../../models/common.model';

export interface WksBusinessModel {
    id?: string;
    stdEntity?: string;
    bsnCountry?: string;
    bsnNature?: string;
    bsnParent?: string;
    bsnType?: string;
    bsnTax?: string;
    bsnName?: any;
    bsnCompany?: any;
    bsnPerson?: any;
    bsnAddress?: any;
    bsnZipcode?: string;
    bsnCity?: string;
    bsnState?: string;
    bsnAccounting?: any;
    bsnContact?: any;
    stdCreation?: string;
    stdLastupdate?: string;
    stdDisable?: Date;
    dataContacts?: WksContactsModel[];
    stringDates?: string;
    nbWorks?: number;
    nbEquipments?: number;
    balance?: any;
    customerOutstanding?: any;
    accountingBalance?: AccountingBalance;
    bsnOtherdatas?: string;
}
export interface BsnAccountingModel  {
    bsnCashpay: boolean;
    bsnAccount: string;
    scaleName: string;
    bsnCoef: number;
    bsnDiscount: number;
    bsnDelayDays: number;
    bsnDefferedPaiement: string;
    customerOutstanding: number;
    taxableVat: boolean;
    idVat: string;
    customsRegistration: boolean;
    customsId: string;
    freeZone: boolean;
    freeZoneId: string;
}
export interface WksContactsModel {
    id?: string;
    stdEntity: string;
    cntAppli: string;
    cntAppliLnk?: string;
    cntCivility: string;
    cntFirstname: string;
    cntLastname: string;
    cntAddress?: string;
    cntMails?: string;
    cntPhones?: string;
    cntChannels?: string;
    cntFunction?: string;
    cntHabil?: string;
    cntSpeciality?: string;
    stdCreation?: string;
    stdLastupdate?: Date;
    stdDisable?: Date;
    cntStatut?: string;
}
export interface AccountModel {
    bsnCashpay: boolean;
    bsnAccount: string;
    scaleName: string;
    bsnCoef: number;
    bsnDelayDays: number;
    bsnDefferedPaiement: string;
    customerOutstanding: number;
}
export interface WksBusinessLabel {
    id: string;
    bsnName?: any;
    disabled?: boolean;
    selected?: boolean;
}
export interface PaiementsTerm {
    delaysDays: number[];
    conditions: string[];
    conditionsWorks: string[];
    monthlyInstallmentsMax: number;
    paiements: string[];
}

export interface AccountingModel {
    id?: string;
    stdEntity: string;
    workId: string;
    customerId: string;
    accOperation: string;
    accLabel: string;
    accStatut: string;
    accNwsref: string;
    accToTransfer: boolean;
    accTransferDate: Date;
    accTransferEntry: string;
    accTransferAccount: string;
    accJson: string;
    accAmount: number;
    stdCreation?: Date;
    stdLastupdate?: Date;
    stdDisable?: Date;
}

export interface AccountingWork {
    workId: string;
    workObject: string;
    workRef: string;
    workBalance: number;
    wksAccountings: AccountingModel[];
}

export interface AccountingBalance {
    balance: number;
    customerOutstanding: number;
    wksAccountingWorks: AccountingWork[];
}

export const wksbusiness_sch = [
    { colName: 'id', colType: 'string' },
    { colName: 'stdEntity', colType: 'string' },
    { colName: 'bsnCountry', colType: 'string' },
    { colName: 'bsnNature', colType: 'string' },
    { colName: 'bsnParent', colType: 'string' },
    { colName: 'bsnType', colType: 'boolean' },
    { colName: 'bsnTax', colType: 'boolean' },
    { colName: 'bsnCompany', colType: 'string' },
    { colName: 'bsnPerson', colType: 'string' },
    { colName: 'bsnAccounting', colType: 'string' },
    { colName: 'bsnName', colType: 'string' },
    { colName: 'bsnAddress', colType: 'string' },
    { colName: 'bsnZipcode', colType: 'string' },
    { colName: 'bsnCity', colType: 'string' },
    { colName: 'bsnState', colType: 'string' },
    { colName: 'bsnContact', colType: 'string' },
    { colName: 'stdCreation', colType: 'Date' },
    { colName: 'stdLastupdate', colType: 'Date' },
    { colName: 'stdDisable', colType: 'Date' },
    { colName: 'dataContacts', colType: 'string' },
    { colName: 'dataAddress', colType: 'string' },
    { colName: 'stringDates', colType: 'string' },
    { colName: 'nbWorks', colType: 'number' },
    { colName: 'nbEquipments', colType: 'number' },
    { colName: 'balance', colType: 'string' },
    { colName: 'customerOutstanding', colType: 'string' },
    { colName: 'accountingBalance', colType: 'string' },
    { colName: 'bsnOtherdatas', colType: 'string' },
    
];
