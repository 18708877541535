<div #refElement class="mat-elevation-z8 container-fluid stdTheme"
        [ngStyle]="{'margin-top': '10px', width: '100%',
                        padding: '10px', 'overflow-y': 'hidden'}" >
    <div class="flex-row row" style="padding-top: 10px;margin: 0px;overflow-y: hidden;">
        <div class="col-12">
            <div class="container-fluid d-flex justify-content-between" >
                <div *ngIf="statutCall === 'popover'" class="flex-row row" style= "margin: 0px; width: 100%">
                    <div class="col-12">
                        <h5 class="titleTheme" >{{ workTitle }}</h5>
                    </div>
                </div>
                <div *ngIf="statutCall !== 'popover'" class="flex-row row" style= "margin: 0px; width: 100%">
                    <div class="col-1">
                        <button  mat-raised-button  #buttonSubmit type="reset" (click)="doCancel()">
                            <mat-icon>arrow_back</mat-icon>
                            {{ 'goback' | translate }}
                        </button>
                    </div>
                    <div class="col-1">
                    </div>
                    <div class="col-2">
                        <button *ngIf="okOwner" mat-raised-button  #buttonSubmit type="submit" (click)="registerOwner()" >
                            <mat-icon>save</mat-icon>
                            {{ 'save customer' | translate }}
                        </button>
                        <button *ngIf="changeOwner" mat-raised-button (click)="changeOwnerCur()" >
                            {{ 'change customer' | translate }}
                        </button>
                        <button *ngIf="changeEquipment" mat-raised-button (click)="changeEquipmentCur()" >
                            {{ 'change equipement' | translate }}
                        </button>
                    </div>
                    <div class="col-6">
                        <h5 class="titleTheme" >{{ workTitle }}</h5>
                    </div>
                    <div class="col-2 justify-content-end d-flex">
                        <button *ngIf="isOkToSave && actionType !== 'display'" mat-raised-button  #buttonSubmit type="submit" (click)="registerWork()" >
                            <mat-icon>save</mat-icon>
                            {{ 'save intervention' | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <!--dynamicHeight class="mat-tab-fill-height" -->
    <mat-tab-group mat-align-tabs="start" (selectedTabChange)="selectedTabChange('owner', $event)" >
        <mat-tab  style="height: 100%!important;max-height: 100%!important;">
            <ng-template mat-tab-label>
                <span (click)="currentTabSelected('ownerTab')">{{ 'owner' | translate }}</span>
            </ng-template>
            <div *ngIf="ownerChoice" class="flex-row row stdTheme" style="margin: 0px;">
                <div class="col-3" >
                    <mat-radio-group #workOwner="matRadioGroup" aria-label="Select an option" >
                        <mat-radio-button style="margin-left: 10px;margin-right: 10px; margin-top: 5px;" (change)="radioCustomerChange($event)"
                                        value="newCustomer">{{ 'newCustomer' | translate }}</mat-radio-button>
                        <mat-radio-button style="margin-left: 10px;margin-right: 10px; margin-top: 5px;" (change)="radioCustomerChange($event)"
                                        value="existsCustomer">{{ 'existsCustomer' | translate }}</mat-radio-button>
                    </mat-radio-group>
                </div>
                <div class="col-3" >
                    <div *ngIf="customersLoading">
                        <span class="blink_text">
                            {{ 'loadingCustomer' | translate }}
                        </span>
                    </div>
                    <div *ngIf="customersLoaded">
                        <mat-select id="workOwner" #workOwner  placeholder="{{ 'Choose owner' | translate }}"
                            panelClass="mdiselectcontent" ngDefaultControl
                            (selectionChange)="onChangeworkOwner(workOwner.value)" >
                            <mat-select-header>
                                <div class="filterInput" style="display: inline-block;" >            
                                    <button mat-icon-button matPrefix aria-label="search" >
                                        <mat-icon>search</mat-icon>
                                    </button>  
                                    <input class="searchTheme" #filterOwners placeholder="{{ 'filterInput' | translate }}" 
                                                style="margin: 5px" (keyup)="onKeyOwner($event.target.value)" >
                                    <button mat-icon-button matSuffix aria-label="clear" 
                                                *ngIf="filterOwners.value" (click)="filterOwners.value=''; onKeyOwner('');">
                                        <mat-icon>close</mat-icon>
                                    </button>
                                </div>
                            </mat-select-header>
                            <mat-option class="selectOptionsTheme" *ngFor="let workOwnerCur of customerFiltredList" 
                                [value]="workOwnerCur"  >{{workOwnerCur.bsnName }}
                            </mat-option>
                        </mat-select>
                    </div>
                </div>
            </div>
            <div *ngIf="!ownerChoice && (ownerIsLoaded || ownerNew)" class="flex-row row stdTheme" style="margin-left: 0px;margin-right: 0px;">

                <mdi-bns-card-sheets  style="width: 1000px!important;"
                    [ihmOptions]="ownerTabOptions"
                    [statutCall]="bsnStatut" 
                    [wksBnsToUpdate]="ownerCur" 
                    [optionItem]="'wks-customers'" 
                    [originCall]="worksCard"
                    (dataOut)="dataOutBsnCardSheet($event)"
                    (onload)="ngOnInit()">
                </mdi-bns-card-sheets>
            </div>
        </mat-tab>
        <mat-tab *ngIf="openEquipment && equipmentTabOptions.display"  >
            <ng-template mat-tab-label>
                <span (click)="currentTabSelected('equipmentTab')">{{ 'equipment' | translate }}</span>
            </ng-template>
            <ng-template matTabContent>
                <mdi-works-equipment
                    [ihmOptions]="equipmentTabOptions"
                    [workEquipmentsArgs]="workEquipments"
                    [ownerArg]="ownerCur" 
                    [optionItem]="'works'" 
                    [actionType]="actionType"
                    [workEquipmentId]="workEquipmentId"
                    [changeEquipment]="changeEquipment"
                    (dataOut)="dataOutWorkEquipment($event)"
                >
                </mdi-works-equipment>
            </ng-template>
        </mat-tab>
        <mat-tab *ngIf="openIntervention && interventionTabOptions.display" >
            <ng-template mat-tab-label>
                <span (click)="currentTabSelected('interventionTab')">{{ 'intervention' | translate }}</span>
            </ng-template>
            <ng-template matTabContent >
                <mdi-works-intervention
                    [ihmOptions]="interventionTabOptions"
                    [planningOptions]="planningOptions"
                    [dataLocationEquip]="dataLocationEquip"
                    [statutCall]="statutCall" 
                    [wksWorks]="wksWorks"
                    [accountCur]="accountCur"
                    [actionType]="actionType"
                    (dataOut)="dataOutWorkIntervention($event)"
                >
                </mdi-works-intervention>
            </ng-template>
        </mat-tab>
        <mat-tab *ngIf="openIntervention  && tasksTabOptions.display" >
            <ng-template mat-tab-label>
                <span (click)="currentTabSelected('tasksTab')">{{ 'tasks' | translate }}</span>
            </ng-template>
            <ng-template matTabContent>
                <!--<ng-container *ngTemplateOutlet="tasksCardTemplate"></ng-container>-->
                <mdi-works-tasks
                    [ihmOptions]="tasksTabOptions"
                    [statutCall]="statutCall" 
                    [workTasksContent]="workTasksContent"
                    [equipItem]="equipItem"
                    [actionType]="actionType"
                    [workStatut]="workStatut"
                    [wksWorks]="wksWorks"                   
                    (dataOut)="dataOutTasks($event)"
                    (dataValidTasks)="dataValidTasks($event)"
                >
                </mdi-works-tasks>
            </ng-template>
        </mat-tab>
        <mat-tab *ngIf="openIntervention && quoteTabOptions.display" >
            <ng-template mat-tab-label>
                <span (click)="currentTabSelected('quoteTab')">{{ 'quote' | translate }}</span>
            </ng-template>
            <ng-template matTabContent>
                <ng-container *ngTemplateOutlet="quotesTemplate"></ng-container>
            </ng-template>
        </mat-tab>
        <mat-tab *ngIf="openIntervention && dispatchTabOptions.display" >
            <ng-template mat-tab-label>
                <span (click)="currentTabSelected('dispatchTab')">{{ 'dispatchtab' | translate }}</span>
            </ng-template>
            <ng-template matTabContent >
                <ng-container *ngTemplateOutlet="dispatchTemplate"></ng-container>
            </ng-template>
        </mat-tab>
        <!--<mat-tab *ngIf="wksWorks !== undefined && wksWorks.workId !== undefined && openIntervention && docsTabOptions.display" >-->
        <mat-tab *ngIf="openIntervention && docsTabOptions.display" >
            <ng-template mat-tab-label>
                <span (click)="currentTabSelected('docsTab')">{{ 'docs' | translate }}</span>
            </ng-template>
            <ng-template matTabContent>
                <ng-container *ngTemplateOutlet="documentsTemplate"></ng-container>
            </ng-template>
        </mat-tab>
        <!--<mat-tab *ngIf="wksWorks !== undefined && wksWorks.workId !== undefined && openIntervention && quoteTabOptions.display" > -->

        <mat-tab *ngIf="openIntervention && photosTabOptions.display" >
            <ng-template mat-tab-label>
                <span (click)="currentTabSelected('photosTab')">{{ 'photos' | translate }}</span>
            </ng-template>
            <ng-template matTabContent>
                <ng-container *ngTemplateOutlet="photosTemplate"></ng-container>
            </ng-template>
        </mat-tab>
        <mat-tab *ngIf="openIntervention && outputStockTabOptions.display" >
            <ng-template mat-tab-label>
                <span (click)="currentTabSelected('ouputStockTab')">{{ 'ouputStockTab' | translate }}</span>
            </ng-template>

            <ng-template matTabContent>
                <mdi-works-output-stocks
                    [ihmOptions]="outputStockTabOptions"
                    [statutCall]="statutCall" 
                    [actionType]="actionType"
                    [iRow]="dataIn.iRow"
                    [wksWorks]="wksWorks"
                    (dataOut)="dataOutoutputStock($event)"
                >
                </mdi-works-output-stocks>
            </ng-template>
        </mat-tab>
        <mat-tab *ngIf="openIntervention && checkListTabOptions.display" >
            <ng-template mat-tab-label>
                <span (click)="currentTabSelected('checkListTab')">{{ 'check list' | translate }}</span>
            </ng-template>

            <ng-template matTabContent>
                <mdi-works-checklist
                    [ihmOptions]="checkListTabOptions"
                    [statutCall]="statutCall" 
                    [actionType]="actionType"
                    [iRow]="dataIn.iRow"
                    [workId]="wksWorks.id"
                    [wksWorks]="wksWorks"
                    (dataOut)="dataOutTasks($event)"
                >
                </mdi-works-checklist>
            </ng-template>
        </mat-tab>
        <mat-tab *ngIf="openIntervention && invoicingTabOptions.display" >
            <ng-template mat-tab-label>
                <span (click)="currentTabSelected('invoicingTab')">{{ 'invoicing' | translate }}</span>
            </ng-template>
            <ng-template matTabContent>
                <ng-container *ngTemplateOutlet="invoicingTabTemplate"></ng-container>
            </ng-template>
        </mat-tab>
        <mat-tab *ngIf="openIntervention && invoicingTabOptions.display" >
            <ng-template mat-tab-label>
                <span (click)="currentTabSelected('balanceTab')">{{ 'balance' | translate }}</span>
            </ng-template>
            <ng-template matTabContent>
                <mdi-works-accounting 
                    [statutCall]="'work'" 
                    [accountingBalance]="wksWorks.accountingBalance"
                    [workCur]="wksWorks"
                    [ihmOptions]="balanceTabOptions">
                </mdi-works-accounting>
            </ng-template>
        </mat-tab>
        <mat-tab *ngIf="openIntervention && statusJournalTabOptions.display" >
            <ng-template mat-tab-label>
                <span (click)="currentTabSelected('statusJournalTab')">{{ 'statusJournal' | translate }}</span>
            </ng-template>
            <ng-template matTabContent>
                <ng-container *ngTemplateOutlet="statusJournalTemplate"></ng-container>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</div>
<ng-template #dispatchTemplate >
    <div class="container-fluid stdTheme " >
        <div class="flex-row row stdTheme" style="margin: 0px;">
          <div class="col-10 table-responsive" style="height: 90%;">
            <table class="table stdTheme" id="dispatchTemplate"  >
              <thead class="theadWork">
                <tr class="trWork">
                    <th style="width: 30%; padding: 0px; text-align: center;">{{'identity' | translate}}</th>
                    <th style="width: 20%; padding: 0px; text-align: center;">{{'selectDept' | translate}}</th>
                    <th style="width: 20%; padding: 0px; text-align: center;">{{'fonctionDept' | translate}}</th>
                    <th style="width: 5%; padding: 0px; text-align: center;"></th>
                    <th style="width: 5%; padding: 0px; text-align: center;"></th>
                </tr>
              </thead>
              <tbody class="tbodyWork" >
                <div *ngFor="let contactCur of dataContacts; let i = index">
                    <!--<tr class="trWork " [class.data-selected]="i === selectedCnt" (click)="setClickedRow(i)">-->
                    <tr class="trWork " [class.data-selected]="i === selectedCnt">          
                        <td style="width: 30%; padding: 0px; text-align: left; vertical-align: middle;">{{contactCur.identity.cntFirstname}} {{contactCur.identity.cntLastname}}
                            ({{contactCur.identity.cntCivility  | translate }})</td>
                        <td style="width: 20%; padding: 0px; text-align: left; vertical-align: middle;">{{contactCur.selectDept }}</td>
                        <td style="width: 20%; padding: 0px; text-align: left; vertical-align: middle;">{{contactCur.fonctionDept }}</td>
                        <td style="width: 5%; padding: 0px; text-align: left; vertical-align: middle;">
                            <button mat-icon-button class="workbutton" (click)="displayEmail(i)" >
                                <mat-icon  >email</mat-icon>
                            </button>
                        </td>
                        <td style="width: 5%; padding: 0px; text-align: left;">
                            <button mat-icon-button class="workbutton" (click)="displayPhone(i)" >
                                <mat-icon >phone</mat-icon>
                            </button>
                        </td>
                    </tr>
                    <tr class="trWork " *ngIf="i === selectedCnt && doDisplayEmail">
                        <td >
                            <div>
                                <h6>{{'emailList' | translate}} </h6>
                            </div>
                            <div class="row stdTheme" style="margin-left: 0px; margin-right: 0px;" 
                                                            *ngFor= "let emailCur of emailsList; let rowEmail=index; let isLast=last" >
                                <table class="addressTable table table-bordered table-striped">
                                    <tbody class="stdTheme" >
                                        <tr style="height: 100%">
                                            <td class="addressTable" style="width: 45%; padding: 2px; padding-left: 10px; padding-right: 10px;">
                                                {{emailCur.emailAddress}}
                                            </td>
                                            <td class="addressTable" style="width: 25%; padding: 2px; padding-left: 10px; padding-right: 10px;">
                                                {{emailCur.emailType }}
                                            </td> 
                                               
                                        </tr>
                                    </tbody>
                                </table>                         
                            </div>
                        </td>
                    </tr>
                    <tr class="trWork " *ngIf="i === selectedCnt && doDisplayPhone">
                        <td >
                            <div>
                                <h6>{{'phonesList' | translate}} </h6>
                            </div>
                            <div class="row stdTheme"  style="margin-left: 0px; margin-right: 0px;" *ngFor= "let phoneCur of phonesList; let rowTel=index; let isLast=last" >
                                <div class="col" style="width: 100%;padding-right: 0px; padding-left: 0px">
                                    <table class="addressTable table table-bordered table-striped" border="0" cellpadding="0" cellspacing="0">
                                        <tbody class="stdTheme" >
                                            <tr  >
                                                <td class="addressTable" style="width: 30%; padding: 2px; padding-left: 5px; padding-right: 5px;">
                                                    {{ 'Country' | translate }}  {{phoneCur.phoneCountry}}
                                                </td>

                                                <td class="addressTable" style="width: 40%; padding: 2px; padding-left: 5px; padding-right: 5px;">
                                                    {{phoneCur.phonePrefix}}  {{phoneCur.phoneNumber}}

                                                </td>
                                                <td class="addressTable" style="width: 25%; padding: 2px; padding-left: 5px; padding-right: 5px;">
                                                    {{phoneCur.phoneType  | translate }}
                                                </td>     
                                                  
                                            </tr> 
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </td>
                    </tr>
                </div>
              </tbody>
            </table>
          </div>
        </div>
    </div>
</ng-template>

<ng-template #documentsTemplate >
    <!--   https://stackblitz.com/angular/ynbnyenqnvx?file=src%2Fapp%2Fsidenav-open-close-example.ts -->
    <div class="container-fluid stdTheme " >
        <div class="flex-row row stdTheme" style="margin: 0px;">
            <div class="col-1" style="margin-top: 20px;">
                <mat-toolbar class="stdTheme">
                    <!-- *ngIf="!uploadDocs" -->
                    <mat-toolbar-row class="toolbar-work" >
                        <button mat-icon-button class="buttonTheme" matTooltip="{{ 'uploadFiles' | translate }}" 
                                (click)="toggleUploadFiles()" [disabled]='uploadDocs'
                            style="margin-left: 10px;margin-right: 10px;">
                            <mat-icon class="mat-icon-work">cloud_upload</mat-icon>
                        </button>
                    </mat-toolbar-row>
                    <!-- *ngIf="!displayImages" -->
                    <mat-toolbar-row class="toolbar-work" >
                        <button mat-icon-button class="buttonTheme" matTooltip="{{ 'displayImages' | translate }}" 
                                (click)="toggleDisplayImages()" [disabled]='displayImages'
                            style="margin-left: 10px;margin-right: 10px;">
                            <mat-icon class="mat-icon-work">image</mat-icon>
                        </button>
                    </mat-toolbar-row>
                    <!-- *ngIf="!displayPdf" -->
                    <mat-toolbar-row class="toolbar-work" >
                        <button mat-icon-button class="buttonTheme" matTooltip="{{ 'displayPdf' | translate }}" 
                                (click)="toggleDisplayPdf()" [disabled]='displayPdf'
                            style="margin-left: 10px;margin-right: 10px;">
                            <mat-icon class="mat-icon-work" >picture_as_pdf</mat-icon>
                        </button>
                    </mat-toolbar-row>
                    <!-- *ngIf="!displayList" -->
                    <mat-toolbar-row class="toolbar-work"  >
                        <button mat-icon-button class="buttonTheme" matTooltip="{{ 'displayList' | translate }}" 
                                (click)="toggleDisplayList()" [disabled]='displayList'
                            style="margin-left: 10px;margin-right: 10px;">
                            <mat-icon class="mat-icon-work">list</mat-icon>
                        </button>
                    </mat-toolbar-row>
                </mat-toolbar>
            </div>
            <div class="col-11">
                <div *ngIf="uploadDocs" >
                    <mdi-works-docs 
                        [statutCall]=""
                        [wksWorks]= "wksWorks"
                        [actionType]="'docs'"
                        >
                    </mdi-works-docs>
                </div>
                <div *ngIf="displayPdf" >
                    <mdi-works-pdf 
                        [statutCall]=""
                        [dataList]= "filesToDisplay"
                        >
                    </mdi-works-pdf>
                </div>
                <div *ngIf="displayImages" >
                    <mdi-works-img 
                        [statutCall]=""
                        [dataList]= "filesToDisplay"
                        >
                    </mdi-works-img>
                </div>
                <div *ngIf="displayList" [ngStyle]="{'margin-top': '10px', 'width': '100%','padding': '0px'}" >
                    <table class="table stdTheme" id="wksWorksDocs"  >
                        <thead class="theadWork">
                            <tr class="trWork">
                                <th style="width: 50%; padding: 0px; text-align: center;" >{{'workdocLegend' | translate}}</th>
                                <th style="width: 25%; padding: 0px; text-align: center;" >{{'workdocFilename' | translate}}</th>
                                <th style="width: 15%; padding: 0px; text-align: center;" >{{'uploadDate' | translate}}</th>
                                <!--<th style="width: 5%; padding: 0px; text-align: center;">{{'workdocType' | translate}}</th>-->
                                <th style="width: 5%; padding: 0px; text-align: center;"></th>
                            </tr>
                        </thead>
                        <tbody *ngIf="wksWorks.workDocs !== undefined" class="tbodyWork" >
                            <div *ngFor="let docCur of wksWorks.workDocs; let i = index">
                                <div *ngIf="docCur.docCur.workdocType !== 'photo' ">
                                    <tr class="trWork " [class.data-selected]="i === selectedDoc" (click)="setClickedDoc(i)">
                                        <td style="width: 50%; padding: 0px; text-align: left;">{{docCur.workdocLegend }}</td>
                                        <td style="width: 25%; padding: 0px; text-align: left;">{{docCur.workdocFilename }}</td>
                                        <td style="width: 15%; padding: 0px; text-align: center;">{{getDateUpload(docCur.stdCreation)}}</td>
                                        <td style="width: 5%; padding: 0px; text-align: center;">
                                            <button mat-icon-button class="local-button" (click)="removeDoc(i)">
                                                <i class="far fa-trash-alt"></i>
                                            </button>
                                        </td>
                                    </tr>
                            </div>
                            </div>
                        </tbody>
                      </table>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #photosTemplate >
    <!--   https://stackblitz.com/angular/ynbnyenqnvx?file=src%2Fapp%2Fsidenav-open-close-example.ts -->
    <div class="container-fluid stdTheme " >
        <div *ngIf="equipItem.equipList !== undefined && equipItem.equipList.length > 0" class="flex-row row stdTheme" style="margin: 0px;">
            <form [formGroup]="photoDataForm" *ngIf="formPhotoIsBuild" style="margin: 0px; height: 100%; width: 100%;" >
                <div class="flex-row row stdTheme w-100" style="margin: 0px;">
                    <div class="col-4" > 
                        <mat-select id="photoEquipment" #photoEquipment  placeholder="{{ 'Choose equipment' | translate }}"
                            panelClass="mdiselectcontent" 
                            (selectionChange)="onChangePhotoEquipment(photoEquipment.value)" 
                            formControlName="photoEquipment">
                            <mat-option class="selectOptionsTheme" *ngFor="let equipItem of equipItem.equipList" 
                                [value]="equipItem.value"  >{{equipItem.label }}
                            </mat-option>
                        </mat-select>
                    </div>
                    <div class="col-4" > 
                        <mat-select id="photoTask" #photoTask  placeholder="{{ 'Choose task' | translate }}"
                            panelClass="mdiselectcontent" 
                            (selectionChange)="onChangePhotoTask(photoTask.value)"
                            formControlName="photoTask" >
                            <mat-option class="selectOptionsTheme" *ngFor="let taskItem of tasksList" 
                                [value]="taskItem.value"  >{{taskItem.label }}
                            </mat-option>
                        </mat-select>
                    </div>
                    <div class="col-2" > 
                        <mat-select id="photoSequenceAtt" #photoSequenceAtt  placeholder="{{ 'Choose moment' | translate }}"
                            panelClass="mdiselectcontent" 
                            (selectionChange)="onChangePhotoSequenceAtt(photoSequenceAtt.value)" 
                            formControlName="photoSequenceAtt" >
                            <mat-option class="selectOptionsTheme" *ngFor="let attItem of sequenceAttList" 
                                [value]="attItem.value"  >{{attItem.label }}
                            </mat-option>
                        </mat-select>
                    </div>
                    <div class="col-1" > 
                        <button mat-icon-button class="buttonTheme" matTooltip="{{ 'linkPhotos' | translate }}" 
                            (click)="getLinkPhotos()" [disabled]='uploadPhotos'
                            style="margin-left: 10px;margin-right: 10px;">
                            <mat-icon style="font-size: 40px">link</mat-icon>
                        </button>
                    </div>

                </div>
            </form>
        </div>

        <div class="flex-row row stdTheme" style="margin: 0px;">
            <div class="col-1" style="margin-top: 20px;">
                <mat-toolbar class="stdTheme">
                    <!-- *ngIf="!uploadDocs" -->
                    <mat-toolbar-row class="toolbar-work" >
                        <button mat-icon-button class="buttonTheme" matTooltip="{{ 'uploadPhotos' | translate }}" 
                                (click)="toggleUploadPhotos()" [disabled]='uploadPhotos'
                            style="margin-left: 10px;margin-right: 10px;">
                            <mat-icon class="mat-icon-work">cloud_upload</mat-icon>
                        </button>
                    </mat-toolbar-row>
                    <!-- *ngIf="!displayPhotos" -->
                    <mat-toolbar-row class="toolbar-work" >
                        <button mat-icon-button class="buttonTheme" matTooltip="{{ 'displayPhotos' | translate }}" 
                                (click)="toggleDisplayPhotos()" [disabled]='displayPhotos'
                            style="margin-left: 10px;margin-right: 10px;">
                            <mat-icon class="mat-icon-work">image</mat-icon>
                        </button>
                    </mat-toolbar-row>
                </mat-toolbar>
            </div>
            <div class="col-11">
                <div *ngIf="uploadPhotos" >
                    <mdi-works-docs 
                        [statutCall]=""
                        [fileExt]="'PNG'"
                        [wksWorks]= "wksWorks"
                        [actionType]="'photos'"
                        [photoDataBase64]="photoDataBase64"
                        >
                    </mdi-works-docs>
                </div>
                <div *ngIf="displayPhotos" >
                    <mdi-works-photos
                        [statutCall]=""
                        [wksWorks]= "wksWorks"
                        [photoData]="photoData"
                        [ihmOptions]="photosTabOptions"
                        [workTasksContent]="workTasksContent"
                        [workEquipments]="workEquipments"
                        >
                    </mdi-works-photos>
                </div>
                
            </div>
        </div>
    </div>
</ng-template>
<ng-template #quotesTemplate >
    <!--   https://stackblitz.com/angular/ynbnyenqnvx?file=src%2Fapp%2Fsidenav-open-close-example.ts -->
    <div class="container-fluid stdTheme " fxFill style="overflow:hidden !important" >
        <mat-tab-group mat-align-tabs="start" dynamicHeight class="mat-tab-fill-height">
            <mat-tab label="{{ 'elaboration' | translate }}">
                <mdi-works-recap-amount
                    [ihmOptionsCkeck]="ihmOptionsCkeck"
                    [statutCall]="'quotation'" 
                    [workCur]="wksWorks"
                    (dataOut)="dataOutQuotation($event)"
                >
                </mdi-works-recap-amount>
            </mat-tab>
            <mat-tab label="{{ 'quotationJournal' | translate }}">
                <mat-tab-group *ngIf="displayQuotesList" mat-align-tabs="start" dynamicHeight class="mat-tab-fill-height"
                            style="height: calc(100% - 64px); min-height: calc(100% - 64px);">
                    <div *ngFor="let listCur of listQuotes; let i = index">
                        <mat-tab label="{{ listCur.name | translate }}">
                            <mat-tab-content fxFill fxLayout="column" >
                                <div class="flex-row row rowTitle w-100" >
                                    <div class="col-2 d-flex justify-content-center">{{'workdocLabel' | translate}}</div>
                                    <div class="col-4 d-flex justify-content-center">{{'workdocFilename' | translate}}</div>
                                    <div class="col-2 d-flex justify-content-center">{{'uploadDate' | translate}}</div>
                                    <div class="col-1 d-flex justify-content-center"></div>
                                    <div *ngIf="listCur.name === 'quoteNews'"  class="col-1 d-flex justify-content-center"></div>
                                    <div *ngIf="listCur.name === 'quoteNews'" class="col-1 d-flex justify-content-center"></div>
                                    <div *ngIf="listCur.name === 'quoteSubmitted'" class="col-1 d-flex justify-content-center"></div>
                                    <div *ngIf="listCur.name === 'quoteSubmitted' || listCur.name === 'quoteApproved'" class="col-1 d-flex justify-content-center"></div>
                                </div>
                                <div style="overflow: auto; height: 600px; max-height: 600px;">
                                    <div class="stdTheme" *ngFor="let docCur of listCur.list; let i = index"  >
                                        <div class="flex-row row rowlist w-100" [class.data-selected]="i === selectedQuote" (click)="setClickedQuote(i)" >
                                            <div class="col-2 d-flex justify-content-center">{{docCur.workdocLegend }}</div>
                                            <div class="col-4 d-flex justify-content-center">{{docCur.workdocFilename }}</div>
                                            <div class="col-2 d-flex justify-content-center">{{getDateUpload(docCur.stdCreation)}}</div>
                                            <div class="col-1 d-flex justify-content-center">
                                                <button mat-icon-button class="local-button" (click)="viewQuote(docCur,listCur.name, 'read')">
                                                    <mat-icon matTooltip="{{'view' | translate}}">pageview</mat-icon>
                                                </button>
                                            </div>
                                            <div *ngIf="listCur.name === 'quoteNews' && !quoteTabOptions.readOnly"  class="col-1 d-flex justify-content-center">
                                                <button mat-icon-button class="local-button" (click)="removeQuote(null, listCur.name, i, docCur)">
                                                    <mat-icon matTooltip="{{'delete' | translate}}">delete_forever</mat-icon>
                                                </button>
                                            </div>
                                            <div *ngIf="listCur.name === 'quoteNews' && !quoteTabOptions.readOnly" class="col-1 d-flex justify-content-center">
                                                <button mat-icon-button class="local-button" (click)="viewQuote(docCur, listCur.name, 'validated')">
                                                    <mat-icon matTooltip="{{'submit' | translate}}">fact_check</mat-icon>
                                                </button>
                                            </div>
                                            <div *ngIf="listCur.name === 'quoteSubmitted' && !quoteTabOptions.readOnly" class="col-1 d-flex justify-content-center">
                                                <button mat-icon-button class="local-button" (click)="viewQuote(docCur, listCur.name, 'approved')">
                                                    <mat-icon matTooltip="{{'approve' | translate}}">approval</mat-icon>
                                                </button>
                                            </div>
                                            <div *ngIf="listCur.name === 'quoteSubmitted' && !quoteTabOptions.readOnly" class="col-1 d-flex justify-content-center">
                                                <button mat-icon-button class="local-button" (click)="viewQuote(docCur, listCur.name, 'refused')">
                                                    <mat-icon  matTooltip="{{'reject' | translate}}"><i class="material-icons">highlight_off</i></mat-icon>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                               
                            </mat-tab-content>
                        </mat-tab>
                    </div>

                </mat-tab-group>
            </mat-tab>
        </mat-tab-group>
    </div>
</ng-template>

<ng-template #invoicingTabTemplate >
    <div class="container-fluid stdTheme " fxFill style="overflow:hidden !important" >
        <mat-tab-group mat-align-tabs="start" dynamicHeight class="mat-tab-fill-height" (selectedTabChange)="selectedTabChange('invoicing',$event)">
            <mat-tab label="{{ 'invoice' | translate }}">
                <mdi-works-recap-amount *ngIf="invoiceTabDisplay === 0"
                    [ihmOptionsCkeck]="invoicingTabOptions"
                    [statutCall]="'invoicing'" 
                    [workCur]="wksWorks"
                    (dataOut)="dataOutInvoicing($event)"
                >
                </mdi-works-recap-amount>
            </mat-tab>
            <mat-tab label="{{ 'invoicingJournal' | translate }}">
                <div *ngIf="displayInvoicesList" [ngStyle]="{'margin-top': '10px', 'width': '100%','padding': '0px'}" >
                    <mat-tab-group  mat-align-tabs="center" dynamicHeight class="mat-tab-fill-height" 
                    style="height: calc(100% - 64px); min-height: calc(100% - 64px);">
                        <div *ngFor="let listCur of listInvoices; let i = index">
                            <mat-tab label="{{ listCur.name | translate }}">
                                <mat-tab-content>
                                    <div class="flex-row row rowTitle w-100" >
                                        <div class="col-2 d-flex justify-content-center">{{'workdocLabel' | translate}}</div>
                                        <div class="col-4 d-flex justify-content-center">{{'workdocFilename' | translate}}</div>
                                        <div class="col-2 d-flex justify-content-center">{{'uploadDate' | translate}}</div>
                                        <div class="col-1 d-flex justify-content-center"></div>
                                        
                                    </div>
                                    <div *ngIf="listCur.list !== undefined" style="overflow: auto; height: 600px; max-height: 600px;">
                                        <div class="stdTheme" *ngFor="let docCur of listCur.list; let i = index"  >
                                            <div class="flex-row row rowlist w-100" [class.data-selected]="i === selectedQuote" (click)="setClickedInvoice(i)" >
                                                <div class="col-2 d-flex justify-content-center">{{docCur.workdocLegend }}</div>
                                                <div class="col-4 d-flex justify-content-center">{{docCur.workdocFilename }}</div>
                                                <div class="col-2 d-flex justify-content-center">{{getDateUpload(docCur.stdCreation)}}</div>
                                                <div class="col-1 d-flex justify-content-center">
                                                    <button mat-icon-button class="local-button" (click)="viewInvoice(docCur,listCur.name, 'read')">
                                                        <mat-icon matTooltip="{{'view' | translate}}">pageview</mat-icon>
                                                    </button>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </mat-tab-content>
                            </mat-tab>
                        </div>

                    </mat-tab-group>
                </div>        
            </mat-tab>
            <mat-tab label="{{ 'advances' | translate }}">
                <mdi-works-recap-amount *ngIf="invoiceTabDisplay === 2"
                    [ihmOptionsCkeck]="invoicingTabOptions"
                    [statutCall]="'advances'" 
                    [workCur]="wksWorks"
                    (dataOut)="dataOutAdvance($event)"
                >
                </mdi-works-recap-amount>
            </mat-tab>
            <mat-tab label="{{ 'creditnotes' | translate }}">
                <mdi-works-recap-amount *ngIf="invoiceTabDisplay === 3"
                    [ihmOptionsCkeck]="invoicingTabOptions"
                    [statutCall]="'creditnotes'" 
                    [workCur]="wksWorks"
                    (dataOut)="dataOutCreditnote($event)"
                >
                </mdi-works-recap-amount>
            </mat-tab>
        </mat-tab-group> 
    </div>
</ng-template>

<ng-template #statusJournalTemplate >
    <div class="container-fluid stdTheme " >
        <div class="flex-row row stdTheme" style="margin: 0px;">
            <mdi-works-tracking style="width: 100%;"                                     
                [trackingList]="wksWorksTracking"
                [optionItem]="'nothing'"
                [orig]="'workCard'"
                >
            </mdi-works-tracking>
        </div>    
    </div>
</ng-template>