import { Component, OnInit, ViewChild, NgZone, TemplateRef, ViewEncapsulation  } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { take } from 'rxjs/operators';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { v4 as uuidv4 } from 'uuid';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
// import { uuidv4 } from 'uuid';

import { takeUntil } from 'rxjs/operators';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { CoordinatesService, TransformationType, Direction} from 'angular-coordinates';
import { GlobalEventsManagerService } from '../../../../../job/common/services/global-events-manager.service';
import { RessourcesService } from '../../../../../job/common/services/ressources.service';
import { ModalCommonComponent } from '../../../../../job/common/components/modal-common/modal-common.component';
import { UserService } from '../../../../../services/user.service';
import { TranslateService } from '../../../../../services/translate.service';
import { SUPPORTED_FMTS, SUPPORTED_LANGS } from '../../../../../i18n/translation';

import { WksCentralService } from '../../../services/wks-central.service';
import { SmtpModel, WksEntityModel } from '../../../models/wks-entity.model';
import { WksWorksDocs  } from '../../../models/wks-works.model';

import { CountryDef, GoogleMaps, LabelValue, LatLng, ProfileTax } from '../../../../../models/data.model';
import { GridTools } from '../../../../../job/common/tools/gridTools';
import { CommonMethods } from '../../../../../job/common/tools/commonMethods';
import { ToolApiConnect, ToolApiEntity, CalculationCmd, ToolApi } from '../../../../../models/common.model';
import { environment } from '../../../../../../environments/environment';
import { WksApisService } from '../../../services/wks-apis.service';

@Component({
  selector: 'mdi-wks-entity',
  templateUrl: './wks-entity.component.html',
  styleUrls: ['./wks-entity.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class WksEntityComponent implements OnInit {

  private readonly onDestroy = new Subject<void>();
  @ViewChild('entityScales', {static: true})  entityScalesChild: CdkTextareaAutosize;
  @ViewChild('modalMap', {read: TemplateRef, static: false}) modalMap: TemplateRef<any>;
  isRegistered: boolean;

  optionItem: any;
  supportedFmts: LabelValue[];
  locationForm: FormGroup;
  generalSettingsForm: FormGroup;
  smtpForm: FormGroup;
  statutCall: string;
  titleCard: string;
  isReadOnly: boolean;
  entityCur: string;
  countryCur: string;
  
  entityToUpdate: WksEntityModel;
  entityCache: WksEntityModel;
  tabSelected: number;
  calculationSelected: number;
  apiSelected: number;
  configAddress: any;
  dataAddressInput: any;
  dataIdentifierInput: any;
  isProfessionnal: boolean;
  isIndividual: boolean;
  isCompany: boolean;
  testSmtpOK: boolean;
  isPerson: boolean;
  isCountrySelected: boolean;
  addressCur: any;
  markChange: boolean;
  entityTzList: string[];
  // upload models
  displayUpload: boolean;
  displayList: boolean;
  listExt = 'XLSX, DOCX, DOC, ODT, PDF';
  selectedDoc: number;
  cpltEventName: string;
  entitiesList: LabelValue[];
  activitiesList: LabelValue[];
  supportedLangs: any[];
  modalRef: BsModalRef;
  configModal = {
    class: 'modal-dialog-centered modalTask',
    backdrop: true,
    ignoreBackdropClick: true,
    animated: false,
    
  };
  googleMaps: GoogleMaps;
  isGeoLocation: boolean;
  isQuotationActive: boolean;
  isInvoicingActive: boolean;
  isPaginationActive: boolean;
  isformOK: boolean;
  isOkToSave: boolean;
  isFillData: boolean;
  countryDef: CountryDef;
  taxProfilesList: ProfileTax[];
  numberFormat: {
    locale: string;
    minDecimal: string;
    maxDecimal: string;
    currency: string;
  };
  customerOutstandingValue: any;
  algoLocalPrice: CalculationCmd[];
  algoImportedPrice: CalculationCmd[];
 
  stockManagementApi: ToolApiConnect;
  accountingApi: ToolApiConnect;
  notificationsApi: ToolApiConnect;
  customersApi: ToolApiConnect;
  invoicingApi: ToolApiConnect;
  suppliersApi: ToolApiConnect;
  apisEntity: ToolApiEntity[];
  smtpParamsCache: SmtpModel;
  showSmtpPassword = false;
  isSmtpDebug: boolean;
  // smtpPortsList: [25, 465, 587, 2525];

  smtpPortsList: LabelValue[];
  smtpConnectSecurityList: LabelValue[];
  tagsArg: LabelValue[];
  paginationFormats: LabelValue[];

  notificationsAppList =  ['Email', 'SMS', 'WhatsApp', 'XMPP'];

  constructor(private fb: FormBuilder, 
              private _userService: UserService, 
              private _ngZone: NgZone,
              private router: Router,
              private _currentRoute: ActivatedRoute,
              private _dialog: MatDialog,
              private _translate: TranslateService,
              private _wksCentralService: WksCentralService,
              private modalService: BsModalService,
              private _coordinatesService: CoordinatesService,
              private _ressourcesService: RessourcesService,
              private _apiService: WksApisService,
              private _globalEventsManagerService: GlobalEventsManagerService) { }

  ngOnInit() {

    this.cpltEventName = 'wks-entity';
    this.initData();
  }
  initData() {
    
    if ((this._userService.getUserLogged() === undefined) || ( this._userService.getUserLogged().entity === undefined)) {
      return;
    }

    this.optionItem = 'noItem';
    this.optionItem = this._currentRoute.snapshot.data[0]['itemOption'];

    this.initBooleans();
    this.initParams();
    this.buildForm();
    this.loadData();
    this.initSubscribes();
  }
  initBooleans() {
    this.displayUpload = false;
    this.displayList = true;
    this.isQuotationActive = false;
    this.isRegistered = false;
    this.isReadOnly = false;
    this.isGeoLocation = false;
    this.isCompany = false;
    this.isCountrySelected = false;
    this.isIndividual = false;
    this.isPerson = false;
    this.isProfessionnal = false;
    this.isformOK = false;
    this.isOkToSave = false;
    this.isFillData = false;
    this.testSmtpOK = false;
  }
  initParams() {
    this.configAddress = {
      cpltEventName: this.cpltEventName,
      langCur: this._translate.getCurrentLang(),
      countryDefault: this._userService.getUserLogged().entitycountry,
      testAlone: false,
      address: true,
      telephone: false,
      email: false,
      contact: false,
      corporate: true,
      identifier: 'professional',
      fieldsId: [],
      localAddress: true,
    };
    this.tabSelected = 0;
    this.supportedLangs = SUPPORTED_LANGS;
    this.entityCur = this._userService.getUserLogged().entity;
    this.countryCur = this._userService.getEntityLogged().entitycountry;
    this.titleCard = this.entityCur.toUpperCase();
    this.paginationFormats =  [];
    const lisPaginationFormat = this._wksCentralService.getPaginationFormats() as string[];
    for ( const paginationItem of lisPaginationFormat )  {
      const opt = {
        value: paginationItem,
        label: paginationItem,
      };
      this.paginationFormats.push(opt);
    } 
    this.supportedFmts =  [];
    for (const name of this._ressourcesService.getListIntFormat() ) {
      const opt = {
        value: name,
        label: name,
      };
      this.supportedFmts.push(opt);
    }
    this.activitiesList =  [];
    let activityItem = {
      value: 'workshop',
      label: this._translate.instant('workshop')
    };
    this.activitiesList.push(activityItem);
    activityItem = {
      value: 'concierge',
      label: this._translate.instant('concierge')
    };
    this.activitiesList.push(activityItem);
    this.countryDef = this._ressourcesService.getCountry(this._userService.getEntityLogged().entitycountry);
    this.taxProfilesList = this.countryDef.taxation.profilesTax;

    this.smtpPortsList = [];
    for (const item of environment.smtpPorts) {
      const opt: LabelValue = {
        value: item,
        label: item,
      };
      this.smtpPortsList.push(opt);
    }
    this.smtpConnectSecurityList = [];
    for (const item of environment.smtpConnects) {
      const opt: LabelValue = {
        value: item,
        label: item,
      };
      this.smtpConnectSecurityList.push(opt);
    }
    this.tagsArg = [];
    const listTags = ['quotation', 'invoiceAppendix', 'creditnoteAppendix', 'creditnote', 'backPage', 'invoice', 'quotationAppendix' ];
    const listTagsLabel = ['modelQuotation', 'invoiceAppendix', 'creditnoteAppendix', 'modelCreditnote', 'backPage', 'modelInvoice', 'quotationAppendix' ];
    let idxTag = 0;
    for (const item of listTags) {
      const opt: LabelValue = {
        value: item,
        label: this._translate.instant(listTagsLabel[idxTag])
      };
      this.tagsArg.push(opt);
      idxTag++;
    }
    const optBlanck: LabelValue = {
      value: '',
      label: ''
    };
    this.tagsArg.push(optBlanck);
    this.tagsArg.sort((obj1, obj2) => {
      return obj1.label > obj2.label ? 1 : -1;
    });
    if (this._wksCentralService.getNotifApp() !== undefined) {
      this.notificationsAppList = this._wksCentralService.getNotifApp();
    }
  }
  initSubscribes() {
    this._globalEventsManagerService.synchroEvents
    .pipe(takeUntil(this.onDestroy))
    .subscribe( event => {
      // closeMapModalLatLng__
      let eventString: string;
      const typeEvent = typeof event;
      if (typeEvent === 'string') {
        eventString = String(event);
        if (eventString.startsWith('closeMapModalLatLng__')) {
          this.closeModal();
          const latLng = JSON.parse(eventString.split('__')[1]) as LatLng;

          this.displayLatLng(latLng);
          // this.loadDocs();
          // console.log(typeEvent);
        }
      }
    });
    this.locationForm.valueChanges.subscribe(x => {
      this.isOkToSave = false;
      if ((this.isFillData) && (JSON.stringify(this.entityCache) !== JSON.stringify(this.fillModel()))) {
        if (this.locationForm.status === 'VALID' 
          && this.generalSettingsForm.status === 'VALID'
          && this.smtpForm.status === 'VALID') {
          this.isOkToSave = true;
        }
      }
    });
    this.generalSettingsForm.valueChanges.subscribe(x => {
      this.isOkToSave = false;
      if ((this.isFillData) && (JSON.stringify(this.entityCache) !== JSON.stringify(this.fillModel()))) {
        if (this.locationForm.status === 'VALID' 
        && this.generalSettingsForm.status === 'VALID'
        && this.smtpForm.status === 'VALID') {
          this.isOkToSave = true;
        }
      }
    });
    this.smtpForm.valueChanges.subscribe(x => {
      this.isOkToSave = false;
      if ((this.isFillData) && (JSON.stringify(this.entityCache) !== JSON.stringify(this.fillModel()))) {
        if (this.locationForm.status === 'VALID' 
        && this.generalSettingsForm.status === 'VALID'
        && this.smtpForm.status === 'VALID') {
          this.isOkToSave = true;
        }
      }
    });
  }
  loadData() {
    this.loadEntity();
    this.loadHeadquarters()
    .then ((responseEntity: any) => {
      this.entitiesList = [];
      for (const entityCur of responseEntity) {
        const opt = {
          value: entityCur.entityId,
          label: entityCur.stdEntity,
        };
        this.entitiesList.push(opt);
      }
    })
    .catch ((error: any) => {
      console.log('loadHeadquarters : ' + error);
      return;
    });
  }
  buildForm() {
    this.locationForm = new FormGroup({
      internationalFormat: this.fb.control({value: '', disabled: this.isReadOnly}),
      language: this.fb.control({value: '', disabled: this.isReadOnly}),
      usersLanguages: this.fb.control({value: '', disabled: this.isReadOnly}),
      entityPosition:  this.fb.control({value: '', disabled: true }),
      entityPositionCoord:  this.fb.control({value: '', disabled: true }),
      subsidiaryOf: this.fb.control({value: '', disabled: this.isReadOnly}),
      entityTz: this.fb.control({value: '', disabled: this.isReadOnly}),
    },
    {
      updateOn: 'blur'
    });
    this.generalSettingsForm = new FormGroup({
      entityDataPurge: this.fb.control({value: '', disabled: this.isReadOnly}),
      notificationsAppFg: this.fb.control({value: '', disabled: this.isReadOnly}),
      accountCash: this.fb.control({value: '', disabled: this.isReadOnly}),
      accountFmt: this.fb.control({value: '', disabled: this.isReadOnly}),
      entityScales: this.fb.control({value: '', disabled: this.isReadOnly}),
      entityCurrency: this.fb.control({value: '', disabled: this.isReadOnly}),
      quotationActivation: this.fb.control({value: '', disabled: this.isReadOnly}),
      invoicingActivation: this.fb.control({value: '', disabled: this.isReadOnly}),
      customerOutstanding: this.fb.control({value: '', disabled: this.isReadOnly}),
      taxProfile: this.fb.control({value: '', disabled: this.isReadOnly}),
      invoicingNumber: this.fb.control({value: '', disabled: this.isReadOnly}),
      activity: this.fb.control({value: '', disabled: this.isReadOnly}),
      maxBdd: this.fb.control({value: '', disabled: this.isReadOnly}),
      maxWebdav: this.fb.control({value: '', disabled: this.isReadOnly}),
      maxSms: this.fb.control({value: '', disabled: this.isReadOnly}),
      maxNotifs: this.fb.control({value: '', disabled: this.isReadOnly}),
      paginationActivation: this.fb.control({value: '', disabled: this.isReadOnly}),
      paginationLabel: this.fb.control({value: '', disabled: this.isReadOnly}),
      paginationFormat: this.fb.control({value: '', disabled: this.isReadOnly}),
      paginationPosV: this.fb.control({value: '', disabled: this.isReadOnly}),
      paginationPosH: this.fb.control({value: '', disabled: this.isReadOnly}),
      partsLabelRegex: this.fb.control({value: '', disabled: this.isReadOnly}, [Validators.required]),
      partsLabelMask: this.fb.control({value: '', disabled: this.isReadOnly}, [Validators.required]),
      partsRefRegex: this.fb.control({value: '', disabled: this.isReadOnly}, [Validators.required]),
      partsRefMask: this.fb.control({value: '', disabled: this.isReadOnly}, [Validators.required]),
      prefixRefServices: this.fb.control({value: '', disabled: this.isReadOnly}, [Validators.required, , Validators.minLength(3)])
    },
    {
      updateOn: 'blur'
    });
    this.smtpForm = new FormGroup({
      smtpServer: this.fb.control({value: '', disabled: this.isReadOnly}),
      smtpPort: this.fb.control({value: '', disabled: this.isReadOnly}),
      smtpConnectSecurity: this.fb.control({value: '', disabled: this.isReadOnly}),
      smtpUser: this.fb.control({value: '', disabled: this.isReadOnly}),
      smtpPasswd: this.fb.control({value: '', disabled: this.isReadOnly}),
      smtpReplyTo: this.fb.control({value: '', disabled: this.isReadOnly}),
      smtpNoreply: this.fb.control({value: '', disabled: this.isReadOnly}),
      smtpFrom: this.fb.control({value: '', disabled: this.isReadOnly}),
      smtpDebug: this.fb.control({value: '', disabled: this.isReadOnly}),
      storePrefix: this.fb.control({value: '', disabled: this.isReadOnly}),
      storeWorks: this.fb.control({value: '', disabled: this.isReadOnly}),
      storeAppointments: this.fb.control({value: '', disabled: this.isReadOnly}),
      storeRH: this.fb.control({value: '', disabled: this.isReadOnly}),
      storeQuotation: this.fb.control({value: '', disabled: this.isReadOnly}),
      storeInvoice: this.fb.control({value: '', disabled: this.isReadOnly}),
      storeAdvance: this.fb.control({value: '', disabled: this.isReadOnly}),
      storeCustomer: this.fb.control({value: '', disabled: this.isReadOnly}),
      storeRevisions: this.fb.control({value: '', disabled: this.isReadOnly}),
      storeSmtp: this.fb.control({value: '', disabled: this.isReadOnly}),
    },
    {
      updateOn: 'blur'
    });

    this.isformOK = true;
  }


  loadEntity() {
    
    this._wksCentralService.getWksEntityInput(this.entityCur)
    .subscribe(
      data => {
        this.statutCall = 'updateEntity';
        this.fillForm(data.body);
      },
      () => {
        this.statutCall = 'createEntity';
        this.fillForm(null);
      }
    );
  }
  loadTimeZones() {
    this.entityTzList = this._ressourcesService.getTimezones(this.entityToUpdate.entityCountry);
    this.locationForm.controls.entityTz.setValue(this.entityToUpdate.entityTz);
  }
  loadHeadquarters() {
    // const currentEquipment = this.fillModel();
    // this._wksCentralService.searchWksEquipment(currentEquipment)
    if (this._userService.getUserLogged() === undefined ) { return; }

    return new Promise((resolve, reject) => {
      this._wksCentralService.getWksEntityHeadquarters()
      .pipe(takeUntil(this.onDestroy))
      .subscribe(
        data => {
          const responseEntity = data.body as WksEntityModel[];
          
          resolve(responseEntity);
        }, err => {
          // console.log('loadHeadquarters' + err.message);
          if (err.status === 404) {
            
          }
          reject(err.status);
        }
      );
    });
  }
  numberAndDotOnly(event: any): boolean {
    return CommonMethods.numberAndDotOnly(event);
  }
  onInputChangePartsRules(fieldName: string, value: string): void { 
    let errorField = false;
    value = value.replace('^', '');
    try  {
      const pattern = new RegExp('^' + value + '*$', 'g');
      if (!pattern.test('aD/25-')) {
        errorField = true;
      }
    } catch (error) {
      errorField = true;
    } finally { 
      if (errorField) { 
        if (fieldName === 'partsLabelRegex') { 
          this.generalSettingsForm.controls['partsLabelRegex'].setErrors({'incorrect': true});
        }
        if (fieldName === 'partsRefRegex') { 
          this.generalSettingsForm.controls['partsRefRegex'].setErrors({'incorrect': true});
        }
      } else {
        if (fieldName === 'partsLabelRegex') { 
          this.generalSettingsForm.controls['partsLabelRegex'].setErrors(null);
        }
        if (fieldName === 'partsRefRegex') { 
          this.generalSettingsForm.controls['partsRefRegex'].setErrors(null);
        }
      } 
    }

    /*
    if (!pattern.test(this.entityCard.extendedaccess)) {
      this.entityCard.extendedaccess = this.entityCard.entity + ',' + this.entityCard.extendedaccess;
    }
      */
  }
  onInputPrefixRefServices($event: any): void {
    // const value = this.generalSettingsForm.controls.prefixRefServices.value;
    const value = $event;
    const lettersOnly = value.match(/[a-zA-Z]+/g);
    let result = lettersOnly ? lettersOnly.join('') : '';
    result = result.toUpperCase();
    this.generalSettingsForm.controls.prefixRefServices.setValue(result);
  }
  onChangeFmt($event: any): void {
    const internationnalFormat = $event;
    this.numberFormat = {
      locale: this._ressourcesService.getFormat(internationnalFormat, 'numbers', 'locale'),
      minDecimal: this._ressourcesService.getFormat(internationnalFormat, 'numbers', 'minimumFractionDigits'),
      maxDecimal: this._ressourcesService.getFormat(internationnalFormat, 'numbers', 'maximumFractionDigits'),
      currency:  this._ressourcesService.getFormat(internationnalFormat, 'numbers', 'currency'),
    };
  }
  onChangeLanguage($event: any) {

  }
  onChangeTz($event: any) {

  }
  onChangeActivity($event: any) {
    // console.log($event);
  }
  onChangeTaxProfile($event: any) {
    // console.log($event);
  }
  onChangeCustomerOutstanding($event: any) {
    this.customerOutstandingValue = $event;
  }
  onTabChanged($event: any) {
    // console.log($event);
    this.tabSelected = $event;
  }
  onCalculationChanged($event: any) {
    // console.log($event);
    this.calculationSelected = $event;
  }
  onAPIChanged($event: any) {
    this.apiSelected = $event;
  }  
  doVerifNumber($event: any) {
    try {
      if (isNaN($event.value))  {
        this.locationForm.controls.invoicingNumber.setValue('');
      }
    } catch (error) {
      this.locationForm.controls.invoicingNumber.setValue('');
    } finally { }
  }
  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable.pipe(take(1))
        .subscribe(() => this.entityScalesChild.resizeToFitContent(true));
  }
  onChangeField(_field: string, value: any) {
    switch (_field) {
      case 'quotationActivation': {
        if (value !== this.isQuotationActive) {
          if (this.locationForm.status === 'VALID') {
            this.isOkToSave = true;
          }
        }
        this.isQuotationActive = value;
 
        break;
      }
      case 'invoicingActivation': {
        if (value !== this.isInvoicingActive) {
          this.isInvoicingActive = value;
          if (!this.isInvoicingActive) {
            this.locationForm.controls.invoicingNumber.setValidators(undefined);
            this.locationForm.controls.invoicingNumber.setValue('');
          } else {
            this.locationForm.controls.invoicingNumber.setValidators([Validators.required]);
            this.locationForm.controls.invoicingNumber.setValue('');
          }
          if (this.locationForm.status === 'VALID') {
            this.isOkToSave = true;
          }
        }
        break;
      }
      case 'paginationActivation': {
        if (value !== this.isPaginationActive) {
          this.isPaginationActive = value;
          
          if (this.locationForm.status === 'VALID') {
            this.isOkToSave = true;
          }
          if (!this.isPaginationActive) {
            this.generalSettingsForm.controls.paginationLabel.setValue('');
            this.generalSettingsForm.controls.paginationFormat.setValue('');
            this.generalSettingsForm.controls.paginationPosH.setValue(0);
            this.generalSettingsForm.controls.paginationPosV.setValue(0);
          }
        }
        break;
      }
      // paginationFormat
      case 'paginationFormat': {
        break;
      }
      case 'smtpDebug': {
        if (value !== this.isSmtpDebug) {
          if (this.smtpForm.status === 'VALID') {
            this.isOkToSave = true;
          }
        }
        this.isSmtpDebug = value;
 
        break;
      }
      default: {
        break;
      }
    }
  }
  testSmtp(): void {
    const smtpCard = this.fillModelSmtp();
    const stdEntity = this._userService.getUserLogged().entity;
    this._wksCentralService.testSmtp(stdEntity, smtpCard)
    .subscribe(
      response => {
        console.log('testSmtp ' + JSON.stringify(response.body));
        this.errorMessage('testSmtpOK', null);
      },
      err => {
        this.errorMessage('testSmtpError', err.error.status);
      }
    );
  }
  registerEntity(apiCall?: boolean) {
    const entityValidation = this.fillModel();
    this._wksCentralService.saveWksEntity(entityValidation, 'update')
    .subscribe(
      wksEntityReturn => {
        this.entityToUpdate = wksEntityReturn.body;
        this._wksCentralService.setWksEntityCur(this.entityToUpdate);
        this.isOkToSave = false;
        if (apiCall) {
          return;
        }
        this.router.navigate(['/jobHome']);
      },
      err => {
        this.errorMessage('register', err.error.status);
      }
    );
  }
  onChangeSubsidiary(_value: any)  {
    console.log('onChangeSubsidiary : ' + _value);
  }
  notificationsAppChange(_value: any)  {
    console.log('notificationsAppChange : ' + _value);
  }
  fillModel(): WksEntityModel {
    // otherData: '{"internationnalFormat" : ' + JSON.stringify(this.locationForm.controls.internationalFormat.value + '}',
    if (this.generalSettingsForm.controls.customerOutstanding.value !== undefined) {
      const regexNum = new RegExp('[^0-9]', 'g');
      this.customerOutstandingValue = this.generalSettingsForm.controls.customerOutstanding.value.replace(regexNum, '');
    }
    const pagination = {
      paginationActivation: this.isPaginationActive,
      paginationLabel: this.generalSettingsForm.controls.paginationLabel.value,
      paginationFormat: this.generalSettingsForm.controls.paginationFormat.value,
      paginationPosH: this.generalSettingsForm.controls.paginationPosH.value,
      paginationPosV: this.generalSettingsForm.controls.paginationPosV.value
    };
    this.getApis();
    const smtpCard = this.fillModelSmtp();
    if ( this.algoImportedPrice === undefined || this.algoImportedPrice.length === 0 ) {
      this.algoImportedPrice = [];
    }
    if (this.algoLocalPrice === undefined || this.algoLocalPrice.length === 0 ) {
      this.algoLocalPrice = [];
    }
    const algoPricesTmp = {
      algoImportedPrice: (this.algoImportedPrice !== undefined ? this.algoImportedPrice : ''),
      algoLocalPrice: (this.algoLocalPrice !== undefined ? this.algoLocalPrice : '')
    };
    const partsRulesTmp = {
      partsLabelRegex: this.generalSettingsForm.controls.partsLabelRegex.value ? this.generalSettingsForm.controls.partsLabelRegex.value : '',
      partsLabelMask: this.generalSettingsForm.controls.partsLabelMask.value ? this.generalSettingsForm.controls.partsLabelMask.value : '',
      partsRefRegex: this.generalSettingsForm.controls.partsRefRegex.value ? this.generalSettingsForm.controls.partsRefRegex.value : '',
      partsRefMask: this.generalSettingsForm.controls.partsRefMask.value ? this.generalSettingsForm.controls.partsRefMask.value : '',
      prefixRefServices: this.generalSettingsForm.controls.prefixRefServices.value ? this.generalSettingsForm.controls.prefixRefServices.value : '',
    };
    const entityValidation: WksEntityModel = {
      id: (this.entityToUpdate !== undefined ? this.entityToUpdate.id : undefined),
      entityId: (((this.entityToUpdate !== undefined) && (this.entityToUpdate.entityId !== undefined)) ? this.entityToUpdate.entityId : undefined),
      stdEntity: this.entityCur,
      entityCountry: this.countryCur,
      entityDataPurge: this.generalSettingsForm.controls.entityDataPurge.value,
      accountCash: this.generalSettingsForm.controls.accountCash.value,
      accountFmt: this.generalSettingsForm.controls.accountFmt.value,
      entityScales: this.generalSettingsForm.controls.entityScales.value,
      otherData: '{"internationnalFormat" : ' + JSON.stringify(this.locationForm.controls.internationalFormat.value) + ',' + 
                  '"language" : ' + JSON.stringify(this.locationForm.controls.language.value) + ',' +
                  '"usersLanguages" : ' + JSON.stringify(this.locationForm.controls.usersLanguages.value)  + ',' +
                  '"entityCurrency" : ' + JSON.stringify(this.generalSettingsForm.controls.entityCurrency.value)  + ',' +
                  '"activity" : ' + JSON.stringify(this.generalSettingsForm.controls.activity.value)  + ',' +
                  '"algoPrices" : ' + JSON.stringify(algoPricesTmp)  + ',' +
                  '"partsRules" : ' + JSON.stringify(partsRulesTmp) +
                  '}' ,
      entityAddress: JSON.stringify(this.dataAddressInput),
      entityPosition: this.locationForm.controls.entityPosition.value,
      subsidiaryOf: this.locationForm.controls.subsidiaryOf.value,
      entityTz: this.locationForm.controls.entityTz.value,
      entityOptions: '{"quotationActivation" : ' + (this.isQuotationActive !== undefined ? JSON.stringify(this.isQuotationActive) : false ) + ',' +
                        '"invoicingActivation" : ' + (this.isInvoicingActive !== undefined ? JSON.stringify(this.isInvoicingActive) : false )   + ',' +
                        '"invoicingNumber" : ' + JSON.stringify(this.generalSettingsForm.controls.invoicingNumber.value) + ',' +
                        '"taxProfile" : ' + JSON.stringify(this.generalSettingsForm.controls.taxProfile.value) + ',' +
                        '"customerOutstanding" : ' + (this.customerOutstandingValue !== undefined ? JSON.stringify(this.customerOutstandingValue) : '') + ',' +
                        '"maxBdd" : ' + JSON.stringify(this.generalSettingsForm.controls.maxBdd.value) + ',' +
                        '"maxWebdav" : ' + JSON.stringify(this.generalSettingsForm.controls.maxWebdav.value) + ',' +
                        '"maxSms" : ' + JSON.stringify(this.generalSettingsForm.controls.maxSms.value) + ',' +
                        '"maxNotifs" : ' + JSON.stringify(this.generalSettingsForm.controls.maxNotifs.value) + ',' +
                        '"pagination": ' + JSON.stringify(pagination) + ',' +
                        '"APIS" : ' + JSON.stringify(this.apisEntity) + ',' +
                        '"notificationsApp" : ' + JSON.stringify(this.generalSettingsForm.controls.notificationsAppFg.value) + ',' +
                        '"smtpParams" : ' + JSON.stringify(smtpCard) +
                      '}' ,
      entityUUID: (((this.entityToUpdate !== undefined) && (this.entityToUpdate.entityUUID !== undefined) && (this.entityToUpdate.entityUUID !== null)) ? this.entityToUpdate.entityUUID : uuidv4()),
      stdCreation: (this.entityToUpdate !== undefined ? this.entityToUpdate.stdCreation : undefined),
    };
    return entityValidation;
  }
  fillModelSmtp(): SmtpModel {
    const smtpCard: SmtpModel = {
      smtpServer: this.smtpForm.controls.smtpServer.value,
      smtpPort: this.smtpForm.controls.smtpPort.value,
      smtpConnectSecurity: this.smtpForm.controls.smtpConnectSecurity.value,
      smtpUser: btoa(this.smtpForm.controls.smtpUser.value),
      smtpPasswd: btoa(this.smtpForm.controls.smtpPasswd.value),
      smtpReplyTo: this.smtpForm.controls.smtpReplyTo.value,
      smtpFrom: this.smtpForm.controls.smtpFrom.value,
      smtpNoreply: this.smtpForm.controls.smtpNoreply.value,
      smtpDebug:  this.isSmtpDebug  ? 'true' : 'false',
      storePrefix: this.smtpForm.controls.storePrefix.value,
      storeWorks: this.smtpForm.controls.storeWorks.value,
      storeAppointments: this.smtpForm.controls.storeAppointments.value,
      storeRH: this.smtpForm.controls.storeRH.value,
      storeQuotation: this.smtpForm.controls.storeQuotation.value,
      storeInvoice: this.smtpForm.controls.storeInvoice.value,
      storeAdvance: this.smtpForm.controls.storeAdvance.value,
      storeCustomer: this.smtpForm.controls.storeCustomer.value,
      storeRevisions: this.smtpForm.controls.storeRevisions.value,
      storeSmtp: this.smtpForm.controls.storeSmtp.value,
    };
    return smtpCard;
  }
  getApis(): void {
    this.apisEntity = [] ;
    let apiCur: ToolApiEntity;
    let apisDef = this._apiService.getApisParam('stockManagement');
    apiCur = {
      apiName : 'stockManagement',
      apiTool: this.stockManagementApi,
      apiActive: (this.stockManagementApi !== undefined && this.stockManagementApi.toolName !== undefined && this.stockManagementApi.toolName !== '' ? true : false),
      apiTestedOK: this.verifyTest(this.stockManagementApi, apisDef)
    };
    this.apisEntity.push(apiCur);
    apisDef = this._apiService.getApisParam('accounting');
    apiCur = {
      apiName : 'accounting',
      apiTool: this.accountingApi,
      apiActive: (this.accountingApi !== undefined && this.accountingApi.toolName !== undefined && this.accountingApi.toolName !== '' ? true : false),
      apiTestedOK: this.verifyTest(this.accountingApi, apisDef)
    };
    this.apisEntity.push(apiCur);
    apisDef = this._apiService.getApisParam('notifications');
    apiCur = {
      apiName : 'notifications',
      apiTool: this.notificationsApi,
      apiActive: (this.notificationsApi !== undefined && this.notificationsApi.toolName !== undefined && this.notificationsApi.toolName !== '' ? true : false),
      apiTestedOK: this.verifyTest(this.notificationsApi, apisDef)
    };
    this.apisEntity.push(apiCur);
    apisDef = this._apiService.getApisParam('invoicing');
    apiCur = {
      apiName : 'invoicing',
      apiTool: this.invoicingApi,
      apiActive: (this.invoicingApi !== undefined && this.invoicingApi.toolName !== undefined && this.invoicingApi.toolName !== '' ? true : false),
      apiTestedOK: this.verifyTest(this.invoicingApi, apisDef)
    };
    this.apisEntity.push(apiCur);
    apisDef = this._apiService.getApisParam('customers');
    apiCur = {
      apiName : 'customers',
      apiTool: this.customersApi,
      apiActive: (this.customersApi !== undefined && this.customersApi.toolName !== undefined && this.customersApi.toolName !== '' ? true : false),
      apiTestedOK: this.verifyTest(this.customersApi, apisDef)
    };
   this.apisEntity.push(apiCur);
   apisDef = this._apiService.getApisParam('suppliers');
   apiCur = {
     apiName : 'suppliers',
     apiTool: this.suppliersApi,
     apiActive: (this.suppliersApi !== undefined && this.suppliersApi.toolName !== undefined && this.suppliersApi.toolName !== '' ? true : false),
     apiTestedOK: this.verifyTest(this.suppliersApi, apisDef)
   };
    this.apisEntity.push(apiCur);
  }
  verifyTest(toolApiConnect: ToolApiConnect, apisDef: ToolApi[]): boolean {
   // const isOK = false;
    // toolTests: ToolTest[] 
    let isTestOK = true;
    if (toolApiConnect === undefined || toolApiConnect.toolName === undefined || toolApiConnect.toolName === '') {
      return false;
    }
    let toolTests = toolApiConnect.toolTests;
    let toolApiCur: ToolApi;
    for (const apisDefItem of apisDef) {
      if (apisDefItem.toolName === toolApiConnect.toolName) {
        toolApiCur = apisDefItem;
        break;
      }
    } 
    toolTests = this._wksCentralService.ctrlApisTestList(toolApiCur.toolVersions, toolApiConnect.toolVersion, toolTests );
    for (const toolTest of toolTests) {
      if (toolTest.menuTest.trim() === '' ) {
        continue;
      }
      if (toolTest.authorised === undefined) { // downward compatibility
        toolTest.authorised = true;
      }
      if (toolTest.statut === 'NT') {
        isTestOK = false;
        break;
      } else if (toolTest.statut !== 'OK') {
        isTestOK = false;
        break;
      }
    }
    return isTestOK;
  }
  dataOutAddress(event: any) {
    if (event.cpltEventName === this.cpltEventName) {
      this.dataAddressInput = JSON.parse(JSON.stringify(event));
    }
    if ((this.addressCur !== this.dataAddressInput) && (this.dataAddressInput.formAdressValid)) {
      if (this.locationForm.status === 'VALID') {
        this.isOkToSave = true;
      }
    }
    if ((this.addressCur !== this.dataAddressInput) && (this.dataAddressInput.formAdressValid)) {
      if (this.generalSettingsForm.status === 'VALID') {
        this.isOkToSave = true;
      }
    }
  }

  fillForm(_wksEntity: WksEntityModel) {

    this.isProfessionnal = true;
    this.markChange = true;

    if (_wksEntity === null) {
      this.entityToUpdate = undefined;
      // this.locationForm.controls.stdEntity.setValue(this.entityCur);
      return;
    }
    this.entityToUpdate = _wksEntity;
    this.entityCache = JSON.parse(JSON.stringify(_wksEntity));
    this.loadTimeZones();
    this.generalSettingsForm.controls.entityDataPurge.setValue(this.entityToUpdate.entityDataPurge);
    this.generalSettingsForm.controls.accountCash.setValue(this.entityToUpdate.accountCash);
    this.generalSettingsForm.controls.accountFmt.setValue(this.entityToUpdate.accountFmt);
    this.generalSettingsForm.controls.entityScales.setValue(this.entityToUpdate.entityScales);
    this.locationForm.controls.subsidiaryOf.setValue(this.entityToUpdate.subsidiaryOf);
    
    // this.addressCur = JSON.parse(this.entityToUpdate.entityAddress);
    this.addressCur = JSON.parse(JSON.stringify(this.entityToUpdate.entityAddress));
    this.dataAddressInput = JSON.parse(this.addressCur);
    // this.entityToUpdate.dataAddress = this.addressCur;
    const otherData = JSON.parse(this.entityToUpdate.otherData);
    const algoPrices = otherData.algoPrices;
    if (algoPrices !== undefined) {
      this.algoImportedPrice = algoPrices.algoImportedPrice;
      this.algoLocalPrice = algoPrices.algoLocalPrice;
    } else  {
      this.algoImportedPrice = [];
      this.algoLocalPrice = [];
    }

    const partsRules = otherData.partsRules;
    if (partsRules !== undefined) {
      this.generalSettingsForm.controls.partsLabelRegex.setValue(partsRules.partsLabelRegex);
      this.generalSettingsForm.controls.partsLabelMask.setValue(partsRules.partsLabelMask);
      this.generalSettingsForm.controls.partsRefRegex.setValue(partsRules.partsRefRegex);
      this.generalSettingsForm.controls.partsRefMask.setValue(partsRules.partsRefMask);
      this.generalSettingsForm.controls.prefixRefServices.setValue(partsRules.prefixRefServices);
    }

    const internationnalFormat = otherData.internationnalFormat;
    const entityCurrency = otherData.entityCurrency;
    if (internationnalFormat !== undefined) {
      this.locationForm.controls.internationalFormat.setValue(internationnalFormat);
    }
    this.numberFormat = {
      locale: this._ressourcesService.getFormat(internationnalFormat, 'numbers', 'locale'),
      minDecimal: this._ressourcesService.getFormat(internationnalFormat, 'numbers', 'minimumFractionDigits'),
      maxDecimal: this._ressourcesService.getFormat(internationnalFormat, 'numbers', 'maximumFractionDigits'),
      currency:  this._ressourcesService.getFormat(internationnalFormat, 'numbers', 'currency'),
    };
    if (entityCurrency !== undefined) {
      this.generalSettingsForm.controls.entityCurrency.setValue(entityCurrency);
    }

    const activityCur = (otherData.activity !== undefined ? otherData.activity : '');
    this.generalSettingsForm.controls.activity.setValue(activityCur);

    const language = otherData.language;
    if (language !== undefined) {
      this.locationForm.controls.language.setValue(language);
    }
    const bindUsersLanguages: string[] = [];
    const usersLanguagesBind = otherData.usersLanguages;
    if (usersLanguagesBind !== undefined) {
      for (const langCur of usersLanguagesBind) {
        bindUsersLanguages.push(langCur);
      }
      // bindUsersLanguages = usersLanguagesBind.split(',');
    }
    this.locationForm.controls.usersLanguages.setValue(bindUsersLanguages);
    if (this.entityToUpdate.entityOptions !== undefined && this.entityToUpdate.entityOptions !== null) {
      const entityOptions = JSON.parse(this.entityToUpdate.entityOptions);
     
      if (entityOptions.quotationActivation !== undefined) {
        const quotationActivation = entityOptions.quotationActivation;
        this.isQuotationActive = quotationActivation;
        // this.locationForm.controls.quotationActivation.setValue(quotationActivation);
      }
      if (entityOptions.invoicingActivation !== undefined) {
        const invoicingActivation = entityOptions.invoicingActivation;
        if (invoicingActivation !== undefined) {
          this.isInvoicingActive = invoicingActivation;
          const invoicingNumber = entityOptions.invoicingNumber;
          if (invoicingNumber !== undefined) {
            this.generalSettingsForm.controls.invoicingNumber.setValue(invoicingNumber);
          } else  {
            this.generalSettingsForm.controls.invoicingNumber.setValue('');
          }
        }
      }
      if (entityOptions.customerOutstanding !== undefined) {
        const customerOutstanding = entityOptions.customerOutstanding;
        if (customerOutstanding !== undefined) {
          const regexNum = new RegExp('[^0-9]', 'g');
          this.customerOutstandingValue = customerOutstanding.replace(regexNum, '');
          this.generalSettingsForm.controls.customerOutstanding.setValue(this.customerOutstandingValue);
        } else  {
          this.generalSettingsForm.controls.customerOutstanding.setValue(0);
          this.customerOutstandingValue = 0;
        }
      }
      if (entityOptions.notificationsApp !== undefined) {
        this.generalSettingsForm.controls.notificationsAppFg.setValue(entityOptions.notificationsApp);
      }
      if (entityOptions.pagination !== undefined) {
        const pagination  = entityOptions.pagination;
        const paginationActivation = pagination.paginationActivation;
        if (paginationActivation !== undefined) {
          this.isPaginationActive = paginationActivation;
          const paginationLabel = pagination.paginationLabel;
          if (paginationLabel !== undefined) {
            this.generalSettingsForm.controls.paginationLabel.setValue(paginationLabel);
          } else  {
            this.generalSettingsForm.controls.paginationLabel.setValue('');
          }
          const paginationFormat = pagination.paginationFormat;
          if (paginationFormat !== undefined) {
            this.generalSettingsForm.controls.paginationFormat.setValue(paginationFormat);
          } else  {
            this.generalSettingsForm.controls.paginationFormat.setValue('');
          }
          const paginationPosH = pagination.paginationPosH;
          if (paginationPosH !== undefined) {
            this.generalSettingsForm.controls.paginationPosH.setValue(paginationPosH);
          } else  {
            this.generalSettingsForm.controls.paginationPosH.setValue(0);
          }
          const paginationPosV = pagination.paginationPosV;
          if (paginationPosV !== undefined) {
            this.generalSettingsForm.controls.paginationPosV.setValue(paginationPosV);
          } else  {
            this.generalSettingsForm.controls.paginationPosV.setValue(0);
          }
        }
      } else {
        this.isPaginationActive = false;
        this.generalSettingsForm.controls.paginationLabel.setValue('');
        this.generalSettingsForm.controls.paginationFormat.setValue('');
        this.generalSettingsForm.controls.paginationPosH.setValue(0);
        this.generalSettingsForm.controls.paginationPosV.setValue(0);
      }
      const taxProfile = (entityOptions.taxProfile !== undefined ? entityOptions.taxProfile : '');
      this.generalSettingsForm.controls.taxProfile.setValue(taxProfile);

      let maxdefault = (entityOptions.maxBdd ? entityOptions.maxBdd :  this._wksCentralService.getWksMaxDefaults().maxBdd);
      this.generalSettingsForm.controls.maxBdd.setValue(maxdefault);
      maxdefault = (entityOptions.maxWebdav ? entityOptions.maxWebdav :  this._wksCentralService.getWksMaxDefaults().maxWebdav);
      this.generalSettingsForm.controls.maxWebdav.setValue(maxdefault);
      maxdefault = (entityOptions.maxSms ? entityOptions.maxSms :  this._wksCentralService.getWksMaxDefaults().maxSms);
      this.generalSettingsForm.controls.maxSms.setValue(maxdefault);
      maxdefault = (entityOptions.maxNotifs ? entityOptions.maxNotifs :  this._wksCentralService.getWksMaxDefaults().maxNotifs);
      this.generalSettingsForm.controls.maxNotifs.setValue(maxdefault);
      this.stockManagementApi = undefined;
      this.notificationsApi = undefined;
      this.suppliersApi = undefined;
      this.customersApi = undefined;
      this.accountingApi = undefined;
      this.invoicingApi = undefined;
      if (entityOptions.APIS !== undefined) {
        const apisEntity = entityOptions.APIS as ToolApiEntity[];
        if (apisEntity !== undefined) {
          for (const apiCur of apisEntity ) {
            if (apiCur.apiName === 'stockManagement') {
              this.stockManagementApi = apiCur.apiTool;
              if (this.stockManagementApi === undefined || this.stockManagementApi.toolName === undefined) {
                this.stockManagementApi = undefined;
              }
            } else if (apiCur.apiName === 'accounting') {
              this.accountingApi = apiCur.apiTool;
              if (this.accountingApi === undefined || this.accountingApi.toolName === undefined) {
                this.accountingApi = undefined;
              }
            } else if (apiCur.apiName === 'notifications') {
              this.notificationsApi = apiCur.apiTool;
              if (this.notificationsApi === undefined || this.notificationsApi.toolName === undefined) {
                this.notificationsApi = undefined;
              }
            } else if (apiCur.apiName === 'customers') {
              this.customersApi = apiCur.apiTool;
              if (this.customersApi === undefined || this.customersApi.toolName === undefined) {
                this.customersApi = undefined;
              }
            } else if (apiCur.apiName === 'invoicing') {
              this.invoicingApi = apiCur.apiTool;
              if (this.invoicingApi === undefined || this.invoicingApi.toolName === undefined) {
                this.invoicingApi = undefined;
              }
            } else if (apiCur.apiName === 'suppliers') {
              this.suppliersApi = apiCur.apiTool;
              if (this.suppliersApi === undefined || this.suppliersApi.toolName === undefined) {
                this.suppliersApi = undefined;
              }
            } 
          }
        }
        if (this.stockManagementApi === undefined) {
          this.stockManagementApi = this.emptyToolApiConnect();
        }
        if (this.accountingApi === undefined) {
          this.accountingApi = this.emptyToolApiConnect();
        }
        if (this.notificationsApi === undefined) {
          this.notificationsApi = this.emptyToolApiConnect();
        }
        if (this.customersApi === undefined) {
          this.customersApi = this.emptyToolApiConnect();
        }
        if (this.invoicingApi === undefined) {
          this.invoicingApi = this.emptyToolApiConnect();
        }
        if (this.suppliersApi === undefined) {
          this.suppliersApi = this.emptyToolApiConnect();
        }
        this.getApis();
      }
      if (entityOptions.smtpParams !== undefined) {

        // const asciiStringDecoded = atob(_wksEntity.string64);
        // console.log(`Decoded string: [${asciiStringDecoded}]`);
        // this.smtpParamsCache.smtpPasswd = atob(this.smtpParamsCache.smtpPasswd);
        // this.smtpParamsCache.smtpUser = atob(this.smtpParamsCache.smtpUser);

        this.smtpParamsCache = entityOptions.smtpParams as SmtpModel;

        this.smtpForm.controls.smtpServer.setValue(this.smtpParamsCache.smtpServer);
        this.smtpForm.controls.smtpPort.setValue(this.smtpParamsCache.smtpPort);
        this.smtpForm.controls.smtpConnectSecurity.setValue(this.smtpParamsCache.smtpConnectSecurity);
        this.smtpForm.controls.smtpUser.setValue(atob(this.smtpParamsCache.smtpUser));
        this.smtpForm.controls.smtpPasswd.setValue(atob(this.smtpParamsCache.smtpPasswd));
        this.smtpForm.controls.smtpReplyTo.setValue(this.smtpParamsCache.smtpReplyTo);
        this.smtpForm.controls.smtpFrom.setValue(this.smtpParamsCache.smtpFrom);
        this.smtpForm.controls.smtpNoreply.setValue(this.smtpParamsCache.smtpNoreply);
        this.smtpForm.controls.smtpDebug.setValue(this.smtpParamsCache.smtpDebug);
        this.isSmtpDebug = this.smtpParamsCache.smtpDebug === 'true' ? true : false;
        this.smtpForm.controls.storePrefix.setValue(this.smtpParamsCache.storePrefix);
        
        this.smtpForm.controls.storeWorks.setValue(this.smtpParamsCache.storeWorks);
        this.smtpForm.controls.storeAppointments.setValue(this.smtpParamsCache.storeAppointments);
        this.smtpForm.controls.storeRH.setValue(this.smtpParamsCache.storeRH);
        this.smtpForm.controls.storeQuotation.setValue(this.smtpParamsCache.storeQuotation);
        this.smtpForm.controls.storeInvoice.setValue(this.smtpParamsCache.storeInvoice);
        this.smtpForm.controls.storeAdvance.setValue(this.smtpParamsCache.storeAdvance);
        this.smtpForm.controls.storeCustomer.setValue(this.smtpParamsCache.storeCustomer);
        this.smtpForm.controls.storeRevisions.setValue(this.smtpParamsCache.storeRevisions);
        this.smtpForm.controls.storeSmtp.setValue(this.smtpParamsCache.storeSmtp);
      }
      if ((this.entityToUpdate.entityPosition !== undefined) && (this.entityToUpdate.entityPosition !== null)) {
        this.locationForm.controls.entityPosition.setValue(this.entityToUpdate.entityPosition);
        const tmp = this.entityToUpdate.entityPosition.split(',');
        const wrongPosition = this._translate.getTranslate('anchoragePositionCoordError');
        if ((tmp === null) || (tmp.length < 2)) {
          this.locationForm.controls.entityPositionCoord.setValue(wrongPosition);
          this.isFillData = true;
          return;
        }
        const latLng = this.getLatLng(tmp);
        if ((latLng !== undefined) && (latLng !== null)) {
          this.locationForm.controls.entityPositionCoord.setValue(latLng[0] + ' / ' + latLng[1]);
        } else {
          this.locationForm.controls.entityPositionCoord.setValue(wrongPosition);
        }
      }
    } else  {
      let maxdefault = (this._wksCentralService.getWksMaxDefaults().maxBdd);
      this.generalSettingsForm.controls.maxBdd.setValue(maxdefault);
      maxdefault = (this._wksCentralService.getWksMaxDefaults().maxWebdav);
      this.generalSettingsForm.controls.maxWebdav.setValue(maxdefault);
      maxdefault = (this._wksCentralService.getWksMaxDefaults().maxSms);
      this.generalSettingsForm.controls.maxSms.setValue(maxdefault);
      maxdefault = (this._wksCentralService.getWksMaxDefaults().maxNotifs);
      this.generalSettingsForm.controls.maxNotifs.setValue(maxdefault);
      this.isPaginationActive = false;
      this.generalSettingsForm.controls.paginationLabel.setValue('');
      this.generalSettingsForm.controls.paginationFormat.setValue('');
      this.generalSettingsForm.controls.paginationPosH.setValue(0);
      this.generalSettingsForm.controls.paginationPosV.setValue(0);
      this.stockManagementApi = this.emptyToolApiConnect();
      this.accountingApi = this.emptyToolApiConnect();
      this.notificationsApi = this.emptyToolApiConnect();
      this.suppliersApi = this.emptyToolApiConnect();
      this.customersApi = this.emptyToolApiConnect();
      this.invoicingApi = this.emptyToolApiConnect();
      this.getApis();
      this.smtpParamsCache = undefined;

    }
    this.isFillData = true;
  }
  emptyToolApiConnect(): ToolApiConnect {

    const apiContent: ToolApiConnect = {
      toolName: '',
      toolVersion: '',
      toolCustomerNwsId: '',
      toolCustomerNwsIdField: '',
      toolCustomerNwsCode: '',
      toolCustomerNwsCodeField: '',
      toolWebService: { 
        toolProtocol: 'REST',
        toolCredentials: { 
          toolAuthentification: 'tooluser',
          username: '',
          pwd: '',
          toolkeyname: '',
          toolkeyvalue: '',
        },
        toolArgs: [],
        toolHeaders: [],
        toolServerUrl: '',
        toolServerArgs: '',
        // toolServices: this.emptyServices(),
        toolAuth2: { 
          activateJwt: false,
          toolJwtServerUrl: '',
          toolJwtServerArgs: ''
        }
      },
      toolTests: [],
      toolImport: { 
        model: '',
        scheduling: '',
      },
      toolExport: { 
          model: '',
          scheduling: '',
      },
    };

    return apiContent;
  }
  errorMessage(_actionCur: string, cpltMes: String) {
    const dialogConfig = new MatDialogConfig();
    let titleBox: string;
    let messageBox: string;
    if (_actionCur === 'register') {
      messageBox = 'error recording';
      messageBox = this._translate.getTranslate(messageBox) + ' : ' + cpltMes;
      titleBox = 'recording entity';
      
    }
    if (_actionCur === 'testSmtpError') {
      messageBox = 'error to send mail' ;
      messageBox = this._translate.getTranslate(messageBox) + ' : ' + cpltMes;
      titleBox = 'send mail error';
    }
    if (_actionCur === 'testSmtpOK') {
      messageBox = 'test smtp OK' ;
      messageBox = this._translate.getTranslate(messageBox) ;
      titleBox = 'send mail OK';
    }
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      id: 1,
      title: this._translate.getTranslate(titleBox),
      typeDialog: 'alertWks',
      panelClass: 'stdTheme',
      contentMessage: this._translate.getTranslate(messageBox),
      data1: '',
      data2: '',
      messageType: 'ERROR'

      };
 //   this._dialog.open(ModalCommonComponent, dialogConfig);

    const dialogRef = this._dialog.open(ModalCommonComponent, dialogConfig);

    dialogRef.afterClosed()
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
        if (_actionCur === 'register') {
          console.log('Dialog output:', data);
          dialogRef.close();
          // this.doClose();
        }
        if (_actionCur === 'testSmtpError') {
          console.log('Dialog output:', data);
          dialogRef.close();
        }
        if (_actionCur === 'testSmtpOK') {
          console.log('Dialog output:', data);
          dialogRef.close();
        }
      });

  }
  doClose() {
    
    this.router.navigate(['/jobHome']);
  }
  doCancel() {
    this.router.navigate(['/jobHome']);
  }

  // documents models
  displayMessageBox(_titleBox: string, _messageBox: string, _messageType: string, _typeDialog: string, _actionCur: string, dataCur: any) {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      id: 1,
      title: _titleBox,
      typeDialog: _typeDialog,
      panelClass: 'stdTheme',
      contentMessage: _messageBox,
      data1: '',
      data2: '',
      messageType: _messageType

      };

    const dialogRef = this._dialog.open(ModalCommonComponent, dialogConfig);

    dialogRef.afterClosed()
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
        // console.log('Dialog output:', data);
        if (( data === 'okAction') && (_actionCur === 'removeModel')) {
          this.deleteDocOK(dataCur);
        }
      });

  }
  toggleUploadFiles() {
    
    this.displayUpload = ! this.displayUpload;
    this.displayList = !this.displayUpload;
  }
  toggleDisplayList() {
    this.displayList = ! this.displayList;
    this.displayUpload = !this.displayList;
  }
  setClickedDoc(row: number)  {
    if ( this.selectedDoc === row) {
      this.selectedDoc = -1;

    } else {
      this.selectedDoc = row;
    }
  }
  removeDoc(_row: number) {
    // console.log('deleteEntity Row clicked: ', row);
    let titleBox: string;
    let messageBox: string;
    titleBox = 'deleting docs';
      
    titleBox = this._translate.getTranslate(titleBox);
    messageBox = this.entityToUpdate.workDocs[_row].workdocFilename;
    const dataIn =  {
      fileToRemove: this.entityToUpdate.workDocs[_row],
      row: _row
    };
    this.displayMessageBox(titleBox, messageBox, 'WARNING', 'confirmAction', 'removeModel', dataIn );
  }
  deleteDocOK(data: any) {

    // this.filesToDisplay.splice(data.row, 1);
    //  as WksWorksDocs []
    // this.wksWorks.workDocs = [];

    for (const docCur of this.entityToUpdate.workDocs)  {
      if (docCur.workdocFilename === data.fileToRemove.workdocFilename)  {        
        this.removeFile(docCur)
        .then ((responseType: any) => {
          this.entityToUpdate.workDocs.splice(data.row, 1);
        })
        .catch ((error: any) => {
          console.log('removeFile : ' + error);
          return;
        });
        break;
      }
    }

  }
  private removeFile(currentDoc: WksWorksDocs) {

    // console.log('fichier transféré ' + _file.name);
    return  new Promise<void>((resolve, reject) => {
      this._wksCentralService.removeWksWorksDoc(currentDoc).subscribe((event) => {
            resolve();
        },
        response => {
          reject(response);
          } ,
        );
      });
  } 
  getDateUpload(_value: string) {
    return GridTools.date_unix(_value, this._translate.getLocalFmt('timeFormat'));
  }
  newListDocs(event: any) {
    this.entityToUpdate.workDocs.push(...event);
  }

/* geolocation */
  // Maps
  displayMap() {

    let positionCur ;
    let zoom = 1;
    let latLng: LatLng;
    if ((this.locationForm.controls.entityPosition !== undefined) 
      && (this.locationForm.controls.entityPosition.value !== null)
      && (this.locationForm.controls.entityPosition.value !== '')) {
      zoom = 14;
      positionCur = this.locationForm.controls.entityPosition.value;
      const values = positionCur.split(',');
      latLng = {
          lat: Number.parseFloat(values[0]),
          lng: Number.parseFloat(values[1])
      };
    }
    this.googleMaps = {
      isModal: true,
      style: {
        width: '1200px',
        height: '700px',
      },
      displayLocal: true,
      // displayLatLng , getLatLng
      action: 'getLatLng',
      position: latLng,
      mapOptions:  {
        center: latLng,
        zoom : 1
     },
     markers:  [],
    };
    if (positionCur !== undefined) {
      this.googleMaps.markers.push({ 
        position: latLng,
        title: ''}
      );
    }
    this.openModalMap();
  }
  openModalMap() {

    const configModalMap = {
      class: 'modal-dialog-centered modalQuote handleUpdate',
      backdrop: true,
      ignoreBackdropClick: true,
      animated: true,
    };
    this.modalRef = this.modalService.show(this.modalMap, configModalMap);
  }
  closeModal() {
    if (this.modalRef !== undefined) {
      this.modalRef.hide();
    } 
  }
  displayLatLng(_coord: LatLng) {
    const wrongPosition = this._translate.getTranslate('anchoragePositionCoordError');
    const values: string[] = [];
    values[0] = _coord.lat.toString();
    values[1] = _coord.lng.toString();
    const latLng = this.getLatLng(values);
    if ((latLng !== undefined) && (latLng !== null)) {
      this.locationForm.controls.entityPosition.setValue(values[0] + ',' + values[1]);
      this.locationForm.controls.entityPositionCoord.setValue(latLng[0] + ' / ' + latLng[1]);
    } else {
      this.locationForm.controls.entityPositionCoord.setValue(wrongPosition);
    }
  }
  getLatLng(_values: string[]): number[] {
    const latLng = [];
    const lat = Number.parseFloat(_values[0]);
    const lng = Number.parseFloat(_values[1]);

    // console.log('Lat : ' + lat + ' Lng : ' + lng );
    latLng[0] = this._coordinatesService.transform(lat, TransformationType.ToDegrees, Direction.Latitude);
    latLng[1] = this._coordinatesService.transform(lng, TransformationType.ToDegrees, Direction.Longitude);
    return latLng;
  }
  dataOutAlgo(calculationType: string,  algoOut: CalculationCmd[]): void {
    // console.log(calculationType);
    // console.log(JSON.stringify(algoOut));

    if (calculationType === 'importedPrice') {
      this.algoImportedPrice = algoOut;
    } else {
      this.algoLocalPrice = algoOut;
    }
    
    this.registerEntity();
  } 
  dataOutAPI(apiType: string, apiEvent: ToolApiConnect ): void {

    switch (apiType) {
      case 'stockManagement': {
        this.stockManagementApi = apiEvent;
        break;
      }
      case 'accounting': {
        this.accountingApi = apiEvent;
        break;
      }
      case 'notifications': {
        this.notificationsApi = apiEvent;
        break;
      }
      case 'customers': {
        this.customersApi = apiEvent;
        break;
      }
      case 'invoicing': {
        this.invoicingApi = apiEvent;
        break;
      }
      case 'suppliers': {
        this.suppliersApi = apiEvent;
        break;
      }
      default: {
        break;
      }
    } 
    
    this.registerEntity(true);
  }
}
