import { WksTasksModel, ParamLabelsI18n, TaskActionRecapAmountModel, RecapAmountPriceModel, PartsRequirement } from './wks-param.model';
import { AccountingBalance, AccountModel, WksBusinessModel } from '../models/wks-business.model';
import { CalendarEventBdd, FileToDisplay } from '../../../models/common.model';
import { WksEquipmentModel } from '../models/wks-equipment.model';
import { FieldValue } from '../../../models/data.model';
// CalendarEventBdd

export interface WksAlarmMessage {
    sendBy: string;
    recipientsMes: string;
    sendDate: Date;
    mediaMes: string;
    objectMes: string;
    bodyMes: string;
    metaData: string;
}

export interface WksWorksTracking {
    numberTrack: number;
    userLogged: string;
    recordedTrack: Date;
    workStatut: string;
    workComment: string;
    alarmsMes: WksAlarmMessage [];
}

export interface WksWorksDocs {
    id?: string;
    stdEntity: string;
    workId: string;
    workdocType?: string;
    workdocLegend?: string;
    workdocStorage?: string;
    workdocPath?: string;
    workdocFilename?: string;
    workdocJson?: string;
    stdCreation?: Date;
    stdLastupdate?: Date;
    stdDisable?: Date;
}

export const wksworkstracking_sch = [
    { colName: 'numberTrack', colType: 'number' },
    { colName: 'userLogged', colType: 'string' },
    { colName: 'recordedTrack', colType: 'Date' },
    { colName: 'workStatut', colType: 'string' },
    { colName: 'workComment', colType: 'string' },
    { colName: 'alarmsMes', colType: 'string' },
    ];

export interface WksWorksModel {
    id?: string;
    stdEntity: string;
    workEquip?: string;
    workRef?: string;
    workMainEquipName?: string;
    workCustomerName?: string;
    ownerId?: string;
    workObject?: string;
    workCriticality?: string;
    workCriticalityLabel?: string;
    workPlanningId?: string;
    workComment?: string;
    workLocation?: string;
    workLocationData?: string;
    workPosition?: string;
    workRecordedDate?: string;
    workTasksContent?: WksTasksModel[];
    workTicket?: string;
    workStatutDate?: Date;
    workStatut?: string;
    workStatutLabel?: string;
    workJsonData?: string;
    workJsonQuotation?: string;
    workJsonInvoicing?: string;
    workIdPrevious?: string;
    stdCreation?: Date;
    stdLastupdate?: Date;
    stdDisable?: Date;
    langLabels?: ParamLabelsI18n[];
    userLang?: string;
    wksWorksTracking?: WksWorksTracking [];
    workDocs?: WksWorksDocs [];
    workQuotes?: WksWorksDocs [];
    workInvoices?: WksWorksDocs [];
    allAvailable?: boolean;
    statutChange?: boolean;
    wksCalendar?: CalendarEventBdd;
    addressCustomer?: string;
    workStartDate?: string;
    workEndDate?: string;
    workDuration?: number;
    accountingBalance?: AccountingBalance;
    statutCommand?: string;
    taskTem?: string;
}
export interface WorkEquipRecapAmountModel {
    equipId: string;
    equipDesign: string;
    equipSN: string;
    tasksRecapAmount: TaskActionRecapAmountModel[];
    otherEquip: RecapAmountPriceModel[];
    suppliesTotalBT: number;
    servicesTotalBT: number;
    laborTotalBT: number;
    otherTotalBT: number;
    otherEquipTotalBT: number;
    isCollapsed?: boolean;
}
export interface RecapAmountOtherWorkModel {
    rowId: string;
    rowLabel: string;
    rowSubtracting: boolean;
    supplies: number;
    services: number;
    labor: number;
}
export interface RecapAmountFinancialWorkModel {
    rowType: string; // discount - surcharge - rounding (remise / majoration / arrondi) 
    rowLabel: string;
    rowLabelCplt: string;
    rowPercent: number; // discount - surcharge
    rowSubtracting: boolean; // discount - surcharge
    rowBaseWork: string; // labor / supplies
    rowBase: number;
    rowAmount: number; // after tax amount
}
export interface InvoiceOtherDataModel {
    rowType: string; // discount - surcharge - rounding (remise / majoration / arrondi) 
    rowLabel: string;
    rowLabelCplt: string;
    rowPercent: number; // discount - surcharge
    supplies: number;
    services: number;
    labor: number;   
}
export interface RecapAmountRowModel {
    rowLabel: string;
    supplies: number;
    services: number;
    labor: number;
}
export interface RecapAmountTaxModel {
    taxName: string;
    taxLabel: string;
    taxPercent: number;
    taxBaseWork: string; // labor / supplies
    supplies?: number;
    services?: number;
    labor?: number;
}
export interface PaymentRecap {
    workShopEditor: string;
    delayValidity: number;
    customerConditions: boolean;
    customerAccount: AccountModel;
    specificsConditions: {
        numberPayments: number;
        payments: Payment [];
        cashpay: boolean;
    };
    customerFinancials: boolean;
    specificsFinancials: {
        scaleName: string;
        bsnCoef: number;
    };
}
export interface CreditnoteWorkModel  {
    item: string;
    id?: string;
    emitOK: boolean;
    emitDate?: Date;
    creditnoteWorkRow: CreditnoteItem;
    creditnoteWorkTax: RecapAmountTaxModel[];
    creditnoteWorkTotalTax: RecapAmountRowModel;
    creditnoteWorkTotalWT: RecapAmountRowModel;
}
export interface WksWorksRecapAmountModel {
    equipRecapAmount: WorkEquipRecapAmountModel[];
    equipTotalBT: RecapAmountRowModel;
    otherWorkRows: RecapAmountOtherWorkModel[];
    otherWorkTotalBT: RecapAmountRowModel;
    financialWorkRows: RecapAmountFinancialWorkModel[];
    financialWorkTotalBT: RecapAmountRowModel;
    advanceWorkRows: InvoiceOtherItem[];
    advanceWorkTotalBT: RecapAmountRowModel;
    creditnoteWorkRows: CreditnoteWorkModel[];
    creditnoteWorkTotalBT: RecapAmountRowModel;
    workTotalBT: RecapAmountRowModel;
    workTax: RecapAmountTaxModel[];
    workTotalTax: RecapAmountRowModel;
    workTotalWT: RecapAmountRowModel;
    paymentRecap: PaymentRecap;
    comments: string;
    isCollapsed?: boolean;
    isTaxCollapsed?: boolean;
    isOtherCollapsed?: boolean;
    isFinancialCollapse?: boolean;
}
export interface InvoicingItem {
    item: string;
    wksWorksRecapAmount: WksWorksRecapAmountModel;
}
export interface InvoiceItem {
    item: string;
    recapAmount: WksWorksRecapAmountModel;
}
export interface CreditnoteItem {
    rowLabel: string;
    rowLabelCplt: string;
    rowPercent: number; // discount - surcharge
    supplies: number;
    services: number;
    labor: number;   
}
export interface InvoiceOtherItem {
    id: string;
    item: string;
    invoiceOtherData: InvoiceOtherDataModel;
    generatedRow: boolean;
    internalRow: boolean;
    emitOK: boolean;
    emitDate?: Date;
}
export interface InvoicingDataModel {
    invoices?: InvoiceItem [];
    advances?: InvoiceOtherItem [];
    creditnotes?: CreditnoteWorkModel [];
}
export interface WksWorksInterventionModel {
    workObject: string;
    workCriticality: string;
    workCriticalityLabel?: string;
    workPlanningId: string;
    workComment: string;
    workLocation: string;
    workLocationData: string;
    /*workMooringPort: string;
    workMooringPool: string;
    workMooringPontoon: string;
    workMooringRing: string;
*/
    workPosition?: string;
    workRecordedDate?: Date;
    workTicket?: string;
    workStatutDate?: Date;
    workStatut: string;
    workType?: string;
    workStatutLabel?: string;
    workJsonData?: string;
    workIdPrevious?: string;
    delayValidity?: number;
    customerConditions?: boolean;
    customerAccount?: AccountModel;
    specificsConditions?: {
        numberPayments?: number;
        payments?: Payment [];
    };
    customerFinancials?: boolean;
    specificsFinancials?: {
        scaleName: string;
        bsnCoef: number;
    };
    calendarEventBdd?: CalendarEventBdd;
}
export interface WksWorksInterventionEvent {
    eventAction: string;
    wksWorksIntervention: WksWorksInterventionModel;
}
export interface Payment {
    orderNumber: number;
    term: string; // a la commande, a la livraison materiel, a la réception matériel, à la fin des travaux ou en  3 fois, verifier total 100 %
    delay: number;
    percent: number;
    balance: boolean;
    observations: string;
}

export interface DataWorkModel {
    work?: WksWorksModel;
    newWork?: {
        ownerId?: string;
        equipments?: {
            mainEquipment?: WksEquipmentModel;
            subEquipments?: WksEquipmentModel [];
        }
    };
    iRow?: number;
}
export interface DataLocationWorkModel  {
    typeLocation: string;
    dataLocation: {
        portId?: string;
        dataFields: FieldValue[];
    };
}

export interface WorkQuotation {
    workShopEditor: string;
    delayValidity: number;
    customerConditions: boolean;
    customerAccount: AccountModel;
    specificsConditions: {
        numberPayments: number;
        payments: Payment [];
    };
    customerFinancials: boolean;
    specificsFinancials: {
        scaleName: string;
        bsnCoef: number;
    };
}

export const wksworks_sch = [
    { colName: 'id', colType: 'string' },
    { colName: 'stdEntity', colType: 'string' },
    { colName: 'workEquip', colType: 'string' },
    { colName: 'ownerId', colType: 'string' },
    { colName: 'workObject', colType: 'string' },
    { colName: 'workCriticality', colType: 'string' },
    { colName: 'workCriticalityLabel', colType: 'string' },
    { colName: 'workPlanningId', colType: 'string' },
    { colName: 'workComment', colType: 'string' },
    { colName: 'workLocation', colType: 'string' },
    { colName: 'workLocationData', colType: 'string' },
    { colName: 'workPosition', colType: 'string' },
    { colName: 'workRecordedDate', colType: 'Date' },
    { colName: 'workTasksContent', colType: 'string' },
    { colName: 'wksWorksTracking', colType: 'string' },
    { colName: 'workTicket', colType: 'string' },
    { colName: 'workStatutDate', colType: 'Date' },
    { colName: 'workStatut', colType: 'string' },
    { colName: 'workType', colType: 'string' },
    { colName: 'workStatutLabel', colType: 'string' },
    { colName: 'workJsonData', colType: 'string' },
    { colName: 'workIdPrevious', colType: 'string' },   
    { colName: 'workDocs', colType: 'string' },   
    { colName: 'workQuotes', colType: 'string' },   
    { colName: 'workInvoices', colType: 'string' },   
    { colName: 'langLabels', colType: 'string' },
    { colName: 'userLang', colType: 'string' },   
    { colName: 'stdCreation', colType: 'Date' },
    { colName: 'stdLastupdate', colType: 'Date' },
    { colName: 'stdDisable', colType: 'Date' },
    { colName: 'allAvailable', colType: 'boolean' },
    { colName: 'amountTotalization', colType: 'string' },
    { colName: 'workJsonQuotation', colType: 'string' },
    { colName: 'workJsonInvoicing', colType: 'string' },
    { colName: 'statutChange', colType: 'string' },
    { colName: 'statutCommand', colType: 'string' },
    { colName: 'mainEquipment', colType: 'string' },
    { colName: 'subEquipments', colType: 'string' },
    { colName: 'wksCalendar', colType: 'string' },
    { colName: 'workRef', colType: 'string' },
    { colName: 'workMainEquipName', colType: 'string' },
    { colName: 'workCustomerName', colType: 'string' },
    { colName: 'addressCustomer', colType: 'string' },
    { colName: 'workStartDate', colType: 'Date' },
    { colName: 'workEndDate', colType: 'Date' },
    { colName: 'workDuration', colType: 'Date' },
    { colName: 'workAdvance', colType: 'string' },
    { colName: 'accountingBalance', colType: 'string' },
    { colName: 'taskTem', colType: 'string' },
];
export interface WorkInputEvent {
    objectCur: string;
    action: string;
    customer?: WksBusinessModel;
    equipments?: WksEquipmentModel [];
    quotations?: WorkQuotation;
    wksWorksTracking?: WksWorksTracking [];
    wksWorksDocs?: WksWorksDocs;
    orderedParts?: PartsRequirement[];
    iRow?: number;
}
export interface WorkEquipmentRef {
    mainEquipment: string;
    subEquipments?: string [];
}
export interface WorkEquipments {
    mainEquipment: WksEquipmentModel;
    subEquipments?: WksEquipmentModel [];
}
export interface WksUuidLinksId {
    uuidParent?: string;
    uuidChild?: string;
    uuidChildType?: string;
    uuidLink?: string;
    stdEntity?: string;
    stdDisable?: string;
}
export interface WksEquipmentsRequest {
    stdEntity: string;
    mainEquipment: string;
    subEquipments?: string [];
    allSubEquip?: boolean;
    onlySubEquip?: boolean;
    onlyMainEquip?: boolean;
}

export interface WksEquipmentUsage {
    id: string;
    equipId: string;
    histoMonths: number;
    histoHours: number;
    histoDate: string;
    histoRevision: boolean;
    taskrefRow?: string;
    taskrefId?: string;
    histoOtherdata?: string;
    stdEntity: string;
    stdCreation: Date;
    stdLastupdate: Date;
    stdDisable: Date;
    inputStatut: string;
    taskrefLabel?: string;
}
export interface PhotoData {
    workId: string;
    equipId: string;
    taskId: string;
    sequenceAtt: string;
}
export interface PhotosSequence {
    sequenceDateTime: string;
    sequenceDateTimeLabel: string;
    filesToDisplay: FileToDisplay[];
    isCollapsed?: boolean;
}
export interface PhotosSequences {
    order: number;
    sequenceAtt: string;
    photosSequence: PhotosSequence[];
    isCollapsed?: boolean;
} 
export interface PhotosTask {
    taskId: string;
    taskLabel: string;
    photosSequences: PhotosSequences[];
    isCollapsed?: boolean;
}
export interface PhotosEquip {
    equipId: string;
    equipLabel: string;
    photosTask: PhotosTask[];
    isCollapsed?: boolean;
} 
