import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { MatDialog, MatDialogConfig } from '@angular/material';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ModalCommonComponent } from '../../../../../common/components/modal-common/modal-common.component';
import { SUPPORTED_LANGS, LangSupportedModel } from '../../../../../../i18n/translation';

import { UserService } from '../../../../../../services/user.service';
import { WksCentralService } from '../../../../services/wks-central.service';
import { TranslateService } from '../../../../../../services/translate.service';
import { RessourcesService } from '../../../../../common/services/ressources.service';
import { WksApisService } from '../../../../services/wks-apis.service';

import { CommonMethods } from '../../../../../common/tools/commonMethods';
import { InternationalsFormats, LabelValue, ProfileTax } from '../../../../../../models/data.model';
import { BrandWksModel, MeasurementList } from '../../../../models/wks-common.model';
import { ToolArgs, ToolKeyValue, ToolApiResponse, ToolApiDatasReturn } from '../../../../../../models/common.model';
import { LangLabelModel, ParamLabelsI18n, ParamOtherData, ParamPartsSuppliesModel, ParamRatesModel } from '../../../../models/wks-param.model';

@Component({
  selector: 'mdi-parts-supplies-warehouse',
  templateUrl: './parts-supplies-warehouse.component.html',
  styleUrls: ['./parts-supplies-warehouse.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class PartsSuppliesWarehouseComponent implements OnInit {

  @Input() statutCall: string;
  private readonly onDestroy = new Subject<void>();

  titleCard: string;
  tabSelected: number;
  actionCur: string;
  okToRegister: boolean;
  okToDisplay: boolean;
  displayCreateForm: boolean;
  okToCreatePart: boolean;
  inventoryInProgress: boolean;

  partsListFa: FormArray;
  movementForm: FormGroup;

  movementSession: {
    movementLabel: string; 
    partsList: {
      brandName: string;
      brandRef: string;
      paramLabel: string;
      paramUnit: string;
      movementQty: number;
      paramRate: number;
      sellingPrice: number;
      virtualStock: number;
      stockId: string;
      referentialId: string;
      doCreateReferencial: boolean;
      doCreateStock: boolean;
    }[]
  };
  themeClass: {
    buyPriceLess: boolean;
    sellingPriceLess: boolean;
    sellingPriceToLow: boolean;
  }[];
  numberFormat: {
    locale: string;
    minDecimal: string;
    maxDecimal: string;
    currency: string;
  };
  hiddenPrices: boolean;

  unitsQuantity: LabelValue[];
  supportedLangs: LangSupportedModel[];
  langLabelsList: LangLabelModel[];
  warehouseCur: string;
  wareHousesList: {
    warehouseId: string;
    warehouseRef: string;
    warehouseLabel: string;
  }[];
  suppliersList: {
    supplierId: string;
    supplierName: string;
    supplierCode: string;
  }[];

  brandFilteredList: BrandWksModel[];
  createValues: {
    brandName: string;
    brandRef: string;
    brandLabel: string;
    paramUnit: string;
  };
  idxRowToCreate: number;

  partsRules:  {
    partsLabelRegex: string;
    partsLabelMask: string;
    partsRefRegex: string;
    partsRefMask: string;
  };

  constructor(private fb: FormBuilder, 
                private _wksCentralService: WksCentralService,
                private _userService: UserService,
                private _dialog: MatDialog,
                private _translate: TranslateService,
                private _ressourcesService: RessourcesService,
                private _apiService: WksApisService) { }

  ngOnInit(): void {
    if (this._userService.getUserLogged() === undefined) { 
      return;
    }
    this.initData();
  }

  initData(): void {
    this.titleCard = 'stockMovements';
    this.initAllBooleans();
    this.settingFormats();
    this.initVariables();
    this.loadSuppliers();
    this.loadWareHouses();
    this.loadBrands();
  }
  initAllBooleans(): void {
    this.okToRegister = false;
    this.okToDisplay = false;
    this.hiddenPrices = false;
    this.displayCreateForm = false;
    this.okToCreatePart = false;
    this.inventoryInProgress = false;
  }
  settingFormats(): void {
    if (this._wksCentralService.getWksEntityCur() === undefined) {
      return;
    }
    const otherData = JSON.parse(this._wksCentralService.getWksEntityCur().otherData);
    const intFormat = otherData.internationnalFormat;
    this.numberFormat = {
      locale: this._ressourcesService.getFormat(intFormat, 'numbers', 'locale'),
      minDecimal: this._ressourcesService.getFormat(intFormat, 'numbers', 'minimumFractionDigits'),
      maxDecimal: this._ressourcesService.getFormat(intFormat, 'numbers', 'maximumFractionDigits'),
      currency:  this._ressourcesService.getFormat(intFormat, 'numbers', 'currency'),
    };
    const countryEntity  = this._ressourcesService.getCountry(this._userService.getUserLogged().entitycountry);
    const isMetric = (countryEntity.measurementUnit === 'METRIC');
    const jsonUnits = this._wksCentralService.getUnitsQuantity() as MeasurementList[];
    let measurementLocal = 'METRIC';
    if (!isMetric) {
      measurementLocal = 'NON_METRIC';
    }
    this.unitsQuantity  = [];
    for (const measurLocal of jsonUnits) {
      if (measurLocal.measurement === measurementLocal) {
        const arrayUnits = [...measurLocal.list];
        for (const unitName of arrayUnits) {

          this.unitsQuantity.push({
            label: this._translate.getTranslate(unitName + '_abb'),
            value: unitName
          });
        }
        break;
      }
    }
    this.unitsQuantity.sort((obj1, obj2) => {
      return obj1.label > obj2.label ? 1 : -1;
    });
    this.langLabelsList = [];
    this.supportedLangs = [...SUPPORTED_LANGS];
    for (const langCur of this.supportedLangs) {
      const langTmp: LangLabelModel = {
        langValue: langCur.value,
        langDisplay: this._translate.getTranslate(langCur.display),
        langDirection: langCur.direction,
        langLabel: ''
      } ;
      this.langLabelsList.push(langTmp);
    }
    const partsRulesTmp = otherData.partsRules;
    if (partsRulesTmp !== undefined) {
          // /^[a-zA-Z0-9-\/]*$
          // [^a-zA-Z0-9-\/]
      this.partsRules =  {
        partsLabelRegex: partsRulesTmp.partsLabelRegex,
        partsLabelMask: partsRulesTmp.partsLabelMask,
        partsRefRegex: partsRulesTmp.partsRefRegex,
        partsRefMask: partsRulesTmp.partsRefMask,
      };
    }
  }
  initVariables(): void {
    this.movementSession = {
      movementLabel: '',
      partsList: []
    };
    this.themeClass = [];
    this.wareHousesList = [];
    this.suppliersList =  [];
    this.createValues = {
      brandName: '',
      brandRef: '',
      brandLabel: '',
      paramUnit: ''
    };
    this.idxRowToCreate = -1;
  }
  loadBrands(): any {
    this.brandFilteredList = [];
    
    let brandList: BrandWksModel[];

    return new Promise((resolve, reject) => {
      this._wksCentralService.getWksBrandsList(this._userService.getUserLogged().entity)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(
        data => {
          brandList = data.body;
          for (const brandCur of brandList) {
            this.brandFilteredList.push(brandCur);
          }
          this.brandFilteredList.sort((obj1, obj2) => {
            return obj1.brandName > obj2.brandName ? 1 : -1;
          });

          resolve(brandList);
        }, err => {
          // console.log(err);
          if (err.status === 404) {
            this.brandFilteredList.push({
              stdEntity: this._userService.getUserLogged().entity,
              brandRow: 'brand',
              brandName: ''
            });
          }
          reject(err.status);
        }
      );
    });
  }
  loadSuppliers(): void {
    const apiService = 'list';
    const apiName = 'suppliers';
    const toolArgs: ToolArgs = {
      toolDatas: []
    };
    let testData = {
      key: 'toolCustomerNwsCode',
      value: this._apiService.getApiStockManagement().toolCustomerNwsCode
    };
    toolArgs.toolDatas.push(testData);
    testData = {
      key: 'toolCustomerNwsId',
      value: this._apiService.getApiStockManagement().toolCustomerNwsId
    };
    toolArgs.toolDatas.push(testData);

    this._apiService.requestApis(this._wksCentralService.getWksEntityCur().stdEntity, apiName, apiService, toolArgs )
    .subscribe(
      (data: any) => {

        const toolApiResponse = data.body as ToolApiResponse;
        if (toolApiResponse.statut === 'KO') {

        } else  {
          this.affectResponseSuppliers(toolApiResponse);
        }
      },
      err => {

      }
    );
  }
  affectResponseSuppliers(toolApiResponse: ToolApiResponse): void {

    for (const wareHouseItem of toolApiResponse.toolDatas[0].listValues) {
      const itemWareHouse = {
        supplierId: wareHouseItem.nws_supplier_id,
        supplierName: wareHouseItem.nws_supplier_label,
        supplierCode: wareHouseItem.nws_supplier_code
      };
      this.suppliersList.push(itemWareHouse);
    }
    
  }
  loadWareHouses(): void {
    const apiService = 'warehousesList';
    const apiName = 'stockManagement';
    const toolArgs: ToolArgs = {
      toolDatas: []
    };
    let testData = {
      key: 'toolCustomerNwsCode',
      value: this._apiService.getApiStockManagement().toolCustomerNwsCode
    };
    toolArgs.toolDatas.push(testData);
    testData = {
      key: 'toolCustomerNwsId',
      value: this._apiService.getApiStockManagement().toolCustomerNwsId
    };
    toolArgs.toolDatas.push(testData);

    this._apiService.requestApis(this._wksCentralService.getWksEntityCur().stdEntity, apiName, apiService, toolArgs )
    .subscribe(
      (data: any) => {

        const toolApiResponse = data.body as ToolApiResponse;
        if (toolApiResponse.statut === 'KO') {

        } else  {
          this.affectResponseWharehouses(toolApiResponse);
          this.buildForm();
        }
      },
      err => {

      }
    );
  }
  affectResponseWharehouses(toolApiResponse: ToolApiResponse): void {

    for (const wareHouseItem of toolApiResponse.toolDatas[0].listValues) {
      const itemWareHouse = {
        warehouseId: wareHouseItem.nws_wharehouse_id,
        warehouseRef: wareHouseItem.nws_wharehouse_ref,
        warehouseLabel: wareHouseItem.nws_wharehouse_label
      };
      this.wareHousesList.push(itemWareHouse);
    }
    this.warehouseCur = this.wareHousesList[0].warehouseId;
  }
  buildForm(): void {
    this.movementForm = undefined;
    this.buildArrayFa();
    this.movementForm = new FormGroup({
      movementLabel: new FormControl({value: '', disabled: false}),
      partsListFg: this.partsListFa,
    });
    this.addItemPart();
    this.okToDisplay = true;
    this.initAllSubscribe();
  }
  buildArrayFa(): void {
    // this.argListFa = new FormArray([this.buildItemArg()]);
    this.partsListFa = new FormArray([]);
    this.themeClass = [];
  } 
  buildItemParts(): FormGroup {
    let disabledPrices = false;
    if ( this.actionCur === 'outputStock') {
      disabledPrices = true;
    }
    const formGroupTmp: FormGroup = new FormGroup ({
      brandRef: new FormControl({value: '', disabled: false}),
      paramLabel: new FormControl({value: '', disabled: true}),
      paramUnit: new FormControl({value: '', disabled: false}),
      movementQty: new FormControl({value: '', disabled: false}),
      paramRate: new FormControl({value: '', disabled: disabledPrices}),
      sellingPrice: new FormControl({value: '', disabled: disabledPrices}),
      stockQty: new FormControl({value: '', disabled: true})
    });
    formGroupTmp.setErrors({'incorrect': true});

    return formGroupTmp;
  }
  initAllSubscribe(): void {    
    this.movementForm.valueChanges.subscribe(x => {
      this.okToRegister = false;
        if (this.movementForm.status === 'VALID' && this.partsListFa.status === 'VALID' ) {
          this.okToRegister = true;
        }
    });
    this.partsListFa.valueChanges.subscribe(x => {
      this.okToRegister = false;    
      if (this.movementForm.status === 'VALID' && this.partsListFa.status === 'VALID' ) {
        this.okToRegister = true;

      }
    });
  }
  launchInventory(): void {   
    this.inventoryInProgress = true;
    const mimeApp = this._wksCentralService.getContentMime('csv');
    let todayDate = CommonMethods.formatDate(new Date());
    todayDate = todayDate.replace(/[-]/g, '');
    todayDate = todayDate.replace(/[ ]/g, '');
    todayDate = todayDate.replace(/[:]/g, '');
    const filename = 'inventory_' + todayDate + '.csv';
    this._wksCentralService.inventoryParamPartsSuppliesCSV(this._userService.getUserLogged().entity,  
                            this._userService.getUserLogged().userlang, mimeApp, filename)
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
        // this.dataSource.data = data.body;
        this.inventoryInProgress = false;
        const response = data.body;
        this._wksCentralService.saveToFileSystem(response, filename, mimeApp);
        // console.log(JSON.stringify(response));
      },
      err => {
        console.log(JSON.stringify(err));
        this.inventoryInProgress = false;
        if ((err.status === 500) || (err.status === 404)) {

        }
      }
    );
  }
  changeMovementLabel(value: string): void {   
    this.movementSession.movementLabel = value;
  }
  createPartForm(orig: string, value: any): void {   

    switch (orig) {
      case 'brandName':
        this.createValues.brandName = value;
        this.verifOkToCreatePart();
        break;
      case 'brandRef':
        this.createValues.brandRef = value;
        this.verifOkToCreatePart();
        break;
      case 'brandLabel':
        this.createValues.brandLabel = value;
        this.verifOkToCreatePart();
        break;
      case 'paramUnit':
        this.createValues.paramUnit = value;
        this.verifOkToCreatePart();
        break;
    }
  }
  verifOkToCreatePart(): void {
    this.okToCreatePart = true;
    if (this.createValues.brandName === '') {
      this.okToCreatePart = false;
      return;
    } 
    if (this.createValues.brandRef === '') {
      this.okToCreatePart = false;
      return;
    } 
    if (this.createValues.brandLabel === '') {
      this.okToCreatePart = false;
      return;
    } 
    if (this.createValues.paramUnit === '') {
      this.okToCreatePart = false;
      return;
    } 
  }
  numberAndDotOnly(event: any): boolean {
    return CommonMethods.numberAndDotOnly(event);
  }
  addItemPart(): void {
    this.partsListFa.push(this.buildItemParts());
    this.movementForm.controls.partsListFg = this.partsListFa;
    this.movementSession.partsList.push(this.emptyRowPart());
    this.themeClass.push({
      buyPriceLess: false,
      sellingPriceLess: false,
      sellingPriceToLow: false,
    });
  }
  emptyRowPart(): any {
    return {
      brandName: '',
      brandRef: '',
      paramLabel: '',
      paramUnit: '',
      movementQty: 0.0,
      paramRate: 0.0,
      sellingPrice: 0.0,
      virtualStock: 0.0,
      stockId: '',
      referentialId: '',
      doCreateReferencial: false,
      doCreateStock: false
    };
  }
  addPart(): void {
    this.addItemPart();
  }
  removePart(idxRow: number): void {
    this.partsListFa.removeAt(idxRow);
    this.movementForm.controls.partsListFg = this.partsListFa;
    this.movementSession.partsList.splice(idxRow, 1);
    if (this.movementSession.partsList.length === 0) {
      this.okToRegister = false;
    }
    this.themeClass.splice(idxRow, 1);
  }
  onTabChanged($event: any): void {
    // console.log($event);
    this.hiddenPrices = false;
    this.tabSelected = $event;
    if (this.tabSelected  === 0) {
      this.actionCur = 'inputStock';
    } else {
      this.actionCur = 'outputStock';
      this.hiddenPrices = true;
    }
    this.clearVariables();
    this.buildForm();
  }
  warehouseChoice(value: string): void {
    this.warehouseCur = value;
  }
  formatNumericField(fieldName: string): void {
    CommonMethods.formatNumericField(fieldName, this._translate, 
        ['quantity', this.numberFormat.locale, this.numberFormat.minDecimal, this.numberFormat.maxDecimal]);
  }
  formatCurrencyField(fieldName: string): void {
    CommonMethods.formatNumericField(fieldName, this._translate, 
        ['currency', this.numberFormat.locale, this.numberFormat.currency, 'symbol']);
  }
  handleTab(event: KeyboardEvent, fieldName?: string, idxRow?: number) {
    event.preventDefault(); // Empêche l'ajout automatique
    // console.log('Enter pressé mais sans ajout !');
  }
  handleEnter(event: KeyboardEvent, fieldName?: string, idxRow?: number) {
    event.preventDefault(); // Empêche l'ajout automatique
    if (fieldName) {
      // console.log('enter ' + fieldName);
      const input = document.getElementById(fieldName) as HTMLInputElement;
      this.movementSession.partsList[idxRow].brandRef = input.value;
      this.searchBrandrefReferencial(this.movementSession.partsList[idxRow].brandRef, idxRow);
    }
    // console.log('Enter pressé mais sans ajout !');
  }
  unformatNumericField(fieldName: string): void {
    CommonMethods.unformatNumericField(fieldName);
  }
  setTextField(id: string, newvalue: string) {
    const s = document.getElementById(id);
    s.innerHTML = newvalue;
  }
  onInputChangeBrandRef(fieldName: string, idxRow?: number): void {
    const value = CommonMethods.getTextFieldValue(fieldName);
    const pattern = new RegExp(this.partsRules.partsRefRegex, 'g');
    const newValue = value.replace(pattern, '');
    if (idxRow !== undefined) {
      this.movementSession.partsList[idxRow].brandRef = newValue;
      const itemFG: FormGroup = this.partsListFa.get(idxRow.toString()) as FormGroup;
      itemFG.controls.brandRef.setValue(this.movementSession.partsList[idxRow].brandRef);
    } else  {
      this.createValues.brandRef = newValue;
      CommonMethods.setTextFieldValue(fieldName, newValue);
    }
  }
  onInputChangeParamLabel(fieldName: string, idxRow?: number): void {
    const value = CommonMethods.getTextFieldValue(fieldName);
    const pattern = new RegExp(this.partsRules.partsLabelRegex, 'g');
    const newValue = value.replace(pattern, '');
    if (idxRow !== undefined) {
      this.movementSession.partsList[idxRow].paramLabel = newValue;
      const itemFG: FormGroup = this.partsListFa.get(idxRow.toString()) as FormGroup;
      itemFG.controls.paramLabel.setValue(this.movementSession.partsList[idxRow].paramLabel);
    } else  {
      this.createValues.brandLabel = newValue;
      CommonMethods.setTextFieldValue(fieldName, newValue);
    }
  }
  setMovmentQty(fieldName: string, idxRow: number): void {
    const value = CommonMethods.getNumericFieldValue(fieldName);
    this.movementSession.partsList[idxRow].movementQty = value;
  }
  setParamRate(fieldName: string, idxRow: number): void {
    const value = CommonMethods.getNumericFieldValue(fieldName);
    this.movementSession.partsList[idxRow].paramRate = value;
    
    this.initClassTheme(idxRow);
  }
  setSellingPrice(fieldName: string, idxRow: number): void {
    const value = CommonMethods.getNumericFieldValue(fieldName);
    this.movementSession.partsList[idxRow].sellingPrice = value;
    this.initClassTheme(idxRow);
  }
  initClassTheme(idxRow: number): void {
    this.themeClass[idxRow].sellingPriceLess = false;
    this.themeClass[idxRow].sellingPriceToLow = false;
    this.themeClass[idxRow].buyPriceLess = false;

    if (this.movementSession.partsList[idxRow].paramRate === 0) {
      this.themeClass[idxRow].buyPriceLess = true;
    }

    if (this.movementSession.partsList[idxRow].sellingPrice < this.movementSession.partsList[idxRow].paramRate) {
      this.themeClass[idxRow].sellingPriceLess = false;
      this.themeClass[idxRow].sellingPriceToLow = true;
    }
    if (this.movementSession.partsList[idxRow].sellingPrice === 0) {
      this.themeClass[idxRow].sellingPriceLess = true;
      this.themeClass[idxRow].sellingPriceToLow = false;
    }
  }
  searchBrandrefReferencial(brandRef: string, idxRow: number): void {
    let titleBox: string;
    let messageBox: string;
    this.movementSession.partsList[idxRow].doCreateReferencial = false;
    this.movementSession.partsList[idxRow].doCreateStock = false;
    this._wksCentralService.getParamPartsSupplieSelect(this._userService.getUserLogged().entity, '', '', brandRef, this._userService.getUserLogged().userlang)
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
        // this.dataSource.data = data.body;
        const response = data.body as ParamPartsSuppliesModel[];
        if (response.length > 1) {
          titleBox = this._translate.getTranslate('searching part & supplie');
          messageBox = this._translate.getTranslate('multiple responses');
          this.displayMessageBox(titleBox, messageBox, 'ERROR', 'alertWks', '', null );
        } else {
          this.affectResponseReferential(response[0], idxRow);
          this.readStockByRef(brandRef, idxRow);
        }
        // console.log(JSON.stringify(response));
      },
      err => {
        console.log(err);
        if ((err.status === 500) || (err.status === 404)) {
          titleBox = this._translate.getTranslate('searching part & supplie');
          messageBox = this._translate.getTranslate('no part or supplie found add');
          this.displayMessageBox(titleBox, messageBox, 'ERROR', 'confirmAction', 'confirmCreateReferential', idxRow );

        }
      }
    );
  }
  affectResponseReferential(paramPartsSupplies: ParamPartsSuppliesModel, idxRow: number): void {
    this.movementSession.partsList[idxRow].brandRef = paramPartsSupplies.brandRef;
    this.movementSession.partsList[idxRow].paramUnit = (paramPartsSupplies.paramUnit !== null 
                                                        && paramPartsSupplies.paramUnit !== undefined 
                                                        && paramPartsSupplies.paramUnit !== '' ? paramPartsSupplies.paramUnit : 'unit');
    this.movementSession.partsList[idxRow].brandName = paramPartsSupplies.brandName;
    this.movementSession.partsList[idxRow].referentialId = paramPartsSupplies.id;
    // console.log('user lang ' + this._userService.getUserLogged().userlang);
    for (const labelCur of paramPartsSupplies.langLabels) {
      if (labelCur.labelLang === this._userService.getUserLogged().userlang) {
        this.movementSession.partsList[idxRow].paramLabel = labelCur.labelText; 
        break;
      }
    }
    const itemFG: FormGroup = this.partsListFa.get(idxRow.toString()) as FormGroup;
    itemFG.controls.brandRef.setValue(this.movementSession.partsList[idxRow].brandRef);
    itemFG.controls.paramLabel.setValue(this.movementSession.partsList[idxRow].paramLabel);
    itemFG.controls.paramUnit.setValue(this.movementSession.partsList[idxRow].paramUnit);
  }
  readStockByRef(brandRef: string, idxRow: number): void {
    const apiService = 'stockByRef';
    const apiName = 'stockManagement';
    const toolArgs: ToolArgs = {
      toolDatas: []
    };
    let testData = {
      key: 'toolCustomerNwsCode',
      value: this._apiService.getApiStockManagement().toolCustomerNwsCode
    };
    toolArgs.toolDatas.push(testData);
    testData = {
      key: 'toolCustomerNwsId',
      value: this._apiService.getApiStockManagement().toolCustomerNwsId
    };
    toolArgs.toolDatas.push(testData);

    const toolKeyValue: ToolKeyValue = {
      key: 'brandRef',
      value: brandRef,
      listValues: undefined,
      arrayValues: undefined
    };
    const jsonTmp = JSON.parse(JSON.stringify(toolKeyValue));
    toolArgs.toolDatas.push(jsonTmp);
    this._apiService.requestApis(this._wksCentralService.getWksEntityCur().stdEntity, apiName, apiService, toolArgs )
    .subscribe(
      (data: any) => {
        const toolApiResponse = data.body as ToolApiResponse;
        return this.affectResponseStockByRef(toolApiResponse, idxRow);
      },
      err => {
        console.log(' readStockByRef : ' + JSON.stringify(err));
        return undefined;
      }
    );
  }
  affectResponseStockByRef(toolApiResponse: ToolApiResponse, idxRow: number ): void {
    if (toolApiResponse.statut === 'KO') {
      this.movementSession.partsList[idxRow].doCreateStock = true;
      const itemResponse = toolApiResponse.toolDatas[0];
      if (itemResponse.value === '404') {
        toolApiResponse.toolDatas.push({
          key: 'nws_stock_qty',
          value: '0.0'
        });
        toolApiResponse.toolDatas.push({
          key: 'nws_stock_price',
          value: '0.0'
        });
      }
    } else {
      this.movementSession.partsList[idxRow].doCreateStock = false;
    }
    for (const itemCur of toolApiResponse.toolDatas) {
      if (itemCur.key === 'nws_stock_qty') {
        this.movementSession.partsList[idxRow].virtualStock = parseFloat(itemCur.value);
      }
      if (itemCur.key === 'nws_stock_id') {
        this.movementSession.partsList[idxRow].stockId = itemCur.value;
      }
      if (itemCur.key === 'nws_stock_price') {
        this.movementSession.partsList[idxRow].sellingPrice = parseFloat(itemCur.value);
      }
      if (itemCur.key === 'nws_stock_pmp') {
        this.movementSession.partsList[idxRow].paramRate = parseFloat(itemCur.value);
      }
    }
    const itemFG: FormGroup = this.partsListFa.get(idxRow.toString()) as FormGroup;
    itemFG.controls.stockQty.setValue(this.movementSession.partsList[idxRow].virtualStock);
    itemFG.controls.sellingPrice.setValue(this.movementSession.partsList[idxRow].sellingPrice);
    itemFG.controls.paramRate.setValue(this.movementSession.partsList[idxRow].paramRate);
    this.formatCurrencyField('sellingPrice_' + idxRow);
    this.formatNumericField('stockQty_' + idxRow);
    this.formatCurrencyField('paramRate_' + idxRow);
    this.initClassTheme(idxRow);
    
  }
  addProduct(): void {
    this.displayCreateForm = false;
    // this.addItemPart();
    const idxRow = this.idxRowToCreate;
    this.movementSession.partsList[idxRow].brandName = this.createValues.brandName;
    this.movementSession.partsList[idxRow].brandRef = this.createValues.brandRef;
    this.movementSession.partsList[idxRow].paramLabel = this.createValues.brandLabel;
    this.movementSession.partsList[idxRow].paramUnit = this.createValues.paramUnit;
    
    const itemFG: FormGroup = this.partsListFa.get(idxRow.toString()) as FormGroup;
    itemFG.controls.brandRef.setValue(this.movementSession.partsList[idxRow].brandRef);
    itemFG.controls.paramLabel.setValue(this.movementSession.partsList[idxRow].paramLabel);
    itemFG.controls.paramUnit.setValue(this.movementSession.partsList[idxRow].paramUnit);
  }

  createStockProduct(idxRow: number): void {

    const apiService = 'create';
    const apiName = 'stockManagement';
    const toolArgs: ToolArgs = {
      toolDatas: []
    };
    let testData = {
      key: 'toolCustomerNwsCode',
      value: this._apiService.getApiStockManagement().toolCustomerNwsCode
    };
    toolArgs.toolDatas.push(testData);
    testData = {
      key: 'toolCustomerNwsId',
      value: this._apiService.getApiStockManagement().toolCustomerNwsId
    };
    toolArgs.toolDatas.push(testData);

    let toolKeyValue: ToolKeyValue = {
      key: 'nws_label',
      value: this.movementSession.partsList[idxRow].paramLabel,
      listValues: undefined,
      arrayValues: undefined
    };
    let jsonTmp = JSON.parse(JSON.stringify(toolKeyValue));
    toolArgs.toolDatas.push(jsonTmp);

    toolKeyValue = {
      key: 'nws_id',
      value: this.movementSession.partsList[idxRow].referentialId,
      listValues: undefined,
      arrayValues: undefined
    };
    jsonTmp = JSON.parse(JSON.stringify(toolKeyValue));
    toolArgs.toolDatas.push(jsonTmp);

    toolKeyValue = {
      key: 'brandRef',
      value: this.movementSession.partsList[idxRow].brandRef,
      listValues: undefined,
      arrayValues: undefined
    };
    jsonTmp = JSON.parse(JSON.stringify(toolKeyValue));
    toolArgs.toolDatas.push(jsonTmp);

    if (this.movementSession.partsList[idxRow].paramRate !== undefined) {
      toolKeyValue = {
        key: 'nws_price',
        value: '' + this.movementSession.partsList[idxRow].paramRate.toString(),
        listValues: undefined,
        arrayValues: undefined
      };
      jsonTmp = JSON.parse(JSON.stringify(toolKeyValue));
      toolArgs.toolDatas.push(jsonTmp);
    }

    this._apiService.requestApis(this._wksCentralService.getWksEntityCur().stdEntity, apiName, apiService, toolArgs )
    .subscribe(
      (data: any) => {

        const toolApiResponse = data.body as ToolApiResponse;
        if (toolApiResponse.statut === 'KO') {
          const titleBox = this._translate.getTranslate('validate part & supplie');
          const messageBox = this._translate.instant('stock create', [this.movementSession.partsList[idxRow].brandRef]);
          this.displayMessageBox(titleBox, messageBox, 'ERROR', 'alertWks', '', null );
        } else  {
          const nwsId =  toolApiResponse.toolDatas[0].value;
          this.movementSession.partsList[idxRow].stockId = nwsId;
          this.movementSession.partsList[idxRow].virtualStock = 0;
          this.movementSession.partsList[idxRow].doCreateStock = false;
          this.registerStockUpdate(idxRow);
        }
      },
      err => {

      }
    );

  }
  fillModel(idxRow: number): ParamPartsSuppliesModel {
    
    const stdEntity = this._wksCentralService.getWksEntityCur().stdEntity;
    const userLogged = this._userService.getUserLogged().username;
    const userLang  = this._userService.getUserLogged().userlang;

    const paramOtherData: ParamOtherData = {
      stockRefExists: true,
      accountingBuy: '0.0',
      accountingSale: '0.0'
    };
    const paramLabelsI18n: ParamLabelsI18n[] = [];
    // parampartssupplies
    const paramLabelItem: ParamLabelsI18n = {
      stdEntity: stdEntity,
      labelModule: 'parampartssupplies',
      labelObject: undefined,
      labelLang: userLang,
      labelText: this.movementSession.partsList[idxRow].paramLabel,
    };
    paramLabelsI18n.push(paramLabelItem);
    const paramPartsSupplies: ParamPartsSuppliesModel = {
        id: undefined,
        stdEntity: stdEntity,
        paramType: 'part',
        brandName: this.movementSession.partsList[idxRow].brandName,
        brandRef: this.movementSession.partsList[idxRow].brandRef,
        brandBarcode: '',
        paramUnit: this.movementSession.partsList[idxRow].paramUnit !== undefined  ?
                                this.movementSession.partsList[idxRow].paramUnit : 'unit',
        paramCurrency: undefined,
        otherData: JSON.stringify(paramOtherData),
        langLabels: paramLabelsI18n,
        userLang: userLang,
        paramLabel: this.movementSession.partsList[idxRow].paramLabel
    };
    const paramRatesTmp: ParamRatesModel = {
      stdEntity: stdEntity,
      paramScaleName: 'PUBLIC',
      paramRate: 0,
      paramCurrency: this.numberFormat.currency,
      otherData: ''
    };
    const paramRatesLocal: ParamRatesModel[] = [];
    paramRatesLocal.push(paramRatesTmp);
    paramPartsSupplies.paramRates = paramRatesLocal;
    return paramPartsSupplies;
  }
  registerMovements(): void {
    
    for (let idxRow = 0; idxRow < this.movementSession.partsList.length; idxRow++) {
      if (this.movementSession.partsList[idxRow].doCreateReferencial) {
        const paramPartsSuppliesCard = this.fillModel(idxRow);
        this._wksCentralService.saveParamPartSupplie(paramPartsSuppliesCard, 'create')
        .subscribe(
          (data: any) => {
            const paramPartsSupplies = data.body as ParamPartsSuppliesModel;
            this.movementSession.partsList[idxRow].doCreateReferencial = false;
            this.movementSession.partsList[idxRow].referentialId = paramPartsSupplies.id;
            this.createStockProduct(idxRow);
          },
          err => {
            console.log('createProduct error' + JSON.stringify(err));
            const titleBox = this._translate.getTranslate('validate part & supplie');
            const messageBox = this._translate.instant('referential create', [this.movementSession.partsList[idxRow].brandRef]);
            
            this.displayMessageBox(titleBox, messageBox, 'ERROR', 'alertWks', '', null );
          }
        );
      } else if (this.movementSession.partsList[idxRow].doCreateStock) {
        this.createStockProduct(idxRow);
      } else {
        this.registerStockUpdate(idxRow);
      }
    }
    

  }
  registerStockUpdate(idxRow: number): void {
    const apiName = 'stockManagement';
    const apiService = 'update';
    
    const toolArgs: ToolArgs = {
      toolDatas: []
    };
    let testData = {
      key: 'toolCustomerNwsCode',
      value: this._apiService.getApiStockManagement().toolCustomerNwsCode
    };
    toolArgs.toolDatas.push(testData);
    testData = {
      key: 'toolCustomerNwsId',
      value: this._apiService.getApiStockManagement().toolCustomerNwsId
    };
    toolArgs.toolDatas.push(testData);

    let toolKeyValue: ToolKeyValue = {
      key: 'nws_stock_id',
      value: this.movementSession.partsList[idxRow].stockId,
      listValues: undefined,
      arrayValues: undefined
    };
    let jsonTmp = JSON.parse(JSON.stringify(toolKeyValue));
    toolArgs.toolDatas.push(jsonTmp);

    toolKeyValue = {
      key: 'nws_selling_price',
      value: '' + this.movementSession.partsList[idxRow].sellingPrice,
      listValues: undefined,
      arrayValues: undefined
    };
    jsonTmp = JSON.parse(JSON.stringify(toolKeyValue));
    toolArgs.toolDatas.push(jsonTmp);

    toolKeyValue = {
      key: 'nws_label',
      value: this.movementSession.partsList[idxRow].paramLabel,
      listValues: undefined,
      arrayValues: undefined
    };
    jsonTmp = JSON.parse(JSON.stringify(toolKeyValue));
    toolArgs.toolDatas.push(jsonTmp);

    toolKeyValue = {
      key: 'nws_unit',
      value: this.movementSession.partsList[idxRow].paramUnit,
      listValues: undefined,
      arrayValues: undefined
    };
    jsonTmp = JSON.parse(JSON.stringify(toolKeyValue));
    toolArgs.toolDatas.push(jsonTmp);

    this._apiService.requestApis(this._wksCentralService.getWksEntityCur().stdEntity, apiName, apiService, toolArgs )
    .subscribe(
      (data: any) => {
        const toolApiResponse = data.body as ToolApiResponse;
        if (toolApiResponse.statut === 'KO') {
          const titleBox = this._translate.getTranslate('validate part & supplie');
          const messageBox = this._translate.instant('stock update', [this.movementSession.partsList[idxRow].brandRef]);
          this.displayMessageBox(titleBox, messageBox, 'ERROR', 'alertWks', '', null );
        } else  {
          this.registerStockMovements(idxRow);
        }
      },
      err => {

      }
    );
  }
  registerStockMovements(idxRow: number): void {
    const apiName = 'stockManagement';
    
    let apiService = 'movementAdd';
    let movementStatut = 'nws_add_qty';
    const movementStock  = this.movementSession.partsList[idxRow].movementQty;
    if (this.actionCur === 'outputStock') {
      apiService = 'movementLess';
      // movementStock = this.movementSession.partsList[idxRow].movementQty * -1;
      movementStatut = 'nws_less_qty';
    }
    
    const toolArgs: ToolArgs = {
      toolDatas: []
    };
    let testData = {
      key: 'toolCustomerNwsCode',
      value: this._apiService.getApiStockManagement().toolCustomerNwsCode
    };
    toolArgs.toolDatas.push(testData);
    testData = {
      key: 'toolCustomerNwsId',
      value: this._apiService.getApiStockManagement().toolCustomerNwsId
    };
    toolArgs.toolDatas.push(testData);

    let toolKeyValue: ToolKeyValue = {
      key: 'product_id',
      value: this.movementSession.partsList[idxRow].stockId,
      listValues: undefined,
      arrayValues: undefined
    };
    let jsonTmp = JSON.parse(JSON.stringify(toolKeyValue));
    toolArgs.toolDatas.push(jsonTmp);

    toolKeyValue = {
      key: 'warehouse_id',
      value: this.warehouseCur,
      listValues: undefined,
      arrayValues: undefined
    };
    jsonTmp = JSON.parse(JSON.stringify(toolKeyValue));
    toolArgs.toolDatas.push(jsonTmp);

    toolKeyValue = {
      key: 'movementcode',
      value: this._userService.getUserLogged().username,
      listValues: undefined,
      arrayValues: undefined
    };
    jsonTmp = JSON.parse(JSON.stringify(toolKeyValue));
    toolArgs.toolDatas.push(jsonTmp);

    toolKeyValue = {
      key: 'movementlabel',
      value: this.movementSession.movementLabel,
      listValues: undefined,
      arrayValues: undefined
    };
    jsonTmp = JSON.parse(JSON.stringify(toolKeyValue));
    toolArgs.toolDatas.push(jsonTmp);

    toolKeyValue = {
      key: movementStatut,
      value: '' + movementStock,
      listValues: undefined,
      arrayValues: undefined
    };
    jsonTmp = JSON.parse(JSON.stringify(toolKeyValue));
    toolArgs.toolDatas.push(jsonTmp);

    toolKeyValue = {
      key: 'nws_param_rate',
      value: '' + this.movementSession.partsList[idxRow].paramRate,
      listValues: undefined,
      arrayValues: undefined
    };
    jsonTmp = JSON.parse(JSON.stringify(toolKeyValue));
    toolArgs.toolDatas.push(jsonTmp);

    if (apiService === 'movementAdd') { // updatePurchasePrice
      const buyPrice = (this.movementSession.partsList[idxRow].paramRate * this.movementSession.partsList[idxRow].movementQty);
      toolKeyValue = {
        key: 'nws_buyprice',
        value: '' + buyPrice,
        listValues: undefined,
        arrayValues: undefined
      };
      jsonTmp = JSON.parse(JSON.stringify(toolKeyValue));
      toolArgs.toolDatas.push(jsonTmp);

      toolKeyValue = {
        key: 'nws_stock_id',
        value: this.movementSession.partsList[idxRow].stockId,
        listValues: undefined,
        arrayValues: undefined
      };
      jsonTmp = JSON.parse(JSON.stringify(toolKeyValue));
      toolArgs.toolDatas.push(jsonTmp);
      // nws_min_qty
      toolKeyValue = {
        key: 'nws_min_qty',
        value: '' + this.movementSession.partsList[idxRow].movementQty,
        listValues: undefined,
        arrayValues: undefined
      };
      jsonTmp = JSON.parse(JSON.stringify(toolKeyValue));
      toolArgs.toolDatas.push(jsonTmp);
      // nws_supplier_stock_id
      let supplierCur = null;
      for (const supplierItem of this.suppliersList) {
        if (supplierItem.supplierName === this.movementSession.partsList[idxRow].brandName) {
          supplierCur = supplierItem;
          break;
        }
      }
      toolKeyValue = {
        key: 'nws_supplier_stock_id',
        value: '' + supplierCur.supplierId,
        listValues: undefined,
        arrayValues: undefined
      };
      jsonTmp = JSON.parse(JSON.stringify(toolKeyValue));
      toolArgs.toolDatas.push(jsonTmp);

      // nws_stock_ref
      toolKeyValue = {
        key: 'nws_stock_ref',
        value: this.movementSession.partsList[idxRow].brandRef,
        listValues: undefined,
        arrayValues: undefined
      };
      jsonTmp = JSON.parse(JSON.stringify(toolKeyValue));
      toolArgs.toolDatas.push(jsonTmp);
    }

    this._apiService.requestApis(this._wksCentralService.getWksEntityCur().stdEntity, apiName, apiService, toolArgs )
    .subscribe(
      (data: any) => {
        const toolApiResponse = data.body as ToolApiResponse;
        if (toolApiResponse.statut === 'KO') {
          const titleBox = this._translate.getTranslate('validate part & supplie');
          const messageBox = this._translate.instant('stock movement', [this.movementSession.partsList[idxRow].brandRef]);
          this.displayMessageBox(titleBox, messageBox, 'ERROR', 'alertWks', '', null );
        } else  {
          this.clearListMovements();
        }
      },
      err => {

      }
    );
  }
  clearListMovements(): void {
    this.clearVariables();
    this.partsListFa = new FormArray([]);
    this.addItemPart();
    this.okToRegister = false;
  }
  clearVariables(): void {
    this.movementSession.movementLabel = '';
    this.movementSession.partsList = [];
    this.okToRegister = false;
  }
  doCancel(): void {
    if  (this.statutCall === 'sidenav') {
      this._wksCentralService.onRegisterValid.emit('cancelDisplayStockMovements');
      return;
    }
  }
cancelAddProduct(): void  {
  this.movementSession.partsList[this.idxRowToCreate].doCreateReferencial = false;
  this.movementSession.partsList[this.idxRowToCreate].doCreateStock = false;
  this.displayCreateForm = false;
  this.idxRowToCreate = -1;
  this.createValues.brandRef = '';
}
displayMessageBox(_titleBox: string, _messageBox: string, _messageType: string, _typeDialog: string, _actionCur: string, dataCur: any): void {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      id: 1,
      title: _titleBox,
      typeDialog: _typeDialog,
      panelClass: 'stdTheme',
      contentMessage: _messageBox,
      data1: '',
      data2: '',
      messageType: _messageType

      };

    const dialogRef = this._dialog.open(ModalCommonComponent, dialogConfig);

    dialogRef.afterClosed()
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
        // console.log('Dialog output:', data);
        if (( data === 'okAction') && _actionCur === 'confirmCreateReferential') {
          this.movementSession.partsList[dataCur].doCreateReferencial = true;
          this.movementSession.partsList[dataCur].doCreateStock = true;
          this.idxRowToCreate = dataCur;
          this.createValues.brandRef = this.movementSession.partsList[dataCur].brandRef;
          this.displayCreateForm = true;
        }
      });

  }
}
