import * as moment from 'moment';
import * as crypto from 'crypto-js';
import * as alasql from 'alasql';
import { saveAs } from 'file-saver';
// import { parse, unparse } from 'papaparse';
import { Papa } from 'ngx-papaparse';
import { TranslateService } from '../../../services/translate.service';
import { AlertType, ColumnsHeaderUpload } from '../../../models/common.model';
import * as numeral from 'numeral';
import 'numeral/locales';
import { environment } from '../../../../environments/environment';
import { AesUtil } from '../tools/aesUtil';

export class CommonMethods {
/* JLG TESTS
    const jsonObj = {
        members: [
              {
                host: 'hostName',
                viewers:
                {
                    user1: 'value1',
                    user2: 'value2',
                    user3: 'value3'
                }
              }
            ]
      };

      let i;
      // adding properties on member 0
      for(i = 4; i <= 8; i++){
        const newUser = 'user' + i;
        const newValue = 'value' + i;
        jsonObj.members[0].viewers[newUser] = newValue ;

      }
      jsonObj.members[1] = {host : 'hostname2',
                          viewers: {  user1: 'value1', user2: '', user3: ''}
                        } ;
      const membre = Object.create(jsonObj.members[1]); // cloning firstmember
      membre.host = 'hostname3';
      membre.viewers = {  user1: 'value1', user2: '', user3: ''};
      // jsonObj = {...jsonObj[1], members[host]: 'hostName1'};
      jsonObj.members[2] = membre; // adding second membre

      console.log(jsonObj);
      // delete jsonObj.members[0];// null at deleted position : KO
      jsonObj.members.splice(0, 1); // delete member index 0 alone properly, for delete severals rows replace 1 by the number to delete.
      console.log(jsonObj);
      let jsonTmp = {host : 'hostname1',
                          viewers: {  user1: 'value1', user2: '', user3: ''}
                        };
      // jsonObj.members[0]  = membre;
      jsonObj.members.splice(0, 0, jsonTmp); // add one node at index 0
      console.log(jsonObj); // null a la place de l'entrée 0
      jsonTmp = {host : 'hostname5',
                  viewers: {  user1: 'value5', user2: '', user3: ''}
                };
      jsonObj.members.push(jsonTmp); // ass one node at end of list
*/
/**
 * jsonObj.members.push(jsonTmp); // ass one node at end of list
 * @param _jsonTarget
 * @param _atLast
 * @param _jsonString
 * @param _index
 */
  public static jsonAddNode( _jsonTarget: any, _atLast: boolean, _jsonString: string, _index: number) {
    const jsonArg = JSON.parse(_jsonString); // convert string to json
    if (_atLast) {
      _jsonTarget.push(jsonArg);
    } else {
      _jsonTarget.splice(_index, 0, jsonArg);
    }
    return _jsonTarget;
  }
  /**
   * jsonObj.members.splice(0, 1); // delete member index 0 alone properly, for delete severals rows replace 1 by the number to delete.
   * delete jsonObj.members[0]; // if node : null at deleted position :
   * @param _jsonTarget
   * @param _jsonKey
   * @param _start
   * @param _end
   */
  public static jsonDelete(_jsonTarget: any, _jsonKey: string, _start: number, _end: number) {
      if (!_jsonKey) { // delete node
        _jsonTarget.splice(_start, _end);
      } else { // delete key
        for (let i = 0; i < _jsonTarget.length; i++) {
          delete _jsonTarget[i][_jsonKey];
      }
      return _jsonTarget;
    }
  }
  /**
   * Update key on path Target
   * @param _jsonTarget
   * @param _jsonString
   */
  public static jsonUpdateKey(_jsonTarget: any, _jsonString: string) {
    const jsonArg = JSON.parse(_jsonString);
    _jsonTarget = jsonArg;
  }
  /**
   * adding propertie (key/value) on target
   * @param _jsonTarget
   * @param _jsonKey
   * @param _jsonValue
   * @param _index
   */
  public static jsonAddKey(_jsonTarget: any, _jsonKey: string, _jsonValue: string, _index: number) {

    if (_index < 0) {
      _jsonTarget[_jsonKey] = _jsonValue ;
    } else {
      _jsonTarget[_index][_jsonKey] = _jsonValue ;
    }
    return _jsonTarget;
  }
  public static getAlertType(messageType: string) {

    switch (messageType) {
        case 'SUCCESS':
            return AlertType.Success;
        case 'ERROR':
            return AlertType.Error;
        case 'INFO':
            return AlertType.Info;
        case 'IMPORTANT':
            return AlertType.Important;
        case 'WARNING':
            return AlertType.Warning;
    }
  }
  public static controlField (_content: string, _regex: string): any {
    const pattern = new RegExp(_regex, 'i');
    // let testMatch: any;
    return _content.match(pattern);
  }
/**
 * test email format
 * @param _email
 */
  public static controlEmail (_email: string): boolean {
    const reg = new RegExp('^[a-z0-9]+([_|\.|-]{1}[a-z0-9]+)*@[a-z0-9]+([_|\.|-]{1}[a-z0-9]+)*[\.]{1}[a-z]{2,6}$', 'i');
    return(reg.test(_email));
  }

  // https://stackoverflow.com/questions/41432896/cryptojs-aes-encryption-and-java-aes-decryption
  public static encryptAES(_textToEncrypt: string, _secretKey: string): string {

    const encrypted = crypto.AES.encrypt(_textToEncrypt, _secretKey);
    const encryptedString = encrypted.toString();
    // console.log('Cipher text: ' + encryptedString);
    return encryptedString;
  }
  
  public static decryptAES(_texteToDecrypt: string, _secretKey: string): string {
    return crypto.AES.decrypt(_texteToDecrypt, _secretKey).toString();
 }
  public static controlForbiddenWords (_textRequest: string, _forbiddenWords: string): boolean {
    const reg = new RegExp('(?i)(\W|^)(' + _forbiddenWords + ' )(\W|$)', 'i');
    return(reg.test(_textRequest));
  }

  // https://bobbyhadz.com/blog/javascript-format-date-yyyy-mm-dd-hh-mm-ss
  public static  padTo2Digits(num: number): string {
    return num.toString().padStart(2, '0');
  }
  public static  formatDate(date: any): string {
    return (
      [
        date.getFullYear().toString(),
        this.padTo2Digits(date.getMonth() + 1),
        this.padTo2Digits(date.getDate()),
      ].join('-') +
      ' ' +
      [
        this.padTo2Digits(date.getHours()),
        this.padTo2Digits(date.getMinutes()),
        this.padTo2Digits(date.getSeconds()),
      ].join(':')
    );
  }

  public static dateToTZ(_dateCur, _formatTZ): string {
    return moment(_dateCur).format(_formatTZ);
  }  
  public static dateTimeToString(_dateCur: any): string {
   //   "\"2021-10-16T14:31:03.000+0000\""
    let dateCur = JSON.stringify(_dateCur);
    const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/ ;
    dateCur = dateCur.replace(/\\/g, '');
    dateCur = dateCur.replace(/"/g, '');
    const dateRegex = dateCur.match(regex);
    let dateString = dateRegex[0];
    dateString = dateString.replace('T', 'T');

    return dateString;
  }

  public static dateToString(_bdDate: any , _formatOrig: string, _bddFormat: string): string {
    let displayDate = null;
    let parsingUnix = null;
    const myDate = new Date();
    const offSet = myDate.getTimezoneOffset() * -1; // difference between UTC and local time zone
    // const offSet = 0;
    // const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // console.log(tz);
    /*
    if (_formatOrig === 'unixM') {

      const regex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})(?:\.(\d{3}))?(Z|[\+-]\d{4}(?::\d{2})?)$/ ;
      const stringCur = _bdDate as string;
      parsingUnix = stringCur.match(regex);

      myDate.setUTCFullYear(parsingUnix[1]);
      myDate.setUTCMonth(parsingUnix[2]);
      myDate.setUTCDate(parsingUnix[3]);
      myDate.setUTCHours(parsingUnix[4]);
      myDate.setUTCMinutes(parsingUnix[5]); // timezone offset set here, after hours
      myDate.setUTCSeconds(parsingUnix[6]);
      myDate.setUTCMilliseconds(parsingUnix[7]);
      displayDate = moment(myDate).format(_bddFormat);
    }
*/
    switch (_formatOrig) {
      case 'unixM': {
        const regex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})(?:\.(\d{3}))?(Z|[\+-]\d{4}(?::\d{2})?)$/ ;

        const bdDate = _bdDate as string;
        const stringCur = String(bdDate);
        parsingUnix = stringCur.match(regex);
        const monthUTC = Number.parseInt(parsingUnix[2], 0) - 1 ;
        myDate.setUTCFullYear(parsingUnix[1]);
        myDate.setUTCMonth(monthUTC); // The setUTCMonth() method sets the month (from 0 to 11), according to universal time
        myDate.setUTCDate(parsingUnix[3]);
        myDate.setUTCHours(parsingUnix[4]);
        myDate.setUTCMinutes(parsingUnix[5]); 
        myDate.setUTCSeconds(parsingUnix[6]);
        myDate.setUTCMilliseconds(parsingUnix[7]); 

        // displayDate = moment(myDate).add(offSet, 'minutes').format(_bddFormat); // UTCDate corrige la date zulu en local
        displayDate = moment(myDate).format(_bddFormat);
        break;
      }
      // ^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})(?:\.(\d{3}))$
      case 'unixMnoZ': {
        const regex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})(?:\.(\d{3}))$/ ;

        const bdDate = _bdDate as string;
        const stringCur = String(bdDate);
        parsingUnix = stringCur.match(regex);
        const monthUTC = Number.parseInt(parsingUnix[2], 0) - 1 ;
        
        myDate.setFullYear(parsingUnix[1]);
        myDate.setMonth(monthUTC); // The setUTCMonth() method sets the month (from 0 to 11), according to universal time
        myDate.setDate(parsingUnix[3]);
        myDate.setHours(parsingUnix[4]);
        myDate.setMinutes(parsingUnix[5]); 
        myDate.setSeconds(parsingUnix[6]);
        myDate.setMilliseconds(parsingUnix[7]); 

        // displayDate = moment(myDate).add(offSet, 'minutes').format(_bddFormat); // UTCDate corrige la date zulu en local
        displayDate = moment(myDate).format(_bddFormat);
        break;
      }
      case 'json': {
        const regex = /^(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})(?:\.(\d{3}))$/ ;
        const stringCur = _bdDate as string;
        parsingUnix = stringCur.match(regex);
        const monthUTC = Number.parseInt(parsingUnix[2], 0) - 1 ;
        myDate.setUTCFullYear(parsingUnix[1]);
        myDate.setUTCMonth(monthUTC); // The setUTCMonth() method sets the month (from 0 to 11), according to universal time
        myDate.setUTCDate(parsingUnix[3]);
        myDate.setUTCHours(parsingUnix[4]);
        myDate.setUTCMinutes(parsingUnix[5]); // timezone offset set here, after hours
        myDate.setUTCSeconds(parsingUnix[6]);
        myDate.setUTCMilliseconds(parsingUnix[7]);
        // displayDate = moment(myDate).add(offSet, 'minutes').format(_bddFormat);
        displayDate = moment(myDate).format(_bddFormat);
        break;
      }
      case 'json1': {
        const regex = /^(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})(?:\.(\d{3}))$/ ;
        const stringCur = _bdDate as string;
        _bddFormat = _bddFormat.replace('dd', 'DD');
        _bddFormat = _bddFormat.replace('yyyy', 'YYYY');
        // displayDate = moment(myDate).add(offSet, 'minutes').format(_bddFormat);
        displayDate = moment(stringCur).format(_bddFormat);
        break;
      }
      case 'json2': {
        const regex = /^(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})$/ ;
        const stringCur = _bdDate as string;
        parsingUnix = stringCur.match(regex);
        const monthUTC = Number.parseInt(parsingUnix[2], 0) - 1 ;
        myDate.setUTCFullYear(parsingUnix[1]);
        myDate.setUTCMonth(monthUTC); // The setUTCMonth() method sets the month (from 0 to 11), according to universal time
        myDate.setUTCDate(parsingUnix[3]);
        myDate.setUTCHours(parsingUnix[4]);
        myDate.setUTCMinutes(parsingUnix[5]); // timezone offset set here, after hours
        myDate.setUTCSeconds(parsingUnix[6]);
        // displayDate = moment(myDate).add(offSet, 'minutes').format(_bddFormat);
        displayDate = moment(myDate).format(_bddFormat);
        break;
      }
      case 'date': {
        displayDate = moment.unix(_bdDate / 1000).format(_bddFormat);
        break;
      }
      case 'hour': {
        displayDate = moment.unix(_bdDate / 1000).format(_bddFormat);
        break;
      }
      default: {
        displayDate = moment.unix(_bdDate / 1000).format(_bddFormat);
        break;
      }
    }
    return displayDate;

  }
  public static dateMomentForBdd (_bdDate: any , _bddFormat: string): string {

    if ((_bddFormat === undefined) || (_bddFormat === null)) {
      _bddFormat = `${environment.fmtDateTimeBdd}`;
    }
    if ((_bdDate === undefined) || (_bdDate === null)) {
      _bdDate = moment();
    }
    /*
    const birthDayValue = _bdDate;
    const bdDateLocal = {year: 0, month: 0, day: 0} as YMDfmt;
    bdDateLocal.day = birthDayValue.date.day;
    bdDateLocal.month = birthDayValue.date.month;
    bdDateLocal.year = birthDayValue.date.year;
    */
   const finalDate = moment.unix(_bdDate / 1000).format(_bddFormat);
   // console.log(finalDate);
    const finalMoment = moment(_bdDate, _bddFormat);
    // const finalDate = moment(finalMoment).format(_bddFormat);
    /*let finalDate = _bddFormat;
    const search = 'DD';
    finalDate = finalDate.replace(new RegExp('DD', 'g'), _bdDate.date.day.toString());
    finalDate = finalDate.replace(new RegExp('MM', 'g'), _bdDate.date.month.toString());
    finalDate = finalDate.replace(new RegExp('YYYY', 'g'), _bdDate.date.year.toString());*/
    return finalDate;
  }
  public static escapeRegExpSymbols(text: string): string {
    // /^[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/
    // return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    return text.replace(/^[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/g, '\\$&');
  }
  
  public static escapeRegExpPonctuation(text: string): string {
    return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  }
  
  public static listToArray(_fullString: string, _separator: string): string[]  {
    let fullArray: string[] = [];
    if (_fullString !== undefined) {
      if (_fullString.indexOf(_separator) === -1) {
        fullArray.push(_fullString);
      } else {
        fullArray = _fullString.split(_separator);
      }
    }
    return fullArray;
  }
  public static arrayStringToString(_array: string[], _separator: string ): string {
      let fullString = '';
      if (_array !== undefined) {
        for (const unitString of _array) {
          if (fullString === '') {
            fullString = fullString.concat( unitString);
          } else {
            fullString = fullString.concat( _separator + unitString);
          }
        }
      }
      return fullString;
  }
  public static arrayValueToString(_array: any[], _separator: string ): string {
    let fullString = '';
    if (_array !== undefined) {
      for (const unitString of _array) {
        if (fullString === '') {
          fullString = fullString.concat( unitString.value);
        } else {
          fullString = fullString.concat( _separator + unitString.value);
        }
      }
    }
    return fullString;
}
  public static jsonToArray(_structureInput: any): any[] {
    const localString = JSON.stringify(_structureInput);
    // const usersJson: any[] = Array.of(this.conditions);
    const parsed = JSON.parse(localString);
    const arr = [];
    for (const x of parsed) {
      arr.push(x);
    }
    return arr;
  }
  public static jsonPropToArray(_structureInput: any, _propertyName): any[] {
    const localString = JSON.stringify(_structureInput);
    // const usersJson: any[] = Array.of(this.conditions);
    const parsed = JSON.parse(localString);
    const arr = [];
    for (const x of parsed) {
      arr.push(x[_propertyName]);
    }
    return arr;
  }
  public static filterArray(itemList: any, searchKeyword: string, isPresent: boolean) {
    if (!itemList) {
      return [];
    }
    if (!searchKeyword) {
      return itemList;
    }
    const filteredList = [];
    
    if (itemList.length > 0) {
      searchKeyword = searchKeyword.toLowerCase();
      itemList.forEach(item => {
        // Object.values(item) => gives the list of all the property values of the 'item' object
        const propValueList = Object.values(item);
        let isFound = false;
        for (let i = 0; i < propValueList.length; i++) {
          if (propValueList[i]) {
            if (isPresent && propValueList[i].toString().toLowerCase().indexOf(searchKeyword) > -1) {
              filteredList.push(item);
              break;
            }
            if (!isPresent && (propValueList[i].toString().toLowerCase().indexOf(searchKeyword) > -1)) {
              isFound = true;
            }
          }
        }
        if (!isPresent && !isFound) {
          filteredList.push(item);
        }
      });
    }
    return filteredList;
  }
  public static optionsSelected(_translate: TranslateService, _dataColumnsDef: any[],
                              _listeName: string[], _listSelected: any[], _jumpFirst: boolean ): any[] {
    const listOptions = [];
    let curId = 0;
    let isSelected: boolean;
    for (const name of _listeName) {
      if (_jumpFirst && curId === 0) {
        curId++;
        continue;
      }
      // console.log('optionsSelected colonne : ' + name);
      isSelected = false;
      /* if (_listSelected.indexOf(name) > -1) {
        isSelected = true;
      } */
      const selectedOption = _listSelected.find(c => {
        return c.prop === name ;
      });
      const dataType = _dataColumnsDef.find(c => {
        if (c.colName === name) {
          return c.colType;
        }
      });
      if (selectedOption) {
        isSelected = true;
      }
      if (dataType === undefined) {
        console.log('undefined type : ' + name);
      } else if (dataType.colType === undefined) {
        console.log('undefined colType : ' + name);
      }
      listOptions.push({ 'label': _translate.getTranslate(name),
                          'value': name, 'isSelected': isSelected, 'dataType': dataType.colType});
      curId++;
    }
    return listOptions;
  }
  public static removeOption(_listeOptions: any[], _optionToDelete: string): any[] {
    const listOptions = [];
    for (const dataname of _listeOptions) {
      if (dataname.prop === _optionToDelete) {
        continue;
      }
      listOptions.push({ 'prop': dataname.prop, 'name': dataname.name});
    }
    return listOptions;
  }
  public static addOption(_listeOptions: any[],  _dataColumnsDef: any[], _optionToAdd: string): any[] {
    const listOptions = [];
    let dataType: any;
    let colDate = false;
    const nbOptions = _listeOptions.length;
    for (const dataname of _listeOptions) {
      // insertion avant les boutons action
      dataType = _dataColumnsDef.find(c => {
        if (c.colName === dataname) {
          return c.colType;
        }
      });
      colDate = false;
      if (dataType === 'Date') {
        colDate = true;
      }
      if (dataname === 'actions') {
        listOptions.push({ 'prop': _optionToAdd, 'name': _optionToAdd,  'isDate': colDate});
        listOptions.push(dataname);
        break;
      } else {
        listOptions.push(dataname);
        if (listOptions.length === nbOptions) {
          dataType = _dataColumnsDef.find(c => {
            if (c.colName === _optionToAdd) {
              return c.colType;
            }
          });
          colDate = false;
          if (dataType === 'Date') {
            colDate = true;
          }
          listOptions.push({ 'prop': _optionToAdd, 'name': _optionToAdd});
        }
      }
    }
    return listOptions;
  }
  public static removeItemFromArray(_listeArray: any[], pos: number): any[] {
    const listeArray = _listeArray;
    listeArray.slice(0, pos - 1).concat(listeArray.slice(pos, listeArray.length));
    return listeArray;
  }

  public static randomString(len: number, charSet?: string): string {
    charSet = charSet || 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    let randomString = '';
    for (let i = 0; i < len; i++) {
        const randomPoz = Math.floor(Math.random() * charSet.length);
        randomString += charSet.substring(randomPoz, randomPoz + 1 );
    }
    return randomString;
  }
  public static randomString1(len: number, charSet?: string): string {
    charSet = charSet || 'ABCDEFGHIJKLMNPRSTUVWXYZ';
    let randomString = '';
    for (let i = 0; i < len; i++) {
        const randomPoz = Math.floor(Math.random() * charSet.length);
        randomString += charSet.substring(randomPoz, randomPoz + 1 );
    }
    return randomString;
  }
  
  public static formatNumericField(fieldName: string, _translate: TranslateService, args?: string | string[] ) {
    const input = document.getElementById(fieldName) as HTMLInputElement;
    // const value = input.value.replace(/\D/g, ''); // Supprime tout sauf les chiffres
    const value = input.value.replace(/[^\d,.]/g, ''); // Supprime tout sauf chiffres et virgule
    if (value === '') {
      return ;
    }
    let valueArg: number;
    if (args[0] === 'quantity') {
      valueArg = parseFloat(value);
    } else if (args[0] === 'number') {
      valueArg = parseInt(value, 10);
    } else if (args[0] === 'quantity0') {
      valueArg = parseInt(value, 10);
    } else {
      valueArg = parseFloat(value);
    }
    const returnValue = _translate.instantNumber(valueArg, args); // pass in args
    // return returnValue
    input.value = returnValue.toString();
  }
 
  public static formatCurrencyField(fieldName: string, localeArg: string, currencyArg: string) {
    const input = document.getElementById(fieldName) as HTMLInputElement;
    const value = input.value.replace(/\s/g, '').replace(',', '.'); // Enlever espaces et convertir la virgule
    const number = parseFloat(value);
    /*
    if (!isNaN(number)) {
      input.value = number.toLocaleString("fr-FR", {
        style: "currency",
        currency: "EUR",
      });
    }
      */
    if (!isNaN(number)) {
      input.value = number.toLocaleString(localeArg, {
        style: 'currency',
        currency: currencyArg,
      });
    }
  }
  public static unformatNumericField(fieldName: string) {
    const input = document.getElementById(fieldName) as HTMLInputElement;
    // input.value = input.value.replace(/[^\d,.]/g, ''); // Supprime tout sauf chiffres et virgule
    input.value = '';
  }
  public static getTextFieldValue(fieldName: string): string {
    const input = document.getElementById(fieldName) as HTMLInputElement;
    const rawValue = input.value;
    // const rawValue = input.value.replace(/\s/g, '').replace(/[^\d,.]/g, '').replace(',', '.');
    // console.log(parseFloat(rawValue)); // Affiche la valeur brute sans formatage
    return rawValue;
  }
  public static setTextFieldValue(fieldName: string, valueArg: string): void {
    const input = document.getElementById(fieldName) as HTMLInputElement;
    input.value = valueArg;
  }
  public static getNumericFieldValue(fieldName: string): number {
    const input = document.getElementById(fieldName) as HTMLInputElement;
    let rawValue = input.value.replace(/\s/g, '').replace(/[^\d,.]/g, '').replace(',', '.');
    // console.log(parseFloat(rawValue)); // Affiche la valeur brute sans formatage
    if (isNaN(parseFloat(rawValue))) {
      rawValue = '0.0';
    }
    return parseFloat(rawValue);
  }
  public static setNumericFieldValue(fieldName: string, valueArg: any): void {
    const input = document.getElementById(fieldName) as HTMLInputElement;
    if (input !== null) {
      input.value = valueArg;
    }
  }
  public static sqlArrayGroup(_anyArray: any[], _prop: string): any[] {
    const result = alasql('SELECT ' + _prop + ' AS a , COUNT(*) AS b FROM ? GROUP BY ' + _prop, [ _anyArray]);
    return result;
  }
  public static sqlSearch(_anyArray: any[], _prop: string, _secondArg: any, _aggType: string): any {

    let result: any;
    switch (_aggType) {

      case 'distinct': {
        result = alasql('SELECT DISTINCT(' + _prop + ') AS ' + _prop + ', ' + _secondArg + ' FROM ? ', [_anyArray]);
        break;
      }
      case 'select': {
        const typeOf = typeof _secondArg;
        if (typeOf === 'number') {
          result = alasql('SELECT * FROM ? WHERE ' + _prop + ' = ' + _secondArg , [_anyArray]);
        } else  {
          result = alasql('SELECT * FROM ? WHERE ' + _prop + ' = \'' + _secondArg + '\'' , [_anyArray]);
        }
       
        break;
      }
      case 'selectMax': {
        result = alasql('SELECT MAX(' + _prop + ') AS ' + _prop + ', ' + _secondArg + ' FROM ? '
                        , [_anyArray]);
        break;
      }
      default : {
        result = undefined;
        break;
      }

    }

    return result;
  }
  public static sqlWhereSearch(_anyArray: any[], _where: string): any {
    let result: any;

    try {
      result = alasql('SELECT * FROM ? ' + _where , [_anyArray]);
    } catch (error) {
      result = null;
    } 
    return result;
  }
  public static sqlAggregate(_anyArray: any[], _prop: string, _aggType: string, _colType: string): any {
    if (_colType !== 'numeric') { _aggType = 'colCount'; }
    let result: any;
    switch (_aggType) {
      case 'colAvg': {
        result = alasql('SELECT AVG(' + _prop + ') AS a  FROM ? ', [_anyArray]);
        break;
      }
      case 'colCount': {
        result = alasql('SELECT COUNT(*) As a FROM ? WHERE ' + _prop + ' IS NOT NULL', [_anyArray]);
        break;
      }
      case 'colSum': {
        result = alasql('SELECT SUM(' + _prop + ') AS a FROM ? ', [_anyArray]);
        break;
      }
      case 'distinct': {
        result = alasql('SELECT DISTINCT(' + _prop + ') AS a FROM ? ', [_anyArray]);
        break;
      }
      default : {
        result = undefined;
        break;
      }

    }

    return result;
  }
  public static getNumbersandLetters(_value: string): string {

    // /[a-zA-Z0-9]/g
    const patternCur = /[a-zA-Z0-9]/g;
    let newValue  = _value.match(patternCur).join();
    newValue = CommonMethods.replaceAll(newValue, ',', '');
    return newValue;
  }

  /*
const NUMERIC_REGEXP = /[-]{0,1}[\d]*[.]{0,1}[\d]+/g;
const numbers = '2.2px 3.1px 4px -7.6px obj.key'.match(NUMERIC_REGEXP)
console.log(numbers); // ["2.2", "3.1", "4", "-7.6"]

var value = '675-805-714';
var numberPattern = /\d+/g;
value = value.match( numberPattern ).join([]);
alert(value);
//Show: 675805714

*/
  public static replaceAll(input: string, searchString: string, replaceString: string): string {
    return input.split(searchString).join(replaceString);
  }
  public static getNumbers(input: string): string {
    const numberPattern = /\d+/g;
    let newValue = input.match(numberPattern).join();
    // newValue = newValue.split(',').join('');
    newValue = CommonMethods.replaceAll(newValue, ',', '');
    return newValue;
  }
  
  public static fmtNumber (_value: number, _fmt: string, _lang: string): string {
    let result: string;
    numeral.locale(_lang);
    let fmtString: string;
    // http://numeraljs.com/
    switch (_fmt) {
      case 'integer': {
        fmtString = '0,0';
        break;
      }
      case 'dec1' : {
        fmtString = '0,0.0' ;
        break;
      }
      case 'dec2' : {
        fmtString = '0,0.00' ;
        break;
      }
      case 'zero_left_Int5' : {
        fmtString = '00000' ;
        break;
      }
      case 'zero_left_Int6' : {
        fmtString = '000000' ;
        break;
      }
      case 'decCurrency2':  {
        fmtString = '0,0[.]00 $';
        break;
      }
      case 'intCurrency' : {
        fmtString = '0,0 $';
        break;
      }
      case 'percent' : {
        fmtString = '0%';
        break;
      }
      default : {
        fmtString = '0.0';
        break;
      }
    }
    result = numeral(_value).format(fmtString);
    return result;
  }
// https://mika-s.github.io/javascript/colors/hsl/2017/12/05/generating-random-colors-in-javascript.html
  public static generateHslaColors(_saturation?: number, _lightness?: number, _alpha?: number, _amount?: number) {
      // const h = this.getRandomIntInclusive(0, 360 * 10);
      const s = _saturation >= 0 && _saturation <= 100 ? _saturation : 100;
      const l = _lightness >= 0 && _lightness <= 100 ? _lightness : 50;
      const a = _alpha >= 0 && _alpha <= 100 ? _alpha : 100;
      // return `hsla(${h / 10},${s}%,${l}%,${a})`;
      const colors = [];
      const huedelta = Math.trunc(360 / _amount);
    
      for (let i = 0; i < _amount; i++) {
        const hue = i * huedelta;
        colors.push(`hsla(${hue},${s}%,${l}%,${a})`);
      }
    
      return colors;
  }
  public static genAlphaHslaColors(_saturation?: number, _lightness?: number, _alpha?: number[], _amount?: number) {
    // const h = this.getRandomIntInclusive(0, 360 * 10);
    const s = _saturation >= 0 && _saturation <= 100 ? _saturation : 100;
    const l = _lightness >= 0 && _lightness <= 100 ? _lightness : 50;
    // let a = _alpha >= 0 && _alpha <= 100 ? _alpha : 100;
    let a = 0;
    // return `hsla(${h / 10},${s}%,${l}%,${a})`;
    const colors = [];
    let colorAlphas = [];
    const huedelta = Math.trunc(360 / _amount);
  
    for (let i = 0; i < _amount; i++) {
      const hue = i * huedelta;
      colorAlphas = [];
      if (!_alpha) {
        a = 100;
        colorAlphas.push(`hsla(${hue},${s}%,${l}%,${a})`);
        colors.push(colorAlphas);
      } else {
        for ( const alphaCur of _alpha) {
          a = alphaCur >= 0 && alphaCur <= 100 ? alphaCur : 100;
          colorAlphas.push(`hsla(${hue},${s}%,${l}%,${a})`);
        }
        colors.push(colorAlphas);
      }

    }
  
    return colors;
  }
  public static sleep(milliseconds: number) {
    const date = Date.now();
    let currentDate = null;
    do {
      currentDate = Date.now();
    } while (currentDate - date < milliseconds);
  }
    /**
   *  http://www.webanalytix.fr/comment-encoder-les-caracteres-speciaux-dans-les-urls/
   * @param strToClean 
   */
  public static cleanURLString(strToClean: string): string {
    strToClean = strToClean.replace(/[&]/g, '%26');
    strToClean = strToClean.replace(/[ ]/g, '%20');
    // &#124;
    strToClean = strToClean.replace(/[|]/g, '&#124;');
    return strToClean;
  }
  public static isUUID( uuid: string ): boolean {
    const s = '' + uuid;

    const matcherString = s.match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$');
    if (matcherString === null) {
      return false;
    }
    return true;
  }
  public static numberAndDotOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    // console.log('charCode' + charCode);
    if (charCode === 46) { // dot code
      return true;
    }
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  
  }
  public static csvToJson (columnsHeader: ColumnsHeaderUpload[], rowsCSV: any[], delimiterArg: string): any {
    const rowsJson: any[] = [];
    let recordTarget = {};
    let rowNum = 0;
    for (const rowCur of rowsCSV) {
      if (rowNum === 0)  {
        rowNum++;
        continue;
      }
      const rowData = rowCur.split(delimiterArg);
      let headerNum = 0;
      recordTarget = {};
      for (const headerCur of columnsHeader) {
        recordTarget[headerCur.columnName]  = rowData[headerNum];
        headerNum++;
      }
      rowsJson.push(recordTarget);
      rowNum++;
    }

    return rowsJson;
  }
  // PAPA parse
  // https://alberthaff.dk/projects/ngx-papaparse/docs/v4/parsing-csv/options
  public static parseCSV(delimiterArg: string, headerArg: boolean, origFile: File, papa: Papa): any {
   
    papa.parse(origFile, {
      delimiter: delimiterArg,
      header: headerArg,
      dynamicTyping: true,
      skipEmptyLines: true,
      transformHeader: function(h) {
        h = h.trim();
        h = h.replace(/[ ]/g, '_');
        return h;
      },
      complete: results => {
        // const { data, meta, errors } = results;
        return results;
      },
      error: (error) => {
        console.log('parseCSV : ' + JSON.stringify(error));
      },
    });
     
  }
  // https://alberthaff.dk/projects/ngx-papaparse/docs/v4/parsing-csv/options
  public static unparseCSV(delimiterArg: string, headerArg: boolean, origFile: File, papa: Papa): any {
    const config = {
      delimiter: delimiterArg,
      header: headerArg,
      dynamicTyping: true,
      skipEmptyLines: true,
      transformHeader: function(h) {
        return h.trim();
      }
    };
    return papa.unparse(origFile, config);
  }
  // https://stackoverflow.com/questions/54562812/how-to-read-and-write-from-angular-app-to-local-file
  // https://www.npmjs.com/package/file-saver
  public static saveFileCsv(csvArray: any[], fileName: string) {
    const file = new Blob(csvArray, { type: 'text/csv;charset=utf-8' });
    saveAs(file, fileName);
  }
}

/*
  generateHslaColors(saturation?: number, lightness?: number, alpha?: number) {
    const h = this.getRandomIntInclusive(0, 360 * 10);
    const s = saturation >= 0 && saturation <= 100 ? saturation : 80;
    const l = lightness >= 0 && lightness <= 100 ? lightness : 80;
    const a = alpha >= 0 && alpha <= 100 ? alpha : 100;
    return `hsla(${h / 10},${s}%,${l}%,${a})`;
}

getRandomIntInclusive(min, max) {
	min = Math.ceil(min);
	max = Math.floor(max);
	const random = Math.floor(Math.random() * (max - min + 1)) + min; // The maximum is inclusive and the minimum is inclusive
	return random;
}
*/

/*
//https://dmitripavlutin.com/how-to-iterate-easily-over-object-properties-in-javascript/
let simpleColors = {
	colorA: 'white',
	colorB: 'black',
	colorE: 'blue',
};
let natureColors = {
	colorC: 'green',
	colorD: 'yellow',
};
Object.setPrototypeOf(natureColors, simpleColors);
console.log(Object.keys(natureColors)); // => ['colorC', 'colorD']
console.log(natureColors['colorA']); // => 'white'
console.log(natureColors['colorB']); // => 'black'
console.log(natureColors['colorE']); // => 'black'
console.log(Object.values(natureColors));
console.log(Object.entries(natureColors));
let enumerableKeys = [];
for (let key in natureColors) {
	enumerableKeys.push(key);
}
console.log(enumerableKeys); // => ['colorC', 'colorD', 'colorA', 'colorB']
for (let key of Object.keys(simpleColors)) {
	let colorName = simpleColors[key];
	// ... do something with colorName
	console.log(colorName);
}

for (let colorName of Object.values(simpleColors)) {
	console.log(colorName);
}

for (let [key, value] of Object.entries(simpleColors)) {
	console.log(key + ':' + value);
}

let colorsMap = new Map(Object.entries(simpleColors));
console.log(colorsMap.get('colorA')); // => 'Good morning'
console.log(colorsMap.get('colorB')); // => 'Good day'
console.log(colorsMap.get('colorE')); // => 'Good evening'

console.log([...colorsMap.values()]);

console.log([...colorsMap.entries()]);

*/
