import { Injectable } from '@angular/core';
import {throwError as observableThrowError,  BehaviorSubject ,  Observable, of, Subject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { catchError, tap, map, takeUntil} from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { ToolArgs, ToolKeyValue, ToolApiResponse, ToolApiDatasReturn, ToolsApisType, ToolApi, ToolApiConnect, ToolService, ToolApiEntity, ToolApiReturn, ToolApiParam } from '../../../models/common.model';

@Injectable({
  providedIn: 'root'
})
export class WksApisService {
  
  stockManagementApi: ToolApiConnect;
  accountingApi: ToolApiConnect;
  notificationsApi: ToolApiConnect;
  customersApi: ToolApiConnect;
  invoicingApi: ToolApiConnect;

  APIS: ToolsApisType [];
  httpHeaders: HttpHeaders;

  constructor(public  httpClient: HttpClient) { }

  setHeaders(_headers: HttpHeaders) {
    this.httpHeaders = _headers;
  }


  public settingAPIS(APIS_args: ToolsApisType []) {
    this.APIS = APIS_args;
  }
  public getApisParam(apiArg: string): ToolApi[] {
    const apisList = this.APIS as ToolsApisType [];
    let apiCur: ToolApi[];

    for (const apiParam of apisList) {
      if (apiParam.apiName === apiArg) {
        apiCur = apiParam.apiTools;
      } 
    } 
    return apiCur;
  }
 
  public setApisEntity(apisEntity: ToolApiEntity[]): void {
    if (apisEntity !== undefined) {
      for (const apiCur of apisEntity ) {
        if (apiCur.apiName === 'stockManagement') {
          this.stockManagementApi = apiCur.apiTool;
          if ( this.stockManagementApi === undefined || this.stockManagementApi.toolName === undefined) {
            this.stockManagementApi = undefined;
          }
        } else if (apiCur.apiName === 'accounting') {
          this.accountingApi = apiCur.apiTool;
          if (this.accountingApi === undefined || this.accountingApi.toolName === undefined) {
            this.accountingApi = undefined;
          }
        } else if (apiCur.apiName === 'notifications') {
          this.notificationsApi = apiCur.apiTool;
          if (this.notificationsApi === undefined || this.notificationsApi.toolName === undefined) {
            this.notificationsApi = undefined;
          }
        } else if (apiCur.apiName === 'customers') {
          this.customersApi = apiCur.apiTool;
          if (this.customersApi === undefined || this.customersApi.toolName === undefined) {
            this.customersApi = undefined;
          }
        } else if (apiCur.apiName === 'invoicing') {
          this.invoicingApi = apiCur.apiTool;
          if (this.invoicingApi === undefined ||  this.invoicingApi.toolName === undefined) {
            this.invoicingApi = undefined;
          }
        }
      }
    }
  }
  public getApiStockManagement(): ToolApiConnect {
    return this.stockManagementApi;
  }
  public getServicesStockManagement(): ToolService[] {
    const apisDef = this.getApisParam('stockManagement');
    let toolApiCur: ToolApi;
    for (const apisDefItem of apisDef) {
      if (apisDefItem.toolName === this.stockManagementApi.toolName) {
        toolApiCur = apisDefItem;
        break;
      }
    }
    let toolServices: ToolService[];
    for (const toolVersionItem of toolApiCur.toolVersions) {
      if (toolVersionItem.toolVersion === this.stockManagementApi.toolVersion) {
        toolServices = toolVersionItem.toolServices;
      }
    }
    return toolServices;
  }
  public getApiAccounting(): ToolApiConnect {
    // const apisDef = this.getApisParam('accounting');
    return this.accountingApi;
  }
  public getApiNotifications(): ToolApiConnect {
    // const apisDef = this.getApisParam('notifications');
    return this.notificationsApi;
  }
  public getApiCustomers(): ToolApiConnect {
    // const apisDef = this.getApisParam('customers');
    return this.customersApi;
  }
  public getApiinvoicing(): ToolApiConnect {
    // const apisDef = this.getApisParam('invoicing');
    return this.invoicingApi;
  }

  readStockByRef(brandRef: string, stdEntity: string): ToolApiDatasReturn {
  
    const apiService = 'stockByRef';
    const apiName = 'stockManagement';
    const toolArgs: ToolArgs = {
      toolDatas: []
    };
    let testData = {
      key: 'toolCustomerNwsCode',
      value: this.getApiStockManagement().toolCustomerNwsCode
    };
    toolArgs.toolDatas.push(testData);
    testData = {
      key: 'toolCustomerNwsId',
      value: this.getApiStockManagement().toolCustomerNwsId
    };
    toolArgs.toolDatas.push(testData);

    const toolKeyValue: ToolKeyValue = {
      key: 'brandRef',
      value: brandRef,
      listValues: undefined,
      arrayValues: undefined
    };
    const jsonTmp = JSON.parse(JSON.stringify(toolKeyValue));
    toolArgs.toolDatas.push(jsonTmp);
    this.requestApis(stdEntity, apiName, apiService, toolArgs )
    .subscribe(
      (data: any) => {

        const toolApiResponse = data.body as ToolApiResponse;
        if (toolApiResponse.statut === 'KO') {
          const itemResponse = toolApiResponse.toolDatas[0];
          if (itemResponse.value === '404') {
            toolApiResponse.toolDatas.push({
              key: 'nws_stock_qty',
              value: '0.0'
            });
          }
          return this.affectResponseStockByRef(toolApiResponse);
        } else  {
          return this.affectResponseStockByRef(toolApiResponse);
        }
      },
      err => {
        console.log(' readStockByRef : ' + JSON.stringify(err));
        return undefined;
      }
    );
    return undefined;
  }
  affectResponseStockByRef(toolApiResponse: ToolApiResponse ): ToolApiDatasReturn {
    const toolApiDatasReturn: ToolApiDatasReturn = {
      // datas: ToolApiReturn[];
      datas: []
    };

    // const itemResponse = toolApiResponse.toolDatas[0];
    let itemValue = '';
    for (const itemCur of toolApiResponse.toolDatas) {
      if (itemCur.key === 'nws_stock_qty') {
        itemValue = itemCur.value;
        const toolApiReturn: ToolApiReturn = {
          dataName: 'nws_stock_qty',
          dataValue: parseFloat(itemValue)
        };
        toolApiDatasReturn.datas.push(toolApiReturn);
      }
      if (itemCur.key === 'nws_stock_id') {

        const toolApiReturn: ToolApiReturn = {
          dataName: 'nws_stock_id',
          dataValue: itemCur.value
        };
        toolApiDatasReturn.datas.push(toolApiReturn);
      }

    }
    return toolApiDatasReturn;
  }
requestApis(stdEntity: string, apiName: string, serviceName: string, toolTestArgs: ToolArgs): Observable<HttpResponse<ToolApiParam>> {

    const urlCur = `${environment.baseUrl}/wks/wksentity/requestApis/?` + 'stdEntity=' + stdEntity + '&apiName=' + apiName + '&serviceName=' + serviceName;

    const body = JSON.stringify(toolTestArgs);

    return this.httpClient
      .post<ToolApiParam>(urlCur, body, {
        headers: this.httpHeaders,
        observe: 'response',
      })
      .pipe(
        catchError(err => {
          // console.log(err.status);
          return observableThrowError(err);
        } ));
  }
}
