import { Component, OnInit, Input, SimpleChanges,  OnChanges, ViewChild, ElementRef, AfterViewInit } from '@angular/core';

import { MatDialog, MatDialogConfig } from '@angular/material';

import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

import { takeUntil } from 'rxjs/operators';
import { ModalCommonComponent } from '../../../../../common/components/modal-common/modal-common.component';
import { UserService } from '../../../../../../services/user.service';
import { TranslateService } from '../../../../../../services/translate.service';
import { RessourcesService } from '../../../../../common/services/ressources.service';
import { GlobalEventsManagerService } from '../../../../../../job/common/services/global-events-manager.service';
import { WksCentralService } from '../../../../services/wks-central.service';
import { LangLabelModel, ParamLabelsI18n, ParamRatesModel, ParamOtherData } from '../../../../models/wks-param.model';
import { BrandWksModel, MeasurementList } from '../../../../models/wks-common.model';
import { WksEntityModel } from '../../../../models/wks-entity.model';
import { SUPPORTED_LANGS, LangSupportedModel } from '../../../../../../i18n/translation';
import { ParamPartsSuppliesModel } from '../../../../models/wks-param.model';
import { InternationalsFormats, LabelValue, ProfileTax } from '../../../../../../models/data.model';
import { ToolArgs, ToolKeyValue, ToolApiResponse } from '../../../../../../models/common.model';
import { WksApisService } from '../../../../services/wks-apis.service';
import { CommonMethods } from '../../../../../../job/common/tools/commonMethods';

@Component({
  selector: 'mdi-parts-supplies-card',
  templateUrl: './parts-supplies-card.component.html',
  styleUrls: ['./parts-supplies-card.component.css']
})
export class PartsSuppliesCardComponent implements OnInit, OnChanges, AfterViewInit  {

  @Input() statutCall: any;
  @Input() optionItem: any;
  @Input() actionType: any;
  @Input() partSupplieToUpdate: ParamPartsSuppliesModel;

  @ViewChild('filterbrand', {static: false}) filterbrand: ElementRef;
  
  private readonly onDestroy = new Subject<void>();

  isRegistered: boolean;
  isService: boolean;
  isFirstLoad: boolean;
  supportedLangs: LangSupportedModel[];
  langLabelsList: LangLabelModel[];

  paramTypeList: LabelValue[];
  unitsQuantity: LabelValue[];


  brandCacheList: BrandWksModel[];
  brandFilteredList: BrandWksModel[];
  modelCacheList: BrandWksModel[];
  modelFilteredList: BrandWksModel[];
  brandSelected: BrandWksModel;

  partSupplieForm: FormGroup;

  titleCard: string;

  entityCur: string;
  entityCurrency: string;
  wksEntity: WksEntityModel;
  isLoaded: boolean;
  paramRatesLocal: ParamRatesModel[];
  partSupplieCache: ParamPartsSuppliesModel;
  paramOtherData: ParamOtherData;
  virtualStock: number;
  productStock: {
    virtualStock: number;
    stockId: string;
    stockPrice: number;
    stockPmp: number;
  };
  profileTax: ProfileTax;
  numberFormat: {
    locale: string;
    minDecimal: string;
    maxDecimal: string;
    currency: string;
    justifCurrency: string;
    justifText: string;
    justifNumbers: string;
  };

  internationalsFormats: InternationalsFormats;
  dateFormat: string;
  dateFormatMoment: string;
  dateTimeFormat: string;
  localelang: string;
  localeDateFmt: string;

  partsRules:  {
    partsLabelRegex: string;
    partsLabelMask: string;
    partsRefRegex: string;
    partsRefMask: string;
    prefixRefServices: string;
  };

  initTerminated: boolean;
  constructor(private fb: FormBuilder, 
    private _userService: UserService, 
    private _globalEventsManagerService: GlobalEventsManagerService,
    private _ressourcesService: RessourcesService,
    private router: Router,
    private _dialog: MatDialog,
    private adapterDate: DateAdapter<any>,
    private _translate: TranslateService,
    private _wksCentralService: WksCentralService,
    private _apiService: WksApisService) { }

  ngOnInit() {
  }
  ngOnChanges(changes: SimpleChanges) {

    const listKey = Object.keys(changes);
    for (const propName of listKey) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'actionType': {
            this.actionType = changes['actionType'].currentValue;
            break;
          }
          case 'optionItem': {
            this.optionItem = changes['optionItem'].currentValue;
            break;
          }
          case 'statutCall': {
            this.statutCall = changes['statutCall'].currentValue;
            break;
          }
          case 'partSupplieToUpdate': {
            this.partSupplieToUpdate = changes['partSupplieToUpdate'].currentValue;

            break;
          }
        } // end switch
      } // end if
    } 

    this.initData();
  }

  initData() {
    
    if ((this._userService.getUserLogged() === undefined) || ( this._userService.getUserLogged().entity === undefined)) {
      return;
    }

    if ((this.actionType === 'add') && (this.statutCall === 'modal')) {
      this.partSupplieToUpdate = undefined;
    }
    this.initAllBooleans();
    this.productStock = {
      virtualStock: 0.0,
      stockId: '',
      stockPrice: 0.0,
      stockPmp: 0.0
    };
    this.settingParams(); 
    this.buildForm();
    this.loadEntity();
  }
  ngAfterViewInit() {
    /*
    const observer = new MutationObserver((mutations) => {
      if (!this.initTerminated) { 
        const inputElement = document.getElementById('paramRate');
        if (inputElement) {
          this.fillInputNumeric();
          this.initTerminated = true;
          observer.disconnect(); // Arrêter d'observer après l'apparition de l'élément
        }
      }
    });
    observer.observe(document.body, { childList: true, subtree: true });
    */
  }
  initAllBooleans(): void {
    this.isRegistered = true;
    this.isLoaded = false;
    this.isService = false;
    this.isFirstLoad = true;
    this.initTerminated = false;
  }
  settingParams(): void {
    const listItem = this._wksCentralService.getTypesPartsSupplies();
    this.paramTypeList = [];
    for (const item of listItem) {
           const itemCur: LabelValue = {
        label: this._translate.getTranslate(item),
        value: item,
      };
      this.paramTypeList.push(itemCur);
    }
    const countryEntity  = this._ressourcesService.getCountry(this._userService.getUserLogged().entitycountry);
    const isMetric = (countryEntity.measurementUnit === 'METRIC');
    const jsonUnits = this._wksCentralService.getUnitsQuantity() as MeasurementList[];
    let measurementLocal = 'METRIC';
    if (!isMetric) {
      measurementLocal = 'NON_METRIC';
    }
    // tires
    this.unitsQuantity  = [];
    for (const measurLocal of jsonUnits) {
      if (measurLocal.measurement === measurementLocal) {
        const arrayUnits = [...measurLocal.list];
        for (const unitName of arrayUnits) {

          this.unitsQuantity.push({
            label: this._translate.getTranslate(unitName),
            value: unitName
          });
        }
        /*this.unitsQuantity.splice(0, 0, {
          label: '',
          value: ''
        });*/
        break;
      }
    }

    this.langLabelsList = [];
    this.supportedLangs = [...SUPPORTED_LANGS];
    for (const langCur of this.supportedLangs) {
      const langTmp: LangLabelModel = {
        langValue: langCur.value,
        langDisplay: this._translate.getTranslate(langCur.display),
        langDirection: langCur.direction,
        langLabel: ''
      } ;
      this.langLabelsList.push(langTmp);
    }
  }
  buildForm(): void {
    this.entityCur = this._userService.getUserLogged().entity;   
    this.titleCard = this._translate.instant(this.optionItem);
    let readOnlyData = true;
    if (this.actionType === 'add') {
      readOnlyData = false;
    }
    this.partSupplieForm = this.fb.group({
      paramLabel:  this.fb.control(''),
      paramType:  this.fb.control({value: '', disabled: readOnlyData }),
      brandName:  this.fb.control({value: '', disabled: readOnlyData }),
      brandRef:  this.fb.control({value: '', disabled: readOnlyData }),
      brandBarcode:  this.fb.control(''),
      paramUnit:  this.fb.control(''),
      paramRate:  this.fb.control(''),
      paramCurrency:  this.fb.control(''),
      accountingBuy:  this.fb.control(''),
      accountingSale:  this.fb.control(''),
      virtualStock: this.fb.control({value: '', disabled: true }),
      stockPrice: this.fb.control({value: '', disabled: true }),
      stockPmp: this.fb.control({value: '', disabled: true }),
    },
    {
      updateOn: 'blur'
    });
  
  }
  initAllSubscribe(): void {    
    this.partSupplieForm.valueChanges.subscribe(x => {
      this.isRegistered = true;
      const locItem = this.fillModel();
      // console.log(JSON.stringify(this.partSupplieCache));
      // console.log(JSON.stringify(locItem));
      if (this.partSupplieForm.status === 'VALID' ) {
        if (this.partSupplieCache === undefined) {
          this.isRegistered = false;
        } else {
          if ((JSON.stringify(this.partSupplieCache) !== JSON.stringify(locItem))) {
              this.isRegistered = false;
          } 
        }
      }
    });
  }
  settingIntFmts(): void {
    const otherData = JSON.parse(this.wksEntity.otherData);
    const internationnalFormat = otherData.internationnalFormat;
    this.internationalsFormats = this._ressourcesService.getIntFormat(internationnalFormat);
    for (const fmtCur of this.internationalsFormats.datetime) {
      if (fmtCur.name === 'dateFormat') {
        this.dateFormat = fmtCur.value;
        this.dateFormatMoment = fmtCur.value;
        this.dateFormatMoment = this.dateFormatMoment.replace('dd', 'DD');
        this.dateFormatMoment = this.dateFormatMoment.replace('yyyy', 'YYYY');
      }
      if (fmtCur.name === 'timeFormat') {
        this.dateTimeFormat = fmtCur.value;
        this.dateTimeFormat = this.dateTimeFormat.replace('dd', 'DD');
        this.dateTimeFormat = this.dateTimeFormat.replace('yyyy', 'YYYY');
      }
    }
    const partsRulesTmp = otherData.partsRules;
    if (partsRulesTmp !== undefined) {
          // /^[a-zA-Z0-9-\/]*$
          // [^a-zA-Z0-9-\/]
      const labelRegex = partsRulesTmp.partsLabelRegex.split('[');
      const labelRegex1 =  labelRegex[1].split(']');
      const labelRegex2 =  '[^' + labelRegex1[0] + ']' ;

      const refRegex = partsRulesTmp.partsRefRegex.split('[');
      const refRegex1 =  refRegex[1].split(']');
      const refRegex2 =  '[^' + refRegex1[0] + ']' ;

      this.partsRules =  {
        partsLabelRegex: partsRulesTmp.partsLabelRegex,
        partsLabelMask: partsRulesTmp.partsLabelMask,
        partsRefRegex: partsRulesTmp.partsRefRegex,
        partsRefMask: partsRulesTmp.partsRefMask,
        prefixRefServices: partsRulesTmp.prefixRefServices
      };
    }
    // this.adapterDate.setLocale(this.translate.currentLang);
    const userLang = navigator.language ;
    this.adapterDate.setLocale(userLang ? userLang : otherData.language + '_' + internationnalFormat);
    // this.localelang = userLang;
    this.localelang =  this._translate.currentLang;
    this.localeDateFmt =  userLang ? userLang : otherData.language + '_' + internationnalFormat;
    const intFormat = otherData.internationnalFormat;
    this.numberFormat = {
      locale: this._ressourcesService.getFormat(intFormat, 'numbers', 'locale'),
      minDecimal: this._ressourcesService.getFormat(intFormat, 'numbers', 'minimumFractionDigits'),
      maxDecimal: this._ressourcesService.getFormat(intFormat, 'numbers', 'maximumFractionDigits'),
      currency:  this._ressourcesService.getFormat(intFormat, 'numbers', 'currency'),
      justifCurrency:  this._ressourcesService.getFormat(internationnalFormat, 'justifs', 'currency'),
      justifText:  this._ressourcesService.getFormat(internationnalFormat, 'justifs', 'text'),
      justifNumbers:  this._ressourcesService.getFormat(internationnalFormat, 'justifs', 'numbers')
    };
    const countryDef = this._ressourcesService.getCountry(this.wksEntity.entityCountry);
    const taxProfilesList = countryDef.taxation.profilesTax as ProfileTax[];
    const entityOptions = JSON.parse(this.wksEntity.entityOptions);
    const profileName = entityOptions.taxProfile;
    for (const taxProfileCur of taxProfilesList) {
      if (taxProfileCur.profileName === profileName) {
        this.profileTax = taxProfileCur;
        break;
      }
    }
  }
  loadEntity(): void {
    this.isLoaded = false;
    this.wksEntity = this._wksCentralService.getWksEntityCur();
    this.settingIntFmts();
    this.loadBrands()
    .then (brandList => {
      if (this.partSupplieToUpdate !== undefined) {
        if (this.partSupplieToUpdate.brandName) {
          let brandSelected: BrandWksModel;
          for (const curBrand of this.brandCacheList) {
            if (curBrand.brandName === this.partSupplieToUpdate.brandName) {
              brandSelected = curBrand;
              break;
            }
          }
        }
        if (this.partSupplieToUpdate.paramType !== 'service') {
          this.readStockByRef(this.partSupplieToUpdate.brandRef);
          this.fillForm();
        } else {
          this.fillForm();
        }
      } else { // adding service
        this.paramOtherData = this.otherDataEmpty();
        if (this.isFirstLoad) {
          this.isFirstLoad = false;
          this.initAllSubscribe();
        }
      }
      this.isLoaded = true;
    })
    .catch(error  => {
      console.log(error);
      this.isLoaded = true;
    });
  }

  loadBrands() {
    if (this._userService.getUserLogged() === undefined ) { return; }
    this.brandCacheList = [];
    this.brandFilteredList = [];
    this.modelCacheList = [];
    this.modelFilteredList = [];
    let brandList: BrandWksModel[];
  
    return new Promise((resolve, reject) => {
      this._wksCentralService.getWksBrandsList(this._userService.getUserLogged().entity)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(
        data => {
          brandList = data.body;
          for (const brandCur of brandList) {
            this.brandCacheList.push(brandCur);
          }
          this.brandCacheList.sort((obj1, obj2) => {
            return obj1.brandName > obj2.brandName ? 1 : -1;
          });
          this.brandCacheList.splice(0, 0,  {stdEntity: this._userService.getUserLogged().entity, brandRow: 'brand', brandName: ''} );
          this.brandFilteredList = [...this.brandCacheList];
          resolve(brandList);
        }, err => {
          console.log(err);
          if (err.status === 404) {
            this.brandCacheList.push({
              stdEntity: this._userService.getUserLogged().entity,
              brandRow: 'brand',
              brandName: ''
            });
            this.brandFilteredList = [...this.brandCacheList];

          }
          reject(err.status);
        }
      );
    });
  }
  onChangeType($event: any) {
    this.isService = false;
    if ($event === 'service') {
      this.isService = true;
      this.partSupplieForm.controls['paramRate'].setErrors({'incorrect': true}); // forcing invalid form
    }
  }
  onChangeBrand($event: any) {
    // console.log('onChangeBrand : ' + $event);
    let brandSelected: BrandWksModel;
    for (const curBrand of this.brandCacheList) {
      if (curBrand.brandName === $event) {
        brandSelected = curBrand;
        break;
      }
    }
    this.brandSelected = brandSelected;
  }

  onKeyBrand($event: any) {
    // console.log(value);
  
    let valueFinal: any;
    if ($event === '') {
      valueFinal = '';
    } else  {
      valueFinal = $event.target.value;
      if ( $event.keyCode === 32) {
        valueFinal += ' ';
      }
    }
    this.brandFilteredList = this.searchBrand(valueFinal);
    this.filterbrand.nativeElement.value = valueFinal;
  }
  searchBrand(value: string) { 
    const filter = value.toLowerCase();
    return this.brandCacheList.filter(brandCur => brandCur.brandName.toLowerCase().includes(filter));
  }
  addBrand(newBrandName: string) {
    // console.log(newBrand);
    if (newBrandName === '') {
      return;
    }
    const newBrand = {
      stdEntity: this._userService.getUserLogged().entity,
      brandRow: 'brand',
      brandName: newBrandName,
      equipType: '',
      modelName: '',
    };
    this.brandCacheList.push(newBrand);
    this.brandCacheList.sort((obj1, obj2) => {
      return obj1.brandName > obj2.brandName ? 1 : -1;
    });
    if (this.brandCacheList[0].brandName === '') {
      this.brandCacheList.splice(0, 1);
    }
    this.brandFilteredList = [...this.brandCacheList];
    this.modelCacheList = [];
    this.modelFilteredList = [...this.modelCacheList];
    // this._wksCentralService.saveWksBrand( newBrand, 'create');
    this._wksCentralService.saveBrandModel(newBrand);
  }
 
  fillForm() {
    if (this.partSupplieToUpdate === undefined) {
      return;
    }
    
    this.fillCache();
    this.isService = false;
    this.isRegistered = true;
    if (this.partSupplieToUpdate.paramRates) {
      this.paramRatesLocal = this.partSupplieToUpdate.paramRates;
    } else {
      this.paramRatesLocal = [];
    }
    
    this.partSupplieForm.controls.paramType.setValue(this.partSupplieToUpdate.paramType); 
    if (this.partSupplieToUpdate.paramType === 'service') {
      this.isService = true;
    }
    this.partSupplieForm.controls.brandName.setValue(this.partSupplieToUpdate.brandName);
    this.partSupplieForm.controls.brandRef.setValue(this.partSupplieToUpdate.brandRef);
    this.partSupplieForm.controls.brandBarcode.setValue(this.partSupplieToUpdate.brandBarcode);
    this.partSupplieForm.controls.paramUnit.setValue(this.partSupplieToUpdate.paramUnit);
    this.partSupplieForm.controls.paramRate.setValue(this.paramRatesLocal[0].paramRate ? this.paramRatesLocal[0].paramRate : '0.0');
    if (this.paramRatesLocal !== undefined && this.paramRatesLocal.length > 0 ) {
      let returnValue = this._translate.instantNumber(this.paramRatesLocal[0].paramRate, ['currency', this.numberFormat.locale, this.numberFormat.currency, 'symbol']) as string; // pass in args
      returnValue = returnValue.replace(/\s/g, '').replace(',', '.'); // Enlever espaces et convertir la virgule
      this.partSupplieForm.controls.paramRate.setValue(returnValue);
    } else {
      this.partSupplieForm.controls.paramRate.setValue(undefined);
    }

    this.partSupplieForm.controls.paramCurrency.setValue(this.entityCurrency); // not used

    if ((this.partSupplieToUpdate.langLabels !== undefined) && (this.partSupplieToUpdate.langLabels !== null)) {
      for (const labelCur of this.partSupplieToUpdate.langLabels) {
        for ( const local of this.langLabelsList) {
          if (local.langValue === labelCur.labelLang) {
            local.langLabel = labelCur.labelText;
            this.partSupplieForm.controls.paramLabel.setValue(local.langLabel); 
          }
        } 
      }
    }
    
    if (this.partSupplieToUpdate.otherData !== undefined && this.partSupplieToUpdate.otherData !== null) {
      const otherData = this.partSupplieToUpdate.otherData;
      if (otherData !== undefined) {
        try {
          this.paramOtherData = JSON.parse(otherData) as ParamOtherData;
        } catch (error) {
          this.paramOtherData = this.otherDataEmpty();
        } finally { }
      } else {
        this.paramOtherData = this.otherDataEmpty();
      } 
    } else {
      this.paramOtherData = this.otherDataEmpty();
    } 
    
    this.partSupplieForm.controls.accountingBuy.setValue(this.paramOtherData.accountingBuy); 
    this.partSupplieForm.controls.accountingSale.setValue(this.paramOtherData.accountingSale); 
    
    if (this.isFirstLoad) {
      this.isFirstLoad = false;
      this.initAllSubscribe();
    }
  }
  /*
  fillInputNumeric(): void {
    if (this.paramRatesLocal !== undefined && this.paramRatesLocal.length > 0 ) {
      CommonMethods.setNumericFieldValue('paramRate', this.paramRatesLocal[0].paramRate ? this.paramRatesLocal[0].paramRate : '0.0');
      CommonMethods.formatNumericField('paramRate', this._translate, ['currency', this.numberFormat.locale, this.numberFormat.currency, 'symbol']);
    }
  }
    */
  otherDataEmpty(): ParamOtherData {
    return {
      stockRefExists: false,
      accountingBuy: '',
      accountingSale: ''
    };
  }
  setLangLabelValue(_event: string, _row: string) {
    let  value = _event.charAt(0).toUpperCase() + _event.slice(1);
    const pattern = new RegExp(this.partsRules.partsLabelRegex, 'g');
    value = value.replace(pattern, '');
    if (this.langLabelsList[_row].langValue === this._translate.getCurrentLang()) {
      this.partSupplieForm.controls.paramLabel.setValue(value);
    }
    this.langLabelsList[_row].langLabel = '';
    this.langLabelsList[_row].langLabel = value;
  }
  onInputChangeBrandRef(value: string): void {
    // const patternRule =  '/' + this.partsRules.partsRefRegex + '/g';
    const pattern = new RegExp(this.partsRules.partsRefRegex, 'g');
    const newValue = value.replace(pattern, '');
    // /^[a-zA-Z0-9-\/]*$
    // [^a-zA-Z0-9-\/]
    // const newValue = value.replace(/[^a-zA-Z0-9-\/]/g, '');
    this.partSupplieForm.controls.brandRef.setValue(newValue);
  }
  /*
  onInputChangeParamLabel(value: string): void {
    const pattern = new RegExp(this.partsRules.partsLabelRegex, 'g');
    const newValue = value.replace(pattern, '');

    this.partSupplieForm.controls.paramLabel.setValue(newValue);
  }
    */
  readStockByRef(brandRef: string): void {
    const apiService = 'stockByRef';
    const apiName = 'stockManagement';
    const toolArgs: ToolArgs = {
      toolDatas: []
    };
    let testData = {
      key: 'toolCustomerNwsCode',
      value: this._apiService.getApiStockManagement().toolCustomerNwsCode
    };
    toolArgs.toolDatas.push(testData);
    testData = {
      key: 'toolCustomerNwsId',
      value: this._apiService.getApiStockManagement().toolCustomerNwsId
    };
    toolArgs.toolDatas.push(testData);

    const toolKeyValue: ToolKeyValue = {
      key: 'brandRef',
      value: brandRef,
      listValues: undefined,
      arrayValues: undefined
    };
    const jsonTmp = JSON.parse(JSON.stringify(toolKeyValue));
    toolArgs.toolDatas.push(jsonTmp);
    this._apiService.requestApis(this.wksEntity.stdEntity, apiName, apiService, toolArgs )
    .subscribe(
      (data: any) => {

        const toolApiResponse = data.body as ToolApiResponse;
        if (toolApiResponse.statut === 'KO') {
          const itemResponse = toolApiResponse.toolDatas[0];
          if (itemResponse.value === '404') {
            toolApiResponse.toolDatas.push({
              key: 'nws_stock_qty',
              value: '0.0'
            });
          }
          return this.affectResponseStockByRef(toolApiResponse);
        } else  {
          return this.affectResponseStockByRef(toolApiResponse);
        }
      },
      err => {
        console.log(' readStockByRef : ' + JSON.stringify(err));
        return undefined;
      }
    );

  }

  affectResponseStockByRef(toolApiResponse: ToolApiResponse ): void {

    for (const itemCur of toolApiResponse.toolDatas) {
      if (itemCur.key === 'nws_stock_qty') {
        this.productStock.virtualStock = parseFloat(itemCur.value);
      }
      if (itemCur.key === 'nws_stock_price') {
        this.productStock.stockPrice = parseFloat(itemCur.value);
      }
      if (itemCur.key === 'nws_stock_pmp') {
        this.productStock.stockPmp = parseFloat(itemCur.value);
      }
      if (itemCur.key === 'nws_stock_id') {
        this.productStock.stockId = itemCur.value;
      }
    }
  }
  registerPartSupplie() {
    const partSupplieValidation = this.fillModel();
    this._wksCentralService.saveParamPartSupplie(partSupplieValidation, this.entityCur)
    .subscribe(
      paramWorksRatesReturn => {
        this.partSupplieToUpdate = paramWorksRatesReturn.body;
        this.isRegistered = true;
        if  (this.statutCall === 'sidenav') {
          this._wksCentralService.onRegisterValid.emit('closeRegisterAction');
          return;
        } else if  (this.statutCall === 'modal') {
            this._globalEventsManagerService.synchroEvents.next('closeRegisterActionModal');
            return;
        } else {
          this.router.navigate(['/jobHome']);
        }

      },
      () => {
        this.errorMessage('register');
      }
    );
  }
  numberAndDotOnly(event: any): boolean {
    return CommonMethods.numberAndDotOnly(event);
  }
  handleEnter(event: KeyboardEvent) {
    event.preventDefault(); // Empêche l'ajout automatique
    // console.log('Enter pressé mais sans ajout !');
  }
  formatCurrencyField(fieldName: string): void {
    switch (fieldName) {
      case 'paramRate': {
        const valueArg = this.partSupplieForm.controls.paramRate.value;
        let returnValue = this._translate.instantNumber(valueArg, ['currency', this.numberFormat.locale, this.numberFormat.currency, 'symbol']) as string; // pass in args
        returnValue = returnValue.replace(/\s/g, '').replace(',', '.'); // Enlever espaces et convertir la virgule en point
        this.partSupplieForm.controls.paramRate.setValue(returnValue);
        break;
      }
    }
  }
  unformatNumericField(fieldName: string): void {
    switch (fieldName) {
      case 'paramRate': {
        this.partSupplieForm.controls.paramRate.setValue(undefined);
        break;
      }
    }
  }
  fillModel(): ParamPartsSuppliesModel {
    const paramLabelsI18nArray: ParamLabelsI18n[] = [];
    if ((this.partSupplieToUpdate !== undefined) 
      && (this.partSupplieToUpdate.langLabels !== undefined) 
      && (this.partSupplieToUpdate.langLabels !== null)) {
    
      // let isExists = false;
      for (const labelCur of this.langLabelsList) {
        for (const paramLabelsI18n of this.partSupplieToUpdate.langLabels) {
          if (labelCur.langValue === paramLabelsI18n.labelLang) {
            paramLabelsI18n.labelText = labelCur.langLabel;
            paramLabelsI18nArray.push(paramLabelsI18n); 
            break;
          }
        }
      }
    } else {
      for (const labelCur of this.langLabelsList) {
        const paramLabelsI18n = {
          stdEntity: this.entityCur,
          labelLang: labelCur.langValue,
          labelText: labelCur.langLabel,
        };
        paramLabelsI18nArray.push(paramLabelsI18n);
      }
    } 

    this.paramOtherData = {
      stockRefExists: this.paramOtherData.stockRefExists ? this.paramOtherData.stockRefExists : false,
      accountingBuy: this.partSupplieForm.controls.accountingBuy.value,
      accountingSale: this.partSupplieForm.controls.accountingSale.value,
    };
    let paramRateValue;
    if (this.partSupplieForm.controls.paramRate.value !== undefined) {
      paramRateValue = this.partSupplieForm.controls.paramRate.value.replace(/\s/g, '').replace(/[^\d,.]/g, '').replace(',', '.');
    } else {
      
    }
    // console.log(parseFloat(rawValue)); // Affiche la valeur brute sans formatage
    const partSupplieValidation: ParamPartsSuppliesModel = {
      id: (this.partSupplieToUpdate !== undefined ? this.partSupplieToUpdate.id : undefined),
      stdEntity: this.entityCur,
      paramType: this.partSupplieForm.controls.paramType.value,
      paramLabel: this.partSupplieForm.controls.paramLabel.value,
      brandName: this.partSupplieForm.controls.brandName.value,
      brandRef: this.partSupplieForm.controls.brandRef.value,
      brandBarcode: this.partSupplieForm.controls.brandBarcode.value,
      paramUnit: this.partSupplieForm.controls.paramUnit.value,
      paramRate: parseFloat(paramRateValue),
      paramCurrency: this.partSupplieForm.controls.paramCurrency.value,
      paramScaleName: 'PUBLIC',
      otherData: JSON.stringify(this.paramOtherData),
      stdCreation: (this.partSupplieToUpdate !== undefined ? this.partSupplieToUpdate.stdCreation : undefined),
      langLabels: paramLabelsI18nArray,
      userLang: this._userService.getUserLogged().userlang,
      prefixRefServices: this.partsRules.prefixRefServices
    };

    const paramRatesTmp: ParamRatesModel = {
      id : (this.paramRatesLocal && this.paramRatesLocal[0] && this.paramRatesLocal[0].id ? this.paramRatesLocal[0].id : undefined),
      stdEntity: this.entityCur,
      paramId: (this.partSupplieToUpdate !== undefined ? this.partSupplieToUpdate.id : undefined),
      paramScaleName: 'PUBLIC',
      paramRate: parseFloat(paramRateValue),
      paramCurrency: this.entityCurrency,
      otherData: ''    
    };
    const paramRatesLocal: ParamRatesModel[] = [];
    paramRatesLocal.push(paramRatesTmp);
    partSupplieValidation.paramRates = paramRatesLocal;

    return partSupplieValidation;
  }
  fillCache(): void {
   
    this.partSupplieCache = {
      id: this.partSupplieToUpdate.id,
      stdEntity: this.entityCur,
      paramType: this.partSupplieToUpdate.paramType,
      paramLabel: this.partSupplieToUpdate.paramLabel,
      brandName: this.partSupplieToUpdate.brandName,
      brandRef: this.partSupplieToUpdate.brandRef,
      brandBarcode: this.partSupplieToUpdate.brandBarcode,
      paramUnit: this.partSupplieToUpdate.paramUnit,
      paramRate: this.partSupplieToUpdate.paramRate,
      paramCurrency: this.partSupplieToUpdate.paramCurrency,
      paramScaleName: this.partSupplieToUpdate.paramScaleName,
      otherData: this.partSupplieToUpdate.otherData,
      stdCreation: this.partSupplieToUpdate.stdCreation,
      langLabels: this.partSupplieToUpdate.langLabels,
      userLang:  this._userService.getUserLogged().userlang
    };
    if ((this.partSupplieToUpdate.langLabels !== undefined) && (this.partSupplieToUpdate.langLabels !== null)) {
      for (const labelCur of this.partSupplieToUpdate.langLabels) {
        for ( const local of this.langLabelsList) {
          if (local.langValue === labelCur.labelLang) {
            local.langLabel = labelCur.labelText;
            this.partSupplieCache.paramLabel = local.langLabel; 
          }
        } 
      }
    }
    this.partSupplieCache.paramRate = (this.partSupplieToUpdate.paramRates[0].paramRate ? this.partSupplieToUpdate.paramRates[0].paramRate : 0.0);
    this.partSupplieCache.paramCurrency = this.entityCurrency;
    this.partSupplieCache.paramScaleName = 'PUBLIC';
    
    const paramRatesTmp: ParamRatesModel = {
      id : (this.partSupplieToUpdate.paramRates && this.partSupplieToUpdate.paramRates[0] && this.partSupplieToUpdate.paramRates[0].id ? 
                this.partSupplieToUpdate.paramRates[0].id : undefined),
      stdEntity: this.entityCur,
      paramId: (this.partSupplieToUpdate !== undefined ? this.partSupplieToUpdate.id : undefined),
      paramScaleName: 'PUBLIC',
      paramRate: this.partSupplieCache.paramRate,
      paramCurrency: this.entityCurrency,
      otherData: ''    
    };
    const paramRatesLocal: ParamRatesModel[] = [];
    paramRatesLocal.push(paramRatesTmp);
    this.partSupplieCache.paramRates = paramRatesLocal;

    let paramOtherDataTmp: ParamOtherData;

    if (this.partSupplieToUpdate.otherData !== undefined) {
      const otherData = this.partSupplieToUpdate.otherData;
      if (otherData !== undefined) {
        try {
          paramOtherDataTmp = JSON.parse(otherData) as ParamOtherData;
        } catch (error) {
          paramOtherDataTmp = this.otherDataEmpty();
        } finally { }
      } else {
        paramOtherDataTmp = this.otherDataEmpty();
      } 
    } else {
      paramOtherDataTmp = this.otherDataEmpty();
    } 
    this.partSupplieCache.otherData = JSON.stringify(paramOtherDataTmp);
  }
  errorMessage(_actionCur: string) {
    const dialogConfig = new MatDialogConfig();
    let titleBox: string;
    let messageBox: string;
    if (_actionCur === 'register') {
      messageBox = 'error recording';
      titleBox = 'recording action ref';
      
    }
    if (_actionCur === 'entity') {
      messageBox = 'error loading';
      titleBox = 'loading entity ';
    }
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      id: 1,
      title: this._translate.getTranslate(titleBox),
      typeDialog: 'alertWks',
      panelClass: 'stdTheme',
      contentMessage: this._translate.getTranslate(messageBox),
      data1: '',
      data2: '',
      messageType: 'ERROR'

      };
 //   this._dialog.open(ModalCommonComponent, dialogConfig);

    const dialogRef = this._dialog.open(ModalCommonComponent, dialogConfig);

    dialogRef.afterClosed()
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
        console.log('Dialog output:', data);
      });

  }
  doClose() {
    if  ((this.statutCall === 'modal')  ) {
      this._globalEventsManagerService.synchroEvents.next('closeActionModal');
      return;
    }
    this.router.navigate(['/jobHome']);
  }
  doCancel() {
    if  ((this.statutCall === 'modal')  ) {
      this._globalEventsManagerService.synchroEvents.next('closeActionModal');
      return;
    }
    if  (this.statutCall === 'sidenav') {
      this._wksCentralService.onRegisterValid.emit('closeRegisterAction');
      return;
    } else {
      this.router.navigate(['/jobHome']);
    }
  }
}
