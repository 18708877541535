import { Component, OnInit, Input, ViewChild, ElementRef  } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import {take} from 'rxjs/operators';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { GlobalEventsManagerService } from '../../../../../../job/common/services/global-events-manager.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

import { takeUntil } from 'rxjs/operators';
import { ModalCommonComponent } from '../../../../../common/components/modal-common/modal-common.component';
import { UserService } from '../../../../../../services/user.service';
import { TranslateService } from '../../../../../../services/translate.service';

import { WksCentralService } from '../../../../services/wks-central.service';
import { ParamActionsrefModel, LangLabelModel, ParamLabelsI18n, ParamWorksRatesModel } from '../../../../models/wks-param.model';
import { BrandWksModel } from '../../../../models/wks-common.model';
import { WksEntityModel } from '../../../../models/wks-entity.model';
import { SUPPORTED_LANGS, LangSupportedModel } from '../../../../../../i18n/translation';
import { LabelValue } from '../../../../../../models/data.model';

@Component({
  selector: 'mdi-actionsref-card',
  templateUrl: './actionsref-card.component.html',
  styleUrls: ['./actionsref-card.component.css']
})
export class ActionsrefCardComponent implements OnInit {

  @Input() statutCall: any;
  @Input() optionItem: any;
  @Input() actionType: any;
  @Input() actionsrefToUpdate: ParamActionsrefModel;

  @ViewChild('filterbrand', {static: false}) filterbrand: ElementRef;
  @ViewChild('filtermodel', {static: false}) filtermodel: ElementRef;

  private readonly onDestroy = new Subject<void>();

  isRegistered: boolean;
  supportedLangs: LangSupportedModel[];
  langLabelsList: LangLabelModel[];
  equipTypeList: LabelValue[];
  workCodeList: LabelValue[];
  billingUnitsList: LabelValue[];

  brandCacheList: BrandWksModel[];
  brandFilteredList: BrandWksModel[];
  modelCacheList: BrandWksModel[];
  modelFilteredList: BrandWksModel[];
  brandSelected: BrandWksModel;

  actionrefLabelCtrl: FormControl;
  equipTypeCtrl: FormControl;
  equipBrandCtrl: FormControl;
  equipModelCtrl: FormControl;
  brandRefCtrl: FormControl;
  workCodeCtrl: FormControl;
  billingUnitCtrl: FormControl;

  actionRefForm: FormGroup;

  titleCard: string;

  entityCur: string;
  wksEntity: WksEntityModel;
  isLoaded: boolean;

  constructor(private fb: FormBuilder, 
    private _userService: UserService, 
    private _globalEventsManagerService: GlobalEventsManagerService,
    private router: Router,
    private _dialog: MatDialog,
    private _translate: TranslateService,
    private _wksCentralService: WksCentralService) { }

  ngOnInit() {
    this.isRegistered = false;
    this.isLoaded = false;
    this.initData();
  }
  initData() {
    
    if ((this._userService.getUserLogged() === undefined) || ( this._userService.getUserLogged().entity === undefined)) {
      return;
    }
    if ((this.actionType === 'add') && (this.statutCall === 'modal')) {
      this.actionsrefToUpdate = undefined;
    }
    const listItem = this._wksCentralService.getComponentsList();
    this.equipTypeList = [];
    for (const item of listItem) {
           const itemCur: LabelValue = {
        label: this._translate.getTranslate(item),
        value: item,
      };
      this.equipTypeList.push(itemCur);
    }
    const listEquip = this._wksCentralService.getEquipmentsList();
    for (const item of listEquip) {
      if (item.equipType === 'engine') {
        continue;
      }
      const itemCur: LabelValue = {
          label: this._translate.getTranslate(item.equipType),
          value: item.equipName,
      };
      this.equipTypeList.push(itemCur);
    }
    this.equipTypeList.sort((obj1: LabelValue, obj2: LabelValue) => {
      return obj1.label > obj2.label ? 1 : -1;
    });

    this.langLabelsList = [];
    this.supportedLangs = [...SUPPORTED_LANGS];
    for (const langCur of this.supportedLangs) {
      const langTmp: LangLabelModel = {
        langValue: langCur.value,
        langDisplay: this._translate.getTranslate(langCur.display),
        langDirection: langCur.direction,
        langLabel: ''
      } ;
      this.langLabelsList.push(langTmp);
    }
    this.entityCur = this._userService.getUserLogged().entity;   
    this.titleCard = this._translate.instant(this.optionItem);
    this.actionrefLabelCtrl = this.fb.control('');
    this.equipTypeCtrl = this.fb.control('');
    this.equipBrandCtrl = this.fb.control('');
    this.equipModelCtrl = this.fb.control('');
    this.brandRefCtrl = this.fb.control('');
    this.workCodeCtrl = this.fb.control('');
    this.billingUnitCtrl = this.fb.control('');

    this.actionRefForm = this.fb.group({
      actionrefLabel: this.actionrefLabelCtrl,
      equipType: this.equipTypeCtrl,
      equipBrand: this.equipBrandCtrl,
      equipModel: this.equipModelCtrl,
      brandRef: this.brandRefCtrl,
      workCode: this.workCodeCtrl,
      billingUnit: this.billingUnitCtrl,
    },
    {
      updateOn: 'blur'
    });
       const listTmp = this._wksCentralService.getBillingUnits();
    this.billingUnitsList = [];
    for (const buCur of listTmp) {
      const curLabel: LabelValue = {
        label: this._translate.getTranslate(buCur),
        value: buCur
      };
      this.billingUnitsList.push(curLabel);
    }
    this.loadEntity();
  }
  loadEntity() {
    
    this._wksCentralService.getWksEntity(this.entityCur)
    .subscribe(
      data => {
        this.wksEntity = data.body;
        // this.displayJobRef();
        this.isLoaded = true;
        this.loadBrands()
        .then (brandList => {
          if ((this.actionsrefToUpdate !== undefined) && (this.actionsrefToUpdate.equipBrand) && (this.actionsrefToUpdate.equipModel)) {
            let brandSelected: BrandWksModel;
            for (const curBrand of this.brandCacheList) {
              if (curBrand.brandName === this.actionsrefToUpdate.equipBrand) {
                brandSelected = curBrand;
                break;
              }
            }
            // this.loadModels(brandSelected)
            // .then (modelList => { 
              this.loadWorksType()
              .then (dataReturn => {
                this.displayJobRef();
                this.isLoaded = true;
              })            
              .catch(error  => {
                console.log(error);
                this.isLoaded = true;
              });
 
            /*})
            .catch(error  => {
              console.log(error);
              this.isLoaded = true;
            });*/
          } else {
            this.loadWorksType()
            .then (dataReturn => {
              this.displayJobRef();
              this.isLoaded = true;
            })            
            .catch(error  => {
              console.log(error);
              this.isLoaded = true;
            });

          }
          this.isLoaded = true;
        })
        .catch(error  => {
          console.log(error);
          this.isLoaded = true;
        });
      },
      () => {
        this.errorMessage('entity');
      }
    );
  }

  loadBrands() {
    if (this._userService.getUserLogged() === undefined ) { return; }
    this.brandCacheList = [];
    this.brandFilteredList = [];
    this.modelCacheList = [];
    this.modelFilteredList = [];
    let brandList: BrandWksModel[];
  
    return new Promise((resolve, reject) => {
      this._wksCentralService.getWksBrandsList(this._userService.getUserLogged().entity)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(
        data => {
          brandList = data.body;
          for (const brandCur of brandList) {
            this.brandCacheList.push(brandCur);
          }
          this.brandCacheList.sort((obj1, obj2) => {
            return obj1.brandName > obj2.brandName ? 1 : -1;
          });
          this.brandCacheList.splice(0, 0,  {stdEntity: this._userService.getUserLogged().entity, brandRow: 'brand', brandName: ''} );
          this.brandFilteredList = [...this.brandCacheList];
          resolve(brandList);
        }, err => {
          console.log(err);
          if (err.status === 404) {
            this.brandCacheList.push({
              stdEntity: this._userService.getUserLogged().entity,
              brandRow: 'brand',
              brandName: ''
            });
            this.brandFilteredList = [...this.brandCacheList];

          }
          reject(err.status);
        }
      );
    });
  }
  onChangeBrand($event: any) {
    // console.log('onChangeBrand : ' + $event);
    let brandSelected: BrandWksModel;
    for (const curBrand of this.brandCacheList) {
      if (curBrand.brandName === $event) {
        brandSelected = curBrand;
        break;
      }
    }
    this.brandSelected = brandSelected;
    this.initModelList(brandSelected);
   
  }
  onChangeWork($event: any) {
 
    for (const curWork of this.workCodeList) {
      if (curWork.value === $event) {
        this.actionRefForm.controls.billingUnit.setValue(curWork.otherData);
        break;
      }
    }
  }
  onKeyBrand($event: any) {
    // console.log(value);
  
    let valueFinal: any;
    if ($event === '') {
      valueFinal = '';
    } else  {
      valueFinal = $event.target.value;
      if ( $event.keyCode === 32) {
        valueFinal += ' ';
      }
    }
    this.brandFilteredList = this.searchBrand(valueFinal);
    this.filterbrand.nativeElement.value = valueFinal;
  }
  searchBrand(value: string) { 
    const filter = value.toLowerCase();
    return this.brandCacheList.filter(brandCur => brandCur.brandName.toLowerCase().includes(filter));
  }
  addBrand(newBrandName: string) {
    // console.log(newBrand);
    if (newBrandName === '') {
      return;
    }
    const newBrand = {
      stdEntity: this._userService.getUserLogged().entity,
      brandRow: 'brand',
      brandName: newBrandName,
      equipType: '',
      modelName: '',
    };
    this.brandCacheList.push(newBrand);
    this.brandCacheList.sort((obj1, obj2) => {
      return obj1.brandName > obj2.brandName ? 1 : -1;
    });
    if (this.brandCacheList[0].brandName === '') {
      this.brandCacheList.splice(0, 1);
    }
    this.brandFilteredList = [...this.brandCacheList];
    this.modelCacheList = [];
    this.modelFilteredList = [...this.modelCacheList];
    // this._wksCentralService.saveWksBrand( newBrand, 'create');
    this._wksCentralService.saveBrandModel(newBrand);
  }
  initModelList(_brandSelected: BrandWksModel) {
    if (this._userService.getUserLogged() === undefined ) { return; }
    this.modelCacheList = [];
    this.modelFilteredList = [];
    this.loadModels(_brandSelected)
    .then ((modelList: BrandWksModel[]) => {

    })
    .catch((error) => {
 
    });
  }
  loadModels(_brandSelected: BrandWksModel) {
    let modelList: BrandWksModel[];
    let equipType = null;
    if (this.actionsrefToUpdate && this.actionsrefToUpdate.equipType) {
      equipType = this.actionsrefToUpdate.equipType;
    } else {
      try {
        equipType = this.actionRefForm.controls.equipType.value;
      } catch (err) {
        return;
      }
    }
    return new Promise((resolve, reject) => {
      this._wksCentralService.getWksModelsList(this._userService.getUserLogged().entity, _brandSelected.brandName, equipType)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(
        data => {
          modelList = data.body;
          for (const brandCur of modelList) {
            this.modelCacheList.push(brandCur);
          }
          this.modelCacheList.sort((obj1, obj2) => {
            return obj1.modelName > obj2.modelName ? 1 : -1;
          });
          this.modelCacheList.splice(0, 0,  {
              stdEntity: this._userService.getUserLogged().entity, 
              brandRow: 'model', 
              brandName: '',
              equipType: '',
              modelName: ''} );
          this.modelFilteredList = [...this.modelCacheList];
          resolve(modelList);
        }, err => {
          console.log(err);
          if (err.status === 404) {
            this.modelCacheList.push({
              stdEntity: this._userService.getUserLogged().entity,
              brandRow: 'model',
              brandName: _brandSelected.brandName,
              equipType: equipType,
              modelName: '',
            });
            this.modelFilteredList = [...this.modelCacheList];
          }
           reject(err);
        }
      );
    });

  }

  onKeyModel($event: any) {
    let valueFinal: any;
    if ($event === '') {
      valueFinal = '';
    } else  {
      valueFinal = $event.target.value;
      if ( $event.keyCode === 32) {
        valueFinal += ' ';
      }
    }
    this.modelFilteredList = this.searchModel(valueFinal);
    this.filtermodel.nativeElement.value = valueFinal;
  }
  searchModel(value: string) { 
    const filter = value.toLowerCase();
    return this.modelCacheList.filter(modelCur => modelCur.modelName.toLowerCase().includes(filter));
  }
  addModel(newModelName: string) {
    // console.log(newModel);
    let equipType = null;
    try {
      equipType = this.actionRefForm.controls.equipType.value;
    } catch (err) {
      return;
    }
    const newModel = {
      stdEntity: this._userService.getUserLogged().entity,
      brandRow: 'model',
      brandName: this.brandSelected.brandName,
      equipType: equipType,
      modelName: newModelName,
    };
    this.modelCacheList.push(newModel);
    this.modelCacheList.sort((obj1, obj2) => {
      return obj1.modelName > obj2.modelName ? 1 : -1;
    });
    if (this.modelCacheList[0].modelName === '') {
      this.modelCacheList.splice(0, 1);
    }
    this.modelFilteredList = [...this.modelCacheList];
    // this._wksCentralService.saveWksBrand( newModel, 'create');
    this._wksCentralService.saveBrandModel(newModel);
  }
 
  loadWorksType() {
    let dataReturn: ParamWorksRatesModel[];
    this.workCodeList = [];
    return new Promise((resolve, reject) => {
      this._wksCentralService.getWksWorkRatesList(this._userService.getUserLogged().entity, this._userService.getUserLogged().userlang)
     .pipe(takeUntil(this.onDestroy))
     .subscribe(
       data => {
          dataReturn = data.body;
          for (const workCur of dataReturn) {
            const actionTypeCur: LabelValue = {
              label: workCur.workLabel,
              value: workCur.workCode,
              otherData: workCur.workUnit
            };
            this.workCodeList.push(actionTypeCur);
          }
          this.workCodeList.sort((obj1: LabelValue, obj2: LabelValue) => {
            return obj1.label > obj2.label ? 1 : -1;
          });
          resolve(dataReturn) ;
        }, err => {
          console.log(err);
          reject(err.status);
        }
      );
    });
  }
  displayJobRef() {
    if (this.actionsrefToUpdate === undefined) {
      return;
    }
    this.actionRefForm.controls.actionrefLabel.setValue(this.actionsrefToUpdate.actionrefLabel); 
    this.actionRefForm.controls.equipType.setValue(this.actionsrefToUpdate.equipType); 

    this.actionRefForm.controls.equipBrand.setValue(this.actionsrefToUpdate.equipBrand);
    this.actionRefForm.controls.equipModel.setValue(this.actionsrefToUpdate.equipModel);
    this.actionRefForm.controls.brandRef.setValue(this.actionsrefToUpdate.brandRef);
    this.actionRefForm.controls.workCode.setValue(this.actionsrefToUpdate.workCode);
    this.actionRefForm.controls.billingUnit.setValue(this.actionsrefToUpdate.billingUnit);

    if ((this.actionsrefToUpdate.langLabels !== undefined) && (this.actionsrefToUpdate.langLabels !== null)) {
      for (const labelCur of this.actionsrefToUpdate.langLabels) {
        for ( const local of this.langLabelsList) {
          if (local.langValue === labelCur.labelLang) {
            local.langLabel = labelCur.labelText;
          }
        } 
      }
    }

  }

  setLangLabelValue(_event: string, _row: string) {
    const value = _event.charAt(0).toUpperCase() + _event.slice(1);
    if (this.langLabelsList[_row].langValue === this._translate.getCurrentLang()) {
      this.actionRefForm.controls.actionrefLabel.setValue(value);
    }
    this.langLabelsList[_row].langLabel = value;
  }

  registerJobRef() {
    const actionRefValidation = this.prepareValidation();
    this._wksCentralService.saveParamActionsRef(actionRefValidation, this.entityCur)
    .subscribe(
      paramWorksRatesReturn => {
        this.actionsrefToUpdate = paramWorksRatesReturn.body;
        this.isRegistered = true;
        if  (this.statutCall === 'sidenav') {
          this._wksCentralService.onRegisterValid.emit('closeRegisterAction');
          return;
        } else if  (this.statutCall === 'modal') {
            this._globalEventsManagerService.synchroEvents.next('closeRegisterActionModal');
            return;
        } else {
          this.router.navigate(['/jobHome']);
        }

      },
      () => {
        this.errorMessage('register');
      }
    );
  }
  prepareValidation(): ParamActionsrefModel {
    const paramLabelsI18nArray: ParamLabelsI18n[] = [];
    if ((this.actionsrefToUpdate !== undefined) 
      && (this.actionsrefToUpdate.langLabels !== undefined) 
      && (this.actionsrefToUpdate.langLabels !== null)) {
      for (const paramLabelsI18n of this.actionsrefToUpdate.langLabels) {
        for ( const local of this.langLabelsList) {
          if (local.langValue === paramLabelsI18n.labelLang) {
            paramLabelsI18n.labelText = local.langLabel;
          }
        }
        paramLabelsI18nArray.push(paramLabelsI18n); 
      }
    } else {
      for (const labelCur of this.langLabelsList) {
        const paramLabelsI18n = {
          stdEntity: this.entityCur,
          labelLang: labelCur.langValue,
          labelText: labelCur.langLabel,
        };
        paramLabelsI18nArray.push(paramLabelsI18n);
      }
    }
    const actionsrefValidation: ParamActionsrefModel = {
      id: (this.actionsrefToUpdate !== undefined ? this.actionsrefToUpdate.id : undefined),
      stdEntity: this.entityCur,
      actionrefId: (this.actionsrefToUpdate !== undefined ? this.actionsrefToUpdate.actionrefId : undefined),
      actionrefLabel: this.actionRefForm.controls.actionrefLabel.value,
      equipType: this.actionRefForm.controls.equipType.value,
      equipBrand: this.actionRefForm.controls.equipBrand.value,
      equipModel: this.actionRefForm.controls.equipModel.value,
      brandRef: this.actionRefForm.controls.brandRef.value,
      workCode: this.actionRefForm.controls.workCode.value,
      billingUnit:  this.actionRefForm.controls.billingUnit.value,
      stdCreation: (this.actionsrefToUpdate !== undefined ? this.actionsrefToUpdate.stdCreation : undefined),
      langLabels: paramLabelsI18nArray,
      userLang: this._userService.getUserLogged().userlang,
    };
    return actionsrefValidation;
  }
 
  errorMessage(_actionCur: string) {
    const dialogConfig = new MatDialogConfig();
    let titleBox: string;
    let messageBox: string;
    if (_actionCur === 'register') {
      messageBox = 'error recording';
      titleBox = 'recording action ref';
      
    }
    if (_actionCur === 'entity') {
      messageBox = 'error loading';
      titleBox = 'loading entity ';
    }
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      id: 1,
      title: this._translate.getTranslate(titleBox),
      typeDialog: 'alertWks',
      panelClass: 'stdTheme',
      contentMessage: this._translate.getTranslate(messageBox),
      data1: '',
      data2: '',
      messageType: 'ERROR'

      };
 //   this._dialog.open(ModalCommonComponent, dialogConfig);

    const dialogRef = this._dialog.open(ModalCommonComponent, dialogConfig);

    dialogRef.afterClosed()
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
        console.log('Dialog output:', data);
      });

  }
  doClose() {
    if  ((this.statutCall === 'modal')  ) {
      this._globalEventsManagerService.synchroEvents.next('closeActionModal');
      return;
    }
    this.router.navigate(['/jobHome']);
  }
  doCancel() {
    if  ((this.statutCall === 'modal')  ) {
      this._globalEventsManagerService.synchroEvents.next('closeActionModal');
      return;
    }
    if  (this.statutCall === 'sidenav') {
      this._wksCentralService.onRegisterValid.emit('closeRegisterAction');
      return;
    } else {
      this.router.navigate(['/jobHome']);
    }
  }
}
