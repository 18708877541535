import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { registerLocaleData } from '@angular/common';
import { DatepickerModule, BsDatepickerModule, BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { ToastrModule } from 'ngx-toastr';
import { MAT_LABEL_GLOBAL_OPTIONS, MAT_DIALOG_DEFAULT_OPTIONS, MatTableModule, MatPaginatorModule, MatFormFieldModule,
          MatSortModule, MatInputModule, MatProgressSpinnerModule, MatPaginatorIntl, MatMenuModule, MatCheckboxModule,
          MatDialogModule, MAT_CHECKBOX_CLICK_ACTION, MatTreeModule, MatChipsModule, MatAutocompleteModule, MatTooltipModule} from '@angular/material';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSelectFilterModule } from 'mat-select-filter';

import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MdePopoverModule } from '@material-extended/mde';
import { MaterialImportModule } from 'ng2-material-import'; // importe tous les éléments de material
import { CdkTreeModule } from '@angular/cdk/tree';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TreeModule } from 'angular-tree-component';
/*
import { ShareModule } from 'ngx-sharebuttons';
import { ShareButtonModule } from 'ngx-sharebuttons/button';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
*/
import { GoogleMapsModule } from '@angular/google-maps';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap'; // https://ng-bootstrap.github.io/#/home
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { FileUploadModule } from 'ng2-file-upload';
import { NgxFileHelpersModule } from 'ngx-file-helpers';
import { NgDashboardModule } from 'ngx-dashboard'; // non utilisé
import { NgxWidgetGridModule } from 'ngx-widget-grid';
import { ChartsModule } from 'ng2-charts';

import { AppComponent } from './app.component';
import { MenuComponent } from './auth/menu/menu.component';

import { ROUTES } from './app.routes';

import { HomeComponent } from './home/home.component';
import { UserService } from './services/user.service';
import { AuthService } from './services/auth.service';
import { FieldsFormgroupService } from './job/common/components/dynamic-form/fields-formgroup.service';
import { UserCardComponent } from './organization/users/user-card/user-card.component';
import { LoginComponent } from './auth/login/login.component';

import { TranslateService } from './services/translate.service';
import { TranslatePipe } from './services/translate.pipe';
import { TRANSLATION_PROVIDERS } from './i18n/translation';
import { MDIMatPaginatorIntl } from './i18n/mdimat-paginator-intl';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AgGridModule } from 'ag-grid-angular';
// import 'ag-grid-enterprise';
import { AuthInterceptor} from './auth/interceptors/auth.interceptor';
import { CdkTableModule } from '@angular/cdk/table';

import { InjectableRxStompConfig, RxStompService, rxStompServiceFactory } from '@stomp/ng2-stompjs';
/*
https://www.npmjs.com/package/@kolkov/angular-editor
https://stackblitz.com/edit/angular-editor-wysiwyg?file=src%2Fapp%2Fapp.component.ts
*/
import { AngularEditorModule } from '@kolkov/angular-editor';

import { CalendarModule, DateAdapter, CalendarNativeDateFormatter, DateFormatterParams, CalendarDateFormatter } from 'angular-calendar';
// import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { ImageViewerModule } from 'ngx-image-viewer';
// job

import { JobHomeComponent } from './job/job-home/job-home.component';
import { ChoiceLangComponent } from './i18n/choice-lang/choice-lang.component';
import { TokenStorage } from './auth/token.storage';
import { UsersTableComponent } from './organization/users/users-table/users-table.component';
import { FilterTemplateComponent } from './job/common/components/filter-template/filter-template.component';
import { DynamicListComponent } from './job/common/components/dynamic-list/dynamic-list.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ContextMenuModule } from 'ngx-contextmenu';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { TagInputModule } from 'ngx-chips';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer'; // <-- Import PdfJsViewerModule module
import { DynamicFormComponent } from './job/common/components/dynamic-form/dynamic-form/dynamic-form.component';
import { TopbarComponent } from './job/common/components/topbar/topbar.component';
import { EntitiesTableComponent } from './organization/entities/entities-table/entities-table.component';
import { EntityCardComponent } from './organization/entities/entity-card/entity-card.component';
import { UsersgrpTableComponent } from './organization/usersgrp/usersgrp-table/usersgrp-table.component';
import { UsersgrpCardComponent } from './organization/usersgrp/usersgrp-card/usersgrp-card.component';
import { UploadFilesComponent } from './job/common/components/upload-files/upload-files.component';

import { ModalCommonComponent } from './job/common/components/modal-common/modal-common.component';
import { AlertCommonComponent } from './job/common/components/alert-common/alert-common.component';
import { UserPwdComponent } from './organization/users/user-pwd/user-pwd.component';
import { UpdatePwdComponent } from './organization/users/update-pwd/update-pwd.component';
import { DatasourcesComponent } from './job/job-data/components/datasources/datasources-table/datasources.component';
import { DatasourceCardComponent } from './job/job-data/components/datasources/datasource-card/datasource-card.component';
import { RequestsTableComponent } from './job/job-data/components/requests/requests-table/requests-table.component';
import { RequestCardComponent } from './job/job-data/components/requests/request-card/request-card.component';
import { ChildTableComponent } from './job/common/components/child-table/child-table.component';
import { RequestDataaccessComponent } from './job/job-data/components/requests/request-dataaccess/request-dataaccess.component';
import { RequestDatalabelComponent } from './job/job-data/components/requests/request-datalabel/request-datalabel.component';
import { ReportsTableComponent } from './job/job-data/components/reports/reports-table/reports-table.component';
import { ReportCardComponent } from './job/job-data/components/reports/report-sheets/report-card/report-card.component';
import { ReportSheetsComponent } from './job/job-data/components/reports/report-sheets/report-sheets.component';
import { ReportColumnsComponent } from './job/job-data/components/reports/report-sheets/report-columns/report-columns.component';
import { ReportGridComponent } from './job/job-data/components/reports/report-ag-grid/grid/report-grid.component';
import { FilterCellComponent } from './job/job-data/components/reports/report-ag-grid/filters/filter-cell/filter-cell.component';
import { RowPinnedComponent } from './job/job-data/components/reports/report-ag-grid/rows/row-pinned/row-pinned.component';
import { ReportPinnedRowComponent } from './job/job-data/components/reports/report-sheets/report-pinned-row/report-pinned-row.component';
// tslint:disable-next-line: max-line-length
import { NumericRendererComponent } from './job/job-data/components/reports/report-ag-grid/renderers/numeric-renderer/numeric-renderer.component';
// tslint:disable-next-line: max-line-length
import { AlphanumRendererComponent } from './job/job-data/components/reports/report-ag-grid/renderers/alphanum-renderer/alphanum-renderer.component';
// tslint:disable-next-line: max-line-length
import { DatetimeRendererComponent } from './job/job-data/components/reports/report-ag-grid/renderers/datetime-renderer/datetime-renderer.component';
import { EntitiesMenuComponent } from './job/job-data/components/reports/entities-menu/entities-menu.component';
// tslint:disable-next-line: max-line-length
import { DataDetailRendererComponent } from './job/job-data/components/reports/report-ag-grid/renderers/datadetail-renderer/datadetail-renderer.component';
// tslint:disable-next-line: max-line-length
import { DetailiconRendererComponent } from './job/job-data/components/reports/report-ag-grid/renderers/detailicon-renderer/detailicon-renderer.component';
import { ReportSubreportComponent } from './job/job-data/components/reports/report-sheets/report-subreport/report-subreport.component';
import { DisplayPdfComponent } from './job/job-data/components/reports/display-pdf/display-pdf.component';
import { DashboardNgxComponent } from './job/job-data/components/reports/dashboard/dashboard-ngx/dashboard-ngx.component';
import { WidgetNgxComponent } from './job/job-data/components/reports/dashboard/widget-ngx/widget-ngx.component';
import { WidgetGridNgxComponent } from './job/job-data/components/reports/dashboard/widget-grid-ngx/widget-grid-ngx.component';
import { BarchartComponent } from './job/job-data/components/reports/charts/barchart/barchart.component';
import { LinechartComponent } from './job/job-data/components/reports/charts/linechart/linechart.component';
import { DoughnutChartComponent } from './job/job-data/components/reports/charts/doughnut-chart/doughnut-chart.component';
import { RadarChartComponent } from './job/job-data/components/reports/charts/radar-chart/radar-chart.component';
import { PolarAreaChartComponent } from './job/job-data/components/reports/charts/polar-area-chart/polar-area-chart.component';
import { PieChartComponent } from './job/job-data/components/reports/charts/pie-chart/pie-chart.component';
import { ReportDashboardComponent } from './job/job-data/components/reports/report-sheets/report-dashboard/report-dashboard.component';
import { JobReportHomeComponent } from './job/job-data/components/reports/jobreporthome/jobreporthome.component';
import { TreeCommonComponent } from './job/common/components/tree-common/tree-common.component';
import { ClipboardModule } from 'ngx-clipboard';
import { ClipboardService } from 'ngx-clipboard';
import { CoordinatesModule } from 'angular-coordinates';
import { BnsTableComponent } from './job/job-wks/components/repositories/bns/bns-table/bns-table.component';
import { BnsCardComponent } from './job/job-wks/components/repositories/bns/bns-card-sheets/bns-card/bns-card.component';
import { BnsCardSheetsComponent } from './job/job-wks/components/repositories/bns/bns-card-sheets/bns-card-sheets.component';
import { WksEntityComponent } from './job/job-wks/components/param/wks-entity/wks-entity.component';

import { ComponentFactoryService } from './job/common/services/component-factory.service';
import { RessourcesService } from './job/common/services/ressources.service';
import { AddressBlockComponent } from './job/common/components/address-block/address-block.component';
import { EquipmentTreeComponent } from './job/job-wks/components/repositories/equipment/equipment-tree/equipment-tree.component';
import { FormatFieldDirective } from './job/common/directives/format-field.directive';
import { EquipmentCardComponent } from './job/job-wks/components/repositories/equipment/equipment-card/equipment-card.component';
import { EquipmentTableComponent } from './job/job-wks/components/repositories/equipment/equipment-table/equipment-table.component';
import { StopPropagationDirective } from './job/common/directives/stop-propagation.directive';
import { EquipmentAccordionComponent } from './job/job-wks/components/repositories/equipment/equipment-accordion/equipment-accordion.component';
import { DocCardComponent } from './job/job-wks/components/repositories/docs/doc-card/doc-card.component';
import { DocsTableComponent } from './job/job-wks/components/repositories/docs/docs-table/docs-table.component';
import { DocPdfComponent } from './job/job-wks/components/repositories/docs/doc-pdf/doc-pdf.component';
import { DocImageComponent } from './job/job-wks/components/repositories/docs/doc-image/doc-image.component';
import { PopoverWksComponent } from './job/job-wks/components/others/popover-wks/popover-wks.component';
import { EquipmentOwnerComponent } from './job/job-wks/components/repositories/equipment/equipment-owner/equipment-owner.component';
import { WorkratesTableComponent } from './job/job-wks/components/param/work-rates/workrates-table/workrates-table.component';
import { WorkratesCardComponent } from './job/job-wks/components/param/work-rates/workrates-card/workrates-card.component';
import { ActionsrefTableComponent } from './job/job-wks/components/param/actionsref/actionsref-table/actionsref-table.component';
import { ActionsrefCardComponent } from './job/job-wks/components/param/actionsref/actionsref-card/actionsref-card.component';
import { TasksrefTableComponent } from './job/job-wks/components/param/tasksref/tasksref-table/tasksref-table.component';
import { TasksrefCardComponent } from './job/job-wks/components/param/tasksref/tasksref-card/tasksref-card.component';
import { TasksrefAccordionComponent } from './job/job-wks/components/param/tasksref/tasksref-accordion/tasksref-accordion.component';
import { TaskrefActionsComponent } from './job/job-wks/components/param/tasksref/taskref-actions/taskref-actions.component';
import { AnchoragesTableComponent } from './job/job-wks/components/repositories/anchorages/anchorages-table/anchorages-table.component';
import { AnchoragesCardComponent } from './job/job-wks/components/repositories/anchorages/anchorages-card/anchorages-card.component';
import { ContactsCardComponent } from './job/job-wks/components/repositories/contacts/contacts-card/contacts-card.component';
import { WorksCardComponent } from './job/job-wks/components/works/works-jobs/works-card/works-card.component';
import { WorksTableComponent } from './job/job-wks/components/works/works-jobs/works-table/works-table.component';
import { CriticalityRendererComponent } from './job/job-wks/components/works/works-jobs/works-table/renderers/criticality-renderer/criticality-renderer.component';
import { WorksTrackingComponent } from './job/job-wks/components/works/works-jobs/works-tracking/works-tracking.component';
import { DocworksWksComponent } from './job/job-wks/components/works/works-jobs/works-docs/works-docs.component';
import { WorksImgComponent } from './job/job-wks/components/works/works-jobs/works-img/works-img.component';
import { WorksPdfComponent } from './job/job-wks/components/works/works-jobs/works-pdf/works-pdf.component';
import { FormatNumberPipe } from './job/common/directives/format-number.pipe';
import { SafeHtmlPipe } from './job/job-wks/directives/safe-html.pipe';
import { AvailableRendererComponent } from './job/job-wks/components/works/works-jobs/works-table/renderers/available-renderer/available-renderer.component';
import { NoRightClickDirective } from './job/common/directives/no-right-click.directive';
import { EnvServiceProvider } from './services/env.service.provider';
import { EquipmentMetadataComponent } from './job/job-wks/components/repositories/equipment/equipment-metadata/equipment-metadata.component';
import { GoogleLocationComponent } from './job/common/components/geolocation/google-location/google-location.component';
import { SubsidiariesComponent } from './job/job-wks/components/param/subsidiaries/subsidiaries.component';
import { AppointmentsTableComponent } from './job/job-wks/components/works/appointments-follow/appointments-table/appointments-table.component';
import { DataTablePagerComponent } from './job/common/components/ngx-datatable/datatable-pager/datatable-pager.component';
import { AppointmentCardComponent } from './job/job-wks/components/works/appointments-follow/appointment-folder/appointment-card/appointment-card.component';
import { AppointmentClientCardComponent } from './job/job-wks/components/works/appointments-follow/appointment-folder/client-card/appointment-client-card.component';
import { AppointmentEquipmentCardComponent } from './job/job-wks/components/works/appointments-follow/appointment-folder/equipment-card/appointment-equipment-card.component';
import { InputFmtFieldDirective } from './job/job-wks/directives/input-fmt-field.directive';
import { AppointmentTrackingComponent } from './job/job-wks/components/works/appointments-follow/appointment-folder/appointment-tracking/appointment-tracking.component';
import { CalendarComponent } from './job/common/components/calendar-agl/calendar/calendar.component';
// import { CalendarUtilsModule } from './job/common/components/calendar-agl/calendar/utils/module';
import { CalendarHeaderComponent } from './job/common/components/calendar-agl/calendar/utils/calendar-header.component';
import { CustomDateFormatter } from './job/common/components/calendar-agl/calendar/utils/customDateFormatter.component';
import localeFr from '@angular/common/locales/fr';
import localeEs from '@angular/common/locales/en';
import { AppointmentsPlanningComponent } from './job/job-wks/components/works/appointments-planning/appointments-planning.component';
import { PlanningHrComponent } from './job/job-wks/components/param/planning-hr/planning-hr.component';
import { EmailFormComponent } from './job/common/components/email-form/email-form.component';
import { TextEditorComponent } from './job/common/components/text-editor/text-editor.component';
import { TaskrefParentComponent } from './job/job-wks/components/param/tasksref/tasksref-card/taskref-parent/taskref-parent.component';
import { PartsSuppliesCardComponent } from './job/job-wks/components/param/parts-supplies/parts-supplies-card/parts-supplies-card.component';
import { PartsSuppliesTableComponent } from './job/job-wks/components/param/parts-supplies/parts-supplies-table/parts-supplies-table.component';
import { PartsSuppliesUploadComponent } from './job/job-wks/components/param/parts-supplies/parts-supplies-upload/parts-supplies-upload.component';
import { PartsSuppliesUpdateComponent } from './job/job-wks/components/param/parts-supplies/parts-supplies-update/parts-supplies-update.component';
import { PartsSuppliesSearchComponent } from './job/job-wks/components/param/parts-supplies/parts-supplies-search/parts-supplies-search.component';
import { AnchoragesUploadComponent } from './job/job-wks/components/repositories/anchorages/anchorages-upload/anchorages-upload.component';
import { WorksEquipmentComponent } from './job/job-wks/components/works/works-jobs/works-equipment/works-equipment.component';
import { WorksInterventionComponent } from './job/job-wks/components/works/works-jobs/works-intervention/works-intervention.component';
import { WorksTasksComponent } from './job/job-wks/components/works/works-jobs/works-tasks/works-tasks.component';
import { WorksAssignmentsComponent } from './job/job-wks/components/works/works-jobs/works-assignments/works-assignments.component';
import { WorksChangeStatutComponent } from './job/job-wks/components/works/works-jobs/works-change-statut/works-change-statut.component';
// WorksLocationComponent
import { LocationWksComponent } from './job/job-wks/components/others/location-wks/location-wks.component';
import { WorksPlanningComponent } from './job/job-wks/components/works/works-jobs/works-planning/works-planning.component';
import { WorksHistoryComponent } from './job/job-wks/components/works/works-jobs/works-history/works-history.component';
import { WorksHistoryCardComponent } from './job/job-wks/components/works/works-jobs/works-history/works-history-card/works-history-card.component';
import { WorksInvoicingComponent } from './job/job-wks/components/works/works-jobs/works-invoicing/works-invoicing.component';
import { WorksChecklistComponent } from './job/job-wks/components/works/works-jobs/works-checklist/works-checklist.component';
import { TasksChecklistComponent } from './job/job-wks/components/works/works-jobs/works-checklist/tasks-checklist/tasks-checklist.component';
import { VerificationCkecklistComponent } from './job/job-wks/components/works/works-jobs/works-checklist/verification-ckecklist/verification-ckecklist.component';
import { WorksRecapAmountComponent } from './job/job-wks/components/works/works-jobs/works-recap-amount/works-recap-amount.component';
import { WorksAccountingComponent } from './job/job-wks/components/works/works-jobs/works-accounting/works-accounting.component';
import { AccountingRendererComponent } from './job/job-wks/components/repositories/bns/bns-table/renderers/accounting-renderer/accounting-renderer.component';
import { SchedulerTableComponent } from './organization/scheduler/scheduler-table/scheduler-table.component';

import { SchedulerCardComponent } from './organization/scheduler/scheduler-card/scheduler-card.component';
import { SchedulerJournalComponent } from './organization/scheduler/scheduler-journal/scheduler-journal.component';
import { localRxStompConfig } from './job/common/components/websocket/config/local-rx-stomp.config';
import { ApplicationTestComponent } from './organization/application/application-test/application-test.component';

import { EnableDisableDirective } from './job/common/directives/enable-disable.directive';
import { CalculationAlgoTableComponent } from './job/common/components/calculation-algo/algo-table/calculation-algo-table.component';
import { ApisComponent } from './job/job-wks/components/param/wks-entity/entity-tabs/apis/apis.component';
import { SnRendererComponent } from './job/job-wks/components/param/tasksref/tasksref-table/sn-renderer/sn-renderer.component';

import { ManufacturersSpecificationsComponent } from './job/job-wks/components/others/manufacturers-specifications/manufacturers-specifications.component';
import { ReloadConfigComponent } from './organization/application/reload-config/reload-config.component';
import { ViewDocComponent } from './job/common/components/view-doc/view-doc.component';
import { SpecificationsTableComponent } from './job/job-wks/components/others/manufacturers-specifications/imports/specifications-table/specifications-table.component';
import { SpecificationsCardComponent } from './job/job-wks/components/others/manufacturers-specifications/imports/specifications-card/specifications-card.component';
import { EquipUsageHoursComponent } from './job/job-wks/components/repositories/equipment/equip-usage-hours/equip-usage-hours.component';
import { WorksPhotosComponent } from './job/job-wks/components/works/works-jobs/works-photos/works-photos.component';
import { WorkIdRendererComponent } from './job/job-wks/components/works/works-jobs/works-table/renderers/work-id-renderer/work-id-renderer.component';
import { WorksOutputStocksComponent } from './job/job-wks/components/works/works-jobs/works-output-stocks/works-output-stocks.component';
import { PartsSuppliesWarehouseComponent } from './job/job-wks/components/param/parts-supplies/parts-supplies-warehouse/parts-supplies-warehouse.component';



registerLocaleData(localeFr);
registerLocaleData(localeEs);
/*
export class CustomDateFormatter extends CalendarNativeDateFormatter {

  public dayViewHour({date, locale}: DateFormatterParams): string {
    // change this to return a different date format
    return new Intl.DateTimeFormat(locale, {hour: 'numeric'}).format(date);
  }

  public weekViewHour({date, locale}: DateFormatterParams): string {
    // change this to return a different date format
    return new Intl.DateTimeFormat(locale, {hour: 'numeric'}).format(date);
  }

}
*/
@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    HomeComponent,
    UserCardComponent,
    LoginComponent,
    TranslatePipe,
    JobHomeComponent,
    ChoiceLangComponent,
    UsersTableComponent,
    FilterTemplateComponent,
    DynamicListComponent,
    DynamicFormComponent,
    TopbarComponent,
    EntitiesTableComponent,
    EntityCardComponent,
    UsersgrpTableComponent,
    UsersgrpCardComponent,
    UploadFilesComponent,
    ModalCommonComponent,
    AlertCommonComponent,
    UserPwdComponent,
    UpdatePwdComponent,
    DatasourcesComponent,
    DatasourceCardComponent,
    RequestsTableComponent,
    RequestCardComponent,
    ChildTableComponent,
    RequestDataaccessComponent,
    RequestDatalabelComponent,
    ReportsTableComponent,
    ReportCardComponent,
    ReportSheetsComponent,
    ReportColumnsComponent,
    ReportGridComponent,
    FilterCellComponent,
    RowPinnedComponent,
    ReportPinnedRowComponent,
    NumericRendererComponent,
    AlphanumRendererComponent,
    DatetimeRendererComponent,
    EntitiesMenuComponent,
    DataDetailRendererComponent,
    DetailiconRendererComponent,
    ReportSubreportComponent,
    DisplayPdfComponent,
    DashboardNgxComponent,
    WidgetNgxComponent,
    WidgetGridNgxComponent,
    BarchartComponent,
    LinechartComponent,
    DoughnutChartComponent,
    RadarChartComponent,
    PolarAreaChartComponent,
    PieChartComponent,
    ReportDashboardComponent,
    JobReportHomeComponent,
    TreeCommonComponent,
    BnsTableComponent,
    BnsCardComponent,
    BnsCardSheetsComponent,
    WksEntityComponent,
    AddressBlockComponent,
    EquipmentTreeComponent,
    FormatFieldDirective,
    EquipmentCardComponent,
    EquipmentTableComponent,
    StopPropagationDirective,
    EquipmentAccordionComponent,
    DocCardComponent,
    DocsTableComponent,
    DocPdfComponent,
    DocImageComponent,
    PopoverWksComponent,
    EquipmentOwnerComponent,
    WorkratesTableComponent,
    WorkratesCardComponent,
    ActionsrefTableComponent,
    ActionsrefCardComponent,
    TasksrefTableComponent,
    TasksrefCardComponent,
    TasksrefAccordionComponent,
    TaskrefActionsComponent,
    AnchoragesTableComponent,
    AnchoragesCardComponent,
    ContactsCardComponent,
    WorksCardComponent,
    WorksTableComponent,
    CriticalityRendererComponent,
    WorksTrackingComponent,
    DocworksWksComponent,
    WorksImgComponent,
    WorksPdfComponent,
    FormatNumberPipe,
    SafeHtmlPipe,
    AvailableRendererComponent,
    NoRightClickDirective,
    EquipmentMetadataComponent,
    GoogleLocationComponent,
    SubsidiariesComponent,
    AppointmentsTableComponent,
    DataTablePagerComponent,
    AppointmentCardComponent,
    AppointmentClientCardComponent,
    AppointmentEquipmentCardComponent,
    InputFmtFieldDirective,
    AppointmentTrackingComponent,
    CalendarComponent,
    CalendarHeaderComponent,
    AppointmentsPlanningComponent,
    PlanningHrComponent,
    EmailFormComponent,
    TextEditorComponent,
    TaskrefParentComponent,
    PartsSuppliesCardComponent,
    PartsSuppliesTableComponent,
    PartsSuppliesUploadComponent,
    PartsSuppliesUpdateComponent,
    PartsSuppliesSearchComponent,
    AnchoragesUploadComponent,
    WorksEquipmentComponent,
    WorksInterventionComponent,
    WorksTasksComponent,
    WorksAssignmentsComponent,
    WorksChangeStatutComponent,
    LocationWksComponent,
    WorksPlanningComponent,
    WorksHistoryComponent,
    WorksHistoryCardComponent,
    WorksInvoicingComponent,
    WorksChecklistComponent,
    TasksChecklistComponent,
    VerificationCkecklistComponent,
    WorksRecapAmountComponent,
    WorksAccountingComponent,
    AccountingRendererComponent,
    SchedulerTableComponent,
    SchedulerCardComponent,
    SchedulerJournalComponent,
    ApplicationTestComponent,
    EnableDisableDirective,
    CalculationAlgoTableComponent,
    ApisComponent,
    SnRendererComponent,
    ManufacturersSpecificationsComponent,
    ReloadConfigComponent,
    ViewDocComponent,
    SpecificationsTableComponent,
    SpecificationsCardComponent,
    EquipUsageHoursComponent,
    WorksPhotosComponent,
    WorkIdRendererComponent,
    WorksOutputStocksComponent,
    PartsSuppliesWarehouseComponent

  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule.forRoot(ROUTES),
    FlexLayoutModule,
    MaterialImportModule, // importe tous les éléments de material
    NgbModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    MatTooltipModule,
    NgxDatatableModule,
    MatMenuModule,
    MatCheckboxModule,
    FileUploadModule,
    NgxFileHelpersModule,
    MatDialogModule,
    MatTreeModule,
    MatChipsModule,
    CdkTreeModule,
    ModalModule.forRoot(),
    AgGridModule.withComponents([FilterCellComponent,
                  RowPinnedComponent,
                  NumericRendererComponent,
                  AlphanumRendererComponent,
                  DatetimeRendererComponent,
                  DataDetailRendererComponent,
                  DetailiconRendererComponent,
                  CriticalityRendererComponent,
                  AvailableRendererComponent,
                  AccountingRendererComponent,
                  SnRendererComponent,
                  WorkIdRendererComponent,
                ]),
    CdkTableModule,
    DragDropModule,
    TreeModule.forRoot(),
    ContextMenuModule.forRoot(),
    PdfViewerModule,
    NgxExtendedPdfViewerModule,
    PdfJsViewerModule,
    TagInputModule,
    NgDashboardModule,
    NgxWidgetGridModule,
    ChartsModule,
    MdePopoverModule,
    ClipboardModule,
    /*
    ShareModule,
    ShareButtonModule,
    ShareIconsModule, // Optional if you want the default share icons
    */
    CoordinatesModule,
    GoogleMapsModule,
    MatDatepickerModule,
    NgxMaterialTimepickerModule,
    MatNativeDateModule ,
    AngularEditorModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    MatSelectFilterModule,
    BsDatepickerModule.forRoot(),
    DatepickerModule.forRoot() ,
    TimepickerModule.forRoot(),
    ToastrModule.forRoot(),
    ImageViewerModule.forRoot()
  ],

  providers: [
    EnvServiceProvider,
    RessourcesService, 
    ComponentFactoryService,
    UserService,
    AuthService,
    FieldsFormgroupService,
    TRANSLATION_PROVIDERS,
    TranslateService,
    TokenStorage,
    MatNativeDateModule,
    MatDatepickerModule,
    {
      provide: InjectableRxStompConfig,
      useValue: localRxStompConfig
    },
    {
      provide: RxStompService,
      useFactory: rxStompServiceFactory,
      deps: [InjectableRxStompConfig]
    },
    ClipboardService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: MAT_CHECKBOX_CLICK_ACTION, useValue: 'check'},
    { provide: MAT_LABEL_GLOBAL_OPTIONS, useValue: {float: 'auto'}},
    { provide: MatPaginatorIntl, useFactory: (translate) => {
      const service = new MDIMatPaginatorIntl();
      service.injectTranslateService(translate);
      return service;
      },
      deps: [TranslateService]
    },
    {provide: CalendarDateFormatter, useClass: CustomDateFormatter},
    BsDatepickerConfig
    /* { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true } */
  ],
  entryComponents: [ModalCommonComponent, 
                    DocPdfComponent,
                    WorksPdfComponent,
                    ViewDocComponent,
                    EquipmentOwnerComponent, 
                    WorksChangeStatutComponent, 
                    WorksPlanningComponent, 
                    WorksHistoryCardComponent,
                    VerificationCkecklistComponent ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  bootstrap: [AppComponent],

})
export class AppModule { }

