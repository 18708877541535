
import { CalendarEvent } from 'angular-calendar';

export class YMDfmt {
    year: number;
    month: number;
    day: number;
}
export interface FormFieldFmt {
    pattern: string;
    displayFmt: string;
    displayUnfmt: string;
    fieldType: string;
    lenght: number;
    separator: string;
    upperCase: boolean;
  }
export interface Condition {
    id: string;
    text: string;
    applic: string;
}

export interface OptionsData {
    dataname: string;
    isSelected: boolean;
}
export interface OptionsTable {
    prop: string;
    name: boolean;
}

export class ReadFile {
    nameFile: string;
    pathFile?: string;
    sizeFile: number;
    typeFile: string;
    rowsFile?: number;
    messageFile: string;
    fileObject: File;
    validatedFile: boolean;
    uploadedFile: boolean;
    legendFile?: string;
}
export interface FileToDisplay {
    fileName: string;
    fileLegend: string;
    filePath: string;
    fileRef?: string;
    dataImgLoaded: boolean;
    imageToShow: any;
    fileNameFormatted?: string;
    fileType?: string;
    fileData?: any;
}
export class ReportEvent {
    level: string; // ERROR WARNING INFO
    message: string;
    nameObject: string;
    data: string;
}

export class AnalyseDataCsv {
    fileName: string;
    action: string;
    numLine: number;
    numColumn: number;
    columnName: string;
    cellValue: any;
    otherValue1: any;
    otherValue2: any;
    errorMessage: string;
}
export interface ColumnsHeader {
    columnName: string;
    required?: boolean;
    otherData?: string;
    isDate?: boolean; 
    isCurrency?: boolean;
    isNumber?: boolean;
}
export interface ColumnsHeaderUpload {
    columnRef: string;
    columnName: string;
    selected?: boolean;
    columnBdd?: string;
}
export interface FileUpload {
    readFile: ReadFile;
    columnsHeader: ColumnsHeaderUpload[];
    crossReferenced: string[];
    jsonContent: any;
}
export interface ColumnsHeaderStatut {
    columnName: string;
    notPresent?: boolean;
    unKnow?: boolean;
    ok?: boolean;
}
export interface Criterias {
    brandName: string;
    incomingCurrency: string;
    targetCurrency: string;
    exchangeRate: string;
    lang: string[];
    action: string;
}
export class Alert {
    type: AlertType;
    message: string;
    data1: string;
    data2: string;
    alertType: string;
}

export enum AlertType {
    Success,
    Important,
    Error,
    Info,
    Warning
}
export enum LevelType {
    parent,
    child,
}
export enum AurAction {
    add,
    update,
    remove
}
export enum StateType {
    noState,
    waiting,
    start,
    done,
}
export class CommonReport {
    type: AlertType;
    level: LevelType;
    state: StateType;
    pathParent: string;
    id: string;
    message: string;
    isEndReport: boolean;
    isStartReport: boolean;
    isLastChild: boolean;
    isNewParent: boolean;
    orig: string;
    toString(): string {
        return ' orig : ' + this.orig + ' type : ' + this.type + ' level : ' + this.level + ' state : ' + this.state + ' pathParent : ' + this.pathParent + ' id : ' +
            this.id + ' message : ' + this.message + ' isEndReport : ' + this.isEndReport +  ' isStartReport : ' + 
            this.isStartReport +  ' isLastChild : ' + this.isLastChild + ' isNewParent : ' + this.isNewParent;
    }
}

export interface NavItem {
    displayName: string;
    disabled?: boolean;
    iconName?: string;
    route?: string;
    children?: NavItem[];
    triggerName?: string;
  }

export class ItemMenu {
    id: string;
    itemLabel: string;
    itemParent: string;
    itemOrder: string;
    itemChild?: string;
    itemStatut: string;
    itemRoles: string;
    itemRoutingPath?: string;
    itemRoutingArgs?: string;
    rolesAllowed: string;
    rolesProhibited?: string;
    itemConstraints?: string;
    itemMenupage?: string;
    itemImg?: string;
    itemChildren?: ItemMenu[];
}
export interface NotifUsers {
    contentMessage: string;
    recipients: string;
    dateSend: Date;
    ID: string;
  }

export interface CommonNode {
    name: string;
    action?: any;
    children?: CommonNode[];
  }
export interface ColumnTableDef { 
    name: string;
    prop: string;
    width?: number;
    isDate?: boolean; 
    isCurrency?: boolean;
    isNumber?: boolean;
    cellTemplate?: any;
}

// start ngx-datatable
export class Page {
    // The number of elements in the page
    size = 0;
    // The total number of elements
    totalElements = 0;
    // The total number of pages
    totalPages = 0;
    // The current page number
    pageNumber = 0;
}
export class PagedData<T> {
    data = new Array<T>();
    page = new Page();
}
// end  ngx-datatable*
// Calendar
export interface EventDef {
  type: string;
  name: string;
  primary: string;
  secondary: string;
  bulletColor: string;
}
export interface EventRh {
    type: string;
    label: string;
}
export interface EventChanged {
    eventCur: CalendarEvt;
    action: string;
}
export interface ColorEvent {
    primary: string;
    secondary: string;
}
export interface CalendarEventBdd {
    id: string;
    stdEntity: string;
    calApplication: string;
    calType: string;
    calLinkid: string;
    calStart: string;
    calEnd: string;
    calAction: string;
    calTitle: string;
    calOutside: string;
    calDescription: string;
    calComment: string;
    calAllday: boolean;
    calActor: string;
    calOtherdatas: string;
    calStatut: string;
    stdCreationDate?: Date;
    stdCreationUser?: string;
    stdLastupdateDate?: Date;
    stdLastupdateUser?: string;
    stdDisableDate?: Date;
    stdDisableUser?: string;
    calDateStart?: string;
    calDateEnd?: string;
    workRef?: string;
    calObject?: any; 
  }
export interface CalendarEvt extends CalendarEvent {
    linkId?: string;
    description?: string;
    comment?: string;
    calType?: string;
    ref?: string;
    outside?: string;
    bddId?: string;
    calStatut?: string;
    stdCreationDate?: Date;
    stdCreationUser?: string;
    action?: string;
    bddAction?: string;
    oldTime?: {
        start?: Date;
        end?: Date;
      };
    calObject?: any;
}
export interface EventChanged {
    eventCur: CalendarEvt;
    action: string;
}
  
export interface WorkingTime {
    dayHours: string[];
    weekStartsOn: number;
    weekendDays: number[];
}
export interface CmdExpression {
    operande: string[];
    numberValues: number[];
    stringValues: string[] ;
    technicalCodes: string[];
}
export interface CondClause {
    orAnd: string;
    comparator: string ;
    numberValues: number[] ;
    stringValues: string[] ;
    technicalCodes: string[];
}
export interface CondExpression {
    condClauses: CondClause[];
    nestedExpressions: CmdExpression[];
}

export interface DataOperation  {
    conditionnal: boolean;
    conditionnalExpression: CondExpression;
    cmdExpression: CmdExpression;
}
export interface CalculationCmd {
    dataLabel: string;
    technicalCode: string;
    constantName: string;
    fixedData: boolean;
    noCmdData?: boolean;
    inputTest?: boolean;
    countryInput?: boolean;
    currencyInput?: boolean;
    currencyFmt?: boolean;
    currencyImported?: boolean;
    duties?: boolean;
    dataOperations: DataOperation[];
    stringValue: boolean;
    numberValue: boolean;
    currentValue: any;
    otherData?: string;
}
export interface CalculationValues { 
    value1: number;
    value2: number;
}

export interface AlgoData { 
    algoName: string;
    dataOrigin: string;
    dataName: string;
}
export interface AlgoDatas { 
    localPrice: AlgoData[];
    importedPrice: AlgoData[];
}

export interface ToolService { 
    toolServiceName: string;
    toolServiceMethod: string;
    toolServiceUrl: string;
    toolServiceArgs: string;
} 
export interface ToolKeyValue { 
    key: string;
    value: string;
    listValues?: any;
    arrayValues?: any[];
}
export interface ToolApiParam { 
    apiService: string;
    menuTest: string;
    authorised: boolean;
    initSession: boolean;
    previousServices: OtherService [];
    nextServices: OtherService [];
    alternativeService: string;
    statut: string;
    testDate: string;
    toolDatas: ToolKeyValue[];
}
export interface ToolApiResponse {
    apiService: string;
    statut: string;
    statutNum: number;
    testDate: string;
    toolDatas: ToolKeyValue[];
}
export interface ToolArgs { 
    apiName?: string;
    serviceName?: string;
    toolDatas: ToolKeyValue[];
} 
export interface ToolApiEntity { 
    apiName: string;
    apiTool: ToolApiConnect;
    apiActive: boolean;
    apiTestedOK: boolean;
} 
export interface ToolApiConnect { 
    toolName: string;
    toolVersion: string;
    toolCustomerNwsId: string;
    toolCustomerNwsIdField: string;
    toolCustomerNwsCode: string;
    toolCustomerNwsCodeField: string;
    toolWebService: { 
        toolProtocol: string;
        toolCredentials: { 
            toolAuthentification: string;
            username?: string;
            pwd?: string;
            toolkeyname?: string;
            toolkeyvalue?: string;
        };
        toolArgs: ToolKeyValue[];
        toolHeaders: ToolKeyValue[];
        toolServerUrl: string;
        toolServerArgs: string;
        // toolServices: ToolService[];
        toolAuth2: { 
            activateJwt: boolean;
            toolJwtServerUrl: string;
            toolJwtServerArgs: string;
        };
    };
    toolTests: ToolApiParam[];
    toolImport: { 
        model: string;
        scheduling: string;
    };
    toolExport: { 
        model: string;
        scheduling: string;
    };
}

export interface ToolsApisType {
    apiName: string;
    apiTools: ToolApi [];
}
export interface ToolApi {
    toolName: string;
    toolVersions: ToolApiVersion [];
}
export interface ToolApiVersion {
    toolVersion: string;
    toolServices: ToolService [];
}
export interface ToolService {
    name: string;
    menuTest: string;
    authorised: boolean;
    initSession: boolean;
    previousServices: OtherService [];
    nextServices: OtherService [];
    alternativeService: string;
    server: Server;
    body: BodyApi [];
    testData: ToolApiTest [];
    response: ResponseStatut [];
}
export interface OtherService {
    name: string;
    args: ServiceArg [];
}
export interface ServiceArg {
    key: string;
    value: string;
    typeData: string;
    mandatoryData: boolean;
    defaultValue: string;
}
export interface Server {
    method: string;
    url: string;
    urlCplt: UrlCplt [];
    args: UrlArg [];
}
export interface BodyApi {
    nodePath: string;
    fields: ToolApiDatas [];
} 
export interface ResponseStatut {
    statut: string;
    result: Result;
}
export interface Result {
    nodeValue: string;
    nodeType: string;
    nodeResult: string;
    fields: ToolApiDatas [];
}

export interface UrlCplt {
    appDataName: string;
    mandatoryData: boolean;
    defaultValue: string;
}
export interface UrlArg {
    key: string;
    value: string;
    mandatoryData: boolean;
    defaultValue: string;
}
export interface ToolApiDatas {
    toolDataName: string;
    toolDataType: string;
    appDataName: string;
    mandatoryData: boolean;
    defaultValue: string;
}
export interface ToolApiTest {
    dataName: string;
    dataValue: string;
}
export interface ToolApiDatasReturn {
   datas: ToolApiReturn[];
}
export interface ToolApiReturn {
    dataName: string;
    dataValue: any;
}
export interface MessageDisplay {
    messageLabel: string;
    messageArgs: string[];
    messageType: string;
    messageDatas: any;
    typeDialog: string;
    titleBox: string;
    actionCur: string;
    progressDeterminate: boolean;
    otherArgs?: any;
}


