
<mdi-topbar [isHome]="false"></mdi-topbar>
<div class="mat-elevation-z8 container-fluid" style="margin-top: 75px; width: 100%;overflow:hidden !important">

    <div class="flex-row row" style="height: 45px; padding: 0px; margin-left: 0px; margin-right: 0px;overflow:hidden !important">
        <div class="col top-sidenav">
          <span class="horizontal-spacer5"></span>
          <span style="margin-left: 45%; font-size:large">{{ 'param_partssupplies' | translate }}</span>
        </div>
      </div>
      <div class="mat-elevation-z8 container-sidenav row w-100" style="margin-top: 125px;overflow:hidden !important">

        <mat-sidenav-container *ngIf="displayType === 'list'" fxFlexFill class="container-sidenav w-100" autosize (backdropClick)="toggleSidenav('backdrop')" [hasBackdrop]="hasBackdrop">
          <mat-sidenav #sidenav fxLayout="column" mode="over" opened="false" position="end" class="lateral-sidenav-70" disableClose>
              <div fxLayout="column" *ngIf="isRegisterPartSupplie">
                <a (click)="toggleSidenav('')" *ngIf="doCreatePartSupplie" mat-raised-button class="buttonTheme" style="font-size: 12px">{{'Creation' | translate}}
                  <a> ({{ 'param_partssupplies' | translate }})</a>
                </a>
                <a (click)="toggleSidenav('')" *ngIf="!doCreatePartSupplie" mat-raised-button class="buttonTheme" style="font-size: 12px">{{'Update' | translate}}
                  <a> ({{ 'param_partssupplies' | translate }})</a>
                </a>
                <a>
                  <mdi-parts-supplies-card style="width: 100%;"
                    [statutCall]="'sidenav'" 
                    [optionItem]="''" 
                    [actionType]="actionType" 
                    [partSupplieToUpdate]="partSupplieToUpdate">
                  </mdi-parts-supplies-card>
                </a>
              </div>
              <div fxLayout="column" *ngIf="isUploadPartsSupplies">
                <a>
                  <mdi-parts-supplies-upload style="width: 100%;"
                    [statutCall]="'sidenav'" 
                    [optionItem]="''" 
                    [actionType]="actionType" 
                    [randomIdString]="randomIdString" 
                    >
                  </mdi-parts-supplies-upload>
                </a>
              </div>
              <!-- doUpdateRatesPartSupplie -->
              <div fxLayout="column" *ngIf="doUpdateRatesPartSupplie">
                <a>
                  <mdi-parts-supplies-update style="width: 100%;"
                    [statutCall]="'sidenav'" 
                     >
                  </mdi-parts-supplies-update>
                </a>
              </div>
              <div fxLayout="column" *ngIf="doDisplayStockMovements">
                <a>
                  <mdi-parts-supplies-warehouse style="width: 100%;"
                    [statutCall]="'sidenav'" 
                     >
                  </mdi-parts-supplies-warehouse>
                </a>
              </div>
          </mat-sidenav>
          <mat-sidenav-content style="padding-left: 15px;overflow:hidden !important">
    
            <div *ngIf="OKtoDisplaySelect" class="container-fluid" style="height: 100%;">
              <div class="flex-row row stdTheme w-100" >
                <div class="col-8 d-flex justify-content-start align-items-center  "  >
                  <div class="flex-row row stdTheme w-100" >
                    <div class="col-1 d-flex justify-content-start align-items-center  "  >
                      <span><b>{{ 'suppliesSelection' | translate }}</b></span>
                    </div>
                    <div class="col-2 d-flex justify-content-start align-items-center  "  >
                      <mat-form-field  appearance="standard">
                          <mat-select id="paramType" placeholder="{{ 'Choose paramType' | translate }}" 
                                  panelClass="mdiselectcontent"   ngDefaultControl required 
                                  #paramType
                                 (selectionChange)="changeSelection('paramType',paramType.value)"
                                  >
                              <mat-option class="selectOptionsTheme" *ngFor="let paramTypeCur of paramTypeList" 
                                  [value]="paramTypeCur.value"  >{{paramTypeCur.label }}
                              </mat-option>
                          </mat-select>
                      </mat-form-field>
                    </div> 
                    <div class="col-2 d-flex justify-content-start align-items-center  "  >
                      <mat-select id="brandName" placeholder="{{ 'Choose brand' | translate }}" 
                                  panelClass="mdiselectcontent" #brandName required
                                  (selectionChange)="changeSelection('brandName',brandName.value)"
                                  ngDefaultControl >
                          <mat-option class="selectOptionsTheme" *ngFor="let brandCur of brandFilteredList" 
                              [value]="brandCur.brandName"  >{{brandCur.brandName}}
                          </mat-option>
                      </mat-select>   
                    </div>
                    <div class="col-3 d-flex justify-content-start align-items-center " >
                      <input type="text"  id="brandRef" matInput
                            style="height: 50px;" #brandRef
                            [value]="selection.brandRef"
                            placeholder="{{ 'brandRef' | translate }}" 
                            (keyup)="changeSelection('brandRef',brandRef.value)"  />
                      <span (click)="clearBrandRef()" style="margin-left: 10px">
                        <i class="fa fa-times " aria-hidden="true"  style="font-size: 20px" ></i>
                      </span>
                    </div>

                    <div class="col-1 d-flex justify-content-end align-items-center  " >
                      <button mat-raised-button [disabled]="!okToSearch" (click)="searchList()" style="margin-left: 10px">
                        <i class="fa fa-search" aria-hidden="true" style="font-size: 15px"></i>
                      </button>
                    </div>

                    <div class="col-3 d-flex justify-content-end align-items-center  " >
                      <input type="text"  id="filter-text-box" matInput
                            [disabled]="!OKtoDisplayList"
                            style="height: 50px;"
                            placeholder="{{ 'global filter' | translate }}" 
                            (keyup)="onFilterTextBoxChanged(inputSearch.value)" #inputSearch />
                    </div>
                  </div>
                </div>

                 <div class="col-4 d-flex justify-content-end align-items-center">
                  <button mat-icon-button class="buttonTheme" 
                        (click)="displayStockMovements();"
                        style="margin-left: 10px;margin-right: 10px;">
                    <span class="fas fa-warehouse" style="font-size: 20px"  ></span>
                  </button>
                   <!--<i class="fas fa-cloud-upload-alt"></i>-->
                   <button mat-icon-button class="buttonTheme" matTooltip="{{ 'uploadPartSupplie' | translate }}"
                          style="margin-left: 10px;margin-right: 10px;" (click)="uploadPartSupplie()">
                      <span class="fas fa-cloud-upload-alt" style="font-size: 20px" ></span>
                    </button>
                  <button mat-icon-button class="buttonTheme" matTooltip="{{ 'addPartSupplies' | translate }}"
                         style="margin-left: 10px;margin-right: 10px;" (click)="addPartSupplie()">
                    <span class="fa fa-plus-square" style="font-size: 20px" ></span>
                  </button>
                  <button mat-icon-button class="buttonTheme" matTooltip="{{ 'updateRatesPartSupplie' | translate }}"
                          style="margin-left: 10px;margin-right: 10px;" (click)="updateRatesPartSupplie()">
                    <span class="fas fa-cogs" style="font-size: 20px" ></span>

                  </button>
                  <button mat-icon-button class="buttonTheme"  (click)="refresh()" matTooltip="{{ 'Refresh' | translate }}"
                          style="margin-left: 10px;margin-right: 10px;">
                    <span class="fas fa-redo" style="font-size: 20px" ></span>
                  </button>
                 
                </div>
              </div>
              <div style="height: 85%;">
                <ag-grid-angular *ngIf="OKtoDisplayList"
                  style="width: 100%; height: 100%;" 
                  class="ag-theme-material"
                  [gridOptions]="gridOptions"
                  [groupMultiAutoColumn] = "true"
                  [groupHideOpenParents]="true"
                  [rowData]="dataSource.data"
                  [localeText]="localeText"
                  [pagination]="true"
                  [paginationAutoPageSize]="true"
                  [autoGroupColumnDef]="autoGroupColumnDef"
                  [suppressDragLeaveHidesColumns]="true"
                  [suppressMakeColumnVisibleAfterUnGroup]="true"
                  (selectionChanged)="onSelectionChanged($event)"
                  (gridReady)="onGridReady($event)"
                  (rowClicked)="onRowClicked($event)"
                  [defaultColDef]="defaultColDef"
                  >
                </ag-grid-angular>
              </div>
     
            </div>
          </mat-sidenav-content>
        </mat-sidenav-container>
      </div>
</div>
