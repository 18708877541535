import { Component, OnInit, OnChanges, Input, ViewChild, ViewEncapsulation, Renderer2, SimpleChanges } from '@angular/core';

import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { Router } from '@angular/router';
// import { IMyDpOptions, IMyDateModel } from 'mydatepicker'; // https://github.com/kekeh/mydatepicker

import { UserService } from '../../../services/user.service';
import { TranslateService } from '../../../services/translate.service';

import { EntityCard, EntityModel } from '../../../models/user.model';
import { CommonMethods } from '../../../job/common/tools/commonMethods';
import { environment } from '../../../../environments/environment';
import { SUPPORTED_LANGS, LangSupported } from '../../../i18n/translation';
import { ConfirmValidParentMatcher } from '../../../job/common/validators/customValidators';
import { RessourcesService } from '../../../job/common/services/ressources.service';
import { CountryLabel, StatesLabel } from '../../../models/data.model';

@Component({
  selector: 'mdi-entity-card',
  templateUrl: './entity-card.component.html',
  styleUrls: ['./entity-card.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class EntityCardComponent implements OnInit, OnChanges {

  @Input() statutCall: any;
  @Input() entityToUpdate: EntityModel;

  @ViewChild('buttonSubmit', {static: false}) buttonSubmit: any;
  @ViewChild('refElement', {static: true}) refElement: any;
  @ViewChild('titleCard', {static: false}) titleCard: any;

  emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';

  applicationsList = environment.applicationsList;

  entityCard = new EntityCard();
  confirmValidParentMatcher = new ConfirmValidParentMatcher();

  registrationFailed: boolean;

  entityCtrl: FormControl;
  entitylabelCtrl: FormControl;
  entitydescCtrl: FormControl;
  datafilterCtrl: FormControl;
  applicationsCtrl: FormControl;
  supervisoremailCtrl: FormControl;
  extendedaccessCtrl: FormControl;
  tagsCtrl: FormControl;
  entitylangCtrl: FormControl;
  entitycountryCtrl: FormControl;

  entityForm: FormGroup;
  RegisterTitle = 'Register';
  messageTranslate: string;

  isEntityFound: boolean;
  isUpdateStatut: boolean;
  entitiesList: any;
  entitiesListCache: any;

  supportedLangs: any[];
  // var default= 'UK';
  selectedLangage: LangSupported;

  countryKeyToLoad: string;
  countriesCacheList: CountryLabel[];
  countriesFilteredList: CountryLabel[];
  statesCacheList: StatesLabel[];
  statesFilteredList: StatesLabel[];

  countriesCount: number;

  // upload models
  displayUpload: boolean;
  displayList: boolean;

  constructor(private fb: FormBuilder, private _userService: UserService, private router: Router,
    private _translate: TranslateService,
    private _ressources: RessourcesService,
    private renderer: Renderer2) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    const statutCallValue = changes['statutCall'];
    const userToUpdateValue = changes['entityToUpdate'];

    this.initData();
  }
  initData() {
    // console.log('Testing ngOnInit');
    this.supportedLangs = SUPPORTED_LANGS;
    this.displayUpload = false;
    this.displayList = true;
    if (typeof this.statutCall === 'undefined') {
      this.statutCall = 'createEntity';
    }

    this.entityCtrl = this.fb.control('', [Validators.required, Validators.minLength(3)]);
    this.entitylabelCtrl = this.fb.control('', [Validators.required, Validators.minLength(3)]);
    this.entitydescCtrl = this.fb.control('');
    this.datafilterCtrl = this.fb.control('');
    this.tagsCtrl = this.fb.control('');
    this.applicationsCtrl = this.fb.control('', Validators.required);

    this.extendedaccessCtrl = this.fb.control('');

    this.supervisoremailCtrl = this.fb.control('', [Validators.required, Validators.pattern(`${environment.emailPattern}`)]);

    this.entityForm = this.fb.group({
      entity: this.entityCtrl,
      entitylabel: this.entitylabelCtrl,
      entitydesc: this.entitydescCtrl,
      datafilter: this.datafilterCtrl,
      supervisoremail: this.supervisoremailCtrl,
      extendedaccess: this.extendedaccessCtrl,
      applications: this.applicationsCtrl,
      tags: this.tagsCtrl,
      entitylang: this.entitylangCtrl,
      entitycountry: this.entitycountryCtrl,
    },
      {
        updateOn: 'blur'
      });


    if (this.statutCall === 'createEntity') {
      this.renderer.setStyle(this.refElement.nativeElement, 'width', '100%');
      this.renderer.setStyle(this.refElement.nativeElement, 'margin-top', '0px');
      this.RegisterTitle = 'Creation';
      let entiyId;
      while (true) {
        entiyId = CommonMethods.randomString1(4) + '_' + Math.floor(Math.random() * 10000).toString();
        this.validEntity();
        if (!this.isEntityFound) {
          break;
        }
      }
      this.entityForm.controls.entity.setValue(entiyId);
      // this.renderer.setValue(this.titleCard.nativeElement, 'Creation');
    }
    this.isUpdateStatut = false;
    this.getListEntities();
    this.loadCountries();

    if (this.statutCall === 'updateEntity') {
      this.isUpdateStatut = true;
      this.renderer.setStyle(this.refElement.nativeElement, 'width', '100%');
      this.renderer.setStyle(this.refElement.nativeElement, 'margin-top', '0px');
      this.RegisterTitle = 'Update';
      this.entityForm.controls['entity'].disable();

      this.setValuesToUpdate();
      // this.renderer.setValue(this.titleCard.nativeElement, 'Creation');
    }
    this.isEntityFound = false;
    this.entityForm.controls['entity'].valueChanges.subscribe (
      () => {
        this.validEntity();
      }
    );

  }
  loadCountries() {
    const countriesListTmp = this._ressources.getCountriesList();

    this.countriesCacheList = [];
    for (const countryTmp of countriesListTmp) {
      const labelLang = this._translate.getLabelCountry(this._translate.currentLang.toUpperCase(), countryTmp.commonName);
      this.countriesCacheList.push({
        isoAlpha2: countryTmp.isoAlpha2,
        commonName: labelLang,
      });
    }

    this.countriesCount = this.countriesCacheList.length;
    this.countriesCacheList.sort((obj1, obj2) => {
      return obj1.commonName > obj2.commonName ? 1 : -1;
    });

    this.countriesFilteredList = [...this.countriesCacheList];

  }
  getListEntities() {
    this._userService.getEntities()
    .subscribe(
      data => {
        const listEntities = data.body;
        this.entitiesList = CommonMethods.jsonPropToArray(listEntities, 'entity');
        this.entitiesListCache = this.entitiesList;
      }
    );
  }
  onKeyCountry(value: any) {
    // console.log(value);
    this.countriesFilteredList = this.searchCountry(value);
  }
  searchCountry(value: string) { 
    const filter = value.toLowerCase();
    return this.countriesCacheList.filter(countryCur => countryCur.commonName.toLowerCase().includes(filter));
  }
  onChangeLang($event: any) {
    console.log('onChangeLang : ' + $event);

  }
  onChangeCountry($event: any) {
    console.log('onChangeCountry : ' + $event);

  }
  getErrorMessage(_ctrlName: string) {
    // https://www.c-sharpcorner.com/article/angular-5-reactive-forms-and-validations/
    let messageLib = '';
    switch (_ctrlName) {
      case 'entity': {
        !this.entityForm.controls[_ctrlName].dirty ? messageLib = '' :
          this.entityForm.controls[_ctrlName].hasError('required') ?
            messageLib = this._translate.getTranslate('Entity is required') :
            this.entityForm.controls[_ctrlName].hasError('minlength') ?
              messageLib = this._translate.getTranslate('Your entity should be at 2 characters') : messageLib = '';
        break;
      }
      case 'entityFound': {
        messageLib = this._translate.getTranslate('entityFound');
        break;
      }
      case 'entitylabel': {
        !this.entityForm.controls[_ctrlName].dirty ? messageLib = '' :
          this.entityForm.controls[_ctrlName].hasError('required') ?
            messageLib = this._translate.getTranslate('Entity label is required') :
            this.entityForm.controls[_ctrlName].hasError('minlength') ?
              messageLib = this._translate.getTranslate('Your entity label should be at 3 characters') : messageLib = '';
        break;
      }
      case 'supervisoremail': {
        !this.entityForm.controls[_ctrlName].dirty ? messageLib = '' :
          this.entityForm.controls[_ctrlName].hasError('required') ?
            messageLib = this._translate.getTranslate('Supervisor email is required') :
            this.entityForm.controls[_ctrlName].hasError('pattern') ?
              messageLib = this._translate.getTranslate('This is not a valid email') : messageLib = '';
        break;
      }
      default: {
        break;
      }
    }
    return messageLib;
  }
  doCancel() {
    this.entityForm.reset();
    if (this.statutCall) {
      this._userService.onRegisterValid.emit('cancelRegister');
    } else {
      this.router.navigate(['/']);
    }
  }
  validEntity() {
    const entity = this.entityForm.controls['entity'].value;
    if ((entity) && (entity.length > 3)) {
      if ((this.statutCall === 'createEntity')
       || ((this.statutCall === 'updateEntity')
              && (entity !== this.entityToUpdate.entity))) {
        this.ctrlEntity(entity);
      }
    }
  }
  ctrlEntity(_entity: string): EntityModel {
    this.isEntityFound = false;
    let dataResponse: EntityModel = null;
    this._userService.getEntity(_entity)
    .subscribe(
      data => {
        dataResponse = data.body;
        this.isEntityFound = true;
        if (this.statutCall === 'createEntity') {
          this.entityForm.controls['entity'].setErrors({ 'isEntityFound': true});
          this.registrationFailed = true;
        }
        // console.log('Entity found : ', _entity);
      }, () => {
        // console.log('Entity unfound : ', _entity);
        const list: Array<string> = [_entity];
        this.entitiesList = this.entitiesListCache;
        this.entitiesList.push(_entity);
        this.entityForm.controls.extendedaccess.setValue(list);
        this.entityForm.controls.entitylabel.setValue(_entity);
      }
    );
    return dataResponse;
  }
  register() {
    this.registrationFailed = false;

    this.prepareValidation();
    this._userService.saveEntity(this.entityCard, this.statutCall)
      .subscribe(
        () => {
          this.entityForm.reset();
          if (this.statutCall) {
            this._userService.onRegisterValid.emit('closeRegister');
          } else {
            this.router.navigate(['/']);
          }
        },
        () => {
          this.messageTranslate = this._translate.getTranslate('Validation error');
          this.registrationFailed = true;
        }
      );
  }
  prepareValidation() {
    this.entityCard.entity = this.entityForm.value.entity;
    if (this.statutCall === 'updateEntity') {
      this.entityCard.entity = this.entityToUpdate.entity;
    }
    this.entityCard.entitylabel = this.entityForm.value.entitylabel;
    this.entityCard.entitydesc = this.entityForm.value.entitydesc;

    this.entityCard.datafilter = this.entityForm.value.datafilter;
    this.entityCard.supervisoremail = this.entityForm.value.supervisoremail;

    this.entityCard.extendedaccess = CommonMethods.arrayStringToString(this.entityForm.value.extendedaccess, ',');
    const pattern = new RegExp(this.entityCard.entity);
    if (!pattern.test(this.entityCard.extendedaccess)) {
      this.entityCard.extendedaccess = this.entityCard.entity + ',' + this.entityCard.extendedaccess;
    }
    this.entityCard.master = false;
    this.entityCard.tags = this.entityForm.value.tags;
    this.entityCard.entitycountry = this.entityForm.value.entitycountry;
    const langCur =  this.entityForm.value.entitylang as LangSupported;
    this.entityCard.entitylang = langCur.value;
    const applisCur = '{ "applications" : "' + this.entityForm.value.applications + '"} ';
    this.entityCard.otherDatas = applisCur;
  }

  setValuesToUpdate() {
    this.entityForm.controls.entity.setValue(this.entityToUpdate.entity);
    this.entityForm.controls.entitylabel.setValue(this.entityToUpdate.entitylabel);
    this.entityForm.controls.entitydesc.setValue(this.entityToUpdate.entitydesc);
    this.entityForm.controls.datafilter.setValue(this.entityToUpdate.datafilter);
    this.entityForm.controls.supervisoremail.setValue(this.entityToUpdate.supervisoremail);
    this.entityForm.controls.extendedaccess.setValue(this.entityToUpdate.extendedaccess);
    this.entityForm.controls.entitycountry.setValue(this.entityToUpdate.entitycountry);
    this.entityForm.controls.entitylang.setValue(this.entityToUpdate.entitylang);

    for (const langObj  of this.supportedLangs) {
      if (langObj.value === this.entityToUpdate.entitylang) {
        this.entityForm.controls.entitylang.setValue(langObj);
      }
    }

    const curEntities = this.entityToUpdate.extendedaccess.split(',');
    const bindEntities: any[] = [];
    for (const curEntity of curEntities) {
          bindEntities.push(curEntity);
      }
    this.entityForm.controls.extendedaccess.setValue(bindEntities);
    if ((this.entityToUpdate.extendedaccess === null) || (this.entityToUpdate.extendedaccess.trim().length < 3)) {
      this.entityForm.controls.extendedaccess.setValue(this.entityToUpdate.entity);
    }

    if (this.entityToUpdate.otherDatas) {
      const othersDatas = JSON.parse(this.entityToUpdate.otherDatas);
      const applicationsCur = othersDatas.applications;

      const bindApplications: any[] = [];
      const curApplis  = applicationsCur.split(',');

      for (const appliValue of curApplis) {
          bindApplications.push(appliValue);
        }
      this.entityForm.controls.applications.setValue(bindApplications);
    }
  }
  // Upload modeles

  toggleUploadFiles() {
    
    this.displayUpload = ! this.displayUpload;
    this.displayList = !this.displayUpload;
  }
  toggleDisplayList() {
    this.displayList = ! this.displayList;
    this.displayUpload = !this.displayList;
  }
}
