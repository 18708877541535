<div #refElement class="mat-elevation-z8 container-fluid stdTheme" *ngIf="okToDisplay"
        [ngStyle]="{'margin-top': '10px', width: ((!isSearch && !isAddOne) ? '100%' : '50%'),padding: '10px'}" >
    <div class="flex-row row wkscard" >
        <div class="col-2  d-flex justify-content-start">
            <button mat-raised-button  #buttonSubmit type="reset" (click)="doCancel()">
                <mat-icon>arrow_back</mat-icon>
                {{ 'goback' | translate }}
            </button>
        </div>
        <div class="col-10 d-flex justify-content-center">
            <h5 class="titleTheme" >{{ titleCard | translate }}</h5>
        </div>
    </div>
    <div class="flex-row row wkscard"  style="margin-bottom: 10px">
        <mat-tab-group mat-stretch-tabs="true" dynamicHeight class="mat-tab-fill-height" style="margin: 0px; height: 100%;width: 100%"
                 (selectedIndexChange)="onTabChanged($event)">
            <mat-tab>
                <ng-template mat-tab-label >
                    <div class="flex-row row align-items-center" >
                        <span>{{ 'inputStock' | translate }}</span>
                    </div>
                </ng-template>
                <mat-tab-body >
                    <ng-template matTabContent >
                        <ng-container *ngTemplateOutlet="movementsTemplate; context: {action: 'inputStock'}"></ng-container>
                    </ng-template>
                </mat-tab-body>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label >
                    <div class="flex-row row align-items-center" >
                        <span>{{ 'outputStock' | translate }}</span>
                    </div>
                </ng-template>

                <mat-tab-body >
                    <ng-template matTabContent >
                        <ng-container *ngTemplateOutlet="movementsTemplate; context: {action: 'outputStock'}"></ng-container>
                    </ng-template>
                </mat-tab-body>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label >
                    <div class="flex-row row align-items-center" >
                        <span>{{ 'inventoryStock' | translate }}</span>
                    </div>
                </ng-template>

                <mat-tab-body >
                    <ng-template matTabContent >
                        <ng-container *ngTemplateOutlet="inventoryTemplate; context: {action: 'inventoryStock'}"></ng-container>
                    </ng-template>
                </mat-tab-body>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
<ng-template #inventoryTemplate let-action="action">
    <mat-card>
        <mat-card-actions style="margin-left: 0px;margin-right: 0px;margin-bottom: 0px;overflow: hidden!important">
            <div class="container-fluid d-flex" >
                <div class="flex-row row w-100">
                    <div class="col-10 d-flex justify-content-center align-items-center">
                        <b>{{ action | translate }}</b>
                    </div>
                    <div class="col-2 d-flex justify-content-center align-items-center">
                        <button  mat-raised-button [disabled]="inventoryInProgress" #buttonSubmit type="submit" (click)="launchInventory()" >
                            <span class="fas fa-store-alt" style="font-size: 20px"  ></span>
                            {{ 'launchInventory' | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </mat-card-actions>
        <mat-card-content class="stdTheme" style="width: 100%">
            <div *ngIf="inventoryInProgress">
                <div class="loader-container">
                    <div class="loading-text">{{ 'inventoryInProgress' | translate }}<span class="dots"></span></div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</ng-template>
<ng-template #movementsTemplate let-action="action">
    <mat-card>
        <mat-card-actions style="margin-left: 0px;margin-right: 0px;margin-bottom: 0px;overflow: hidden!important">
            <div class="container-fluid d-flex" >
                <div class="flex-row row w-100">
                    <div class="col-10 d-flex justify-content-center align-items-center">
                        <b>{{ action | translate }}</b>
                    </div>
                    <div class="col-2 d-flex justify-content-center align-items-center">
                        <button  mat-raised-button [disabled]="!okToRegister" #buttonSubmit type="submit" (click)="registerMovements()" >
                            <mat-icon>save</mat-icon>
                            {{ 'save' | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </mat-card-actions>
        <mat-card-content class="stdTheme" style="width: 100%">
            <form [formGroup]="movementForm"  style="margin: 0px; height: 100%; width: 100%;" *ngIf="!displayCreateForm" >
                <div class="flex-row row w-100">
                    <div class="col-4 d-flex justify-content-start align-items-center" style="padding-top: 15px;">
                        <mat-label style="margin-right: 10px">{{ 'stockWarehouse' | translate }}</mat-label>
                        <mat-select id="warehouse" #warehouse panelClass="mdiselectcontent" ngDefaultControl
                                [(value)]="warehouseCur" 
                                (selectionChange)="warehouseChoice(warehouse.value)">
                            <mat-option class="selectOptionsTheme" *ngFor="let warehouseCur of wareHousesList"
                                [value]="warehouseCur.warehouseId"> {{warehouseCur.warehouseLabel}}
                            </mat-option>
                        </mat-select>
                    </div>
                    <div class="col-4 d-flex justify-content-start align-items-center">
                        <mat-form-field class="mdiinput form-group stdTheme" appearance="standard">
                            <mat-label>{{ 'movementLabel' | translate }}</mat-label>
                            <input matInput  class="form-control inputTheme" formControlName="movementLabel" required
                                #movementLabel id="movementLabel"
                                [value]="movementSession.movementLabel"
                                (input)="changeMovementLabel(movementLabel.value)">
                        </mat-form-field>
                    </div>
                    <div class="col-3">
                        
                    </div>
                    <div class="col-1 d-flex justify-content-start align-items-center">
                        <button mat-icon-button matSuffix aria-label="add" 
                                (click)="addPart()">
                            <mat-icon>add</mat-icon>
                        </button>
                    </div>
                </div>
                <div class="flex-row row w-100 parts-list">
                    <fieldset class="parts-list-border" style="margin-bottom: 10px">
                        <legend class="parts-list-border" align="center" ><b>{{ 'parts-list-title' | translate }}</b></legend>
                        <div #partsListFg  formArrayName="partsListFg" style="width: 100%;" >
                            <div *ngFor="let partItem of partsListFa.controls; let idxRow=index" style="width: 100%;">
                                <div [formGroupName]="idxRow" style="width: 100%;">
                                    <div class="flex-row row rowPart" >
                                        <!--
                                        <div class="col-1 d-flex justify-content-start align-items-center">
                                            <button mat-icon-button matSuffix aria-label="remove" style="margin-top: 10px;"
                                                    (click)="removePart(idxRow)">
                                                    <span class="fa fa-trash"  style="font-size: 15px" aria-hidden="true"></span>
                                            </button>
                                        </div>
                                        -->
                                        <div class="col-2 d-flex justify-content-start align-items-center">
                                            <button mat-icon-button matSuffix aria-label="remove" style="width: 20%; padding-top: 15px;"
                                                    (click)="removePart(idxRow)">
                                                    <span class="fa fa-trash"  style="font-size: 15px" aria-hidden="true"></span>
                                            </button>
                                            <mat-form-field class="mdiinput form-group stdTheme" appearance="standard" style="width: 80%">
                                                <mat-label>{{ 'brandRef' | translate }}</mat-label>
                                                <input matInput  class="form-control inputTheme" formControlName="brandRef" 
                                                            #brandRef_{{idxRow}} id="brandRef_{{idxRow}}"
                                                            placeholder="{{ partsRules.partsRefMask }}" 
                                                            (keydown.tab)="handleTab($event, 'brandRef_' + idxRow, idxRow)"
                                                            (keydown.enter)="handleEnter($event, 'brandRef_' + idxRow, idxRow)" 
                                                            (input)="onInputChangeBrandRef('brandRef_' + idxRow, idxRow)"
                                                            required>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-3 d-flex justify-content-start align-items-center">
                                            <mat-form-field class="mdiinput form-group stdTheme" appearance="standard">
                                                <mat-label>{{ 'paramLabel' | translate }}</mat-label>
                                                <input matInput  class="form-control inputTheme" formControlName="paramLabel"
                                                            #paramLabel_{{idxRow}} id="paramLabel_{{idxRow}}"
                                                            placeholder="{{ partsRules.partsLabelMask }}" 
                                                            [value]="movementSession.partsList[idxRow].paramLabel"
                                                            (input)="onInputChangeParamLabel('paramLabel_' + idxRow, idxRow)"
                                                            (keydown.enter)="handleEnter($event)" required>
                                            </mat-form-field>
                                        </div>
                                        <!-- paramUnit -->
                                        <div class="col-1 d-flex justify-content-start align-items-center">
                                            <mat-form-field class="mdiinput form-group stdTheme" appearance="standard">
                                                <mat-label>{{ 'paramUnit' | translate }}</mat-label>
                                                <mat-select id="paramUnit" placeholder="{{ 'Choose paramUnit' | translate }}" formControlName="paramUnit" 
                                                        panelClass="mdiselectcontent"   ngDefaultControl required 
                                                        >
                                                    <mat-option class="selectOptionsTheme" *ngFor="let unitQuantity of unitsQuantity" 
                                                        [value]="unitQuantity.value"  >{{unitQuantity.label }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-1 d-flex justify-content-end align-items-center">
                                            <!--[value]="movementSession.partsList[idxRow].movementQty | formatNumber : ['quantity',numberFormat.locale,numberFormat.minDecimal, numberFormat.maxDecimal]"/>-->
                                            <mat-form-field class="mdiinput form-group stdTheme" appearance="standard">
                                                <mat-label>{{ 'movementQty' | translate }}</mat-label>
                                                <input matInput  class="form-control inputTheme" 
                                                    formControlName="movementQty" required
                                                    #movementQty_{{idxRow}} id="movementQty_{{idxRow}}" style="text-align: right"
                                                    (keypress)="numberAndDotOnly($event)" 
                                                    (keydown.enter)="handleEnter($event)"
                                                    (focus)="unformatNumericField('movementQty_' + idxRow)"
                                                    (focusout)="formatNumericField('movementQty_' + idxRow); setMovmentQty('movementQty_' + idxRow, idxRow)"
                                                     />
                                            </mat-form-field>
                                        </div>
                                        <div class="col-2 d-flex justify-content-end align-items-center">
                                            <mat-form-field class="mdiinput form-group stdTheme" appearance="standard" [hidden]="hiddenPrices">
                                                <mat-label>{{ 'paramRate' | translate }}</mat-label>
                                                <input matInput  class="form-control inputTheme" 
                                                    formControlName="paramRate" required
                                                    #paramRate_{{idxRow}} id="paramRate_{{idxRow}}" style="text-align: right"
                                                    [ngClass]="{stdTheme: !themeClass[idxRow].buyPriceLess, 
                                                                redTheme: themeClass[idxRow].buyPriceLess}"
                                                    (keypress)="numberAndDotOnly($event)" 
                                                    (keydown.enter)="handleEnter($event)"
                                                    (focus)="unformatNumericField('paramRate_' + idxRow)"
                                                    (focusout)="formatCurrencyField('paramRate_' + idxRow); setParamRate('paramRate_' + idxRow, idxRow)"
                                                    />
                                            </mat-form-field>
                                        </div>
                                        <div class="col-2 d-flex justify-content-end align-items-center">
                                            <mat-form-field class="mdiinput form-group stdTheme" appearance="standard" [hidden]="hiddenPrices">
                                                <mat-label>{{ 'sellingPrice' | translate }}</mat-label>
                                                <input matInput  class="form-control inputTheme" 
                                                    formControlName="sellingPrice" required
                                                    #sellingPrice_{{idxRow}} id="sellingPrice_{{idxRow}}" style="text-align: right"
                                                    [ngClass]="{stdTheme: !themeClass[idxRow].sellingPriceLess && !themeClass[idxRow].sellingPriceToLow, 
                                                            amberTheme: themeClass[idxRow].sellingPriceToLow,
                                                            redTheme: themeClass[idxRow].sellingPriceLess}"
                                                    (keypress)="numberAndDotOnly($event)" 
                                                    (keydown.enter)="handleEnter($event)"
                                                    (focus)="unformatNumericField('sellingPrice_' + idxRow)"
                                                    (focusout)="formatCurrencyField('sellingPrice_' + idxRow); setSellingPrice('sellingPrice_' + idxRow, idxRow)"
                                                    />
                                            </mat-form-field>
                                        </div>
                                        <div class="col-1 d-flex justify-content-end align-items-center">
                                            <mat-form-field class="mdiinput form-group stdTheme" appearance="standard" [hidden]="hiddenPrices">
                                                <mat-label>{{ 'stockQty' | translate }}</mat-label>
                                                <input matInput  class="form-control inputTheme" formControlName="stockQty" 
                                                    #stockQty_{{idxRow}} id="stockQty_{{idxRow}}" style="text-align: right"
                                                    readonly
                                                    />
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>
            </form>
            <div *ngIf="displayCreateForm">
                <ng-container  [ngTemplateOutlet]="createFormTemplate"  ></ng-container>
            </div>
        </mat-card-content>
    </mat-card>
</ng-template>
<ng-template let-value="valueArg" #createFormTemplate>
    <fieldset class="parts-list-border" style="margin-bottom: 10px">
        <legend class="parts-list-border" align="center" ><b>{{ 'new-part-title' | translate }}</b></legend>
        <div class="flex-row row"  style="margin: 0px; margin-top: 15px;">
            <div class="col-2" style="padding-left: 0px;padding-right: 0px;display: flex;justify-content: start;">
                <mat-form-field class="mdiinput form-group stdTheme" appearance="standard">
                    <mat-label>{{ 'brandRef' | translate }}</mat-label>
                    <input class="form-control inputTheme" name="brandRef" #brandRef  id="brandRef"
                        placeholder="{{ partsRules.partsRefMask }}" 
                        type="text"  matInput 
                        (change)="createPartForm('brandRef',brandRef.value)" 
                        (input)="onInputChangeBrandRef('brandRef')"
                        [value]="createValues.brandRef"/> 
                </mat-form-field>
            </div>
            <div class="col-1" style="padding-left: 0px;padding-right: 0px;display: flex;justify-content: start;">
            </div>
            <div class="col-2 " style="padding-left: 0px;padding-right: 0px;display: flex;justify-content: start;">
                <mat-form-field class="mdiinput form-group stdTheme" appearance="standard">
                    <mat-label>{{ 'brandName' | translate }}</mat-label>
                    <mat-select id="brandName" placeholder="{{ 'Choose brand' | translate }}" 
                                panelClass="mdiselectcontent" #brandName required
                                (selectionChange)="createPartForm('brandName',brandName.value)"
                                [value]="createValues.brandName"
                                ngDefaultControl >
                        <mat-option class="selectOptionsTheme" *ngFor="let brandCur of brandFilteredList" 
                            [value]="brandCur.brandName"  >{{brandCur.brandName}}
                        </mat-option>
                    </mat-select>                         
                </mat-form-field>
            </div>
            <div class="col-1" style="padding-left: 0px;padding-right: 0px;display: flex;justify-content: start;">
            </div>
        <!-- label -->
            <div class="col-2" style="padding-left: 0px;padding-right: 0px;display: flex;justify-content: start;">
                <mat-form-field class="mdiinput form-group stdTheme" appearance="standard">
                    <mat-label>{{ 'brandLabel' | translate }}</mat-label>
                    <input class="form-control inputTheme" name="brandLabel" #brandLabel id="brandLabel" required
                            placeholder="{{ partsRules.partsLabelMask }}"
                            (input)="onInputChangeParamLabel('brandLabel')"
                        type="text"  matInput (change)="createPartForm('brandLabel',brandLabel.value)" /> 
                </mat-form-field>
            </div>
            <div class="col-1" style="padding-left: 0px;padding-right: 0px;display: flex;justify-content: start;">
            </div>
        <!-- unit -->
            <div class="col-2" style="padding-left: 0px;padding-right: 0px;display: flex;justify-content: start;">
                <mat-form-field class="mdiinput form-group stdTheme" appearance="standard">
                    <mat-label>{{ 'paramUnit' | translate }}</mat-label>
                    <mat-select id="paramUnit" #paramUnit placeholder="{{ 'Choose paramUnit' | translate }}" 
                            (selectionChange)="createPartForm('paramUnit',paramUnit.value)"
                            panelClass="mdiselectcontent"   ngDefaultControl required >
                        <mat-option class="selectOptionsTheme" *ngFor="let unitQuantity of unitsQuantity" 
                            [value]="unitQuantity.value"  >{{unitQuantity.label }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="flex-row row"  style="margin: 0px; margin-top: 20px">
            <div class="col-2  d-flex justify-content-start">
                <button mat-raised-button  #buttonSubmit type="reset" (click)="cancelAddProduct()">
                    <mat-icon>arrow_back</mat-icon>
                    {{ 'goback' | translate }}
                </button>
            </div>
            <div class="col-8  d-flex justify-content-start">

            </div>
            <div class="col-2 " style="padding-left: 0px;padding-right: 0px;display: flex;justify-content: end;">
                <button  [disabled]="!okToCreatePart" class="mat-raised-button buttonTheme" 
                    style="margin-bottom: 10px;" (click)="addProduct()" >
                    {{'submit' | translate}}
                </button>
            </div>
        </div>
    </fieldset>
</ng-template>
